import React from "react";
import Page from "./page";
import {connect} from "react-redux";

function CalendarioEncabezado(props) {
   
    return <Page 
            hoy={props.hoy}
            anteriorHandler={props.anteriorHandler}
            siguienteHandler={props.siguienteHandler}
            hoyHandler={props.hoyHandler}
            modoVista={props.modoVista}
            setModoVista={props.setModoVista}
            />                               
   
}




/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({   
})
/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => ({    
})

export default connect(mapStateToProps,mapDispatchToProps)(CalendarioEncabezado);

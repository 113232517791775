import {CONSULTAR_ESQUEMA_TIPO_CITAES_BY_ESQUEMA,CONSULTAR_ESQUEMA_TIPO_CITAES,AGREGAR_ESQUEMA_TIPO_CITA,ACTUALIZAR_ESQUEMA_TIPO_CITA, ELIMINAR_ESQUEMA_TIPO_CITA} from "../constants/EsquemaTipoCita-action-type";


/* ESQUEMA_TIPO_CITAES (HOSPITAL CLINICA) */
export const obtenerEsquemaTipoCitaListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_ESQUEMA_TIPO_CITAES,
        dataType:dataType,
        loading: loading
    }
}
//(18Jul21) Se agrega un nuevo action para consultar tipo de cita por id del esquema de trabajo
export const obtenerEsquemaTipoCitaByEsquemaListWSAction = (dataType,payload)=> {
    return {
        type:CONSULTAR_ESQUEMA_TIPO_CITAES_BY_ESQUEMA,
        dataType:dataType,
        loading: false,
        payload: payload
    }
}
export const agregarEsquemaTipoCitaWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_ESQUEMA_TIPO_CITA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarEsquemaTipoCitaWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_ESQUEMA_TIPO_CITA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarEsquemaTipoCitaWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_ESQUEMA_TIPO_CITA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

import React,{Component} from "react";
import Page from "./page";
import {  MEDICO } from "../../../redux/constants/action-type";
import {connect} from "react-redux";
import {obtenerMedicoListWSAction,agregarMedicoWSAction,actualizarMedicoWSAction,eliminarMedicoWSAction} from "../../../redux/actions/MedicoActions";


class MedicoFormSelect extends Component{
    render(){    
        return<Page medicos={this.props.medicos} MEDICO_id={this.props.MEDICO_id} medicoValue={this.props.medicoValue} 
                    handleChange={this.props.handleChange} 
                    errors={this.props.errors}
                    touched={this.props.touched}/>
    }

    componentDidMount(){            
     //   this.props.obtenerMedicoListWSAction(MEDICO,false); /* Invoca por primara vez a productos (default) */
    }
}

const mapStateToProps=(state)=>({
    medicos:state.MedicoReducer[MEDICO],
})


const mapDispatchToProps = (dispatch, ownProps) => ({
     //  obtenerMedicoListWSAction: (type,loading) => dispatch(obtenerMedicoListWSAction(type,loading)),
})


export default connect(mapStateToProps,mapDispatchToProps)(MedicoFormSelect);
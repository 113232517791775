import React,{Component, useState} from "react";
import Page from "./page";
import {connect} from "react-redux";
import moment from "moment";
import { CAT_TIPO_CITA, COLOR } from "../../../redux/constants/action-type";

function EventoPaper(props) {
    
    let colorItem=[];
    if(props.catTipoCita!==undefined){
        colorItem=props.colores.filter(i => i.id === props.catTipoCita.COLOR_id)[0];
    }

    let codColor="";
    if(colorItem!==undefined){
        codColor=colorItem.color;
    }

    let citaHoraInicio=moment(props.cita.fecha+" "+props.cita.horaInicio,"YYYY-MM-DD HH:mm").clone();
    let citaHoraFin=moment(props.cita.fecha+" "+props.cita.horaFin,"YYYY-MM-DD HH:mm").clone();
    
    let diff= citaHoraInicio.diff(props.horaItem, 'minutes');

    let top=(diff*48)/60;

    let duracion=citaHoraFin.diff(citaHoraInicio, 'minutes');

    let height=(duracion*48)/60;

    return <Page 
                index={props.index}
                top={top}
                height={height}
                codColor={codColor} 
                catTipoCita={props.catTipoCita}                          
            />                        
          
}




/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state,ownProps)=>({ 
    catTipoCita:state.CatTipoCitaReducer[CAT_TIPO_CITA].filter(p => p.id === ownProps.cita.CAT_TIPO_CITA_id)[0],
    colores:state.ColorReducer[COLOR],
})
/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(mapStateToProps,mapDispatchToProps)(EventoPaper);

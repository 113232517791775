import * as React from 'react';
import { alpha, Avatar, Box, Collapse, Fade, Grow, makeStyles, Paper, Slide, Snackbar, Table, TableBody, TableCell, TableContainer,TableFooter,TableHead,TablePagination,TableRow, withStyles, Zoom, } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import TablaContentCell from '../TablaContentCell';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {getFormatoFecha} from "../../../utils/moment"
import {ESTATUS_EGRESO} from "../../../utils/constantes"
import ProcedimientoChipFilter from '../ProcedimientoChipFilter';
import FilterListIcon from '@material-ui/icons/FilterList';
import logoBS from '../../../logo/logo.ico'
import logoInstitucion from '../../../logo/gastroUsePlus.jpg'
import DisplayDate from '../Cita/DisplayDate';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import ReactHowler from 'react-howler';
import Sound1 from "../../../audio/1.mp3"
import Sound3 from "../../../audio/3.mp3"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
  snackbarStyleViaContentProps: {
    fontSize:'3.0rem'

  },
  snackbarStyleViaNestedContent: {
    color: "black",
    fontSize:'1.7rem'

  },
}));

const TableHeadMi = withStyles(theme => ({
  root: {
    fontSize:'1.7rem'

  }
}))(TableHead);

const TableHeaderCell = withStyles(theme => ({
  root: {
    color: 'white',
    fontSize:'1.5rem'
  }
}))(TableCell);


export default function Page(props) {

  const classes = useStyles();


  const[filtro,setFiltro]=React.useState("hoy");
  const[procedimientos,setProcedimientos]=React.useState([]);

  const[okSala,setOkSala]=React.useState([]);
  const[playListoEnSala,setPlayListoEnSala]=React.useState(false);

  const[enSala,setEnSala]=React.useState([]);



  const [selected, setSelected] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [open, setOpen] = React.useState(false);
  const [mensaje, setMensaje] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handlePlayListoEnSala = () => {
    setPlayListoEnSala(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, newPage) => {
    if(event.target.value!=undefined){
      setRowsPerPage(event.target.value);
    }
    
 
  };

  if(procedimientos.length <1){
    console.log("procedimientos(catTipoCita):"+JSON.stringify(props.catTipoCita))
    if(props.catTipoCita.length>1){
      setProcedimientos(props.catTipoCita)
    }
  }

  const handleFiltro=(event,nuevoFiltro)=>{
    setFiltro(nuevoFiltro);
  };

  let citas=[];

  if(filtro==="hoy"){
    citas=props.citas.filter(item=>item.fecha===getFormatoFecha(new Date()));
  }else{
    citas=props.citas;
  }

  citas=citas.filter(item=>item.CAT_ESTADO_CITA_id!==ESTATUS_EGRESO);
  citas=citas.filter(item=>procedimientos.map(proc=>proc.id).includes(item.CAT_TIPO_CITA_id));

  return (<span>
    <TableContainer component={Paper} >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHeadMi>
          <TableRow style={{backgroundColor:"whitesmoke"}}>
          <TableHeaderCell align="justify" colSpan={8}> 
          <Grid container spacing={3} align="center" alignItems="center" style={{display:"flex", justifyContent:"space-between", backgroundColor:"transparent",alignItems:"center"}}>
            <Grid item xs>
              <Box display="flex" justifyContent="flex-start" m={1} p={2} style={{ backgroundColor:"transparent",alignItems:"center"}}>                
                    <Chip   
                        avatar={<Avatar alt="Bildsoft"  src={logoInstitucion}  style={{width: "7rem",height: "7rem"}}/>}
                        label=""
                        variant="default"
                        style={{backgroundColor:"transparent",marginTop:"0.6rem",marginBottom:"0.6rem"}}
                        align="center"
                    />                                                                      
                    <Typography variant="h4" style={{ textTransform:"capitalize",color:"#060a47"}} >
                     GASTRO EUS+ ®
                    </Typography>                                      
              </Box>
            </Grid>
            <Grid item xs>
              <Box display="flex" justifyContent="center" m={0} p={0} >
                <Box p={0} >
                 <Typography variant="h4" style={{ textTransform:"uppercase",color:"#0066b8",fontWeight:"bold"}}>
                    PROGRAMACIÓN DIARIA
                 </Typography>  
                </Box>               
              </Box>
            </Grid>
            <Grid item xs>
            <Box display="flex" justifyContent="flex-end" m={0} p={0}>
              <Box p={0} >
                <DisplayDate />
              </Box>
            </Box>
            </Grid>
          </Grid>
          </TableHeaderCell>
          </TableRow>          
          <TableRow style={{ backgroundColor:alpha("#0066b8",0.8)}}>
            <TableHeaderCell align="center"></TableHeaderCell>
            <TableHeaderCell align="left" colSpan={2} style={{textTransform:"uppercase"}}>Paciente</TableHeaderCell>
            <TableHeaderCell align="center" style={{textTransform:"uppercase"}}>Horario</TableHeaderCell>
            <TableHeaderCell align="center" style={{textTransform:"uppercase"}}>Procedimiento</TableHeaderCell>
            <TableHeaderCell align="center" style={{textTransform:"uppercase"}}>Médico</TableHeaderCell>
            <TableHeaderCell align="center" style={{textTransform:"uppercase"}}>Sala</TableHeaderCell>
            <TableHeaderCell align="center" style={{textTransform:"uppercase"}}>Estatus</TableHeaderCell>
          </TableRow>
        </TableHeadMi>
        <TableBody>
        {(rowsPerPage > 0
            ? citas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : citas
          ).map((cita,index) =>(            
            <TablaContentCell key={index} cita={cita} index={index+1} rowsPerPage={page * rowsPerPage} 
                              setOpen={setOpen} setMensaje={setMensaje} setEnSala={setEnSala} enSala={enSala}
                              setPlayListoEnSala={setPlayListoEnSala} okSala={okSala} setOkSala={setOkSala}/>
            ))}
    
        </TableBody>
        <TableFooter style={{backgroundColor:"whitesmoke"}}>
          <TableRow style={{backgroundColor:"whitesmoke"}}>
              <TableHeaderCell align="justify" colSpan={8}  style={{border:"0px",padding:"0px"}} >
              <Collapse in={selected}>
                  <Box m={2}>
                    <ProcedimientoChipFilter
                      procedimientos={procedimientos}
                      setProcedimientos={setProcedimientos}
                    />
                  </Box>
              </Collapse>      
              </TableHeaderCell>
          </TableRow>
          <TableRow>
          <TablePagination       
                colSpan={4}         
                rowsPerPageOptions={[4, 5,6,8,10, { label: 'Todos', value: -1 }]}
                count={citas.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'registros por página' },
                  native: true,
                }}
                labelRowsPerPage='Registros por página'
                onPageChange={handleChangePage}  
                onRowsPerPageChange={handleChangeRowsPerPage}
      
              />
             <TableHeaderCell align="left" colSpan={3} >
              <ToggleButton
                  value="check"
                  selected={selected}
                  onChange={() => {
                    setSelected(!selected);
                  }}
                  size="small"
                >
                  <FilterListIcon />
              </ToggleButton>

              <ToggleButtonGroup
                  value={filtro}
                  exclusive
                  onChange={handleFiltro}
                  style={{backgroundColor:"whitesmoke"}}
                  >
                <ToggleButton value="hoy" size="small">Hoy</ToggleButton>
                <ToggleButton value="todos" size="small">Todos</ToggleButton>
              </ToggleButtonGroup>
            </TableHeaderCell>
            <TableHeaderCell align="right" colSpan={1} style={{alignItems:"center"}}>                          
            </TableHeaderCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>

    <Snackbar
        autoHideDuration={3500} 
        onClose={handleClose}
      
        open={open}
        ContentProps={{
          "aria-describedby": "message-id",
          className: classes.snackbarStyleViaContentProps
        }}
        message={
          <span id="message-id">
            <div>{mensaje}</div>
          </span>
        }
      />    

     <ReactHowler
        src={Sound1}
        playing={open}        
      />
      <Snackbar
        autoHideDuration={3500} 
        onClose={handlePlayListoEnSala}
      
        open={playListoEnSala}
        
        ContentProps={{
          "aria-describedby": "message-id",
          className: classes.snackbarStyleViaContentProps
        }}
        message={
          <span id="message-id">
            <div>{mensaje}</div>
          </span>
        }
        />
      <ReactHowler
        src={Sound3}
        playing={playListoEnSala}        
      />

    </span>
  );
}
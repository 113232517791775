import * as React from 'react';
import SliderItem from '../SliderItem';
import { Box, Container } from '@material-ui/core';
import { Translate } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import SlideButton from '../SliderButton';
import SalasItem from '../SalasItem';
import { useState } from 'react';

const useStyles = makeStyles({

  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",

  },
  row: {
    display:"flex",
    position: "relative",
    backgroundColor: "yellow",
    padding: "0 55px",

  },
 
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  tr:{
    background: "#f1f1f1",
    '&:hover': {
      background: "#f00",
    }
  },

});


export default function Page(props) {
  const classes = useStyles();
  const [citaActual,setCitaActual]=useState({});

  return ( 
       <div className={classes.root} >
         <SalasItem cita={props.cita}/>
       </div>                
  ) 
  
}
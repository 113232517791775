import {connect} from "react-redux";
import React,{Component} from "react";
import Page from "./page";


class FechaDeBusquedaTextField extends Component{

    render(){
        return <Page 
                    handleBlur={this.props.handleBlur}
                    fechaBusqueda={this.props.fechaBusqueda}                  
                    setFechaBusqueda={this.props.setFechaBusqueda}
                    label={this.props.label}
                />
    }

}

const mapStateToProps=(state)=>({

})

const mapDispatchToProps=(dispatch)=>({

})

export default connect(mapStateToProps,mapDispatchToProps)(FechaDeBusquedaTextField);
import React,{Component, useState} from "react";
import Page from "./page";
import {connect} from "react-redux";


function DiaGrid(props) {

    const totalHoras=24;
    const horasArray=[...Array(totalHoras)].map(()=>props.diaInicial.add(1,"hour").clone().subtract(1,"hour"));
    const horasDiaArray=[...Array(totalHoras)];


    return <Page 
            horasArray={horasArray}
            hoy={props.hoy}
            diaInicialDia={props.diaInicialDia}
            horasDiaArray={horasDiaArray}
            />                        
       
    
}




/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
})
/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(mapStateToProps,mapDispatchToProps)(DiaGrid);

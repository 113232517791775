import React from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {withRouter} from "react-router-dom";
import Divider from '@material-ui/core/Divider';
import ConfirmacionDialog from "./ConfirmacionDialog";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

const Page=(props)=>{
		
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	function goBack(){
		props.history.goBack();
	}

	let appBar="";
	let actions="";

	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={goBack} aria-label="close">
						    <ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
						</IconButton>
						<Typography variant="h6" style={{ flexGrow: 1}} >
							{<Typography type="Roboto" style={{flexGrow:"1",color: '#000000', fontSize:'1.2rem',fontWeight:'normal',textTransform: "capitalize" }}>Nueva Tecnico</Typography>}
						</Typography>
                        <IconButton onClick={props.abrirConfirmacion}>
                            <DeleteOutlineIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}/>
                        </IconButton>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
            			<Button variant="contained"  edge="start" color="default" onClick= {props.abrirConfirmacion}>Eliminar</Button>
						<Button variant="contained"  edge="start" color="default" onClick= {props.cerrarModalEditar}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
	}

    return (<form onSubmit={props.handleSubmit}> 
                <Dialog
                    open={props.modalEditar}
                    onClose={props.cerrarModalEditar}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
                    fullScreen={fullScreen}
                >


                        <DialogTitle id="form-dialog-title">Actualice los datos del registro.</DialogTitle>

						{appBar}


							<DialogContent>
							<TextField
								error={this.props.touched.puesto&&this.props.errors.puesto? true:false}
								helperText={this.props.touched.puesto&&this.props.errors.puesto?this.props.errors.puesto:""}
								size="medium"
								margin="normal"
								id="puesto"
								label="puesto:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.puesto}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.nombres&&this.props.errors.nombres? true:false}
								helperText={this.props.touched.nombres&&this.props.errors.nombres?this.props.errors.nombres:""}
								size="medium"
								margin="normal"
								id="nombres"
								label="nombres:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.nombres}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.apellidoPaterno&&this.props.errors.apellidoPaterno? true:false}
								helperText={this.props.touched.apellidoPaterno&&this.props.errors.apellidoPaterno?this.props.errors.apellidoPaterno:""}
								size="medium"
								margin="normal"
								id="apellidoPaterno"
								label="apellidoPaterno:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.apellidoPaterno}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.apellidoMaterno&&this.props.errors.apellidoMaterno? true:false}
								helperText={this.props.touched.apellidoMaterno&&this.props.errors.apellidoMaterno?this.props.errors.apellidoMaterno:""}
								size="medium"
								margin="normal"
								id="apellidoMaterno"
								label="apellidoMaterno:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.apellidoMaterno}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.cedulaProfesional&&this.props.errors.cedulaProfesional? true:false}
								helperText={this.props.touched.cedulaProfesional&&this.props.errors.cedulaProfesional?this.props.errors.cedulaProfesional:""}
								size="medium"
								margin="normal"
								id="cedulaProfesional"
								label="cedulaProfesional:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.cedulaProfesional}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.correo&&this.props.errors.correo? true:false}
								helperText={this.props.touched.correo&&this.props.errors.correo?this.props.errors.correo:""}
								size="medium"
								margin="normal"
								id="correo"
								label="correo:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.correo}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.telefono&&this.props.errors.telefono? true:false}
								helperText={this.props.touched.telefono&&this.props.errors.telefono?this.props.errors.telefono:""}
								size="medium"
								margin="normal"
								id="telefono"
								label="telefono:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.telefono}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.celular&&this.props.errors.celular? true:false}
								helperText={this.props.touched.celular&&this.props.errors.celular?this.props.errors.celular:""}
								size="medium"
								margin="normal"
								id="celular"
								label="celular:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.celular}
								onBlur={this.props.handleBlur}
							/>
                        </DialogContent>                          
					  {actions} 
                    </Dialog>
                    <ConfirmacionDialog modalConfirmacion={props.modalConfirmacion}   
                                        cerrarConfirmacion={props.cerrarConfirmacion}
                                        eliminar={props.eliminar} 
                                        payload={props.payload}/>
                    </form>   
                )
}
export default  withRouter(Page);

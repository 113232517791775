import React,{Component} from "react";
import PacientesListItem from "../UsuarioListItem";
import List from '@material-ui/core/List';

export default class Page extends Component{

  constructor(props) {
    super(props);
  }

    render(){      
      return <List > {
            this.props.usuariosFilter.map(usuario=> 
                      <PacientesListItem usuario={usuario} abrirModalEditar={this.props.abrirModalEditar}/> )
            }</List>
    }                          
}

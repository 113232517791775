import { Document, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { useEffect, useState,memo } from "react";
import {getFechatoLocaleDateString, getHorarioLocaleString} from "../../../utils/date";
import logoInstitucion from '../../../logo/gastroUsePlus.8a182ca4.png'

// Create styles
const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#0066b8'
    },
    section: {
     color: 'white', textAlign: 'center', margin: 30 ,
       display:"table", 
      margin: 10,
      padding: 10,
      width:"100%"
    },
    QRImage:{
        width:'120',
        height:'120',
        borderRightColor:  '#ffffff',
        borderRightWidth: 1,
        padding:"3"
    },
    QRText:{
        display:"table",
        justifyContent:"right",
    },

    view:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        width:"100%"
    },
    viewText:{
        display:"table",
        flexDirection:"row",
        justifyContent:"right",
        width:"20%"
    },
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#ffffff',
    },
    containerTop: {
        flexDirection: 'row',
        borderBottomColor: '#ffffff',
        backgroundColor: '#ffffff',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 200,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    container: {
        flexDirection: 'row',
        borderBottomColor: '#ffffff',
        backgroundColor: '#ffffff',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 480,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },datosCita: {
        width: '80%',
        borderRightColor:  '#ffffff',
        borderRightWidth: 1,
        fontStyle: 'bold'
    },datosCitaTop: {
        width: '100%',
        borderRightColor:  '#ffffff',
        height: 30,
        borderRightWidth: 1,
        fontSize:"bolder",
        color:"navy"

    },datosTop: {
        width: '100%',
        borderRightColor:  '#ffffff',
        borderRightWidth: 1,
    }
    
  });

function QRPDFDocumento(props){

   console.log("dataURL(QRPDFDocumento):"+props.dataURL);

    return (<Document>  
                <Page size="A4" style={styles.page}>
                    <View style={styles.tableContainer}>
                    <View style={styles.containerTop}>
                            <View style={styles.datosTop}>
                                <Image src={logoInstitucion} style={styles.QRImage} />
                                <Text style={{alignContent:"right",padding:"3"}}>ENDOSCOPIA ESPECIALIZADA</Text>
                                <Text style={{alignContent:"right",padding:"3",color:"#0066b8"}}>Salud y calidad a tu alcance.</Text>

                            </View>    
                        </View>
                        <View style={styles.container}>
                            <View style={styles.datosCita}>
                                <Text style={{alignContent:"right",padding:"3",color:"#2196f3"}}>CITA CONFIRMADA</Text>
                                <Text style={{alignContent:"left",padding:"3",fontStyle: 'bold'}}>Paciente: {props.paciente}</Text>
                                <Text style={{alignContent:"left",padding:"3"}}>Médico: {props.medico}</Text>
                                <Text style={{alignContent:"left",padding:"3"}}>Estudio: {props.procedimiento}</Text>
                                <Text style={{alignContent:"left",padding:"3"}}>Fecha: {getFechatoLocaleDateString(props.fecha)}</Text>
                                <Text style={{alignContent:"left",padding:"3"}}>Hora: {getHorarioLocaleString(props.horaCita)}</Text>
                            </View>    
                            <Image allowDangerousPaths src={props.dataURL} style={styles.QRImage} />
                        </View>
                      
                    </View>
                   
            
                </Page>
            </Document>
    )
}
export default memo(QRPDFDocumento);
import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {CITA,RECORDATORIO,CAT_ESTADO_CITA,CAT_TIPO_CITA,PACIENTE,MEDICO,ESQUEMA_TRABAJO,ESQUEMA_TIPO_CITA,MEDICO_USUARIO} from "../../../redux/constants/action-type";
import {actualizarCitaWSAction,eliminarCitaWSAction} from "../../../redux/actions/CitaActions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { ESTATUS_AGENDADA, ESTATUS_ENVIADA, PROFIL_ADMINISTRADOR, PROFIL_MEDICO } from "../../../utils/constantes";



const validationSchema = yup.object({
		MEDICO_id: yup.string().required("Campo requerido"),
		PACIENTE_id: yup.string().required("Campo requerido"),
		CAT_ESTADO_CITA_id: yup.string().required("Campo requerido"),
		CAT_TIPO_CITA_id: yup.string().required("Campo requerido"),
		fecha: yup.string().required("Campo requerido"),
		horaInicio: yup.string().required("Campo requerido"),
		horaFin: yup.string().required("Campo requerido"),
//		RECORDATORIO_id: yup.string().required("Campo requerido")
})

class CitaFormEdit extends Component{
    
    constructor(props) {
        super(props);
        this.state={
                modalConfirmacion:false,
        };
    }
    abrirConfirmacion=()=> {
        this.setState({
            modalConfirmacion:true
        });
    }
    cerrarConfirmacion=()=> {
        this.setState({
            modalConfirmacion:false
        });
    }

    eliminar=(dato)=> {
        this.cerrarConfirmacion();
        this.props.cerrarModalEditar();
        this.props.eliminarCitaWSAction(CITA,dato);
    }

    
    render(){    
    
        let valores=this.props.payload;
        let idEstadosCitaMedico = [ESTATUS_AGENDADA];

        console.log("VALORES:"+JSON.stringify(valores));
       
        let perfilAdministrador=false;
        if(this.props.profil===PROFIL_ADMINISTRADOR){
            perfilAdministrador=true;
        }

        let dataCatEstadoCitaFilter=this.props.catEstadosCita.filter((item)=>{
            if(this.props.profil===PROFIL_ADMINISTRADOR){                                            
                return true;
            }else if(this.props.profil===PROFIL_MEDICO && valores.CAT_ESTADO_CITA_id===ESTATUS_ENVIADA){                                            
                ///*(25oct22) Se quita validacion de autorizacion y el medico puede agendar directaemte solo se bloque para el medico el boton de selecionar horario lib
                //return !idEstadosCitaMedico.includes(item.id);
                return true;
            }else{
                return true;
            }
        });


        
        return <Formik
                    enableReinitialize
                    initialValues={valores}
                    validationSchema={validationSchema}
                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          console.log("(setSubmitting)valores actualizarCitaWSAction:"+JSON.stringify(values));                              
                          resetForm({values:""});
                          setSubmitting(this.props.actualizarCitaWSAction(CITA,values));
                         this.props.history.goBack();
                      }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    setFieldValue
                    }) => < Page setFieldValue={setFieldValue} 
                                 handleSubmit={handleSubmit} 
                                 handleChange={handleChange} 
                                 handleBlur={handleBlur} 
                                 values={values} 
                                 errors={errors} 
                                 touched={touched} 
            					 medico={this.props.medico}  
                                 pacientes={this.props.pacientes}  
                                 catEstadosCita={dataCatEstadoCitaFilter}  
                                 catTipoCita={this.props.catTipoCita}  
                                 recordatorios={this.props.recordatorios}   
                                 modalEditar={this.props.modalEditar} 
                                 tipoSeleccion={this.props.tipoSeleccion} 
                                 cerrarModalEditar={this.props.cerrarModalEditar} 
                                 eliminar={this.eliminar} 
                                 payload={this.props.payload}
                                 actualizarCitaWSAction={this.props.actualizarCitaWSAction}  
                                 modalConfirmacion={this.state.modalConfirmacion}
                                 cerrarConfirmacion={this.cerrarConfirmacion}
                                 abrirConfirmacion={this.abrirConfirmacion} 
                                 esquemaTrabajos={this.props.esquemaTrabajos}
                                 esquemaTipoCita={this.props.esquemaTipoCita}
                                 perfilAdministrador={perfilAdministrador}
                            />}
                </Formik> 
    }



}

const mapStateToProps=(state)=>({
  medico:state.MedicoReducer[MEDICO],
  pacientes:state.PacienteReducer[PACIENTE],
  catEstadosCita:state.CatEstadoCitaReducer[CAT_ESTADO_CITA],
  catTipoCita:state.CatTipoCitaReducer[CAT_TIPO_CITA],
  recordatorios:state.RecordatorioReducer[RECORDATORIO],
  esquemaTrabajos:state.EsquemaTrabajoReducer[ESQUEMA_TRABAJO], 
  esquemaTipoCita:state.EsquemaTipoCitaReducer[ESQUEMA_TIPO_CITA],   
  MedicoUsuario:state.MedicoUsuarioReducer[MEDICO_USUARIO][0], 
  profil:state.autenticacionReducer.profil,     
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        eliminarCitaWSAction: (type,loading) => dispatch(eliminarCitaWSAction(type,loading)),       
        actualizarCitaWSAction: (type,payload) => dispatch(actualizarCitaWSAction(type,payload)),

    }
}


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(CitaFormEdit));


import * as React from 'react';

import TablaBasica from '../TablaBasica';
import SliderContainer from '../SliderContainer';
import SalasContainer from '../SalasContainer';
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import { CAT_TIPO_CITA, CITA, PACIENTE } from '../../../redux/constants/action-type';

const useStyles = makeStyles({

    root: {
        backgroundColor:"#f5f5f5",
    },
    row: {
      display:"flex",
      position: "relative",
      backgroundColor: "yellow",
      padding: "0 55px",
  
    },
   
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    tr:{
      background: "#f1f1f1",
      '&:hover': {
        background: "#f00",
      }
    }
  });
  
export default function Page(props) {
    const classes = useStyles();
    const [citaSala,setCitaSala]=useState({});

   
    useEffect(() => {
        const interval = setInterval(() => {
          console.log('Se recarga citas');          
          props.obtenerCatTipoCitaListWSAction(CAT_TIPO_CITA,false);
          props.obtenerPacienteListWSAction(PACIENTE,false);
          props.obtenerCitaListWSAction(CITA,true); /* Invoca por primara vez a productos (default) */

        }, 9000);
        return () => clearInterval(interval);
      }, []);

//  return <TablaBasica citas={props.citas}/>
    return <div classes={classes.root} style={{backgroundColor:"#ebebeb",padding:"24px 0"}}>
                <SalasContainer cita={citaSala}/>
                <SliderContainer citas={props.citas} setCitaSala={setCitaSala} citaSala={citaSala}/>
            </div>
}
import React,{Component } from "react";
import Page from "./page";
import {connect} from "react-redux";
import {COMIDA,PACIENTE,CAT_TIPO_COMIDA} from "../../../redux/constants/action-type";
import {obtenerCatTipoComidaListWSAction,agregarCatTipoComidaWSAction,actualizarCatTipoComidaWSAction,eliminarCatTipoComidaWSAction} from "../../../redux/actions/CatTipoComidaActions";
import {obtenerPacienteListWSAction,agregarPacienteWSAction,actualizarPacienteWSAction,eliminarPacienteWSAction} from "../../../redux/actions/PacienteActions";
import {obtenerComidaListWSAction,agregarComidaWSAction,actualizarComidaWSAction,eliminarComidaWSAction} from "../../../redux/actions/ComidaActions";


class Comidaes extends Component{

    constructor(props) {
        super(props);
        this.state={
                modal:false,
                modalEditar:false,
                payload: {
					'nombre': '',
					'descripcion': '',
					'notas': '',
					'CAT_TIPO_COMIDA_id': '',
					'PACIENTE_id': ''
                },
                busqueda:""

        };
    }
     
    abrirModal=()=> {
        this.setState({
            modal:true
        });
    }

    cerrarModal=()=> {
        this.setState({
            modal:false
        });
    }

    abrirModalEditar = (value)=> {
        this.setState({
            modalEditar:true,
            payload: value

        });
    };

    cerrarModalEditar=()=> {
        this.setState({
            modalEditar:false
        });
    }

    buscar=(valor)=> {
        this.setState({
            busqueda:valor
        });
    }


    render(){        
        return  <Page comidaes={this.props.comidaes} loading={this.props.loading} 
                    modal={this.state.modal}
                    modalEditar={this.state.modalEditar}
                    payload={this.state.payload}
                       abrirModal={this.abrirModal}
                           cerrarModal={this.cerrarModal}
                                abrirModalEditar={this.abrirModalEditar}
                                    cerrarModalEditar={this.cerrarModalEditar}
                                    tipoSeleccion={this.props.tipoSeleccion}
                                agregarComidaWSAction={this.props.agregarComidaWSAction}
                                actualizarComidaWSAction={this.props.actualizarComidaWSAction}
                                eliminarComidaWSAction={this.props.eliminarComidaWSAction}   
                                buscar={this.buscar} busqueda={this.state.busqueda}/>    
    }

 /* esta es la accion de middleware*/
        componentDidMount(){            
            let valores=this.props.location.valores;            
            if(valores === undefined){
				this.props.obtenerCatTipoComidaListWSAction(CAT_TIPO_COMIDA,false);
				this.props.obtenerPacienteListWSAction(PACIENTE,false);
                this.props.obtenerComidaListWSAction(COMIDA,true); /* Invoca por primara vez a productos (default) */
            }else{
                this.setState(this.props.location.valores);
            }

        }


}

/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
    comidaes:state.ComidaReducer[COMIDA],    
    loading:state.estadosPeticionReducer.loading,
    tipoSeleccion:state.estadosReducer.selectedType,

})

/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        obtenerComidaListWSAction: (type,loading) => dispatch(obtenerComidaListWSAction(type,loading)),
        obtenerCatTipoComidaListWSAction: (type,loading) => dispatch(obtenerCatTipoComidaListWSAction(type,loading)),
        obtenerPacienteListWSAction: (type,loading) => dispatch(obtenerPacienteListWSAction(type,loading)),
        agregarComidaWSAction: (type,payload) => dispatch(agregarComidaWSAction(type,payload)),
        actualizarComidaWSAction: (type,payload) => dispatch(actualizarComidaWSAction(type,payload)),
        eliminarComidaWSAction: (type,payload) => dispatch(eliminarComidaWSAction(type,payload))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Comidaes);



import React,{Component} from "react";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";



export default class Page extends Component{

  constructor(props) {
    super(props);
  }

    render(){     
 
      return   <Fab
      position="fixed"
      variant="extended"
      size="large"
      color="default"
      aria-label="agregar"
      style={{position: 'fixed', bottom: "1.5rem", right: "1rem",boxShadow:"1", backgroundColor:"#FFFFFF"}}
      onClick={this.props.abrirModal}
      component={Link}  to={{
        hash:'id', 
        valores:{
         modal:true
       }
       }} 
    >
       <AddIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
       <Typography type="Roboto" style={{flexGrow:"1",color: '#0288D1', fontSize:'1.2rem',fontWeight:'normal',textTransform: "capitalize" }}>Medición</Typography>
    </Fab>
     
                   
    }                          
}

import React , { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {withRouter} from "react-router-dom";
import Divider from '@material-ui/core/Divider';
import ConfirmacionDialog from "./ConfirmacionDialog";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {PAGINA_WEB} from "../../../redux/constants/action-type";

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import {URL_IMAGES} from "../../../utils/constantes";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ModiloDialog from "./ModuloDialog";
import ModuloPanel from "./ModuloPanel";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

const Page=(props)=>{
		
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const [images,setImage]= useState("");
	const handleClick = (e) => {
		
		var datos ={};
		datos['id']=props.values.id;
		datos['logotipo']=e.target.value;
		datos['file']=e.target.files[0];

		
		console.log("Manejadro FILE: "+e.target.files[0] );				

		console.log("Manejadro datos: "+JSON.stringify(datos) );				
		console.log("Manejadro evento: "+JSON.stringify(props.values) );
		setImage(e.target.files[0].name);

		props.agregarImagenPaginaWebWSAction(PAGINA_WEB,datos);
	  }
	
	function goBack(){
		props.history.goBack();
	}
	

	let appBar="";
	let actions="";

	let logotipo =(props.paginaWeb!=undefined)?props.paginaWeb.logotipo:"";

	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={goBack} aria-label="close">
						    <ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
						</IconButton>
						<Typography variant="h6" style={{ flexGrow: 1}} >
							{<Typography type="Roboto" style={{flexGrow:"1",color: '#000000', fontSize:'1.2rem',fontWeight:'normal',textTransform: "capitalize" }}>Editar Pagina Web</Typography>}
						</Typography>
                        <IconButton onClick={props.abrirConfirmacion}>
                            <DeleteOutlineIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}/>
                        </IconButton>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
            			<Button variant="contained"  edge="start" color="default" onClick= {props.abrirConfirmacion}>Eliminar</Button>
						<Button variant="contained"  edge="start" color="default" onClick= {props.cerrarModalEditar}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
	}

    return (<form onSubmit={props.handleSubmit}> 
                <Dialog
                    open={props.modalEditar}
                    onClose={props.cerrarModalEditar}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
                    fullScreen={fullScreen}
                >


                        <DialogTitle id="form-dialog-title">Actualice los datos del registro.</DialogTitle>

						{appBar}


							<DialogContent>
							<TextField
								error={props.touched.titulo&&props.errors.titulo? true:false}
								helperText={props.touched.titulo&&props.errors.titulo?props.errors.titulo:""}
								size="medium"
								margin="normal"
								id="titulo"
								label="titulo:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.titulo}
								onBlur={props.handleBlur}
							/>
							<TextField
								error={props.touched.descripcion&&props.errors.descripcion? true:false}
								helperText={props.touched.descripcion&&props.errors.descripcion?props.errors.descripcion:""}
								size="medium"
								margin="normal"
								id="descripcion"
								label="descripcion:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.descripcion}
								onBlur={props.handleBlur}
							/>
							
								<GridList cols={2.5}>
									<GridListTile >
										<img src={encodeURI(URL_IMAGES+"images/"+props.values.id+"/"+logotipo)} />

									</GridListTile>
								</GridList>
								{URL_IMAGES+"images/"+props.values.id+"/"+logotipo}
							<TextField 
										size="medium"
										margin="normal"
										id="files"
										name="files"
										label=""
										type="file"
										fullWidth
										variant="outlined"
										onChange={handleClick}

									/>					

							<TextField
								error={props.touched.contacto&&props.errors.contacto? true:false}
								helperText={props.touched.contacto&&props.errors.contacto?props.errors.contacto:""}
								size="medium"
								margin="normal"
								id="contacto"
								label="contacto:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.contacto}
								onBlur={props.handleBlur}
							/>

							<ModiloDialog/>
							<ModuloPanel/>
							

                        </DialogContent>                          
					  {actions} 
                    </Dialog>
                    <ConfirmacionDialog modalConfirmacion={props.modalConfirmacion}   
                                        cerrarConfirmacion={props.cerrarConfirmacion}
                                        eliminar={props.eliminar} 
                                        payload={props.payload}/>
                    </form>   
                )
}
export default  withRouter(Page);

import React,{Component} from "react";
import Documento from "./page";

export default class GlucosaDocument extends Component{
    render(){    
        return <Documento glucosaes={this.props.glucosaes} />
    }

}


import React,{Component} from "react";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import {Link} from "react-router-dom";

export default class Page extends Component{

    render(){
        
        
        function obtenerColor(nombre) {
            if(nombre!=null){
                var size=nombre.length;
                do {
                   if(size>colores.length){
                      size=size-colores.length;
                   } 
                }while (size>colores.length);
  
              return colores[size];
  
            }else{
                return colores[0];
            }
          }

        let colores=["#babdbe","#00bfa5","#8eacbb","#0288D1","#34515e"];
        let p=this.props.institucion;
        
        return  <div>
                <ListItem dense button style={{  paddingBottom:6, paddingTop:6 }} component={Link}  to={{
                  hash:'id', 
                  valores:{
                     modalEditar:true,
                     payload: p
                  }
                  }} >
                    <ListItemAvatar>
						<LocalHospitalIcon style={{ marginRight:".8rem", width: "3.5rem",height: "3.5rem", color:obtenerColor(p.nombre)}} alt={p.nombre} />
                    </ListItemAvatar>

					<ListItemText id={p.id} primary={<Typography type="Roboto" style={{ color: '#000000',fontSize:'1rem',fontWeight:'normal' }}>{p.nombre}</Typography>}
								secondary={<Typography type="Roboto" style={{color: '#6c757d', fontSize:'0.9rem',fontWeight:'normal' }}>{p.descripcion}</Typography>} />


                </ListItem>
                <Divider variant="inset" component="li" />

                </div>
    }
}

import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {GLUCOSA,PACIENTE,CAT_UNIDAD_MEDIDA} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";


const validationSchema = yup.object({
		descripcion: yup.string().required("Campo requerido"),
		valor: yup.string().required("Campo requerido"),
		nota: yup.string().required("Campo requerido"),
		fecha: yup.string().required("Campo requerido"),
		hora: yup.string().required("Campo requerido"),
		CAT_UNIDAD_MEDIDA_id: yup.string().required("Campo requerido"),
		PACIENTE_id: yup.string().required("Campo requerido")
})

class GlucosaFormEdit extends Component{

    constructor(props) {
        super(props);
        this.state={
                modalConfirmacion:false,
        };
    }
    abrirConfirmacion=()=> {
        this.setState({
            modalConfirmacion:true
        });
    }
    cerrarConfirmacion=()=> {
        this.setState({
            modalConfirmacion:false
        });
    }

    eliminar=(dato)=> {
        this.cerrarConfirmacion();
        this.props.cerrarModalEditar();
        this.props.eliminarGlucosaWSAction(GLUCOSA,dato);
    }

    
    render(){    
    
        let valores=this.props.payload;
        let id=this.props.payload.id;
        const { bindSubmitForm } = this.props;

        return <Formik
                    enableReinitialize
                    initialValues={valores}
                    validationSchema={validationSchema}
                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          resetForm({values:""});
                          setSubmitting(this.props.actualizarGlucosaWSAction(GLUCOSA,values));
                         this.props.history.goBack();
                      }}
                >
                {props => < Page {...props}  catUnidadMedida={this.props.catUnidadMedida}  paciente={this.props.paciente}   modalEditar={this.props.modalEditar} tipoSeleccion={this.props.tipoSeleccion} 
                                                cerrarModalEditar={this.props.cerrarModalEditar} 
                                                eliminar={this.eliminar} 
                                                payload={this.props.payload}
                                                actualizarGlucosaWSAction={this.props.actualizarGlucosaWSAction}  
                                                modalConfirmacion={this.state.modalConfirmacion}
                                                cerrarConfirmacion={this.cerrarConfirmacion}
                                                abrirConfirmacion={this.abrirConfirmacion} />}
                </Formik> 
    }



}

const mapStateToProps=(state)=>({
  catUnidadMedida:state.CatUnidadMedidaReducer[CAT_UNIDAD_MEDIDA],
  paciente:state.PacienteReducer[PACIENTE],
})

export default withRouter(connect(mapStateToProps)(GlucosaFormEdit));


import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {PAGINA_WEB,MEDICO_USUARIO} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";


const validationSchema = yup.object({
	//	descripcion: yup.string().required("Campo requerido"),
		titulo: yup.string().required("Campo requerido"),
	//	logotipo: yup.string().required("Campo requerido"),
	//	contacto: yup.string().required("Campo requerido"),
	//	MEDICO_id: yup.string().required("Campo requerido")
})

class PaginaWebForm extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }
    
    goBack(){
        this.props.history.goBack();
    }

    render(){     
        let idMedico="";
        if(this.props.MedicoUsuario!==undefined){
            idMedico=this.props.MedicoUsuario.MEDICO_id;
        }
        console.log("(idMedico)citas "+idMedico);    
        
        return <Formik

                    initialValues={{
                                    'descripcion': '',
                                    'titulo': '',
                                    'logotipo': '',
                                    'contacto': '',
                                    'MEDICO_id': idMedico
                                   }}

                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          setSubmitting(this.props.agregarPaginaWebWSAction(PAGINA_WEB,values));
                          console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          resetForm({values:""});
                          this.goBack();

                    }}
                >
                    {props => < Page {...props}  medico={this.props.medico}   modal={this.props.modal} cerrarModal={this.props.cerrarModal} />}
                </Formik> 
    }
}

const mapStateToProps=(state)=>({
  MedicoUsuario:state.MedicoUsuarioReducer[MEDICO_USUARIO][0],  

})



export default withRouter(connect(mapStateToProps)(PaginaWebForm));

import React,{Component} from "react";
import Page from "./page";

export default class MedicoList extends Component{

    render(){
        let medicosFilter = this.props.medicos.filter((data)=>{
				if(this.props.busqueda == null){
					return data;
				}else  if(data.nombres!==null){
                    if(data.nombres.toLowerCase().includes(this.props.busqueda.toLowerCase())){
                        return data;
                    }
                }else{
                    return data;

                }			
         });   
  
        return< Page medicosFilter={medicosFilter} abrirModalEditar={this.props.abrirModalEditar}/>
    }

}


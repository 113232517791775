import {CONSULTAR_PERIODOS,AGREGAR_PERIODO,ACTUALIZAR_PERIODO, ELIMINAR_PERIODO} from "../constants/Periodo-action-type";


/* PERIODOS (HOSPITAL CLINICA) */
export const obtenerPeriodoListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_PERIODOS,
        dataType:dataType,
        loading: loading
    }
}

export const agregarPeriodoWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_PERIODO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarPeriodoWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_PERIODO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarPeriodoWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_PERIODO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

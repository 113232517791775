import React,{Component, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withRouter} from "react-router-dom";
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';

import CitasDisponibles from "../CitasDisponibles"
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Grid from "@material-ui/core/Grid"

import MedicoFormSelect from "../MedicoFormSelect";

import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';

import NuevoPacienteForm from "../NuevoPacienteForm"
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { CardHeader } from "@material-ui/core";
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { useRef,useEffect } from "react";
import {MASCULINO,FEMENINO} from "../../../utils/constantes";
import { IoPersonCircle } from "react-icons/io5";
import { Badge } from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { CITA } from "../../../redux/constants/action-type";
import {ESTATUS_EN_SALA_DE_ESPERA} from "../../../utils/constantes"

/*
 30mar22 Se tuvo que crear un nuevo componente QRScanField para que no falle el focus(); por alguna razon poner la implementacion en componente dialog no reconoce el input.
*/
  function Page(props) {
	
	let p=props.cita;
	let desde=props.cita.horaInicio.substring(0,5);
	let hasta=props.cita.horaFin.substring(0,5);
	let nombrePaciente="";
	let nombreMedico="";
	let tipoCita="";
	let edad="";
	let colorFace="#90a4ae";


	if(props.paciente!=undefined){
		nombrePaciente=props.paciente.nombres+" "+props.paciente.apellidoPaterno+" "+props.paciente.apellidoMaterno;
	  }
	  if(props.medico!==undefined){
		nombreMedico=<Typography color="primary" style={{ textTransform:"capitalize"}}>
						{props.medico.titulo+" "+props.medico.nombres+" "+props.medico.apellidoPaterno}                                                                   
					 </Typography>											   					  
	  }

	  if(props.catTipoCita!==undefined){
		tipoCita=props.catTipoCita.nombre;
	  }

	  if(props.paciente!==undefined){
		edad=props.paciente.edad+"a.";
	  }

	  if(props.paciente!==undefined){          
		console.log("props.paciente.sexo:"+props.paciente.sexo);
		if(props.paciente.SEXO_id===MASCULINO){
		  colorFace="#00aae4";
		}else if(props.paciente.SEXO_id===FEMENINO){
		  colorFace="#fe4164";
		}  
	  }
	  
	  const handleConfirm=()=>{
		console.log("cita(confirmacion):"+JSON.stringify(props.cita));
		props.cita.CAT_ESTADO_CITA_id=ESTATUS_EN_SALA_DE_ESPERA;
		console.log("cita:"+JSON.stringify(props.cita));
		props.actualizarCitaWSAction(CITA,props.cita);
		props.setQrmodal(false);
		props.setIdPacienteValor("");
	  }

        return (
			<Card variant="elevation" elevation={3}>
				<CardHeader
					avatar={
						<IoPersonCircle className="face_icon" style={{ color:colorFace,marginRight:".8rem", padding:"0rem",width: "3.5rem",height: "3.5rem"}} color="action" alt={p.MEDICO_id} />

					}
					
					title={<Typography  style={{textTransform:"capitalize"}}>
							{nombrePaciente}                                       
				  			</Typography>
						  }
					subheader={<Badge
								color="secondary"
								anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
								}}
								badgeContent={edad} 
								/>   
							  }
				/>
				<CardActionArea>
					<CardContent>
						<Typography gutterBottom variant="h5" component="h2">
							{nombreMedico}					 
						</Typography>
						<Grid container >
							
							<Grid item className="grid-item">
							<Chip  
								variant="outlined"                                           
								size="small"
								color="secondary"
								icon={<QueryBuilderIcon />} 
								label={ <Typography  style={{ textTransform:"capitalize"}}>{desde+" - "+hasta}</Typography>} 
							/>
							</Grid>                               
							<Grid item className="grid-item">
							<Chip 
								variant="default" 
								label={<Typography  style={{ textTransform:"capitalize"}}>{tipoCita}</Typography>}
								size="small" 
								style={{backgroundColor: props.codColor ,color: "whitesmoke"}} 
								/>  
							</Grid>   
						</Grid>
					</CardContent>
				</CardActionArea>
				<CardActions>	
					<Button size="medium" color="secondary" variant="outlined">
							  Editar
					</Button>
					<Button size="medium" color="primary" variant="outlined" onClick={handleConfirm}>
							  Confirmar
					</Button>						
				</CardActions>
			</Card> 
			);
    
}
export default Page;

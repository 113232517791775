import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import {mostrarMensaje} from "../../redux/actions/stateActions"


class SnackbarsMensaje extends Component{
    render(){        
        return  <Page mensaje={this.props.mensaje} 
                        tipoMensaje={this.props.tipoMensaje}
                            abrirMensaje={this.props.abrirMensaje}
                                mostrarMensaje={this.props.mostrarMensaje}
                                />    
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        mostrarMensaje: (valor,mensaje) => dispatch(mostrarMensaje(valor,mensaje))
    }
}

const mapStateToProps=(state)=>({
    mensaje:state.estadosMensajeReducer.mensaje,
    tipoMensaje:state.estadosMensajeReducer.tipoMensaje,
    abrirMensaje:state.estadosMensajeReducer.abrirMensaje,    

})

export default connect(mapStateToProps,mapDispatchToProps)(SnackbarsMensaje);

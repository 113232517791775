import {connect} from "react-redux";
import React,{Component} from "react";
import { CITA, INSTITUCION, MEDICO, MEDICO_USUARIO, PACIENTE } from "../../../redux/constants/action-type";
import Page from "./page";
import { PROFIL_ADMINISTRADOR, PROFIL_MEDICO } from "../../../utils/constantes";
import { actualizarRegistrosAction } from "../../../redux/actions/stateActions";

class CitaList extends Component{

    render(){

		/*
		(09Ago21) Si el perfil es de medico se debe aplicar un filtro a las citas que corresponmda al MEDICO_id
		this.props.citaes
		
		*/

		let citasFilter=this.props.citaes.filter((cita)=>{
			if(this.props.profil===PROFIL_ADMINISTRADOR){
				console.log("institucion de list citas:"+this.props.institucion.id);
				return cita.INSTITUCION_id===this.props.institucion.id;
			}else if(this.props.profil===PROFIL_MEDICO){
				console.log("medico de list citas:"+this.props.medicoUsuario.MEDICO_id);
				return cita.MEDICO_id===this.props.medicoUsuario.MEDICO_id;
			}else{
				return false;
			}
		});

		let citasPacienteCombinacion=citasFilter.map(cita=>Object.assign(cita,this.props.pacientes.find(item=>item.id===cita.PACIENTE_id)));
		
		let citaesPacienteCombinacionFilter = citasPacienteCombinacion.filter((data)=>{
					if(this.props.busqueda === ""){
						return data;
					}else if(data.nombres.toLowerCase().includes(this.props.busqueda.toLowerCase())){
						return data;
					}	
         });   
		 

		console.log(" citasPacienteCombinacion:"+JSON.stringify(citasPacienteCombinacion));	 
		console.log(" citaesPacienteCombinacionFilter:"+JSON.stringify(citaesPacienteCombinacionFilter));	 
		let agrupacionCitasByFecha = citaesPacienteCombinacionFilter.reduce((recursivo, cita) => {
			recursivo[cita.fecha] = [...recursivo[cita.fecha] || [], cita];
			  return recursivo;
			 }, {});

		 console.log("agurcaion grupo:"+JSON.stringify(agrupacionCitasByFecha));	 
		// console.log("agurcaion grupo:"+agrupacionCitasByFecha.size);	 
	   let count=0;
		console.log(Object.keys(agrupacionCitasByFecha));
		Object.keys(agrupacionCitasByFecha).map(fecha=>{
			console.log("fecha key:"+fecha);
			agrupacionCitasByFecha[fecha].map(cita=>{
				count++;
				console.log("cita values:"+JSON.stringify(cita));
			})			
		});
		console.log("actualizarRegistrosAction:"+count);

		this.props.actualizarRegistrosAction(CITA,count);		
		console.log("actualizarRegistrosAction:"+count);

        return< Page citaesFilter={agrupacionCitasByFecha} abrirModalEditar={this.props.abrirModalEditar}
					/>
    }

}


const mapStateToProps=(state)=>({
    profil:state.autenticacionReducer.profil,     
    medicoUsuario:state.MedicoUsuarioReducer[MEDICO_USUARIO][0],  
    institucion:state.InstitucionReducer[INSTITUCION][0],
	pacientes: state.PacienteReducer[PACIENTE],

})

const mapDispatchToProps=(dispatch)=>({
	actualizarRegistrosAction: (type,registros) => dispatch(actualizarRegistrosAction(type,registros)),

})

export default connect(mapStateToProps,mapDispatchToProps)(CitaList);
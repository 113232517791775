import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {USUARIO,ESTADO,INSTITUCION} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {ID_ROL_MEDICO,ROLES_ERLAUB_ARRAY} from "../../../utils/constantes"

const passwordRegex = /^[a-zA-Z0-9][a-zA-Z0-9 .,]*$/;
const alfanumericoRegex = /^[a-zA-Z0-9][a-zA-Z0-9]*$/;

const validationSchema = yup.object().shape({
    usuario:yup.object().shape({
        usuario: yup.string().matches(
            alfanumericoRegex,
            "Solo es permitido valores alphanumericos (letras y numeros)."
          ).min(8, 'debe ser mayor a 8 caracteres').max(25, 'debe ser menor a 25 caracteres').required("Campo requerido"),
		password: yup.string().matches(
            passwordRegex,
            "Solo es permitido valores alphanumericos (letras y numeros) puntos y comas."
          ).min(8, 'debe ser mayor a 8 caracteres').max(45, 'debe ser menor a 45 caracteres').required("Campo requerido"),
        passwordConfirmacion:yup.string().oneOf([yup.ref("password"),null],"Las contraseñas no coinciden. Vuelve a intentarlo."), 
		correo: yup.string().min(2, 'debe ser mayor a 2 caracteres').max(45, 'debe ser menor a 45 caracteres').email('Correo no valido').required("Campo requerido"),
        }),
    rol:yup.object().shape({
        CAT_ROL_id: yup.string().required("Campo requerido"),
        MEDICO_id: yup.string().when("CAT_ROL_id",{ is:ID_ROL_MEDICO,then:yup.string().required("Campo requerido")})
        }),
})

class UsuarioFormEdit extends Component{

    constructor(props) {
        super(props);
        this.state={
                modalConfirmacion:false,
        };
    }
    abrirConfirmacion=()=> {
        this.setState({
            modalConfirmacion:true
        });
    }
    cerrarConfirmacion=()=> {
        this.setState({
            modalConfirmacion:false
        });
    }

    eliminar=(dato)=> {
        this.cerrarConfirmacion();
        this.props.cerrarModalEditar();
        this.props.eliminarUsuarioWSAction(USUARIO,dato);
    }

    
    render(){    
    
        let valores=this.props.payload;
        let id=this.props.payload.id;
        const { bindSubmitForm } = this.props;

        return <Formik
                    enableReinitialize
                    initialValues={valores}
                    validationSchema={validationSchema}
                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          resetForm({values:""});
                          setSubmitting(this.props.actualizarUsuarioWSAction(USUARIO,values));
                         this.props.history.goBack();
                      }}
                >
                {props => < Page {...props}  instituciones={this.props.instituciones}  estados={this.props.estados}   modalEditar={this.props.modalEditar} tipoSeleccion={this.props.tipoSeleccion} 
                                                cerrarModalEditar={this.props.cerrarModalEditar} 
                                                eliminar={this.eliminar} 
                                                payload={this.props.payload}
                                                actualizarUsuarioWSAction={this.props.actualizarUsuarioWSAction}  
                                                modalConfirmacion={this.state.modalConfirmacion}
                                                cerrarConfirmacion={this.cerrarConfirmacion}
                                                abrirConfirmacion={this.abrirConfirmacion} />}
                </Formik> 
    }



}

const mapStateToProps=(state)=>({
  instituciones:state.InstitucionReducer[INSTITUCION],
  estados:state.EstadoReducer[ESTADO],
})

export default withRouter(connect(mapStateToProps)(UsuarioFormEdit));


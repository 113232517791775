//import React, { useEffect } from 'react';
import React, {Component} from "react";

import './App.css';
import {Provider} from "react-redux";
import dataStore from "./redux/store";
import { BrowserRouter} from "react-router-dom";
import Navbar from './component/navbar';
import Contenido from './component/Contenido';
import setAuthorizationToken from './utils/setAuthorizationToken';
import jwt from "jsonwebtoken";
import {agregarUsuarioActual} from "./redux/actions/autenticacionActions";
import {obtenerMedicoUsuarioListLocalStorageAction} from "./redux/actions/MedicoUsuarioActions";
import {obtenerPacienteUsuarioListLocalStorageAction} from "./redux/actions/PacienteUsuarioActions";
import {obtenerInstitucionListLocalStoraAction} from "./redux/actions/InstitucionActions";


import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'; // v1.x
import { blue, pink,teal,orange,red ,lightBlue,grey,blueGrey} from '@material-ui/core/colors';
import { INSTITUCION, MEDICO_USUARIO,PACIENTE_USUARIO} from "./redux/constants/action-type";
import {PROFIL_MEDICO,PROFIL_ADMINISTRADOR, PROFIL_PACIENTE} from "./utils/constantes"

const theme = createTheme({
  typography: {
    "fontFamily": `"Roboto","Helvetica", "Arial", "sans-serif"`,
    "fontSize": 16,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500,
    "fontWeightBold":600,
    "fontWeight": 400,
    h6 : {
      "fontSize": '1.4rem',     
    },
    subtitle1:{
      "fontWeight":500,
      "fontSize": '1.1rem', 
      "lineHeight":"1.2"    

    },
    subtitle2:{
      "fontWeight": 400,
      "fontSize": '1.1rem', 
      "lineHeight":"1.1"    
    },
    body1:{
      "lineHeight":"1.3"   
    }
   },
  palette:{    
    primary:{
      main:blue["600"]
    },
    secondary:{
      main:grey['700']
    },background:{
      main:blueGrey['400']
    }
  },
  
 });

class App extends Component{
  componentWillMount(){
    if(localStorage.jwtToken){
      console.log("componentWillMount App (localStorage.jwtToken) "+localStorage.jwtToken);
      console.log("componentWillMount App (localStorage.profil) "+localStorage.profil);
      setAuthorizationToken(localStorage.jwtToken);
      dataStore.dispatch(agregarUsuarioActual(jwt.decode(localStorage.jwtToken).data,localStorage.profil));
      if(localStorage.profil===PROFIL_MEDICO){
        dataStore.dispatch(obtenerMedicoUsuarioListLocalStorageAction(MEDICO_USUARIO,JSON.parse(localStorage.medicoUsuario)));
      }else if(localStorage.profil===PROFIL_PACIENTE){
        dataStore.dispatch(obtenerPacienteUsuarioListLocalStorageAction(PACIENTE_USUARIO,JSON.parse(localStorage.pacienteUsuario)));
      }
      dataStore.dispatch(obtenerInstitucionListLocalStoraAction(INSTITUCION,JSON.parse(localStorage.institucion)));
     
    }
  }
  render(){
    return <MuiThemeProvider theme={theme}>
            <BrowserRouter>
                    <Provider store={dataStore}>              
                        <Contenido/>
                    </Provider>
                  </BrowserRouter>
          </MuiThemeProvider>
  }
}

export default App;


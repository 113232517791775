import React from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {withRouter} from "react-router-dom";
import Divider from '@material-ui/core/Divider';
import ConfirmacionDialog from "./ConfirmacionDialog";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

const Page=(props)=>{
		
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	function goBack(){
		props.history.goBack();
	}

	let appBar="";
	let actions="";

	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={goBack} aria-label="close">
						    <ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
						</IconButton>
						<Typography variant="h6" style={{ flexGrow: 1}} >
							{<Typography type="Roboto" style={{flexGrow:"1",color: '#000000', fontSize:'1.2rem',fontWeight:'normal',textTransform: "capitalize" }}>Nueva Movimiento</Typography>}
						</Typography>
                        <IconButton onClick={props.abrirConfirmacion}>
                            <DeleteOutlineIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}/>
                        </IconButton>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
            			<Button variant="contained"  edge="start" color="default" onClick= {props.abrirConfirmacion}>Eliminar</Button>
						<Button variant="contained"  edge="start" color="default" onClick= {props.cerrarModalEditar}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
	}

    return (<form onSubmit={props.handleSubmit}> 
                <Dialog
                    open={props.modalEditar}
                    onClose={props.cerrarModalEditar}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
                    fullScreen={fullScreen}
                >


                        <DialogTitle id="form-dialog-title">Actualice los datos del registro.</DialogTitle>

						{appBar}


							<DialogContent>
							<TextField
								error={this.props.touched.nombre&&this.props.errors.nombre? true:false}
								helperText={this.props.touched.nombre&&this.props.errors.nombre?this.props.errors.nombre:""}
								size="medium"
								margin="normal"
								id="nombre"
								label="nombre:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.nombre}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.descripcion&&this.props.errors.descripcion? true:false}
								helperText={this.props.touched.descripcion&&this.props.errors.descripcion?this.props.errors.descripcion:""}
								size="medium"
								margin="normal"
								id="descripcion"
								label="descripcion:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.descripcion}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.cantidad&&this.props.errors.cantidad? true:false}
								helperText={this.props.touched.cantidad&&this.props.errors.cantidad?this.props.errors.cantidad:""}
								size="medium"
								margin="normal"
								id="cantidad"
								label="cantidad:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.cantidad}
								onBlur={this.props.handleBlur}
							/>
							<FormControl
							fullWidth
							margin="normal"
							
							>
							    <InputLabel htmlFor="age-native-simple">PRODUCTO</InputLabel>
								<Select
									error={this.props.touched.PRODUCTO_id&&this.props.errors.PRODUCTO_id? true:false}
									size="medium"
									id="PRODUCTO_id"
									label="PRODUCTO_id:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={this.props.handleChange} value={this.props.values.PRODUCTO_id}
									onBlur={this.props.handleBlur}
									native
								>
								          <option aria-label="None" value="" disabled/>
							
									{
									this.props.producto.map(producto=> (
										<option key={producto.id}  value={producto.id}>{producto.nombre}</option>)
									)}
								</Select>
							</FormControl>
							<FormControl
							fullWidth
							margin="normal"
							
							>
							    <InputLabel htmlFor="age-native-simple">CATTIPOMOVIMIENTO</InputLabel>
								<Select
									error={this.props.touched.CATTIPOMOVIMIENTO_id&&this.props.errors.CATTIPOMOVIMIENTO_id? true:false}
									size="medium"
									id="CATTIPOMOVIMIENTO_id"
									label="CATTIPOMOVIMIENTO_id:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={this.props.handleChange} value={this.props.values.CATTIPOMOVIMIENTO_id}
									onBlur={this.props.handleBlur}
									native
								>
								          <option aria-label="None" value="" disabled/>
							
									{
									this.props.catTipoMovimiento.map(cattipomovimiento=> (
										<option key={cattipomovimiento.id}  value={cattipomovimiento.id}>{cattipomovimiento.nombre}</option>)
									)}
								</Select>
							</FormControl>
							<FormControl
							fullWidth
							margin="normal"
							
							>
							    <InputLabel htmlFor="age-native-simple">TECNICO</InputLabel>
								<Select
									error={this.props.touched.TECNICO_id&&this.props.errors.TECNICO_id? true:false}
									size="medium"
									id="TECNICO_id"
									label="TECNICO_id:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={this.props.handleChange} value={this.props.values.TECNICO_id}
									onBlur={this.props.handleBlur}
									native
								>
								          <option aria-label="None" value="" disabled/>
							
									{
									this.props.tecnico.map(tecnico=> (
										<option key={tecnico.id}  value={tecnico.id}>{tecnico.nombre}</option>)
									)}
								</Select>
							</FormControl>
							<FormControl
							fullWidth
							margin="normal"
							
							>
							    <InputLabel htmlFor="age-native-simple">INSTITUCION</InputLabel>
								<Select
									error={this.props.touched.INSTITUCION_id&&this.props.errors.INSTITUCION_id? true:false}
									size="medium"
									id="INSTITUCION_id"
									label="INSTITUCION_id:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={this.props.handleChange} value={this.props.values.INSTITUCION_id}
									onBlur={this.props.handleBlur}
									native
								>
								          <option aria-label="None" value="" disabled/>
							
									{
									this.props.instituciones.map(institucion=> (
										<option key={institucion.id}  value={institucion.id}>{institucion.nombre}</option>)
									)}
								</Select>
							</FormControl>
                        </DialogContent>                          
					  {actions} 
                    </Dialog>
                    <ConfirmacionDialog modalConfirmacion={props.modalConfirmacion}   
                                        cerrarConfirmacion={props.cerrarConfirmacion}
                                        eliminar={props.eliminar} 
                                        payload={props.payload}/>
                    </form>   
                )
}
export default  withRouter(Page);

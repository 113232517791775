import {CONSULTAR_USUARIOS,AGREGAR_USUARIO,ACTUALIZAR_USUARIO, ELIMINAR_USUARIO} from "../constants/Usuario-action-type";


/* USUARIOS (HOSPITAL CLINICA) */
export const obtenerUsuarioListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_USUARIOS,
        dataType:dataType,
        loading: loading
    }
}

export const agregarUsuarioWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_USUARIO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarUsuarioWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_USUARIO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarUsuarioWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_USUARIO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

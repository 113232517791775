import React,{Component} from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {withRouter} from "react-router-dom";
import {GLUCOSA} from "../../../redux/constants/action-type";
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';
import ConfirmacionDialog from "./ConfirmacionDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

class Page extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }
     
     goBack(){
         this.props.history.goBack();
     }

    guardar=(dato)=> {
        this.props.actualizarGlucosaWSAction(GLUCOSA,dato);
    }

    render(){
        let opcion=this.props.tipoSeleccion;
        const inputProps = {
            step: 300,
            resize:"2rem",
            fontSize:"2rem",
            style: {fontSize: 15} 

          };

        return (
            <form onSubmit={this.props.handleSubmit} style={{margin: 1, width: '25ch'}}> 
                <Dialog
                    open={this.props.modalEditar}
                   // onClose={this.props.cerrarModalEditar}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
                    fullScreen
                    onBackdropClick={this.hola}
                    >
                        <AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.goBack} aria-label="close">
                            <ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
                            </IconButton>
                            <Typography variant="h6" style={{ flexGrow: 1}} >
                                {<Typography type="Roboto" style={{flexGrow:"1",color: '#000000', fontSize:'1.2rem',fontWeight:'normal',textTransform: "capitalize" }}>Editar </Typography>}
                            </Typography>
                            <IconButton onClick={this.props.abrirConfirmacion}>
                                <DeleteOutlineIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
                            </IconButton>
                            <IconButton >
                            </IconButton>
                            <IconButton edge="start" color="inherit" onClick={this.props.handleSubmit} aria-label="close">
                                <DoneIcon  style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
                            </IconButton>
                        </Toolbar>
                        <Divider/>
                        </AppBar>

                        <DialogTitle id="form-dialog-title">Actualice los datos del registro.</DialogTitle>


							<DialogContent>
							<TextField
								error={this.props.touched.descripcion&&this.props.errors.descripcion? true:false}
								helperText={this.props.touched.descripcion&&this.props.errors.descripcion?this.props.errors.descripcion:""}
								size="medium"
								margin="normal"
								id="descripcion"
								label="descripcion:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.descripcion}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.valor&&this.props.errors.valor? true:false}
								helperText={this.props.touched.valor&&this.props.errors.valor?this.props.errors.valor:""}
								size="medium"
								margin="normal"
								id="valor"
								label="valor:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.valor}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.nota&&this.props.errors.nota? true:false}
								helperText={this.props.touched.nota&&this.props.errors.nota?this.props.errors.nota:""}
								size="medium"
								margin="normal"
								id="nota"
								label="nota:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.nota}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.fecha&&this.props.errors.fecha? true:false}
								helperText={this.props.touched.fecha&&this.props.errors.fecha?this.props.errors.fecha:""}
								size="medium"
								margin="normal"
								id="fecha"
								label="fecha:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.fecha}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.hora&&this.props.errors.hora? true:false}
								helperText={this.props.touched.hora&&this.props.errors.hora?this.props.errors.hora:""}
								size="medium"
								margin="normal"
								id="hora"
								label="hora:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.hora}
								onBlur={this.props.handleBlur}
							/>
							<FormControl
							fullWidth
							margin="normal"
							
							>
							    <InputLabel htmlFor="age-native-simple">CATUNIDADMEDIDA</InputLabel>
								<Select
									error={this.props.touched.CATUNIDADMEDIDA_id&&this.props.errors.CATUNIDADMEDIDA_id? true:false}
									size="medium"
									id="CATUNIDADMEDIDA_id"
									label="CATUNIDADMEDIDA_id:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={this.props.handleChange} value={this.props.values.CATUNIDADMEDIDA_id}
									onBlur={this.props.handleBlur}
									native
								>
								          <option aria-label="None" value="" disabled/>
							
									{
									this.props.catUnidadMedida.map(catunidadmedida=> (
										<option key={catunidadmedida.id}  value={catunidadmedida.id}>{catunidadmedida.nombre}</option>)
									)}
								</Select>
							</FormControl>
							<FormControl
							fullWidth
							margin="normal"
							
							>
							    <InputLabel htmlFor="age-native-simple">PACIENTE</InputLabel>
								<Select
									error={this.props.touched.PACIENTE_id&&this.props.errors.PACIENTE_id? true:false}
									size="medium"
									id="PACIENTE_id"
									label="PACIENTE_id:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={this.props.handleChange} value={this.props.values.PACIENTE_id}
									onBlur={this.props.handleBlur}
									native
								>
								          <option aria-label="None" value="" disabled/>
							
									{
									this.props.paciente.map(paciente=> (
										<option key={paciente.id}  value={paciente.id}>{paciente.nombre}</option>)
									)}
								</Select>
							</FormControl>
                        </DialogContent>                          
                    </Dialog>
                    <ConfirmacionDialog modalConfirmacion={this.props.modalConfirmacion}   
                                            cerrarConfirmacion={this.props.cerrarConfirmacion}
                                                eliminar={this.props.eliminar} 
                                                    payload={this.props.payload}/>
                    </form>   
                )
    }
}
export default  withRouter(Page);

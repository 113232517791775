import React,{Component} from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {withRouter} from "react-router-dom";
import {ESQUEMA_TRABAJO} from "../../../redux/constants/action-type";
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';
import ConfirmacionDialog from "./ConfirmacionDialog";
import TiposDeCitasForm from "../TiposDeCitasForm";

import MedicoFormSelect from "../MedicoFormSelect";

import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

  function Page(props) {
		
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	function goBack(){
		props.history.goBack();
	}


    function guardar(dato){
        props.actualizarEsquemaTrabajoWSAction(ESQUEMA_TRABAJO,dato);
    }

    let appBar="";
	let actions="";

	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={goBack} aria-label="close">
						<ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
						</IconButton>
						<Typography variant="h6" style={{ flexGrow: 1}} >
							Editar Plan de Trabajo
						</Typography>

						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
						<Button variant="contained"  edge="start" color="default" onClick= {props.cerrarModalEditar}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
	}
        let opcion=props.tipoSeleccion;
        const inputProps = {
            step: 300,
            resize:"2rem",
            fontSize:"2rem",
            style: {fontSize: 15} 

          };

        return (
            <form onSubmit={props.handleSubmit} style={{margin: 1, width: '25ch'}}> 
                <Dialog
                    open={props.modalEditar}
                   // onClose={props.cerrarModalEditar}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
                    fullScreen={fullScreen}
                    width="80%"
                    >
                        

                        <DialogTitle id="form-dialog-title">Editar Plan de Trabajo</DialogTitle>

						{appBar}

							<DialogContent>
							<MedicoFormSelect MEDICO_id="MEDICO_id"  
								medicoValue={props.values.MEDICO_id}
								errors={props.errors}
								touched={props.touched} 
								handleChange={props.handleChange} 
								/>								
							<TextField
								error={props.touched.nombre&&props.errors.nombre? true:false}
								helperText={props.touched.nombre&&props.errors.nombre?props.errors.nombre:""}
								size="medium"
								margin="normal"
								id="nombre"
								label="Nombre del Plan o Esquema de Trabajo semanal:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.nombre}
								onBlur={props.handleBlur}
							/>
							<TextField
								error={props.touched.descripcion&&props.errors.descripcion? true:false}
								helperText={props.touched.descripcion&&props.errors.descripcion?props.errors.descripcion:""}
								size="medium"
								margin="normal"
								id="descripcion"
								label="Descripcion del esquema de trabajo:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.descripcion}
								onBlur={props.handleBlur}
							/>

                            <TiposDeCitasForm   
                                               setEsquemaTipoDeCita={props.setEsquemaTipoDeCita} 
                                               esquemaTipoCitaHorarioPeriodo={props.esquemaTipoCitaHorarioPeriodo} 
                                               setEsquemaTipoCitaHorarioPeriodo={props.setEsquemaTipoCitaHorarioPeriodo}
                                               ESQUEMA_TRABAJO_id={props.values.id}/>
                        </DialogContent> 
                        {actions}                  
                         
                    </Dialog>
                    <ConfirmacionDialog modalConfirmacion={props.modalConfirmacion}   
                                            cerrarConfirmacion={props.cerrarConfirmacion}
                                                eliminar={props.eliminar} 
                                                    payload={props.payload}/>
                    </form>   
                )
    
}
export default  withRouter(Page);

import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {ESQUEMA_TRABAJO,MEDICO,MEDICO_USUARIO} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";


const validationSchema = yup.object({
		nombre: yup.string().required("Campo requerido"),
	//	descripcion: yup.string().required("Campo requerido"),
		MEDICO_id: yup.string().required("Campo requerido")
})

class EsquemaTrabajoForm extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
        //Estado se crea para guardar el esquema, tipoDeCitas y horarios que se enviaran a WebServices
        this.state={
            esquema:{},
        };
        }
        
    goBack(){
        this.props.history.goBack();
    }

    setEsquema=(valores)=>{
        this.setState({
            esquema: valores
          });
        
    }
   
   

    
    render(){    
    /*    let idMedico="";
        if(this.props.medicoUsuario!==undefined){
            idMedico=this.props.medicoUsuario.MEDICO_id;
        }*/
        return <Formik

                    initialValues={{
                    'nombre': '',
                    'descripcion': '',
                    'MEDICO_id': ''
                    }}

                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                        this.setEsquema(values); //Guarda en el estado local los valores del Form
                        console.log("(state)valores "+JSON.stringify(this.state));                              
                        setSubmitting(this.props.agregarEsquemaTrabajoWSAction(ESQUEMA_TRABAJO,this.state));
                        resetForm({values:""});
                        this.goBack();
                      }}
                >
                    {props => < Page {...props}  
                                                modal={this.props.modal} 
                                                cerrarModal={this.props.cerrarModal}   
                                                
                                                /> }
                </Formik> 
    }
}


const mapDispatchToProps = (dispatch, ownProps) => ({
    //	obtenerEsquemaTipoCitaListWSAction: (type,loading) => dispatch(obtenerEsquemaTipoCitaListWSAction(type,loading)),
        
    })

const mapStateToProps=(state)=>({
// medicoUsuario:state.MedicoUsuarioReducer[MEDICO_USUARIO][0],

})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EsquemaTrabajoForm));



import React,{Component} from "react";
import HorarioListItem from "../HorarioListItem";
import Typography from '@material-ui/core/Typography';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default class Page extends Component{

  constructor(props) {
    super(props);
  }
  
  getFechaComponent=()=>{
    let dia=("0" + this.props.dia).slice(-2);
    let idMes=("0" + (this.props.idMes + 1)).slice(-2);
    let anio=this.props.anio
    return anio+"-"+idMes+"-"+dia;    
  }

  render(){

    let fechaLocal=this.getFechaComponent();    
    //07Ene21 activa la paloma de seleccion para este Card e identificar que dia es selecionado.
    let action=(this.props.fecha===fechaLocal)? <CheckCircleIcon  fontSize="default" color="secondary"/>:"";

      return <Grid container spacing={1}>
                      {this.props.horarios.map((item,index) => 
                            <HorarioListItem 
                              key={index}
                              desde={item.desde.split(' ')[1]} 
                              hasta={item.hasta.split(' ')[1]}     
                              ajustado={item.ajustado} 
                              contenido={item.contenido}   
                              idTipoCita={item.idTipoCita}                                                                                                                           
                              action={action}

                              setFecha={this.props.setFecha} 
                              setHoraInicio={this.props.setHoraInicio} 
                              setHoraFin={this.props.setHoraFin}
                              
                              codColor={this.props.codColor} 
                              horaInicio={this.props.horaInicio} 
                              horaFin={this.props.horaFin}
                              
                              dia={this.props.dia} 
                              idMes={this.props.idMes} 
                              anio={this.props.anio}
                              duracionTipoCita={this.props.duracionTipoCita}
                            />

                      )}
                    </Grid>
                 
    }                          
}
/*
                      {this.props.horarios.map(horario => 
                            <HorarioListItem 
                              desde={horario[0].getHours()+":"+(horario[0].getMinutes()<10?'0':'') + horario[0].getMinutes()} 
                              hasta={horario[1].getHours()+":"+(horario[1].getMinutes()<10?'0':'') + horario[1].getMinutes()}                                                                                                                                   
                              action={action}

                              setFecha={this.props.setFecha} 
                              setHoraInicio={this.props.setHoraInicio} 
                              setHoraFin={this.props.setHoraFin}
                              
                              codColor={this.props.codColor} 
                              horaInicio={this.props.horaInicio} 
                              horaFin={this.props.horaFin}
                              
                              dia={this.props.dia} 
                              idMes={this.props.idMes} 
                              anio={this.props.anio}
                              duracionTipoCita={this.props.duracionTipoCita}
                            />

                      )}
*/ 

import {CONSULTAR_RecordatorioES,AGREGAR_Recordatorio,ACTUALIZAR_Recordatorio, ELIMINAR_Recordatorio} from "../constants/Recordatorio-action-type";


/* RecordatorioES (HOSPITAL CLINICA) */
export const obtenerRecordatorioListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_RecordatorioES,
        dataType:dataType,
        loading: loading
    }
}

export const agregarRecordatorioWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_Recordatorio,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarRecordatorioWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_Recordatorio,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarRecordatorioWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_Recordatorio,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

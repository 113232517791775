import React,{Component} from "react";
import Page from "./page";
import {  PACIENTE } from "../../../redux/constants/action-type";
import {connect} from "react-redux";
import {obtenerPacienteListXInstitucionWSAction} from "../../../redux/actions/PacienteActions"


class PacienteFormSelect extends Component{
    render(){    
        console.log("INSTITUCION_id"+this.props.INSTITUCION_id);

        return<Page pacientes={this.props.pacientes} 
                    PACIENTE_id={this.props.PACIENTE_id} 
                    pacienteValue={this.props.pacienteValue} 
                    handleChange={this.props.handleChange} 
                    errors={this.props.errors}
                    touched={this.props.touched}/>
    }

    componentDidMount(){            
        this.props.obtenerPacienteListXInstitucionWSAction(PACIENTE,this.props.INSTITUCION_id); /* Invoca por primara vez a productos (default) */
    }


}

const mapStateToProps=(state)=>({
    pacientes:state.PacienteReducer[PACIENTE],
})


const mapDispatchToProps = (dispatch, ownProps) => ({
    obtenerPacienteListXInstitucionWSAction: (type,payload) => dispatch(obtenerPacienteListXInstitucionWSAction(type,payload)),
})


export default connect(mapStateToProps,mapDispatchToProps)(PacienteFormSelect);
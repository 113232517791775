import React,{Component} from "react";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import {Link} from "react-router-dom";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Paper from '@material-ui/core/Paper';
import { Badge, Chip } from "@material-ui/core";
import LensIcon from '@material-ui/icons/Lens';

export default class Page extends Component{

    render(){
        
        
        function obtenerColor(nombre) {
            if(nombre!=null){
                var size=nombre.length;
                do {
                   if(size>colores.length){
                      size=size-colores.length;
                   } 
                }while (size>colores.length);
  
              return colores[size];
  
            }else{
                return colores[0];
            }
          }

        let colores=["#babdbe"];
        let codColor=this.props.codColor;
        let p=this.props.catTipoCita;
        let duracionTipoCita=p.duracion+"min";
        return  <div>
                <ListItem dense button style={{  paddingBottom:6, paddingTop:6 }} component={Link}  to={{
                  hash:'id', 
                  valores:{
                     modalEditar:true,
                     payload: p
                  }
                  }} >
                    <ListItemAvatar>
                       <LensIcon elevation={3}  style={{ marginRight:".8rem", width: "2rem",height: "2rem", color:codColor}}/>
                    </ListItemAvatar>

					          <ListItemText id={p.id} 
                                  primary={<Typography  style={{ textTransform:"capitalize"}}>{p.nombre}</Typography>}
								                  secondary={
                                  <Typography  style={{ textTransform:"capitalize"}}>{p.descripcion} <Chip 
                                  variant="default" 
                                    label={<Typography  style={{ textTransform:"capitalize",whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"}}>{duracionTipoCita}</Typography>}
                                    size="medium" 
                                    />    </Typography>
                                }
                    />
                                                                               
                </ListItem>
                <Divider variant="inset" component="li" />

                </div>
    }
}

import {CONSULTAR_CAT_ROLES,AGREGAR_CAT_ROL,ACTUALIZAR_CAT_ROL, ELIMINAR_CAT_ROL} from "../constants/CatRol-action-type";


/* CAT_ROLES (HOSPITAL CLINICA) */
export const obtenerCatRolListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_CAT_ROLES,
        dataType:dataType,
        loading: loading
    }
}

export const agregarCatRolWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_CAT_ROL,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarCatRolWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_CAT_ROL,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarCatRolWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_CAT_ROL,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

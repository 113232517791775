import React,{Component} from "react";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import {Link} from "react-router-dom";
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';

export default class Page extends Component{

    render(){
        
        
        function obtenerColor(nombre) {
            if(nombre!=null){
                var size=nombre.length;
                do {
                   if(size>colores.length){
                      size=size-colores.length;
                   } 
                }while (size>colores.length);
  
              return colores[size];
  
            }else{
                return colores[0];
            }
          }

        let colores=["#babdbe","#00bfa5","#8eacbb","#0288D1","#34515e"];
        let p=this.props.esquemaTrabajo;
        let nombreMedico="";
        if(this.props.medico!==undefined){
          nombreMedico=<Chip  
                            variant="default"                                           
                            size="small"
                            color="primary"                         
                            label={this.props.medico.titulo+" "+this.props.medico.nombres+" "+this.props.medico.apellidoPaterno+" "+this.props.medico.apellidoMaterno}                                                                   
                            style={{backgroundColor:"#01579b"}}
                          /> 
        }
        return  <div>
                <ListItem dense button style={{  paddingBottom:6, paddingTop:6 }} component={Link}  to={{
                  hash:'id', 
                  valores:{
                     modalEditar:true,
                     payload: p
                  }
                  }} >
                    <ListItemAvatar>
						          <TimelapseIcon style={{ marginRight:".8rem", width: "3.5rem",height: "3.5rem", color:obtenerColor(p.nombre)}} alt={p.nombre} color="primary"/>
                    </ListItemAvatar>

					          <ListItemText id={p.id} 
                                  primary={<Typography  style={{ textTransform:"capitalize"}}>{p.nombre+" "+ p.descripcion}</Typography>}
								                  secondary={<Typography  style={{ textTransform:"capitalize"}}>{nombreMedico}</Typography>} />
                </ListItem>
                <Divider variant="inset" component="li" />

                </div>
    }
}

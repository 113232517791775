import React,{Component, useState} from "react";
import Page from "./page";
import {connect} from "react-redux";
import { CITA } from "../../../redux/constants/action-type";

function Dia(props) {
   
    return <Page 
            dia={props.dia}
            hoy={props.hoy}
            citaes={props.citaes}
            />                        
          
}




/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({ 
    citaes:state.CitaReducer[CITA],    
  
})
/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(mapStateToProps,mapDispatchToProps)(Dia);

import React,{Component } from "react";
import Page from "./page";
import {connect} from "react-redux";
import {CAT_TIPO_CITA,COLOR} from "../../../redux/constants/action-type";
import {obtenerColorListWSAction,agregarColorWSAction,actualizarColorWSAction,eliminarColorWSAction} from "../../../redux/actions/ColorActions";
import {obtenerCatTipoCitaListWSAction,agregarCatTipoCitaWSAction,actualizarCatTipoCitaWSAction,eliminarCatTipoCitaWSAction} from "../../../redux/actions/CatTipoCitaActions";


class CatTipoCitaes extends Component{

    constructor(props) {
        super(props);
        this.state={
                modal:false,
                modalEditar:false,
                payload: {
					'nombre': '',
					'descripcion': '',
					'duracion': '',
					'COLOR_id': ''
                },
                busqueda:""

        };
    }
     
    abrirModal=()=> {
        this.setState({
            modal:true
        });
    }

     cerrarModal=()=> {
        this.setState({
            modal:false
        });
    }

    abrirModalEditar = (value)=> {
        this.setState({
            modalEditar:true,
            payload: value

        });
    };

    cerrarModalEditar=()=> {
        this.setState({
            modalEditar:false
        });
    }

    buscar=(valor)=> {
        this.setState({
            busqueda:valor
        });
    }


    render(){        
        return  <Page catTipoCitaes={this.props.catTipoCitaes} loading={this.props.loading} 
                    modal={this.state.modal}
                    modalEditar={this.state.modalEditar}
                    payload={this.state.payload}
                       abrirModal={this.abrirModal}
                           cerrarModal={this.cerrarModal}
                                abrirModalEditar={this.abrirModalEditar}
                                    cerrarModalEditar={this.cerrarModalEditar}
                                    tipoSeleccion={this.props.tipoSeleccion}
                                agregarCatTipoCitaWSAction={this.props.agregarCatTipoCitaWSAction}
                                actualizarCatTipoCitaWSAction={this.props.actualizarCatTipoCitaWSAction}
                                eliminarCatTipoCitaWSAction={this.props.eliminarCatTipoCitaWSAction}   
                                buscar={this.buscar} busqueda={this.state.busqueda}/>    
    }

 /* esta es la accion de middleware*/
        componentDidMount(){            
            let valores=this.props.location.valores;            
            if(valores === undefined){
				this.props.obtenerColorListWSAction(COLOR,false);
                this.props.obtenerCatTipoCitaListWSAction(CAT_TIPO_CITA,true); /* Invoca por primara vez a productos (default) */
            }else{
                this.setState(this.props.location.valores);
            }

        }


}

/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
    catTipoCitaes:state.CatTipoCitaReducer[CAT_TIPO_CITA],    
    loading:state.estadosPeticionReducer.loading,
    tipoSeleccion:state.estadosReducer.selectedType,

})

/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        obtenerCatTipoCitaListWSAction: (type,loading) => dispatch(obtenerCatTipoCitaListWSAction(type,loading)),
        obtenerColorListWSAction: (type,loading) => dispatch(obtenerColorListWSAction(type,loading)),
        agregarCatTipoCitaWSAction: (type,payload) => dispatch(agregarCatTipoCitaWSAction(type,payload)),
        actualizarCatTipoCitaWSAction: (type,payload) => dispatch(actualizarCatTipoCitaWSAction(type,payload)),
        eliminarCatTipoCitaWSAction: (type,payload) => dispatch(eliminarCatTipoCitaWSAction(type,payload))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CatTipoCitaes);



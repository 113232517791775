import React,{Component} from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withRouter} from "react-router-dom";
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import PersonIcon from '@material-ui/icons/PersonRounded';
import ListItemText from '@material-ui/core/ListItemText';


import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import "./estilo.css";
import {getEdad} from "../../../utils/moment"

import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {  Popper } from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import Popover from '@material-ui/core/Popover';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import PersonAdd from '@material-ui/icons/PersonAdd';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function Page(props) {
		
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
	  setAnchorEl(anchorEl ? null : event.currentTarget);
	};
  
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popper' : undefined;

	function goBack(){
		props.history.goBack();
	}


	function handleFechaDeNacimiento(e){
		props.handleChange(e);
		let edad=getEdad(new Date(e.target.value));
		if(edad<150){
			props.setFieldValue("edad",edad);	
		}
	}

	function handleChange(e){
		props.setFieldValue("SEXO_id",e.target.value);			  
	}


	let appBar="";
	let actions="";

	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={goBack} aria-label="close">
						<ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
						</IconButton>
						<Typography variant="h6" style={{ flexGrow: 1}} >
							{<Typography type="Roboto" style={{flexGrow:"1",color: '#000000', fontSize:'1.2rem',fontWeight:'normal',textTransform: "capitalize" }}>Nuevo Paciente</Typography>}
						</Typography>

						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
						<Button variant="contained"  edge="start" color="default" onClick= {props.cerrarModal}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
	}
   
        return (<form onSubmit={props.handleSubmit}> 



				<PopupState variant="popover" popupId="demo-popup-popover">
																	{(popupState) => (
																		<div>
																		
																		<IconButton
																 
																 aria-controls={open ? 'menu-list-grow' : undefined}
																 aria-haspopup="true"
																 onClick={handleClick}
																 variant="outlined"
																 {...bindTrigger(popupState)}
																> 
																	<PersonAdd color="primary" fontSize="large" />
																</IconButton>
																		<Popover
																			
																			{...bindPopover(popupState)}
																			anchorOrigin={{
																			vertical: 'bottom',
																			horizontal: 'center',
																			}}
																			transformOrigin={{
																			vertical: 'top',
																			horizontal: 'center',
																			}}
																		>
																			<Card >
																			<CardContent>
																				<Typography  color="textSecondary" gutterBottom>
																				Word of the Day
																				</Typography>
																				<Typography variant="h5" component="h2">
																				benevoent
																				</Typography>
																				<Typography  color="textSecondary">
																				adjective
																				</Typography>
																				<Typography variant="body2" component="p">
																				well meaning and kindly.
																				<br />
																				{'"a benevolent smile"'}
																				</Typography>
																			</CardContent>
																			<CardActions>
																				<Button size="small">Learn More</Button>
																			</CardActions>
																			</Card>
																		</Popover>
																		</div>
																	)}
																	</PopupState>    
                </form>
                       
                )
    
}
export default  withRouter(Page);

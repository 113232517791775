import React,{Component} from "react";
import ProcedimientoList from "../ProcedimientoList";
import ProcedimientoBusqueda from "../ProcedimientoBusqueda";
import ProcedimientoForm from "../ProcedimientoForm";
import CircularProgress from '@material-ui/core/CircularProgress';
import ProcedimientoButtonBar from "../ProcedimientoFloatButton";
import ProcedimientoFormEdit from "../ProcedimientoFormEdit";
import Fab from '@material-ui/core/Fab';


export default class Page extends Component{

    render(){
        let loading=this.props.loading;
        var resultDatos;
        var animacionCargando;
        if (loading) {
            animacionCargando = <Fab position="fixed" variant="round" size="small" aria-label="cargando" style={{position: 'fixed', top: "5.5rem", float: "left",
                                    left: "45%", boxShadow:"1", backgroundColor:"#FFFFFF",padding:"0.1rem"}}>
                                    <CircularProgress color="secondary" size="1.5rem"/>           
                                </Fab>
        }else{
            animacionCargando=<div/>
        }
        
        if(this.props.procedimientos.length>0){
            resultDatos =  <div className="col-12" >                       
                                <ProcedimientoBusqueda buscar={this.props.buscar}/>                                    
                                <ProcedimientoList  procedimientos={this.props.procedimientos} abrirModalEditar={this.props.abrirModalEditar}
                                                    busqueda={this.props.busqueda}/> 
                            </div>
                                
        }


        return <div style={{marginBottom:"5rem" , marginTop:"5rem" }}>
                                 
                    <ProcedimientoForm  modal={this.props.modal} 
                                      tipoSeleccion={this.props.tipoSeleccion}
                                        cerrarModal={this.props.cerrarModal} 
                                            agregarProcedimientoWSAction={this.props.agregarProcedimientoWSAction}/>                
                               
                    <ProcedimientoFormEdit  modalEditar={this.props.modalEditar} 
                                          payload={this.props.payload} 
                                          tipoSeleccion={this.props.tipoSeleccion}
                                            cerrarModalEditar={this.props.cerrarModalEditar} 
                                                actualizarProcedimientoWSAction={this.props.actualizarProcedimientoWSAction}
                                                eliminarProcedimientoWSAction={this.props.eliminarProcedimientoWSAction}    />                

                    <div className="row">
                        {resultDatos} 
                        {animacionCargando}
                    </div>               
                    <div className="row">
                        <ProcedimientoButtonBar abrirModal={this.props.abrirModal} />
                    </div>                
                    

                </div>
         
    }
}

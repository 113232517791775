import {CONSULTAR_PROCEDIMIENTOS,AGREGAR_PROCEDIMIENTO,ACTUALIZAR_PROCEDIMIENTO, ELIMINAR_PROCEDIMIENTO} from "../constants/Procedimiento-action-type";


/* PROCEDIMIENTOS (HOSPITAL CLINICA) */
export const obtenerProcedimientoListWSAction = (dataType)=> {
    return {
        type:CONSULTAR_PROCEDIMIENTOS,
        dataType:dataType,
        loading: true
    }
}

export const agregarProcedimientoWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_PROCEDIMIENTO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarProcedimientoWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_PROCEDIMIENTO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarProcedimientoWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_PROCEDIMIENTO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

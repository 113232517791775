const colores=["#64dd17","#ffeb3b","#ffb300","#c62828","#ab003c"];

export const getCodigoColorCategoria=(sys,dia)=>{
    let result=0;

    if(sys<120 && dia<80){
        result=0;
    }

    if((sys>=120 && sys<=129)&& dia<=80){
        result=1;
    }
    
    if((sys>=130 && sys<=139)|| (dia>=80 && dia<=89)){
        result=2;
    }

    if((sys>=140)|| (dia>=90)){
        result=3;
    }
    
    if((sys>=180)|| (dia>=120)){
        result=4;
    }

    if((sys>=180) && (dia>=120)){
        result=4;
    }

    return colores[result];

 }

 
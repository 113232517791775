import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {CITA,RECORDATORIO,CAT_ESTADO_CITA,CAT_TIPO_CITA,PACIENTE,MEDICO,ESQUEMA_TRABAJO,ESQUEMA_TIPO_CITA,MEDICO_USUARIO,INSTITUCION} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

class QRScan extends Component{
 
    render(){    
        console.log("citas(qrscan):"+JSON.stringify(this.props.citas));
        return <Page qrmodal={this.props.qrmodal}
                     setQrmodal={this.props.setQrmodal}  
                     citas={this.props.citas}/>
    }            

    componentDidMount(){            
    }

}

const mapStateToProps=(state)=>({
    citas:state.CitaReducer[CITA]
})


const mapDispatchToProps = (dispatch, ownProps) => ({
})


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(QRScan));
import React,{Component, useState} from "react";
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, TextField } from "@material-ui/core";
import DiaSemanaList from "../DiaSemanaList";

const Page=(props)=>{
 
  const MINUTE_MS = 300;

  const divRef = React.useRef();

  const[open,setOpen]=useState(false);

  let buscarHorariosDisponibles="";
  
  const scrollToBottom = () => {  
		if (divRef.current) {
			console.log(">>scrollToBottom()<<:");
			divRef.current.scrollIntoView({behavior: "smooth"});
		}
	}

  const handleClickOpen = () => {
    setOpen(true);
    
    const interval = setTimeout(() => {
      console.log('Logs every minute');
      scrollToBottom();
    }, MINUTE_MS);

    return () => clearInterval(interval);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeDesde=(event)=>{

  }

  const handleChangeHasta=(event)=>{
  }

  const handleClick=()=>{   
  }

  if(open){
    
    buscarHorariosDisponibles=<DiaSemanaList
                                    codColor={props.codColor}
                                    duracionTipoCita={props.duracionTipoCita}

                                    fecha={props.fecha} 
                                    fechaBusqueda={props.fechaBusqueda} 
                                    setFechaBusqueda={props.setFechaBusqueda}
                                    setFieldValue={props.setFieldValue} 
                                    horaInicio={props.horaInicio} 
                                    horaFin={props.horaFin}
                                    idEsquemaTipoCita={props.idEsquemaTipoCita}  
                                />
                              
                              
  }
      return (<Card  
                variant="outlined"
                direction="row"
                justify="center"
                spacing={1} 
                style={{flexGrow:"1",marginTop:"1rem"}} 
              >
              <div id="divRef" ref={divRef} />
                <Button color="primary" onClick={handleClickOpen} variant="contained" fullWidth>
                      BUSCAR HORARIO
                </Button >
                {buscarHorariosDisponibles}
            </Card>)
                             
}

export default Page;
  
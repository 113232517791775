import React,{Component} from "react";
import CitaListItem from "../CitaListItem";
import List from '@material-ui/core/List';
import { Box, Chip, Paper, Typography } from "@material-ui/core";
import {getFecha,getFormatoHora} from "../../../utils/moment";
import {getFechatoLocaleDateString2} from "../../../utils/date";
import { getColorSemana } from "../../../utils/utils";
import Avatar from '@material-ui/core/Avatar';

export default class Page extends Component{
    render(){   
      let fecha= getFechatoLocaleDateString2(this.props.fecha);
      let color=getColorSemana(fecha);     

    return <div>

                <Chip 
                  variant="outlined" 
                  style={{backgroundColor:color,alignSelf:"center",margin:"0.3rem"}}
                  avatar={<Avatar style={{color:"#ffffff",backgroundColor:"#bdbdbd"}}>{this.props.citas.length}</Avatar>}
                  label={<Typography variant="subtitle2" align="left" style={{ color:"#000000",marginLeft:"0.3rem",textTransform:"capitalize"}}>
                          {getFechatoLocaleDateString2(this.props.fecha)}
                          </Typography>
                        }
                />                               

              {
                  this.props.citas.map((cita,item)=>{
                    return <CitaListItem cita={cita}
                                          key={item+this.props.id} 
                                          item={item+this.props.id}
                                          abrirModalEditar={this.props.abrirModalEditar}  
                                         /> 
                  })                              
              }
            </div>
    }                          
}

import React from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {withRouter} from "react-router-dom";
import Divider from '@material-ui/core/Divider';
import ConfirmacionDialog from "./ConfirmacionDialog";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

const Page=(props)=>{
		
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	function goBack(){
		props.history.goBack();
	}

	let appBar="";
	let actions="";

	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={goBack} aria-label="close">
						    <ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
						</IconButton>
						<Typography variant="h6" style={{ flexGrow: 1}} >
							Editar Unidad
						</Typography>
                        <IconButton onClick={props.abrirConfirmacion}>
                            <DeleteOutlineIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}/>
                        </IconButton>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
            			<Button variant="contained"  edge="start" color="default" onClick= {props.abrirConfirmacion}>Eliminar</Button>
						<Button variant="contained"  edge="start" color="default" onClick= {props.cerrarModalEditar}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
	}

    return (<form onSubmit={props.handleSubmit}> 
                <Dialog
                    open={props.modalEditar}
                    onClose={props.cerrarModalEditar}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
                    fullScreen={fullScreen}
                >


                        <DialogTitle id="form-dialog-title">Actualice los datos del registro.</DialogTitle>

						{appBar}

							<DialogContent>
							<TextField
								error={props.touched.nombre&&props.errors.nombre? true:false}
								helperText={props.touched.nombre&&props.errors.nombre?props.errors.nombre:""}
								size="medium"
								margin="normal"
								id="nombre"
								label="nombre:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.nombre}
								onBlur={props.handleBlur}
							/>
							<TextField
								error={props.touched.descripcion&&props.errors.descripcion? true:false}
								helperText={props.touched.descripcion&&props.errors.descripcion?props.errors.descripcion:""}
								size="medium"
								margin="normal"
								id="descripcion"
								label="descripcion:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.descripcion}
								onBlur={props.handleBlur}
							/>
							<TextField
								error={props.touched.direccion&&props.errors.direccion? true:false}
								helperText={props.touched.direccion&&props.errors.direccion?props.errors.direccion:""}
								size="medium"
								margin="normal"
								id="direccion"
								label="direccion:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.direccion}
								onBlur={props.handleBlur}
							/>
							<TextField
								error={props.touched.razonSocial&&props.errors.razonSocial? true:false}
								helperText={props.touched.razonSocial&&props.errors.razonSocial?props.errors.razonSocial:""}
								size="medium"
								margin="normal"
								id="razonSocial"
								label="razonSocial:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.razonSocial}
								onBlur={props.handleBlur}
							/>
                        </DialogContent>                          
					  {actions} 
                    </Dialog>
                    <ConfirmacionDialog modalConfirmacion={props.modalConfirmacion}   
                                        cerrarConfirmacion={props.cerrarConfirmacion}
                                        eliminar={props.eliminar} 
                                        payload={props.payload}/>
                    </form>   
                )
}
export default  withRouter(Page);

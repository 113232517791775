import { Avatar, Box, Card, CardActions, CardContent, CardHeader, Chip, Container, IconButton, List, makeStyles, Paper, Popover, Typography, withStyles } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import moment from "moment/moment";
import React,{Component, useRef} from "react";
import EventoCitaItem from "../EventoCitaItem";
import Link from '@material-ui/core/Link';
import DiaDetalle from "../DiaDetalle";
import { BorderBottom, BorderTop } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 104,
    minHeight: 124,
    maxHeight: 124,
    paddingBottom:0,
    paddingleft:0,
    paddingRight:0,
    paddingTop:0,
    padding:0,
    borderRadius:"0",
    borderTop:0,
    borderLeft:0
  },
  content:{
    paddingBottom:0,
    paddingleft:0,
    paddingRight:0,
    paddingTop:0,
    padding:0,
  },
  avatar: {
    backgroundColor: blue[500],
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    textAlign: 'center'

  },  
  lista:{
    paddingBottom:0,
    paddingleft:0,
    paddingRight:0,
    paddingTop:0,
    padding:0,
  }
}));

function Page(props){   
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const divRef = useRef();

  const handleClick = (event) => {
    setAnchorEl(divRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let backgroundColor=(props.dia.day()===0||props.dia.day()===0)?"#f4f4f6":"white";
  let diaActual=moment().isSame(props.dia,"day");
  let diaAnterior=moment().isAfter(props.dia,"day");
  let mesActual=props.hoy.isSame(props.dia,"month");
  let mesDespues=moment().isBefore(props.dia);

  let backgroundColorAvatar="#FFFFFF";
  let colorAvatar="#ffffff";

  if(diaActual){
    backgroundColorAvatar= "#1e88e5";
  }else if(diaAnterior){
    colorAvatar= "#7f7f7f";
    backgroundColorAvatar= "#FFFFFF";
  }else if(mesActual){
    colorAvatar= "#1e88e5";
    backgroundColorAvatar= "#FFFFFF";
  }else if(mesDespues){
    colorAvatar= "#000000";
    backgroundColorAvatar= "#FFFFFF";

  }

  console.log("Dia de la semana[0-7]:"+props.dia.day());

  let citasFiltradas=props.citaes.filter((item) => {
                          let citaMoment=moment(item.fecha+", "+item.horaInicio,"YYYY-MM-DD, h:mm:ss a").clone();                     
                          let hayCitaMoment=moment(citaMoment,"X").isBetween(props.dia.clone(),props.dia.clone().endOf("day"));                       
                        return hayCitaMoment;
                    });
  const length = citasFiltradas.length;

      return <div ref={divRef}>
              <Card className={classes.root} style={{backgroundColor:backgroundColor }} variant="outlined">
                <CardHeader
                  className={classes.content}
                  avatar={
                    <Avatar 
                      className={classes.small}
                      alt={props.dia.format("MMM Do YY")} 
                      style={{color:colorAvatar,backgroundColor:backgroundColorAvatar,height: "2.1rem", width: "2.1rem"}}>
                      <Typography variant="subtitle2" >{props.dia.format("DD")}</Typography>
                    </Avatar>                    
                  }       
                
                />                
                <CardContent className={classes.content}>
                  <List className={classes.lista}>
                  {
                   citasFiltradas.map((i,index)=>(                      
                        (index<2)?<EventoCitaItem cita={i} key={index}/>:""                      
                    ))
                  }
                  </List>
                </CardContent>  
                {
                  length>2?<CardActions className={classes.lista} style={{alignContent:"center"}}>
                            <Link component="button" onClick={handleClick}>
                              {length-2} más
                            </Link>
                          </CardActions>:""

                }      
                
              </Card>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
              >
               <DiaDetalle
                dia={props.dia}
                hoy={props.hoy}
                eventos={citasFiltradas}
                />
            </Popover>
    </div>           
}

export default Page;

import React,{Component} from "react";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";
import FaceIcon from '@material-ui/icons/Face';
import Chip from '@material-ui/core/Chip';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import {MASCULINO,FEMENINO} from "../../../utils/constantes";
import "./estilo.css";
import { makeStyles } from '@material-ui/styles';
import { AccountCircleOutlined, Person, PersonOutlined, PersonOutlineSharp } from "@material-ui/icons";
import { IoPersonCircle } from "react-icons/io5";

const useStyles = makeStyles(theme => ({
  badge: {
      backgroundColor: "#78909c",
      marginRight:"0.3rem"
  }
}));

  function Page(props) {
    const classes = useStyles();    
        let p=props.cita;
        let desde=props.cita.horaInicio.substring(0,5);
        let hasta=props.cita.horaFin.substring(0,5);
        let nombrePaciente="";
        let nombreMedico="";

        if(props.medico!==undefined){
          nombreMedico=<Typography color="primary" style={{ textTransform:"capitalize"}}>
                                  {props.medico.titulo+" "+props.medico.nombres+" "+props.medico.apellidoPaterno}                                                                   
                                </Typography>
                                                 
                        
        }


        if(props.paciente!=undefined){
          nombrePaciente=props.paciente.nombres+" "+props.paciente.apellidoPaterno+" "+props.paciente.apellidoMaterno;

        }
        let tipoCita="";
        if(props.catTipoCita!==undefined){
          tipoCita=props.catTipoCita.nombre;
        }

        let edad="";
        if(props.paciente!==undefined){
          edad=props.paciente.edad+"a.";
        }
        let colorFace="#90a4ae";
        if(props.paciente!==undefined){          
          console.log("props.paciente.sexo:"+props.paciente.sexo);
          if(props.paciente.SEXO_id===MASCULINO){
            colorFace="#00aae4";
          }else if(props.paciente.SEXO_id===FEMENINO){
            colorFace="#fe4164";
          }  
        }
      

        let divider="";
        if(props.index!==0){
          divider=<Divider variant="middle" />;
        }

        return  <div>
              
                {divider}
                <ListItem dense button style={{  paddingBottom:6, paddingTop:6 }} component={Link}  to={{
                  hash:'id', 
                  valores:{
                     modalEditar:true,
                     payload: p
                  }
                  }} >
                    <ListItemAvatar>
						              <IoPersonCircle  color="#xxxx" className="face_icon" style={{ color:colorFace,marginRight:".8rem", padding:"0rem",width: "3.5rem",height: "3.5rem"}} alt={p.MEDICO_id} />
                          
                    </ListItemAvatar>
                    <ListItemText id={p.id} 
                          primary={
                                    <Typography  style={{ textTransform:"capitalize"}}>
                                      {nombrePaciente}                                       
                                    </Typography>
                                    
                                  }
					      			    secondary={
                                      <Typography  style={{textTransform:"capitalize"}}>                                       
                                      {nombreMedico}
                                        <Grid container >
                                          
                                          <Grid item className="grid-item">
                                            <Chip  
                                              variant="outlined"                                           
                                              size="small"
                                              color="secondary"
                                              icon={<QueryBuilderIcon />} 
                                              label={ <Typography  style={{ textTransform:"capitalize"}}>{desde+" - "+hasta}</Typography>} 
                                            />
                                          </Grid>                               
                                          <Grid item className="grid-item">
                                            <Chip 
                                              variant="default" 
                                              label={<Typography  style={{ textTransform:"capitalize"}}>{tipoCita}</Typography>}
                                              size="small" 
                                              style={{backgroundColor: props.codColor ,color: "whitesmoke"}} 
                                              />  
                                          </Grid>   
                                        </Grid>
                                      </Typography>
                                     } />
                      <ListItemSecondaryAction >
                        <Badge
                              color="secondary"
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              badgeContent={edad} 
                           />                      
                      </ListItemSecondaryAction>
                </ListItem>
                </div>
    
}
export default Page;
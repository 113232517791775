import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {PACIENTE} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {CAT_SEXO} from "../../../redux/constants/action-type";

const validationSchema = yup.object({
	//expediente: yup.string().required("Campo requerido"),
    nombres: yup.string().trim().required("Campo requerido").matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
    apellidoPaterno: yup.string().required("Campo requerido").matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
    apellidoMaterno: yup.string().matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
	SEXO_id: yup.string().required("Campo requerido"),
	fechaNacimiento: yup.string().required("Campo requerido"),
    edad: yup.number().typeError('Debe introducir un valor numerico').required("Campo requerido"),

    calle: yup.string().max(45, 'debe ser menor a 45 caracteres'),
    ciudad: yup.string().max(45, 'debe ser menor a 45 caracteres'),
    estado: yup.string().max(45, 'debe ser menor a 45 caracteres'),
    pais: yup.string().max(45, 'debe ser menor a 45 caracteres'),
    telefono: yup.string().max(45, 'debe ser menor a 45 caracteres'),
    celular: yup.string().max(45, 'debe ser menor a 45 caracteres'),
    correo: yup.string().max(45, 'debe ser menor a 45 caracteres'),
    expediente: yup.string().max(45, 'debe ser menor a 45 caracteres')
})

class PacienteFormEdit extends Component{

    constructor(props) {
        super(props);
        this.state={
                modalConfirmacion:false,
        };
    }
    abrirConfirmacion=()=> {
        this.setState({
            modalConfirmacion:true
        });
    }
    cerrarConfirmacion=()=> {
        this.setState({
            modalConfirmacion:false
        });
    }

    eliminar=(dato)=> {
        this.cerrarConfirmacion();
        this.props.cerrarModalEditar();
        this.props.eliminarPacienteWSAction(PACIENTE,dato);
    }

    
    render(){    
    
        let valores=this.props.payload;
        console.log("valoresvalores:"+JSON.stringify(valores));

        return <Formik
                    enableReinitialize
                    initialValues={valores}
                    validationSchema={validationSchema}
                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          resetForm({values:""});
                          setSubmitting(this.props.actualizarPacienteWSAction(PACIENTE,values));
                         this.props.history.goBack();
                      }}
                >
                {props => < Page 
                                    {...props}  
                                    modalEditar={this.props.modalEditar} 
                                    tipoSeleccion={this.props.tipoSeleccion} 
                                    cerrarModalEditar={this.props.cerrarModalEditar} 
                                    eliminar={this.eliminar} 
                                    payload={this.props.payload}
                                    modalConfirmacion={this.state.modalConfirmacion}
                                    cerrarConfirmacion={this.cerrarConfirmacion}
                                    abrirConfirmacion={this.abrirConfirmacion} 
                                    catSexo={this.props.catSexo}
                                    />}
                </Formik> 
    }



}
const mapStateToProps=(state)=>({
	catSexo:state.CatSexoReducer[CAT_SEXO],
  
  })
  
  export default withRouter(connect(mapStateToProps)(PacienteFormEdit));


import React,{Component, useState} from "react";
import Page from "./page";
import {connect} from "react-redux";
import { CITA } from "../../../redux/constants/action-type";
import moment from "moment";


function Hora(props) {


    let horaItem = props.hora.clone();//moment(horaItemFormatoString,'YYYY-MM-DD HH:mm');
    let horaItemFinal = props.hora.clone().add(59,"minutes");//moment(horaItemFinalFormatoString,'YYYY-MM-DD HH:mm');

    let horaActual = moment();

//(15nov22) Obtiene las citas que coinciden con el horario y la fecha de hoy , la cual es la selecionada
    const citasFiltradas=props.citaes.filter((item) => {
        let citaMomentInicio=moment(item.fecha+" "+item.horaInicio,"YYYY-MM-DD HH:mm").clone().add(1, 'minutes');                     
        let intercepcion=citaMomentInicio.isBetween(horaItem, horaItemFinal); 
        return intercepcion;
    });

    let horaActualIntercepcion=horaActual.isBetween(horaItem, horaItemFinal); 
    let topHoraActual=0;

    if(horaActualIntercepcion){
        let diffHoraActual= horaActual.diff(horaItem, 'minutes');
        topHoraActual=(diffHoraActual*48)/60;
    
    }

    return <Page 
                horaItem={horaItem}
                hoy={props.hoy}
                citasFiltradas={citasFiltradas}
                horaActualIntercepcion={horaActualIntercepcion}
                topHoraActual={topHoraActual}
            />                        
       
    
}




/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
    citaes:state.CitaReducer[CITA],    
})
/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(mapStateToProps,mapDispatchToProps)(Hora);

import React,{Component} from "react";
import GlucosaList from "../GlucosaList";
import GlucosaBusqueda from "../GlucosaBusqueda";
import GlucosaForm from "../GlucosaForm";
import CircularProgress from '@material-ui/core/CircularProgress';
import GlucosaButtonBar from "../GlucosaFloatButton";
import GlucosaPDFFloatButton from "../GlucosaPDFFloatButton"
import GlucosaFormEdit from "../GlucosaFormEdit";
import Fab from '@material-ui/core/Fab';


export default class Page extends Component{

    render(){
        let loading=this.props.loading;
        var resultDatos;
        var animacionCargando;
        if (loading) {
            animacionCargando = <Fab position="fixed" variant="round" size="small" aria-label="cargando" style={{position: 'fixed', top: "5.5rem", float: "left",
                                    left: "45%", boxShadow:"1", backgroundColor:"#FFFFFF",padding:"0.1rem"}}>
                                    <CircularProgress color="secondary" size="1.5rem"/>           
                                </Fab>
        }else{
            animacionCargando=<div/>
        }
        
        if(this.props.glucosaes.length>0){
            resultDatos =  <div className="col-12" >                       
                                <GlucosaBusqueda buscar={this.props.buscar}/>                                    
                                <GlucosaList  glucosaes={this.props.glucosaes} abrirModalEditar={this.props.abrirModalEditar}
                                                    busqueda={this.props.busqueda}/> 
                            </div>
                                
        }


        return <div style={{marginBottom:"5rem" , marginTop:"5rem" }}>
                                 
                    <GlucosaForm  modal={this.props.modal} 
                                      tipoSeleccion={this.props.tipoSeleccion}
                                        cerrarModal={this.props.cerrarModal} 
                                            agregarGlucosaWSAction={this.props.agregarGlucosaWSAction}/>                
                               
                    <GlucosaFormEdit  modalEditar={this.props.modalEditar} 
                                          payload={this.props.payload} 
                                          tipoSeleccion={this.props.tipoSeleccion}
                                            cerrarModalEditar={this.props.cerrarModalEditar} 
                                                actualizarGlucosaWSAction={this.props.actualizarGlucosaWSAction}
                                                eliminarGlucosaWSAction={this.props.eliminarGlucosaWSAction}    />                

                    <div className="row">
                        {resultDatos} 
                        {animacionCargando}
                    </div>               
                    <div className="row">
                        <GlucosaButtonBar abrirModal={this.props.abrirModal} />
                        <GlucosaPDFFloatButton  />
                    </div>                
                    

                </div>
         
    }
}

import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import { CAT_DIA_SEMANA, ESQUEMA_TIPO_CITA } from "../../../redux/constants/action-type";

class TipoCitaCardListForm extends Component{
   
    render(){    
        return <Page 
                    esquemaTipoCita={this.props.esquemaTipoCita}  
                    esquemaTipoCitaHorarioPeriodo={this.props.esquemaTipoCitaHorarioPeriodo} 
                    setEsquemaTipoCitaHorarioPeriodo={this.props.setEsquemaTipoCitaHorarioPeriodo}
                    cat_dia_semana={this.props.cat_dia_semana}
                    
                    />
    }
   

}

const mapStateToProps=(state)=>({
    cat_dia_semana:state.DiaSemanaReducer[CAT_DIA_SEMANA],

})



export default connect(mapStateToProps,null)(TipoCitaCardListForm);



import {CONSULTAR_CAT_TIPO_PRODUCTOS,AGREGAR_CAT_TIPO_PRODUCTO,ACTUALIZAR_CAT_TIPO_PRODUCTO, ELIMINAR_CAT_TIPO_PRODUCTO} from "../constants/CatTipoProducto-action-type";


/* CAT_TIPO_PRODUCTOS (HOSPITAL CLINICA) */
export const obtenerCatTipoProductoListWSAction = (dataType)=> {
    return {
        type:CONSULTAR_CAT_TIPO_PRODUCTOS,
        dataType:dataType,
        loading: true
    }
}

export const agregarCatTipoProductoWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_CAT_TIPO_PRODUCTO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarCatTipoProductoWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_CAT_TIPO_PRODUCTO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarCatTipoProductoWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_CAT_TIPO_PRODUCTO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

import React from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    marginTop:12
  },
}));

export default function Page(props) {

  const handleChangeDesde = (event) => {
    props.setHoraInicial(event.target.value)
  };

  const handleChangeHasta = (event) => {
    props.setHoraFinal(event.target.value)
  };

  const handleClose = () => {
    props.setOpenPeriodoEditDialog(false);
  };
  const handleEditClick = () =>  {
    if(props.existeHorario()){
      props.mostrarMensaje(true,"Horario no valido, revise que los horarios no se crucen.");
    }else{
      props.handleEditClick();
      props.setOpenPeriodoEditDialog(false);   
    }
  };

  const classes = useStyles();

      return (<Dialog open={props.openPeriodoEditDialog}  aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Editar Horario</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Agrega un horario de trabajo para el dia {props.dia}
                  </DialogContentText>
                  <Box mt="1rem" >
                    <TextField
                      id="time"
                      label="Desde"
                      type="time"
                      defaultValue={props.horaInicial}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      onChange={handleChangeDesde}
                    />
                  </Box>
                  <Box mt="1rem" >
                    <TextField
                      id="time"
                      label="Hasta"
                      type="time"
                      defaultValue={props.horaFinal}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      onChange={handleChangeHasta}
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancelar
                  </Button>
                  <Button onClick={handleEditClick} color="primary">
                    Actualizar
                  </Button>
                </DialogActions>
            </Dialog>);
                             
}

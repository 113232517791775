import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import { ESQUEMA_TIPO_CITA } from "../../../redux/constants/action-type";
import {agregarEsquemaTipoCitaWSAction} from "../../../redux/actions/EsquemaTipoCitaActions";


class TiposDeCitasMenuListForm extends Component{ 
    constructor(props) {
        super(props);

    }

    setEsquemaTipoCita=(valor)=>{
    console.log("setEsquemaTipoCita:"+JSON.stringify(valor));
    this.props.agregarEsquemaTipoCitaWSAction(ESQUEMA_TIPO_CITA,valor);

    }

    render(){    
        let tipoDeCitasMenu="";
        if(this.props.cat_tipo_cita!==undefined&&this.props.esquemaTipoCita!==undefined){           
            tipoDeCitasMenu=this.props.cat_tipo_cita.filter(item=>{       
      
                if (this.props.esquemaTipoCita.filter(esquemaTipoCitaItem=> esquemaTipoCitaItem.CAT_TIPO_CITA_id===item.id).length > 0) {
                    return false; 
                  }else{
                      return true;
                  }
            });
        }
                
        return <Page
                        tipoDeCitasMenu={tipoDeCitasMenu}
                        setEsquemaTipoCita={this.setEsquemaTipoCita}   
                        cat_tipo_cita={this.props.cat_tipo_cita}
                        ESQUEMA_TRABAJO_id={this.props.ESQUEMA_TRABAJO_id}
                />
    }



}

const mapStateToProps=(state,ownProps)=>({

})

const mapDispatchToProps=(dispatch)=>({
    agregarEsquemaTipoCitaWSAction: (type,loading) => dispatch(agregarEsquemaTipoCitaWSAction(type,loading)),

})

export default connect(mapStateToProps,mapDispatchToProps)(TiposDeCitasMenuListForm);
import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import {GLUCOSA} from "../../../redux/constants/action-type";
import {obtenerGlucosaListWSAction,agregarGlucosaWSAction,actualizarGlucosaWSAction,eliminarGlucosaWSAction} from "../../../redux/actions/GlucosaActions";


 class GlucosaPDFFloatButton extends Component{
    render(){    
        return <Page glucosaes={this.props.glucosaes}/>
    }
       
 /* esta es la accion de middleware*/
    componentDidMount(){            
        this.props.obtenerGlucosaListWSAction(GLUCOSA,true); /* Invoca por primara vez a productos (default) */
    }

 }

    /*
    funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
    */
    const mapStateToProps=(state)=>({
        glucosaes:state.GlucosaReducer[GLUCOSA],    
    })

    /*
    permite que una funcion o llamada a funcion esten disponibles como un props
    regresa una funcion creadas en actions
    */
    const mapDispatchToProps = (dispatch, ownProps) => ({
        obtenerGlucosaListWSAction: (type,loading) => dispatch(obtenerGlucosaListWSAction(type,loading)),
    })


export default connect(mapStateToProps,mapDispatchToProps)(GlucosaPDFFloatButton);
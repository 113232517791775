import React,{Component} from "react";
import PacientesListItem from "../ProductoListItem";
import List from '@material-ui/core/List';

export default class Page extends Component{

  constructor(props) {
    super(props);
  }

    render(){      
      return <List > {
            this.props.productosFilter.map(producto=> 
                      <PacientesListItem producto={producto} abrirModalEditar={this.props.abrirModalEditar}/> )
            }</List>
    }                          
}

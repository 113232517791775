import React,{Component } from "react";
import Page from "./page";
import {connect} from "react-redux";
import {PACIENTE,CAT_SEXO} from "../../../redux/constants/action-type";
import {obtenerPacienteListWSAction,agregarPacienteWSAction,actualizarPacienteWSAction,eliminarPacienteWSAction} from "../../../redux/actions/PacienteActions";
import {obtenerSexoListWSAction} from "../../../redux/actions/CatSexoActions"


class Pacientees extends Component{

    constructor(props) {
        super(props);
        this.state={
                modal:false,
                modalEditar:false,
                payload: {
					'expediente': '',
					'nombres': '',
					'apellidoPaterno': '',
					'apellidoMaterno': '',
					'SEXO_id': '',
					'fechaNacimiento': '',
					'edad': '',
					'calle': '',
					'ciudad': '',
					'estado': '',
					'pais': '',
					'telefono': '',
					'celular': '',
					'correo': ''
                },
                busqueda:""

        };
    }
     
    abrirModal=()=> {
        this.setState({
            modal:true
        });
    }

    cerrarModal=()=> {
        this.setState({
            modal:false
        });
    }

    abrirModalEditar = (value)=> {
        this.setState({
            modalEditar:true,
            payload: value

        });
    };

    cerrarModalEditar=()=> {
        this.setState({
            modalEditar:false
        });
    }

    buscar=(valor)=> {
        this.setState({
            busqueda:valor
        });
    }


    render(){        
        return  <Page pacientees={this.props.pacientees} loading={this.props.loading} 
                    modal={this.state.modal}
                    modalEditar={this.state.modalEditar}
                    payload={this.state.payload}
                       abrirModal={this.abrirModal}
                           cerrarModal={this.cerrarModal}
                                abrirModalEditar={this.abrirModalEditar}
                                    cerrarModalEditar={this.cerrarModalEditar}
                                    tipoSeleccion={this.props.tipoSeleccion}
                                agregarPacienteWSAction={this.props.agregarPacienteWSAction}
                                actualizarPacienteWSAction={this.props.actualizarPacienteWSAction}
                                eliminarPacienteWSAction={this.props.eliminarPacienteWSAction}   
                                buscar={this.buscar} busqueda={this.state.busqueda}/>    
    }

 /* esta es la accion de middleware*/
        componentDidMount(){            
            let valores=this.props.location.valores;            
            if(valores === undefined){
                this.props.obtenerPacienteListWSAction(PACIENTE,true); /* Invoca por primara vez a productos (default) */
                this.props.obtenerSexoListWSAction(CAT_SEXO,false);
            }else{
                this.setState(this.props.location.valores);
            }

        }


}

/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
    pacientees:state.PacienteReducer[PACIENTE],    
    loading:state.estadosPeticionReducer.loading,
    tipoSeleccion:state.estadosReducer.selectedType,

})

/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        obtenerPacienteListWSAction: (type,loading) => dispatch(obtenerPacienteListWSAction(type,loading)),
        agregarPacienteWSAction: (type,payload) => dispatch(agregarPacienteWSAction(type,payload)),
        actualizarPacienteWSAction: (type,payload) => dispatch(actualizarPacienteWSAction(type,payload)),
        eliminarPacienteWSAction: (type,payload) => dispatch(eliminarPacienteWSAction(type,payload)),
        obtenerSexoListWSAction:(type,loading)=> dispatch(obtenerSexoListWSAction(type,loading))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Pacientees);



import React,{Component, useState} from "react";
import Page from "./page";
import {connect} from "react-redux";

function ReglaLateralHorasDisplay(props) {
   
    return <Page 
                horasDiaArray={props.horasDiaArray}
            />                               
   
}




/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({   
})
/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => ({    
})

export default connect(mapStateToProps,mapDispatchToProps)(ReglaLateralHorasDisplay);

import {CONSULTAR_SEXO} from "../constants/CatSexo-action-type";


/* COLORES (HOSPITAL CLINICA) */
export const obtenerSexoListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_SEXO,
        dataType:dataType,
        loading: loading
    }
}


import React,{Component} from "react";


import { emphasize, withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import TiposDeCitasMenuListForm from "../TiposDeCitasMenuListForm"
import TipoCitaCardListForm from "../TipoCitaCardListForm"

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightBold,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

export default class Page extends Component{

    render(){      
      
    return <div>
                <TiposDeCitasMenuListForm 
                    esquemaTipoCita={this.props.esquemaTipoCita}  
                    cat_tipo_cita={this.props.cat_tipo_cita}
                    ESQUEMA_TRABAJO_id={this.props.ESQUEMA_TRABAJO_id}
                    />

                <TipoCitaCardListForm 
                    esquemaTipoCita={this.props.esquemaTipoCita}  
                    cat_tipo_cita={this.props.cat_tipo_cita}
                    esquemaTipoCitaHorarioPeriodo={this.props.esquemaTipoCitaHorarioPeriodo} 
                    setEsquemaTipoCitaHorarioPeriodo={this.props.setEsquemaTipoCitaHorarioPeriodo}/>
           </div>
    }
}

import React from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withRouter} from "react-router-dom";
import Divider from '@material-ui/core/Divider';

import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
function Page(props) {
		
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	function goBack(){
		props.history.goBack();
	}

	let appBar="";
	let actions="";

	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={goBack} aria-label="close">
						<ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
						</IconButton>
						<Typography variant="h6" style={{ flexGrow: 1}} >
							{<Typography type="Roboto" style={{flexGrow:"1",color: '#000000', fontSize:'1.2rem',fontWeight:'normal',textTransform: "capitalize" }}>Nueva Medición</Typography>}
						</Typography>

						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
						<Button variant="contained"  edge="start" color="default" onClick= {props.cerrarModal}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
	}

    return (<form onSubmit={props.handleSubmit}> 
                    <Dialog
                    open={props.modal}
                    onClose={props.cerrarModal}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
					fullScreen={fullScreen}
                    >

                        <DialogTitle id="form-dialog-title">Nueva Medición</DialogTitle>
                        
						{appBar}

							<DialogContent>
							
							<TextField
								error={props.touched.valorSistolica&&props.errors.valorSistolica? true:false}
								helperText={props.touched.valorSistolica&&props.errors.valorSistolica?props.errors.valorSistolica:""}
								size="medium"
								margin="normal"
								id="valorSistolica"
								label="SYS. (Alta)"
								type="number"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.valorSistolica}
								onBlur={props.handleBlur}
								autoComplete="off"
								InputProps={{
									endAdornment: <InputAdornment position="end">mmHg</InputAdornment>
								  }}
							/>
							<TextField
								error={props.touched.valorDiastolica&&props.errors.valorDiastolica? true:false}
								helperText={props.touched.valorDiastolica&&props.errors.valorDiastolica?props.errors.valorDiastolica:""}
								size="medium"
								margin="normal"
								id="valorDiastolica"
								label="DIA. (Baja)"
								type="number"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.valorDiastolica}
								onBlur={props.handleBlur}
								autoComplete="off"
								InputProps={{
									endAdornment: <InputAdornment position="end">mmHg</InputAdornment>
								  }}

							/>
							<TextField
								error={props.touched.pulso&&props.errors.pulso? true:false}
								helperText={props.touched.pulso&&props.errors.pulso?props.errors.pulso:""}
								size="medium"
								margin="normal"
								id="pulso"
								label="PULSO (opcional)"
								type="number"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.pulso}
								onBlur={props.handleBlur}
								autoComplete="off"
								InputProps={{
									endAdornment: <InputAdornment position="end">/min</InputAdornment>
								  }}
							/>
							
							<TextField
								error={props.touched.fecha&&props.errors.fecha? true:false}
								helperText={props.touched.fecha&&props.errors.fecha?props.errors.fecha:""}
								size="medium"
								margin="normal"
								id="fecha"
								label="Fecha:"
								type="date"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.fecha}
								onBlur={props.handleBlur}
							/>
							<TextField
								error={props.touched.hora&&props.errors.hora? true:false}
								helperText={props.touched.hora&&props.errors.hora?props.errors.hora:""}
								size="medium"
								margin="normal"
								id="hora"
								label="Hora:"
								type="time"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.hora}
								onBlur={props.handleBlur}
							/>
							<TextField
								error={props.touched.nota&&props.errors.nota? true:false}
								helperText={props.touched.nota&&props.errors.nota?props.errors.nota:""}
								size="medium"
								margin="normal"
								id="nota"
								label="NOTA(opcional):"
								type="area"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.nota}
								onBlur={props.handleBlur}
								multiline
								rows={2}
								rowsMax={4}
								
							/>
                            </DialogContent>
                        {actions}                
                    </Dialog>
                    </form>
                       
			)
	}
export default  withRouter(Page);

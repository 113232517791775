import React,{Component} from "react";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";
import { Person } from "@material-ui/icons";

export default class Page extends Component{

    render(){
        
        
        function obtenerColor(nombre) {
            if(nombre!=null){
                var size=nombre.length;
                do {
                   if(size>colores.length){
                      size=size-colores.length;
                   } 
                }while (size>colores.length);
  
              return colores[size];
  
            }else{
                return colores[0];
            }
          }

        let colores=["#babdbe","#00bfa5","#8eacbb","#0288D1","#34515e"];
        let p=this.props.usuario;
        return  <div>
                <ListItem dense button style={{  paddingBottom:6, paddingTop:6 }} component={Link}  to={{
                  hash:'id', 
                  valores:{
                     modalEditar:true,
                     payload: p
                  }
                  }} >
                    <ListItemAvatar>
					          	<Person style={{ marginRight:".8rem", width: "3.5rem",height: "3.5rem", color:obtenerColor(p.usuario)}} alt={p.usuario} />
                    </ListItemAvatar>

					<ListItemText id={p.id} 
                        primary={<Typography  style={{ textTransform:"none"}}>{p.usuario}</Typography>}
								        secondary={<Typography  style={{ textTransform:"none"}}>{p.correo}</Typography>} />


                </ListItem>
                <Divider variant="inset" component="li" />

                </div>
    }
}

import React,{Component} from "react";
import Page from "./page";

export default class CitaSubList extends Component{

    render(){
			console.log("index(CitaSubList):"+this.props.id);
        return <Page key={this.props.id} id={this.props.id} citas={this.props.citas} fecha={this.props.fecha} abrirModalEditar={this.props.abrirModalEditar} 
                     />
    }

}


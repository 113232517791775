import React,{Component, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withRouter} from "react-router-dom";
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';

import CitasDisponibles from "../CitasDisponibles"
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Grid from "@material-ui/core/Grid"

import MedicoFormSelect from "../MedicoFormSelect";

import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';

import NuevoPacienteForm from "../NuevoPacienteForm"
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { useRef,useEffect } from "react";
import QRScanField from "../QRScanField";
import QRScanDialog from "../QRScanDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  function Page(props) {


	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const [idPacienteValor,setIdPacienteValor] =useState("");

	console.log("idPacienteValor:"+idPacienteValor)

	function goBack(){
		props.history.goBack();
	}

	let appBar="";
	let actions="";
	let resultado="";
	let inputScan="";

	console.log("props.citas:"+props.citas.lenght)
	let cita=props.citas.filter(item=>item.id===idPacienteValor)[0]; 

	if(cita!==undefined){
		resultado=<QRScanDialog cita={cita} 
								setQrmodal={props.setQrmodal}
								setIdPacienteValor={setIdPacienteValor}/>

  	}
	  if(resultado===""){
		inputScan=<QRScanField idPacienteValor={idPacienteValor}
								setIdPacienteValor={setIdPacienteValor}/>
	  }
	
	console.log("cita(qrscan):"+JSON.stringify(cita));


	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={goBack} aria-label="close">
							<ArrowBackIcon color="secondary" style={{ borderWidth:"1",width: "2.0rem",height: "2.0rem"}}  />
						</IconButton>
						<Typography variant="h6" style={{ flexGrow: 1}} >
						Escanear QR
						</Typography>

						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
						<Button variant="contained"  edge="start" color="default" onClick= {()=>props.setQrmodal(false)}>Cancelar</Button>
				</MuiDialogActions>
	}

        return (<div>
	
						<Dialog
							open={props.qrmodal}
							onClose={() => props.setQrmodal(false)}
							aria-labelledby="form-dialog-title"
							TransitionComponent={Transition}
							fullScreen={fullScreen}
							disableEnforceFocus
						>
								
						
							<DialogTitle id="form-dialog-title">Escanear de código QR</DialogTitle>                        
							{appBar}

							<DialogContent>				
								<Grid container spacing={1}>
									<Grid item xs={12}>
										{inputScan}			
									</Grid>
									<Grid item xs={12}>
										{resultado}			 							
									</Grid>
								</Grid>
															
							</DialogContent>
							{actions}                			
						</Dialog>
													
					
					</div>
                       
                )


    
}
export default  withRouter(Page);

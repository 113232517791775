import React,{Component} from "react";
import HorariosChipListItemForm from "../HorariosChipListItemForm";


export default class Page extends Component{

   
    render(){
        console.log("horario<:>"+JSON.stringify(this.props.horario));   

        let horariosChip="";
        if(this.props.horario!==undefined){
          horariosChip=this.props.horario.map(horariosChipItem=> 
                <HorariosChipListItemForm horariosChipItem={horariosChipItem} 
                                            dia_semanaItem={this.props.dia_semanaItem}
                                            setHorario={this.props.setHorario}
                                            setOpenPeriodoEditDialog={this.props.setOpenPeriodoEditDialog}
                                            setPeriodo={this.props.setPeriodo}
                                            handleEliminarPeriodoClick={this.props.handleEliminarPeriodoClick}
                                            />        
  
              );
        }

            return  <div>
                        {horariosChip}                        
                    </div>
    }
}


import React,{Component} from "react";
import Page from "./page";
import { connect } from "react-redux";

import {CITA} from "../../../redux/constants/action-type";

import {isHorarioIntersect} from "../../../utils/moment"
import {getFechaHoraString} from "../../../utils/date";
import { PACIENTE,CAT_TIPO_CITA,COLOR } from "../../../redux/constants/action-type";

class HorarioListItem extends Component{

    constructor(props) {
        super(props);

        this.state={
            desde:this.props.desde,
            hasta:this.props.hasta
        }
    }
    
    desdeLocal=this.props.desde;
    hastaLocal=this.props.hasta;
    isjustado=false;




    //16Ene21 formato de fecha en item YYYY-MM-DD (2021-01-16)
    getFechaStringItem(){
        let dia=("0" + this.props.dia).slice(-2);
        let idMes=("0" + (this.props.idMes + 1)).slice(-2);
        let anio=this.props.anio;
        let fecha=anio+"-"+idMes+"-"+dia;
        return fecha;
    }
    getFechaHoraInicialStringItem(){
        let dia=("0" + this.props.dia).slice(-2);
        let idMes=("0" + (this.props.idMes + 1)).slice(-2);
        let anio=this.props.anio;
        let fecha=anio+"-"+idMes+"-"+dia;

        let fechaHora=fecha+" "+this.desdeLocal; //19Ene21 atualizacion a variable local
        return fechaHora;
    }
    getFechaHoraFinalStringItem(){
        let dia=("0" + this.props.dia).slice(-2);
        let idMes=("0" + (this.props.idMes + 1)).slice(-2);
        let anio=this.props.anio;
        let fecha=anio+"-"+idMes+"-"+dia;

        let fechaHora=fecha+" "+this.hastaLocal;
        return fechaHora;
    }



    horarioContenidoList=()=>{
        let horarioContenidoList=[];
/*
        this.props.citaes.map(item=>{
            let horarioStringDesdeItem=this.getFechaHoraInicialStringItem(); //19Ene21 Se actualiza para obtener el valor de variable local(SI SE AJUSTA OBTIEN EL HORARIO AJUSTADO)
            let horarioStringHastaItem=this.getFechaHoraFinalStringItem(); //19Ene21 Se actualiza para obtener el valor de variable local(SI SE AJUSTA OBTIEN EL HORARIO AJUSTADO)
            let horarioStringInicial=getFechaHoraString(item.fecha,item.horaInicio);
            let horarioStringFinal=getFechaHoraString(item.fecha,item.horaFin);

         //   if(this.isHorarioContenido(horarioStringInicial,horarioStringFinal,horarioStringItem)){}
         if(isHorarioIntersect(horarioStringInicial,horarioStringFinal,horarioStringDesdeItem,horarioStringHastaItem)){
                horarioContenidoList.push(item);       
                console.log("AQUIII LO ENCUENTRA");
            //    this.desdeLocal="10:30";

            }        
        })

*/
        return horarioContenidoList;
    }

    getCodColorItem=()=>{
        let codColor="";
        if(this.props.catTipoCita!==undefined){
            const newValue = this.props.colores.find((item)=> item.id === this.props.catTipoCita.COLOR_id );   
            codColor=newValue.color;
        }
        return codColor;
    }

    render(){
        let horarioContenidoList=this.horarioContenidoList();
        let codColorItem=this.getCodColorItem();

        return <Page 
                     horarioContenidoList={horarioContenidoList}
                     desde={this.props.desde} 
                     hasta={this.props.hasta} 
                     action={this.props.action}  
                     ajustado={this.props.ajustado}  
                     contenido={this.props.contenido} 
                     codColorItem={codColorItem}      
                                          
                     setFecha={this.props.setFecha} 
                     setHoraInicio={this.props.setHoraInicio} 
                     setHoraFin={this.props.setHoraFin} 
                     
                     codColor={this.props.codColor} 
                     horaInicio={this.props.horaInicio} 
                     horaFin={this.props.horaFin}
                     
                     dia={this.props.dia} 
                     idMes={this.props.idMes} 
                     anio={this.props.anio}/>
    }
}

const mapStateToProps =(state,ownProps)=>({
    citaes:state.CitaReducer[CITA],    
    colores:state.ColorReducer[COLOR],
    catTipoCita:state.CatTipoCitaReducer[CAT_TIPO_CITA].filter(p => p.id === ownProps.idTipoCita)[0]
  
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(mapStateToProps,mapDispatchToProps)(HorarioListItem);


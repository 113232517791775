import React,{Component} from "react";
import TipoCitaCardListItemForm from "../TipoCitaCardListItemForm"


export default class Page extends Component{

   
    render(){
      let tipoCitaCardListItemForm="";
      if(this.props.esquemaTipoCita!==undefined){
        tipoCitaCardListItemForm=this.props.esquemaTipoCita.map(esquemaTipoCitaItem=> 
            <TipoCitaCardListItemForm 
                        esquemaTipoCitaItem={esquemaTipoCitaItem} 
                        cat_dia_semana={this.props.cat_dia_semana}
                        />
            );
      }

      console.log("tipoCitaCardListItemForm:::-"+JSON.stringify(this.props.esquemaTipoCita));
            return  <div>
                        {
                         tipoCitaCardListItemForm
                        }
                    </div>
    }
}


import React,{Component, useState} from "react";
import Page from "./page";
import {connect} from "react-redux";
import {PACIENTE,CAT_TIPO_CITA, MEDICO_USUARIO} from "../../../redux/constants/action-type";
import { agregarMedicoWSAction } from "../../../redux/actions/MedicoActions";


function Registro(props) {

  const [medicoRegistrado,setMedicoRegistrado]=useState("");

        return <Page 
                  autenticacionLogin={props.autenticacionLogin} 
                  errorsForm={props.errorsForm} 
                  usuario={props.usuario}    
                  loading={props.loading}  
                  agregarMedicoWSAction={props.agregarMedicoWSAction}
                  medicoRegistrado={medicoRegistrado}
                  setMedicoRegistrado={setMedicoRegistrado}
                />                        
       
    
}




/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
    usuario:state.loginReducer.usuario,
    loading:state.loginReducer.loading,
    errorsForm:state.loginReducer.errors,
    autenticado:state.autenticacionReducer.autenticado, 
    profil:state.autenticacionReducer.profil,     
    pacientes:state.datosReducer[PACIENTE],       
    openModalPaciente:state.estadosReducer.openModalPaciente,
    catTipoCitaes:state.CatTipoCitaReducer[CAT_TIPO_CITA]    

})
/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        agregarMedicoWSAction: (type,payload) => dispatch(agregarMedicoWSAction(type,payload)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Registro);

import { Avatar, Box, Button, ButtonGroup, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import React,{Component} from "react";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
 
  textHora:{
    fontSize: [10, "!important"],
    display: "block",
    position: "relative",
    top: "-7px",
    right:"0",
    textAlign:"right",  
    alignItems:"right",

  },
  paper: {
    borderRadius:"0",
    borderBottom:"0",
    borderRight:"0",
    borderTop:"0",
    borderLeft:"0",
    height:"48px",
    width:"48px",

  },
  inpaper: {
    borderRadius:"0",
    borderBottom:"0",
    borderRight:"0",
    borderLeft:"0",
    height:"48px",
    width:"6px"

  },
 
}));

function Page(props){   
  const classes = useStyles();
  
  let backgroundColorAvatar="";
  let diaActual=moment().isSame(props.hoy,"day");
  if(diaActual){
    backgroundColorAvatar="#1a90ff";
  }
  console.log("diaActual_:"+diaActual);

      return<div>
              {
                props.horasDiaArray.map((item,index)=>
                <div key={index}>
                  <Box display="flex" flexDirection="row">
                    <Paper p={1} className={classes.paper} variant="outlined"> 
                      <Typography color="textSecondary" className={classes.textHora}>
                          {(index)?moment(index ,"HH").format("LT"):""}
                      </Typography>  
                    </Paper>
                    <Paper className={classes.inpaper} variant="outlined"></Paper>
                  </Box>
                </div>
              )
              }
            </div>
}

export default Page;

import React,{Component} from "react";
import CitaListItem from "../CitaListItem";
import List from '@material-ui/core/List';
import { Paper, Typography } from "@material-ui/core";
import {getFecha,getFormatoHora} from "../../../utils/moment";

export default class Page extends Component{

  constructor(props) {
    super(props);    
    }
    

    render(){   


      return <div>
              <div elevation={0} style={{backgroundColor:"#eceff1",borderColor:"#eceff1",borderRadius:"0.0rem",borderWidth:"0.0rem",marginLeft:"0.0rem",marginRight:"0.0rem",paddingRight:"0.0rem"}}>
                <Typography  align="left" style={{ color:"#37474f",marginLeft:"0.3rem",textTransform:"capitalize"}}>
                  {getFecha(this.props.fecha)}
                </Typography>
              </div>
              {
                  this.props.citas.map((cita,index)=>{
                    return <CitaListItem cita={cita} 
                                          index={index}
                                         abrirModalEditar={this.props.abrirModalEditar} 
                                         /> 
                  })                              
              }
            </div>
    }                          
}

import React,{Component} from "react";
import Page from "./page";
import {  MEDICO,ESQUEMA_TRABAJO } from "../../../redux/constants/action-type";
import {connect} from "react-redux";
import {obtenerMedicoListWSAction,agregarMedicoWSAction,actualizarMedicoWSAction,eliminarMedicoWSAction} from "../../../redux/actions/MedicoActions";


class MedicoFilterFormSelect extends Component{


    render(){    
    
        let medicosFilter=this.props.medicos.filter(medico=>(!this.props.esquemaTrabajos.some(esquemaT=>esquemaT.MEDICO_id===medico.id)));
   
        return<Page medicos={medicosFilter} MEDICO_id={this.props.MEDICO_id} medicoValue={this.props.medicoValue} 
                    handleChange={this.props.handleChange} 
                    errors={this.props.errors}
                    touched={this.props.touched}/>
    }

    componentDidMount(){            
        this.props.obtenerMedicoListWSAction(MEDICO,false); /* Invoca por primara vez a productos (default) */
    }
}

const mapStateToProps=(state,ownProps)=>({
   // (31jul21) Se consulta los esquema de trabajo existentes para aplicar un filter al array medicos y dejar solo los medicos sin equema de trabajo
   esquemaTrabajos:state.EsquemaTrabajoReducer[ESQUEMA_TRABAJO],    
   medicos:state.MedicoReducer[MEDICO]

})


const mapDispatchToProps = (dispatch, ownProps) => ({
        obtenerMedicoListWSAction: (type,loading) => dispatch(obtenerMedicoListWSAction(type,loading)),
})


export default connect(mapStateToProps,mapDispatchToProps)(MedicoFilterFormSelect);
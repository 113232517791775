import React,{Component} from "react";
import Page from "../page";
import {connect} from "react-redux";
import {logout} from "../../../redux/actions/autenticacionActions"
import requireProfil from "../../../utils/requireProfil";
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import {Link} from "react-router-dom";


class ListItemComponent extends Component{
    render(){        
        console.log("this.props.opcion:"+this.props.opcion);
        console.log("this.props.menu:"+this.props.menu);
        return <ListItem button 
                         selected={this.props.menu===this.props.opcion} 
                         component={Link} 
                         to={this.props.ruta}  
                         onClick={this.props.handleDrawerToggleClose}
                >                                    
                    <ListItemIcon>{this.props.icon}</ListItemIcon>
                    <ListItemText  primary={
                                            <Typography  style={{ textTransform:"capitalize"}}>
                                                {this.props.nombreMenu}                                       
                                            </Typography>
                                            } 
                    />
                </ListItem>
    }
}


export default requireProfil(ListItemComponent);

import React,{Component} from "react";
import Fab from '@material-ui/core/Fab';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PresionArterialDocument from "../PresionArterialDocument"
import { PDFDownloadLink } from '@react-pdf/renderer'


const Page =(props)=>{
 
      let stuff;
      if(props.presionArteriales.length>0){
        console.log("this.props.presionArteriales :"+props.presionArteriales);
        stuff =<Fab
                  position="fixed"
                    variant="extended"
                    size="large"
                    color="default"
                    aria-label="agregar"
                    style={{position: 'fixed', bottom: "5.5rem", right: "1rem",boxShadow:"1", backgroundColor:"#FFFFFF"}}
                  
                  >
                    <PDFDownloadLink document={<PresionArterialDocument presionArteriales={props.presionArteriales}/>} fileName="presionArterial.pdf">
                      <PictureAsPdfIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
                      {({ blob, url, loading, error }) => (loading ? 'Loading document...' : '')}
                    </PDFDownloadLink> 
              </Fab>
            
      }
  
      return (<div>{stuff}</div>)
                   

}

export default Page;
import {CONSULTAR_ESQUEMA_TRABAJOS,AGREGAR_ESQUEMA_TRABAJO,ACTUALIZAR_ESQUEMA_TRABAJO, ELIMINAR_ESQUEMA_TRABAJO} from "../constants/EsquemaTrabajo-action-type";


/* ESQUEMA_TRABAJOS (HOSPITAL CLINICA) */
export const obtenerEsquemaTrabajoListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_ESQUEMA_TRABAJOS,
        dataType:dataType,
        loading: loading
    }
}

export const agregarEsquemaTrabajoWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_ESQUEMA_TRABAJO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarEsquemaTrabajoWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_ESQUEMA_TRABAJO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarEsquemaTrabajoWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_ESQUEMA_TRABAJO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

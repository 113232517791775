import React,{Component} from "react";
import InstitucionList from "../InstitucionList";
import InstitucionBusqueda from "../InstitucionBusqueda";
import InstitucionForm from "../InstitucionForm";
import CircularProgress from '@material-ui/core/CircularProgress';
import InstitucionButtonBar from "../InstitucionFloatButton";
import InstitucionFormEdit from "../InstitucionFormEdit";
import Fab from '@material-ui/core/Fab';


export default class Page extends Component{

    render(){
        let loading=this.props.loading;
        var resultDatos;
        var animacionCargando;
        if (loading) {
            animacionCargando = <Fab position="fixed" variant="round" size="small" aria-label="cargando" style={{position: 'fixed', top: "5.5rem", float: "left",
                                    left: "45%", boxShadow:"1", backgroundColor:"#FFFFFF",padding:"0.1rem"}}>
                                    <CircularProgress color="secondary" size="1.5rem"/>           
                                </Fab>
        }else{
            animacionCargando=<div/>
        }
        
        if(this.props.instituciones.length>0){
            resultDatos =  <div className="col-12" >                       
                                <InstitucionBusqueda buscar={this.props.buscar}/>                                    
                                <InstitucionList  instituciones={this.props.instituciones} abrirModalEditar={this.props.abrirModalEditar}
                                                    busqueda={this.props.busqueda}/> 
                            </div>
                                
        }


        return <div style={{marginBottom:"5rem" , marginTop:"5rem" }}>
                                 
                    <InstitucionForm  modal={this.props.modal} 
                                      tipoSeleccion={this.props.tipoSeleccion}
                                        cerrarModal={this.props.cerrarModal} 
                                            agregarInstitucionWSAction={this.props.agregarInstitucionWSAction}/>                
                               
                    <InstitucionFormEdit  modalEditar={this.props.modalEditar} 
                                          payload={this.props.payload} 
                                          tipoSeleccion={this.props.tipoSeleccion}
                                            cerrarModalEditar={this.props.cerrarModalEditar} 
                                                actualizarInstitucionWSAction={this.props.actualizarInstitucionWSAction}
                                                eliminarInstitucionWSAction={this.props.eliminarInstitucionWSAction}    />                

                    <div className="row">
                        {resultDatos} 
                        {animacionCargando}
                    </div>               
                    <div className="row">
                        <InstitucionButtonBar abrirModal={this.props.abrirModal} />
                    </div>                
                    

                </div>
         
    }
}

import React,{Component, useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {withRouter} from "react-router-dom";
import {CITA} from "../../../redux/constants/action-type";
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';
import ConfirmacionDialog from "./ConfirmacionDialog";


import CitasDisponibles from "../CitasDisponibles"
import AccountCircle from '@material-ui/icons/AccountCircle';
import PersonAdd from '@material-ui/icons/PersonAdd';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Grid from "@material-ui/core/Grid"

import MedicoFormSelect from "../MedicoFormSelect";

import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import { Paper } from "@material-ui/core";
import QRGeneracion from "./QRGeneracion";
import {getFechatoLocaleDateString, getHorarioLocaleString} from "../../../utils/date";
import { ESTATUS_AGENDADA, ESTATUS_ENVIADA } from "../../../utils/constantes";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

  function Page(props) {
	
	useEffect(() => {
        console.log(">>useEffect()<<:");
    });

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


	function goBack(){
		props.history.goBack();
	}

	function handleChange(e,value){
		if(value!==null){
			props.setFieldValue("PACIENTE_id",value.id);	
		}
		
	  }
	  
	let appBar="";
	let actions="";
	let QRCita="";

	console.log("VALORES(id cita):"+props.values.id);
	console.log("VALORES(id medico):"+props.medico);
	

	if(props.values.id!==undefined){
		var pacienteQR=props.pacientes.filter(item=>item.id===props.values.PACIENTE_id)[0];
		var medicoQR=props.medico.filter(item=>item.id===props.values.MEDICO_id)[0];
		var tipoCitaQR=props.catTipoCita.filter(item=>item.id===props.values.CAT_TIPO_CITA_id)[0];
		var estadoCita=props.catEstadosCita.filter(item=>item.id===props.values.CAT_ESTADO_CITA_id)[0];


		var nombrePacienteQR="";
		var nombreMedicoQR="";
		var procedimientoQR="";
		var fechaQR="";
		var horaCita="";
		

		if(pacienteQR!==undefined){
			console.log("pacientesXC:"+JSON.stringify(pacienteQR));
			nombrePacienteQR=pacienteQR.nombres+" "+pacienteQR.apellidoPaterno+" "+pacienteQR.apellidoMaterno;
		}

		if(medicoQR!==undefined){
			console.log("medicoQR:"+JSON.stringify(medicoQR));
			nombreMedicoQR=medicoQR.titulo+" "+medicoQR.nombres+" "+medicoQR.apellidoPaterno+" "+medicoQR.apellidoMaterno;
		}
		console.log("estadoCita(citaformedit):"+JSON.stringify(estadoCita));

		if(tipoCitaQR!==undefined && estadoCita.id===ESTATUS_AGENDADA){
			console.log("tipoCitaQR:"+JSON.stringify(tipoCitaQR));
			procedimientoQR=tipoCitaQR.nombre;

			QRCita=<QRGeneracion id={props.values.id} 
					paciente={nombrePacienteQR} 
					medico={nombreMedicoQR} 
					procedimiento={procedimientoQR} 
					fecha={props.values.fecha} 
					horaCita={props.values.horaInicio}/>

		}
	}


	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
					    <IconButton edge="start" onClick={goBack} aria-label="close">
                            <ArrowBackIcon  color="secondary" style={{ width: "2.0rem",height: "2.0rem"}}  />
                        </IconButton>
						<Typography variant="h6" style={{ flexGrow: 1}} >
							Editar Cita
						</Typography>
						<IconButton onClick={props.abrirConfirmacion}>
							<DeleteOutlineIcon  color="secondary" style={{ width: "2.0rem",height: "2.0rem"}} />
						</IconButton>
						<IconButton >
						</IconButton>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
						<Button variant="contained"  edge="start" color="default" onClick= {props.abrirConfirmacion}>Eliminar</Button>
						<Button variant="contained"  edge="start" color="default" onClick= {props.cerrarModalEditar}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
	}

	console.log("MEDICO SELECCIONADO:"+props.values.MEDICO_id);
	let esquemaMedico=props.esquemaTrabajos.filter(item=>item.MEDICO_id===props.values.MEDICO_id)[0];
	let esquemaTipoCitaDisponible=[];
	let catTipoCitaDisponible=[];
	let esquemaTipoCitaSelecionado=[];


	console.log("[esquemaMedico]:"+JSON.stringify(esquemaMedico));


	if(esquemaMedico!==undefined){			
		esquemaTipoCitaDisponible=props.esquemaTipoCita.filter(eTipoCitaItem=>eTipoCitaItem.ESQUEMA_TRABAJO_id===esquemaMedico.id);
	}


	if(esquemaTipoCitaDisponible.length >0){
	catTipoCitaDisponible=props.catTipoCita.filter(item=>(esquemaTipoCitaDisponible.filter(tCitaDisponible=>
								tCitaDisponible.CAT_TIPO_CITA_id===item.id)[0]!==undefined));


	}

	//07ene21 Filtra el tipo de cita[PANENDO/COLON etc] del medico a partir de su seleecion en el formulario (SIEMPRE VIENE UN ELEMENTO).
	esquemaTipoCitaSelecionado=esquemaTipoCitaDisponible.filter(item=>item.CAT_TIPO_CITA_id===props.values.CAT_TIPO_CITA_id)

	console.log("[esquemaTipoCitaSelecionado]:"+JSON.stringify(esquemaTipoCitaSelecionado));
	console.log("esquemaTipoCitaDisponible:"+JSON.stringify(esquemaTipoCitaDisponible));
	console.log("catTipoCitaDisponible:"+JSON.stringify(catTipoCitaDisponible));


	let medicosSelect="";
		if(props.perfilAdministrador){
			medicosSelect=<MedicoFormSelect MEDICO_id="MEDICO_id"  
							medicoValue={props.values.MEDICO_id}
							errors={props.errors}
							touched={props.touched} 
							handleChange={props.handleChange} 
							esquemaMedico={props.esquemaMedico}
							/>
		}




        return (
            <form onSubmit={props.handleSubmit} style={{margin: 1, width: '25ch'}}> 
                <Dialog
                    open={props.modalEditar}
                   // onClose={props.cerrarModalEditar}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
					fullScreen={fullScreen}
                    >


					<DialogTitle id="form-dialog-title">Editar Cita</DialogTitle>
						{appBar}

							<DialogContent>
						
							{QRCita}
						
 							<FormControl
								fullWidth
								margin="normal"	
								variant="filled" 
								style={{width:"100%"}}
							>														
								 <Autocomplete	
								 									
									options={props.pacientes}	
									value={props.pacientes.filter(paciente=>paciente.id===props.values.PACIENTE_id)[0]}								
									getOptionLabel={(option) => option.nombres +" " +option.apellidoPaterno+" "+option.apellidoMaterno}
									renderInput={(params) => <Box display="flex">
																<TextField 
																	{...params}
																	id="PACIENTE_id" 
																	error={props.touched.PACIENTE_id&&props.errors.PACIENTE_id? true:false}
																	type="text" {...params}  
																	label="PACIENTE" 
																	variant="outlined" 
																	value={props.values.PACIENTE_id}
																	/>

															</Box>	
												}																
									onChange={handleChange} 
									onBlur={props.handleBlur}

									/>																

						    </FormControl>

							{medicosSelect}

							<FormControl
							fullWidth
							margin="normal"
							variant="filled" 
							>
							    <InputLabel htmlFor="age-native-simple"> TIPO DE CITA</InputLabel>
								<Select
									error={props.touched.CAT_TIPO_CITA_id&&props.errors.CAT_TIPO_CITA_id? true:false}
									size="medium"
									id="CAT_TIPO_CITA_id"
									label=" TIPO DE CITA"
									type="text"
									fullWidth
									variant="outlined"
									onChange={props.handleChange} 
									value={props.values.CAT_TIPO_CITA_id}
									onBlur={props.handleBlur}
									native
									
								>
								          <option aria-label="None" value="" disabled/>
							
									{
									catTipoCitaDisponible.map(cattipocita=> (
										<option key={cattipocita.id}  value={cattipocita.id}>{cattipocita.nombre}</option>)
									)}
								</Select>
							</FormControl>

							<FormControl
							fullWidth
							margin="normal"
							variant="filled" 
							>
							    <InputLabel htmlFor="age-native-simple"> ESTATUS</InputLabel>
								<Select
									error={props.touched.CAT_ESTADO_CITA_id&&props.errors.CAT_ESTADO_CITA_id? true:false}
									size="medium"
									id="CAT_ESTADO_CITA_id"
									label="ESTATUS DE CITA"
									type="text"
									fullWidth
									variant="outlined"
									onChange={props.handleChange} 
									value={props.values.CAT_ESTADO_CITA_id}
									onBlur={props.handleBlur}
									native									
								>
								          <option aria-label="None" value="" disabled/>
							
									{
									props.catEstadosCita.map(estadoCita=> (
										<option key={estadoCita.id}  value={estadoCita.id}>{estadoCita.nombre}</option>)
									)}
								</Select>
							</FormControl>
										
							<CitasDisponibles  
								CAT_TIPO_CITA_id={props.values.CAT_TIPO_CITA_id} //07Ene21 Se requiere para obtener duracion del tipo de cita selecionado por el usuario en el formulario
								catTipoCita={props.catTipoCita}  //07Ene21 Se utiliza para seleccion de color del tipo de cita 												
								fecha={props.values.fecha}      //07Ene21 Se utiliza para el action de seleccion en Card
								setFieldValue={props.setFieldValue} 
							
								horaInicio={props.values.horaInicio} 
								horaFin={props.values.horaFin}
							
								esquemaMedico={esquemaMedico}
								esquemaTipoCitaSelecionado={esquemaTipoCitaSelecionado}
							/>

                        </DialogContent>                          
						{actions}                

                    </Dialog>
                    <ConfirmacionDialog 
						modalConfirmacion={props.modalConfirmacion}   
                        cerrarConfirmacion={props.cerrarConfirmacion}
                        eliminar={props.eliminar} 
                        payload={props.payload}
						/>
                    </form>   
                )
    
}
export default  withRouter(Page);

import {CONSULTAR_COMIDAS,AGREGAR_COMIDA,ACTUALIZAR_COMIDA, ELIMINAR_COMIDA} from "../constants/Comida-action-type";


/* COMIDAES (HOSPITAL CLINICA) */
export const obtenerComidaListWSAction = (dataType)=> {
    return {
        type:CONSULTAR_COMIDAS,
        dataType:dataType,
        loading: true
    }
}

export const agregarComidaWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_COMIDA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarComidaWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_COMIDA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarComidaWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_COMIDA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

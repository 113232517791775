import React,{Component} from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withRouter} from "react-router-dom";
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';

import CitasDisponibles from "../CitasDisponibles"
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Grid from "@material-ui/core/Grid"

import MedicoFormSelect from "../MedicoFormSelect";

import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';

import NuevoPacienteForm from "../NuevoPacienteForm"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  function Page(props) {
		
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	
	function goBack(){
		props.history.goBack();
	}

	function handleChange(e,value){
		console.log("e.target.selectedOptions:"+e.target.selectedOptions);
		console.log("e.target.selectedOptions:"+e.target.value);
		console.log("e.target.selectedOptions:"+JSON.stringify(value));
		if(value!==null){
			props.setFieldValue("PACIENTE_id",value.id);	
		}
		
	  }

	let appBar="";
	let actions="";

	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={goBack} aria-label="close">
							<ArrowBackIcon color="secondary" style={{ borderWidth:"1",width: "2.0rem",height: "2.0rem"}}  />
						</IconButton>
						<Typography variant="h6" style={{ flexGrow: 1}} >
							Nueva Cita
						</Typography>

						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
						<Button variant="contained"  edge="start" color="default" onClick= {props.cerrarModal}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
	}

		console.log("MEDICO SELECCIONADO:"+props.values.MEDICO_id);
		console.log("MEDICO SELECCIONADO(props.catTipoCita):"+JSON.stringify(props.catTipoCita));
		console.log("MEDICO SELECCIONADO(props.esquemaTrabajos):"+JSON.stringify(props.esquemaTrabajos));
		let esquemaMedico=props.esquemaTrabajos.filter(item=>item.MEDICO_id===props.values.MEDICO_id)[0];
		let esquemaTipoCitaDisponible=[];
		let catTipoCitaDisponible=[];
		let esquemaTipoCitaSelecionado=[];


		console.log("[esquemaMedico]:"+JSON.stringify(esquemaMedico));


		if(esquemaMedico!==undefined){			
			esquemaTipoCitaDisponible=props.esquemaTipoCita.filter(eTipoCitaItem=>eTipoCitaItem.ESQUEMA_TRABAJO_id===esquemaMedico.id);
			
			catTipoCitaDisponible=props.catTipoCita.filter(catTipoCitaItem=>esquemaTipoCitaDisponible.some(item=>item.CAT_TIPO_CITA_id===catTipoCitaItem.id));
		}

//(05Julio21)  Se elimina filtro de esquema por medico y tipo de cita ya que el tipo de cita ahora es por Id_INSTITUCION
	//	if(esquemaTipoCitaDisponible.length >0){
		//catTipoCitaDisponible=props.catTipoCita.filter(item=>(esquemaTipoCitaDisponible.filter(tCitaDisponible=>
			//						tCitaDisponible.CAT_TIPO_CITA_id===item.id)[0]!==undefined));

			//catTipoCitaDisponible=Object.assign({},props.catTipoCita);
			//JSON.parse(JSON.stringify(props.catTipoCita));
	//		catTipoCitaDisponible=JSON.parse(JSON.stringify(props.catTipoCita));//props.catTipoCita;
	//	}

//05jUlio21 Se agrega validacion para perfil medico no muestre la lista de medicos 
console.log("props.medicos:"+props.medicos);

		let medicosSelect="";
		if(props.medicos){
			medicosSelect=<MedicoFormSelect MEDICO_id="MEDICO_id"  
							medicoValue={props.values.MEDICO_id}
							errors={props.errors}
							touched={props.touched} 
							handleChange={props.handleChange} 
							esquemaMedico={props.esquemaMedico}
							/>
		}

		
//07ene21 Filtra el tipo de cita[PANENDO/COLON etc] del medico a partir de su seleecion en el formulario (SIEMPRE VIENE UN ELEMENTO).
		esquemaTipoCitaSelecionado=esquemaTipoCitaDisponible.filter(item=>item.CAT_TIPO_CITA_id===props.values.CAT_TIPO_CITA_id)

		console.log("[esquemaTipoCitaSelecionado]:"+JSON.stringify(esquemaTipoCitaSelecionado));
		console.log("esquemaTipoCitaDisponible:"+JSON.stringify(esquemaTipoCitaDisponible));
		console.log("catTipoCitaDisponible:"+JSON.stringify(catTipoCitaDisponible));
		console.log("catTipoCitaDisponible2:"+JSON.stringify(props.catTipoCita));
		console.log("props.errors.:"+JSON.stringify(props.errors));



        return (<div><form onSubmit={props.handleSubmit}> 
	
                    <Dialog
                    open={props.modal}
                    onClose={props.cerrarModal}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
					fullScreen={fullScreen}
					disableEnforceFocus

                    >
							
                    
                        <DialogTitle id="form-dialog-title">Nueva Cita</DialogTitle>                        
						{appBar}

						  <DialogContent>							
						  
						  <FormControl
							fullWidth
							margin="normal"	
							variant="filled" 
							style={{width:"100%"}}
							>
							
							
							
								 <Autocomplete
									
									options={props.pacientes}
									getOptionLabel={(option) => option.nombres +" " +option.apellidoPaterno+" " +option.apellidoMaterno}
									renderInput={(params) =>    <Box display="flex">
																  <TextField 
																	id="PACIENTE_id" 
																	error={props.touched.PACIENTE_id&&props.errors.PACIENTE_id? true:false}
																	type="text" {...params}  
																	label="PACIENTE" 
																	variant="outlined" 
																	fullWidth
																	/>																																																														
																</Box>	
												}																
									onChange={handleChange} 
									onBlur={props.handleBlur}
									/>	


																						

						    </FormControl>


							{medicosSelect}

							<FormControl
							fullWidth
							margin="normal"
							variant="filled" 
							>
							    <InputLabel htmlFor="age-native-simple"> TIPO DE CITA</InputLabel>
								<Select
									error={props.touched.CAT_TIPO_CITA_id&&props.errors.CAT_TIPO_CITA_id? true:false}
									size="medium"
									id="CAT_TIPO_CITA_id"
									label=" TIPO DE CITA"
									type="text"
									fullWidth
									variant="outlined"
									onChange={props.handleChange} 
									value={props.values.CAT_TIPO_CITA_id}
									onBlur={props.handleBlur}
									native
									
								>
								          <option aria-label="None" value="" disabled/>
							
									{
									catTipoCitaDisponible.map(cattipocita=> (
										<option key={cattipocita.id}  value={cattipocita.id}>{cattipocita.nombre}</option>)
									)}
								</Select>
							</FormControl>

							

							<CitasDisponibles  
												CAT_TIPO_CITA_id={props.values.CAT_TIPO_CITA_id} //07Ene21 Se requiere para obtener duracion del tipo de cita selecionado por el usuario en el formulario
												catTipoCita={props.catTipoCita}  //07Ene21 Se utiliza para seleccion de color del tipo de cita 												
												fecha={props.values.fecha}      //07Ene21 Se utiliza para el action de seleccion en Card
												setFieldValue={props.setFieldValue} 
											
												horaInicio={props.values.horaInicio} 
												horaFin={props.values.horaFin}
											
											//	esquemaMedico={esquemaMedico}
												esquemaTipoCitaSelecionado={esquemaTipoCitaSelecionado}
																 />

                            </DialogContent>
							{actions}                
          
                    </Dialog>
								
                    </form>
					
					</div>
                       
                )
    
}
export default  withRouter(Page);

							/*
							<FormControl
							fullWidth
							margin="normal"	
							variant="filled" 
						
							>
							
							<InputLabel alignItems="center" style={{marginTop:"0",paddingTop:"0",top: "-0.3rem"}}>
									<Grid container    justify="center"   direction="row" alignItems="center">								
										<Grid item>
										<AccountCircle fontSize="normal" />
										</Grid>
										<Grid item>
										PACIENTE
										</Grid>
									</Grid>							
							   </InputLabel>
								<Select
									error={props.touched.PACIENTE_id&&props.errors.PACIENTE_id? true:false}
									size="medium"
									id="PACIENTE_id"
									label="PACIENTE_id:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={props.handleChange} value={props.values.PACIENTE_id}
									onBlur={props.handleBlur}
									native
								>
									

										<option aria-label="None" value="" disabled/>
						
										{
											props.pacientes.map(paciente=> (
												<option key={paciente.id}  value={paciente.id}>{paciente.nombres} {paciente.apellidoPaterno}</option>
											))
									}
								</Select>
								</FormControl>
								*/
import React,{Component} from "react";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import {Link} from "react-router-dom";
import {IoPersonCircleOutline} from "react-icons/io5"
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';

String.prototype.toHex = function() {
  var hash = 0;
  if (this.length === 0) return hash;
  for (var i = 0; i < this.length; i++) {
      hash = this.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
  }
  var color = '#';
  for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 255;
      color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
}

String.prototype.toColor = function() {
	var colors = ["#bf360c","#3e2723","#212121","#616161","#5d4037","#455a64","#00838f","#f44336","#e91e63","#9c27b0","#673ab7","#3f51b5","#2196f3","#03a9f4","#00bcd4","#009688","#4caf50","#8bc34a","#cddc39","#827717","#ffc107","#ff9800","#ff5722","#795548","#607d8b","#01579b","#006064","#004d40","#6200ea","#1a237e","#c51162","#880e4f"]
	
    var hash = 0;
	if (this.length === 0) return hash;
    for (var i = 0; i < this.length; i++) {
        hash = this.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    hash = ((hash % colors.length) + colors.length) % colors.length;
    return colors[hash];
}

export default class Page extends Component{

  
    render(){               
        function obtenerColor(nombre) {
            if(nombre!=null){
                var size=nombre.length;
                do {
                   if(size>colores.length){
                      size=size-colores.length;
                   } 
                }while (size>colores.length);
  
              return colores[size];
  
            }else{
                return colores[0];
            }
          }

        let colores=["#babdbe","#00bfa5","#8eacbb","#0288D1","#34515e","#babdbe","#00bfa5","#8eacbb","#0288D1","#34515e","#babdbe","#00bfa5","#8eacbb","#0288D1","#34515e","#babdbe","#00bfa5","#8eacbb","#0288D1","#34515e"];
        let medico=this.props.medico;

        return  <div>
                <ListItem dense button style={{  paddingBottom:6, paddingTop:6 }} component={Link}  to={{
                  hash:'id', 
                  valores:{
                     modalEditar:true,
                     payload:medico
                  }
                  }} >
                    <ListItemAvatar>
			        		  	<AccountCircleTwoToneIcon style={{ marginRight:".8rem", width: "3.5rem",height: "3.5rem", color:(medico.titulo+medico.nombres+medico.apellidoPaterno).toColor()}} alt={medico.especialidad} />
                      
                    </ListItemAvatar>

				          	<ListItemText id={medico.id} 
                                  primary={<Typography  style={{ textTransform:"capitalize",color:(medico.titulo+medico.nombres+medico.apellidoPaterno).toColor() }}>{medico.titulo+" "+medico.nombres}</Typography>}
							    	              secondary={<Typography  style={{ textTransform:"capitalize",color:(medico.titulo+medico.nombres+medico.apellidoPaterno).toColor()}}>{medico.apellidoPaterno+" "+medico.apellidoMaterno}</Typography>}
                    />


                </ListItem>
                <Divider variant="inset" component="li" />

                </div>
    }
}

import {connect} from "react-redux";
import React,{Component} from "react";
import Page from "./page";
import {getMesNombre,getDiaSemanaNombre,getFechaHoraString,getFormatoDesdeDate} from "../../../utils/date";
import { CITA } from "../../../redux/constants/action-type";
import { isHorarioIntersect } from "../../../utils/moment";
import {mostrarMensaje} from "../../../redux/actions/stateActions"


class BuscarHorario extends Component{
    
	 
    render(){
        return<Page 
                codColor={this.props.codColor} 
                duracionTipoCita={this.props.duracionTipoCita}

                fecha={this.props.fecha} 
                fechaBusqueda={this.props.fechaBusqueda} 
                setFechaBusqueda={this.props.setFechaBusqueda} 
                setFieldValue={this.props.setFieldValue} 
                horaInicio={this.props.horaInicio} 
                horaFin={this.props.horaFin}
                idEsquemaTipoCita={this.props.idEsquemaTipoCita}  

				/>
    }

}


const mapStateToProps=(state)=>({
})

const mapDispatchToProps=(dispatch)=>({
})

export default connect(mapStateToProps,mapDispatchToProps)(BuscarHorario);
import { Box, Button, ButtonGroup, IconButton, Typography } from "@material-ui/core";
import React,{Component} from "react";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { makeStyles } from "@material-ui/styles";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { MODO_VISTA_AGENDADOS, MODO_VISTA_DIA, MODO_VISTA_MES, MODO_VISTA_SEMANA } from "../../../utils/constantes";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  text: {
    margin: theme.spacing(0),
    textTransform:"capitalize",
  },
}));

function Page(props){   
  const classes = useStyles();
  let formato="DD MMMM [de] YYYY";
  if(props.modoVista===MODO_VISTA_MES){
    formato="MMMM [de] YYYY";
  }

  if(props.modoVista===MODO_VISTA_DIA){
    formato="DD MMMM [de] YYYY";
  }

  if(props.modoVista===MODO_VISTA_SEMANA){
    formato="MMMM [de] YYYY";
  }

  console.log("props.modoVista-"+props.modoVista);

      return<Box display="flex" style={{alignItems:"center",backgroundColor:"white"}}>
              <Box display="flex" px={1} width="70%" style={{alignItems:"center"}} >
                <Button variant="outlined" onClick={props.hoyHandler}>
                    <Typography variant="caption" className={classes.text} color="textPrimary">
                        Hoy
                      </Typography>
                    </Button>
                    <IconButton aria-label="delete" className={classes.margin} onClick={props.anteriorHandler}>
                      <ChevronLeftIcon fontSize="medium" />
                    </IconButton>
                    <IconButton aria-label="delete" className={classes.margin} onClick={props.siguienteHandler}>
                      <ChevronRightIcon fontSize="medium" />
                    </IconButton>

                  <Typography variant="body1" className={classes.margin}>{props.hoy.format(formato)}</Typography>

              </Box>
              <Box px={1} width="30%">
                 <ToggleButtonGroup  size="small" aria-label="small outlined button group">
                  <ToggleButton value={MODO_VISTA_DIA} selected={props.modoVista===MODO_VISTA_DIA} onClick={()=>props.setModoVista(MODO_VISTA_DIA)} ><Typography variant="caption" color="textPrimary" className={classes.text}>Día</Typography></ToggleButton>
                  <ToggleButton value={MODO_VISTA_SEMANA} selected={props.modoVista===MODO_VISTA_SEMANA} onClick={()=>props.setModoVista(MODO_VISTA_SEMANA)} ><Typography variant="caption" color="textPrimary" className={classes.text}>Semana</Typography></ToggleButton>
                  <ToggleButton value={MODO_VISTA_MES} selected={props.modoVista===MODO_VISTA_MES} onClick={()=>props.setModoVista(MODO_VISTA_MES)} ><Typography variant="caption" color="textPrimary" className={classes.text}>Mes</Typography></ToggleButton>
                  <ToggleButton value={MODO_VISTA_AGENDADOS} selected={props.modoVista===MODO_VISTA_AGENDADOS} onClick={()=>props.setModoVista(MODO_VISTA_AGENDADOS)}><Typography variant="caption" color="textPrimary" className={classes.text}>Agendados</Typography></ToggleButton>
                </ToggleButtonGroup >
              </Box>  
           </Box>
}

export default Page;

import {CONSULTAR_TECNICOS,AGREGAR_TECNICO,ACTUALIZAR_TECNICO, ELIMINAR_TECNICO} from "../constants/Tecnico-action-type";


/* TECNICOS (HOSPITAL CLINICA) */
export const obtenerTecnicoListWSAction = (dataType)=> {
    return {
        type:CONSULTAR_TECNICOS,
        dataType:dataType,
        loading: true
    }
}

export const agregarTecnicoWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_TECNICO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarTecnicoWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_TECNICO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarTecnicoWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_TECNICO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

import {connect} from "react-redux";
import React,{Component} from "react";
import { CITA, INSTITUCION, MEDICO, MEDICO_USUARIO, PACIENTE } from "../../../redux/constants/action-type";
import Page from "./page";
import { PROFIL_ADMINISTRADOR, PROFIL_MEDICO } from "../../../utils/constantes";
import { actualizarRegistrosAction } from "../../../redux/actions/stateActions";
import { obtenerCitaListWSAction } from "../../../redux/actions/CitaActions";
import { obtenerPacienteListWSAction } from "../../../redux/actions/PacienteActions";
import { obtenerCatTipoCitaListWSAction } from "../../../redux/actions/CatTipoCitaActions";

class DisplayTV extends Component{

    render(){		
        return <Page 
                    citas={this.props.citas} 
                     obtenerCitaListWSAction={this.props.obtenerCitaListWSAction}
                     obtenerPacienteListWSAction={this.props.obtenerPacienteListWSAction}
                     obtenerCatTipoCitaListWSAction={this.props.obtenerCatTipoCitaListWSAction}
                />
    }

}


const mapStateToProps=(state)=>({
 /*   profil:state.autenticacionReducer.profil,     
    medicoUsuario:state.MedicoUsuarioReducer[MEDICO_USUARIO][0],  
    institucion:state.InstitucionReducer[INSTITUCION][0],
	pacientes: state.PacienteReducer[PACIENTE],
*/
})

const mapDispatchToProps=(dispatch)=>({
	actualizarRegistrosAction: (type,registros) => dispatch(actualizarRegistrosAction(type,registros)),
    obtenerCitaListWSAction: (type,loading) => dispatch(obtenerCitaListWSAction(type,loading)),
    obtenerPacienteListWSAction: (type,payload) => dispatch(obtenerPacienteListWSAction(type,payload)),
    obtenerCatTipoCitaListWSAction: (type,loading) => dispatch(obtenerCatTipoCitaListWSAction(type,loading)),

})

export default connect(mapStateToProps,mapDispatchToProps)(DisplayTV);
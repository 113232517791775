import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import { ESQUEMA_TIPO_CITA_HORARIO,CAT_TIPO_CITA,HORARIO ,PERIODO, COLOR} from "../../../redux/constants/action-type";
import {agregarEsquemaTipocitaHorarioWSAction} from "../../../redux/actions/EsquemaTipoCitaHorarioActions";
import {actualizarPeriodoWSAction,eliminarPeriodoWSAction} from "../../../redux/actions/PeriodoActions";
import {eliminarEsquemaTipoCitaWSAction} from "../../../redux/actions/EsquemaTipoCitaActions"


class TipoCitaCardListItemForm extends Component{
    constructor(props) {
        super(props);
        this.state={
                openPeriodoDialog:false,  /* [23Nov2020] Abre el Dialog para selecion del horario */
                openPeriodoEditDialog:false,  /* [23Nov2020] Abre el Dialog para selecion del horario */
                dia:"x",
                idDia:"y",
                esquemaTipoCitaHorario:{}, /* [23Nov2020] CONTIENEN LOS DATOS QUE SERAN ENVIADOS AL WS AL AGREGAR UN NUEVO HORARIO */
                horario:{},
                periodo:{"horaInicial":"09:00:00","horaFinal":"14:00:00"},

        };

    }
    setOpenPeriodoDialog=(valor)=>{
        this.setState({
            openPeriodoDialog:valor
        });
    }
    setOpenPeriodoEditDialog=(valor)=>{
        this.setState({
            openPeriodoEditDialog:valor
        });
    }
    setDia=(valor)=>{
        console.log("setDia;"+valor);

        this.setState({
            dia:valor
        });
    }
    setIdDia=(valor)=>{
        console.log("setIdDia;"+valor);
        this.setState({
            idDia:valor
        });
    }

    /** [23Nov2020] CONTIENEN LOS DATOS QUE SERAN ENVIADOS AL WS AL AGREGAR UN NUEVO HORARIO ***/

    setEsquemaTipoCitaHorario=(valor)=>{
        this.setState(
            {
                esquemaTipoCitaHorario:valor
            }
        )
    }

    setPeriodo=(valor)=>{
        this.setState(
            {
                periodo:valor
            }
        )
    }


    setHorario=(valor)=>{
        this.setState(
            {
                horario:valor
            }
        )
    }

    handleClick=()=>{

        console.log("this.state this.state -"+JSON.stringify(this.state));
        this.props.agregarEsquemaTipocitaHorarioWSAction(ESQUEMA_TIPO_CITA_HORARIO,this.state);
    }

    handleEditClick=()=>{

        console.log("this.state this.state -"+JSON.stringify(this.state));
        this.props.actualizarPeriodoWSAction(PERIODO,this.state);
    }

    handleEliminarPeriodoClick=()=>{

        console.log("this.state this.state (handleEliminarPeriodoClick) -"+JSON.stringify(this.state));
        this.props.eliminarPeriodoWSAction(PERIODO,this.state);
    }

    
    render(){  
    //    console.log("STATE TIPO CITA:>><<"+JSON.stringify(this.state));
        
        return<Page     cat_dia_semana={this.props.cat_dia_semana} 
                        openPeriodoDialog={this.state.openPeriodoDialog} 
                        setOpenPeriodoDialog={this.setOpenPeriodoDialog} 
                        openPeriodoEditDialog={this.state.openPeriodoEditDialog} 
                        setOpenPeriodoEditDialog={this.setOpenPeriodoEditDialog}                         
                        horario={this.state.horario}
                        esquemaTipoCitaHorario={this.state.esquemaTipoCitaHorario}
                        setEsquemaTipoCitaHorario={this.setEsquemaTipoCitaHorario}
                        setHorario={this.setHorario}
                        periodo={this.state.periodo}
                        setPeriodo={this.setPeriodo}
                        handleClick={this.handleClick}
                        handleEditClick={this.handleEditClick}
                        handleEliminarPeriodoClick={this.handleEliminarPeriodoClick}
                        esquemaTipoCitaItem={this.props.esquemaTipoCitaItem}
                        setDia={this.setDia}   
                        dia={this.state.dia} 
                        setIdDia={this.setIdDia}   
                        idDia={this.state.idDia} 
                        cat_tipo_cita={this.props.cat_tipo_cita}
                        esquemaTipoCitaHorarioList={this.props.esquemaTipoCitaHorarioList}
                        colores={this.props.colores}
                        eliminarEsquemaTipoCitaWSAction={this.props.eliminarEsquemaTipoCitaWSAction}
                        />

    }


}

const mapStateToProps=(state,ownProps)=>({
    esquemaTipoCitaHorarioList:state.EsquemaTipoCitaHorarioReducer[ESQUEMA_TIPO_CITA_HORARIO].filter(e => e.ESQUEMA_TIPO_CITA_id === ownProps.esquemaTipoCitaItem.id),
    cat_tipo_cita:state.CatTipoCitaReducer[CAT_TIPO_CITA],
    colores:state.ColorReducer[COLOR],

})

const mapDispatchToProps=(dispatch)=>({
    agregarEsquemaTipocitaHorarioWSAction: (type,loading) => dispatch(agregarEsquemaTipocitaHorarioWSAction(type,loading)),
    actualizarPeriodoWSAction: (type,loading) => dispatch(actualizarPeriodoWSAction(type,loading)),
    eliminarPeriodoWSAction: (type,loading) => dispatch(eliminarPeriodoWSAction(type,loading)),
    eliminarEsquemaTipoCitaWSAction:(type,loading)=>dispatch(eliminarEsquemaTipoCitaWSAction(type,loading))

})

export default connect(mapStateToProps,mapDispatchToProps)(TipoCitaCardListItemForm);



import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import {COLOR } from "../../../redux/constants/action-type";

class PacientesListItem extends Component{
    render(){    

        console.log("this.props.colores:"+JSON.stringify(this.props.colores));
        console.log("this.props.catTipoCita:"+JSON.stringify(this.props.catTipoCita));

        let color=this.props.colores.filter(item => item.id === this.props.catTipoCita.COLOR_id)[0];
        let codColor="";
        console.log("colorItem (PacientesListItem):"+JSON.stringify(color));
        if(color!==undefined){
            codColor=color.color;
        }
        console.log("codColor:"+JSON.stringify(color));

        return< Page catTipoCita={this.props.catTipoCita} abrirModalEditar={this.props.abrirModalEditar} codColor={codColor}/>
    }

}

const mapStateToProps = (state, ownProps) => ({
    colores:state.ColorReducer[COLOR],
  })
  
 
  
  
   export default connect(mapStateToProps)(PacientesListItem);
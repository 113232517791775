import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {CITA,RECORDATORIO,CAT_ESTADO_CITA,CAT_TIPO_CITA,PACIENTE,MEDICO,ESQUEMA_TRABAJO,ESQUEMA_TIPO_CITA,MEDICO_USUARIO,INSTITUCION} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

class QRScanField extends Component{
 
    render(){    
        return <Page idPacienteValor={this.props.idPacienteValor}
                     setIdPacienteValor={this.props.setIdPacienteValor}  />
    }            

    componentDidMount(){            
    }

}

const mapStateToProps=(state)=>({
})


const mapDispatchToProps = (dispatch, ownProps) => ({
})


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(QRScanField));
import React,{Component, useState} from "react";
import Page from "./page";
import {connect} from "react-redux";


function SemanaGrid(props) {

    
    const totalHorasSemana=168; //24hrs * 7 dias = 168
    const totalDiasSemana=7; //24hrs * 7 dias = 168
    const totalHorasDia=24; //24hrs

    const horasDiaArray=[...Array(totalHorasDia)];
    const horasSemanaArray=[...Array(totalHorasSemana)].map(()=>props.diaInicial.add(1,"hour").clone().subtract(1,"hour"));
    const diasSemanaArray=[...Array(totalDiasSemana)].map(()=>props.diaInicialSemana.add(1,"day").clone().subtract(1,"day"));

    
   return <Page 
            horasSemanaArray={horasSemanaArray}
            diasSemanaArray={diasSemanaArray}
            horasDiaArray={horasDiaArray}
            hoy={props.hoy}
            diaInicialDia={props.diaInicialDia}

            />                        
       
    
}




/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
})
/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(mapStateToProps,mapDispatchToProps)(SemanaGrid);

import React,{Component} from "react";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import {Link} from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import "./estilo.css"
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import {getFecha,getFormatoHora} from "../../../utils/moment";
import {getCodigoColorCategoria} from "../../../utils/presionArterial";
import Avatar from '@material-ui/core/Avatar';


export default class Page extends Component{

    render(){
        

        let p=this.props.presionArterial;
        let fechaTitulo =getFecha(p.fecha);
        let horaTitulo =getFormatoHora(p.hora);
        let colorCategoria=getCodigoColorCategoria(p.valorSistolica,p.valorDiastolica);
        
        return <Card className="card" variant="elevation" boxShadow={3} elevation={3} >
                  <CardHeader                  
                  elevation="2"
                  avatar={
                    <Avatar fontSize="large" style={{  marginRight:".8rem", width: "3.5rem",height: "3.5rem",borderColor:"red",backgroundColor:"#eceff1"}}>
                        <FavoriteIcon fontSize="large" alt={p.descripcion} style={{ color:"primary"}} color="primary" /></Avatar> 
                  }
                
                  title={fechaTitulo} 
                  subheader={horaTitulo}
                />
                    <CardContent  className="card_content" >
                    <Grid container spacing={3}>

                    <Grid item xs={5}>
                      <Typography variant="h3" component="h2">
                          <Typography variant="h5" component="h2" color="primary" >
                            SYS. 
                          </Typography>
                        {p.valorSistolica}
                          <Typography variant="h6" component="h6" color="textSecondary">
                            mmHh
                          </Typography> 
                        </Typography>
                        
                        
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="h3" component="h2">
                          <Typography variant="h5" component="h2" color="secondary">
                              DIA.
                          </Typography>                      
                        {p.valorDiastolica} 
                          <Typography variant="h6" component="h6" color="textSecondary">
                            mmHg
                          </Typography>                      
                        </Typography>
                    </Grid>
                    
                    <Grid item xs={2}>
                      <Typography variant="h5" component="h4">
                          <Typography variant="h6" component="h1">
                              PUL. 
                          </Typography>                      
                        {p.pulso}
                        </Typography>
                    </Grid>

                    </Grid>
                    
                  </CardContent>
                  <CardActions className="card_pie"   >
                    <Typography variant="h5" component="h5" color="textPrimary">
                      Notas
                    </Typography>
                    <Typography variant="body2" component="h5">
                      {p.nota}
                    </Typography>
                  </CardActions>
                </Card>

              
    }
}

import React,{Component, useState} from "react";
import Page from "./page";
import {connect} from "react-redux";
import moment from "moment";
import { MODO_VISTA_DIA, MODO_VISTA_MES, MODO_VISTA_SEMANA } from "../../../utils/constantes";

moment.locale('es', {
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
    monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
    weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
    weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
  }
  );

function Calendario(props) {
    
    const [modoVista,setModoVista]=useState(MODO_VISTA_MES);
    const [hoy,setHoy]=useState(moment());
    let diaInicial =hoy.clone().startOf(modoVista).startOf("week").subtract(1,"day");

    if(modoVista===MODO_VISTA_DIA){
        diaInicial =hoy.clone().startOf(modoVista).startOf("hour");
    }

    if(modoVista===MODO_VISTA_SEMANA){
        diaInicial =hoy.clone().startOf(modoVista);
    }

    console.log("dia(se actualiza)"+hoy.format("MMM Do YY"));

    const anteriorHandler=()=>{
        setHoy(prev=>prev.clone().subtract(1,modoVista))
    };
    const siguienteHandler=()=>{
        setHoy(prev=>prev.clone().add(1,modoVista))
    };
    const hoyHandler=()=>{
        setHoy(moment())
    };

    return <Page 
             hoy={hoy}
             diaInicial={diaInicial}
             anteriorHandler={anteriorHandler}
             siguienteHandler={siguienteHandler}
             hoyHandler={hoyHandler}
             modoVista={modoVista}
             setModoVista={setModoVista}

            />                               
    
}




/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({   
})
/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(mapStateToProps,mapDispatchToProps)(Calendario);

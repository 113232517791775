import React, {Component} from "react";
import {connect} from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import {PROFIL_PACIENTE, PROFIL_ADMINISTRADOR,PROFIL_MEDICO,PROFIL_ROOT} from  "./constantes"

import {CALENDARIO,CITA,ESQUEMA_TRABAJO,CAT_TIPO_CITA,USUARIO} from "../redux/constants/action-type";



export default function(ComponenteCompuesto){

    class Permiso extends Component{

    render(){
                      
        let permisos=[];
        permisos[PROFIL_ROOT]=[CALENDARIO,CITA,"paciente","medico",USUARIO,"instituciones",CAT_TIPO_CITA,ESQUEMA_TRABAJO,"comida","paginaWeb","producto","procedimiento","movimiento","tecnico",,"instituciones"];
        permisos[PROFIL_ADMINISTRADOR]=[CALENDARIO,CITA,"paciente","medico",USUARIO,CAT_TIPO_CITA,ESQUEMA_TRABAJO];
        permisos[PROFIL_MEDICO]=[CALENDARIO,CITA,"paciente","paginaWeb"];
        permisos[PROFIL_PACIENTE]=["inicio","presionArterial","glucosa","comida"]; // debe conincidir con bavbar LIstItemMenu menu={"glucosa"}

        let erlaubt=false;
        let profil=this.props.profil;
        let menu=this.props.menu;
        console.log("erlaubt (menu) :"+menu);
        console.log("profil :"+profil);
        console.log("permisos :"+permisos[profil]);
        if(permisos[profil]!==undefined){
            console.log("include(menu) :"+permisos[profil].filter(p => p === menu)[0]+".");
            erlaubt=((permisos[profil].filter(p => p === menu)[0])===undefined)?false:true;
            console.log("include(erlaubt) :"+erlaubt);
            
        }

        return (<div>
                        {erlaubt?<ComponenteCompuesto {...this.props}/>:null}
                </div>
            );
        }
    } 

    const mapStateToProps=(state)=>({
        profil:state.autenticacionReducer.profil     
    })

    
    
    return  connect(mapStateToProps)(Permiso);

}
import React,{Component} from "react";
import Page from "./page";
import { connect } from "react-redux";
import { ESQUEMA_TIPO_CITA_HORARIO, PERIODO, HORARIO } from "../../../redux/constants/action-type";
import {obtenerPeriodoListWSAction} from "../../../redux/actions/PeriodoActions";
import {obtenerHorarioListWSAction} from "../../../redux/actions/HorarioActions";
import {obtenerEsquemaTipocitaHorarioListWSAction} from "../../../redux/actions/EsquemaTipoCitaHorarioActions";


class SeleccionHorarioCita extends Component{


    render(){

        return <Page 
                    codColor={this.props.codColor} 
                    duracionTipoCita={this.props.duracionTipoCita}

                    fecha={this.props.fecha} 
                    fechaBusqueda={this.props.fechaBusqueda} 
                    setFechaBusqueda={this.props.setFechaBusqueda} 
                    setFieldValue={this.props.setFieldValue} 
                    horaInicio={this.props.horaInicio} 
                    horaFin={this.props.horaFin}
                    idEsquemaTipoCita={this.props.idEsquemaTipoCita}     
                    profil={this.props.profil}                 
        />
    }
	componentDidMount(){            
    }
}

const mapStateToProps =(state,ownProps)=>({
    profil:state.autenticacionReducer.profil,     
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(mapStateToProps,mapDispatchToProps)(SeleccionHorarioCita);



import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmacionDialog(props) {
  
  const handleClose = () => {
    props.setModalDialog(false);
  };

  const handleEliminar = () => {
    props.handleEliminarPeriodoClick();
    props.setModalDialog(false);
  };
  return (
    <div>
      <Dialog
        open={props.modalConfirmacion}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Esta seguro que desea eliminar el registro?"}</DialogTitle>
      
        <DialogActions>
          <Button onClick={handleClose} color="default" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleEliminar} color="default" autoFocus variant="outlined">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {withRouter} from "react-router-dom";
import Divider from '@material-ui/core/Divider';
import ConfirmacionDialog from "./ConfirmacionDialog";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

const Page=(props)=>{
		
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	function goBack(){
		props.history.goBack();
	}

	let appBar="";
	let actions="";

	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={goBack} aria-label="close">
						    <ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
						</IconButton>
						<Typography variant="h6" style={{ flexGrow: 1}} >
							{<Typography type="Roboto" style={{flexGrow:"1",color: '#000000', fontSize:'1.2rem',fontWeight:'normal',textTransform: "capitalize" }}>Nueva Producto</Typography>}
						</Typography>
                        <IconButton onClick={props.abrirConfirmacion}>
                            <DeleteOutlineIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}/>
                        </IconButton>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
            			<Button variant="contained"  edge="start" color="default" onClick= {props.abrirConfirmacion}>Eliminar</Button>
						<Button variant="contained"  edge="start" color="default" onClick= {props.cerrarModalEditar}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
	}

    return (<form onSubmit={props.handleSubmit}> 
                <Dialog
                    open={props.modalEditar}
                    onClose={props.cerrarModalEditar}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
                    fullScreen={fullScreen}
                >


                        <DialogTitle id="form-dialog-title">Actualice los datos del registro.</DialogTitle>

						{appBar}


							<DialogContent>
							<TextField
								error={this.props.touched.descripcion&&this.props.errors.descripcion? true:false}
								helperText={this.props.touched.descripcion&&this.props.errors.descripcion?this.props.errors.descripcion:""}
								size="medium"
								margin="normal"
								id="descripcion"
								label="descripcion:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.descripcion}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.precio&&this.props.errors.precio? true:false}
								helperText={this.props.touched.precio&&this.props.errors.precio?this.props.errors.precio:""}
								size="medium"
								margin="normal"
								id="precio"
								label="precio:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.precio}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.codigo&&this.props.errors.codigo? true:false}
								helperText={this.props.touched.codigo&&this.props.errors.codigo?this.props.errors.codigo:""}
								size="medium"
								margin="normal"
								id="codigo"
								label="codigo:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.codigo}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.codBarras&&this.props.errors.codBarras? true:false}
								helperText={this.props.touched.codBarras&&this.props.errors.codBarras?this.props.errors.codBarras:""}
								size="medium"
								margin="normal"
								id="codBarras"
								label="codBarras:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.codBarras}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.lote&&this.props.errors.lote? true:false}
								helperText={this.props.touched.lote&&this.props.errors.lote?this.props.errors.lote:""}
								size="medium"
								margin="normal"
								id="lote"
								label="lote:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.lote}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.fechaCaducidad&&this.props.errors.fechaCaducidad? true:false}
								helperText={this.props.touched.fechaCaducidad&&this.props.errors.fechaCaducidad?this.props.errors.fechaCaducidad:""}
								size="medium"
								margin="normal"
								id="fechaCaducidad"
								label="fechaCaducidad:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.fechaCaducidad}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.fechaAlta&&this.props.errors.fechaAlta? true:false}
								helperText={this.props.touched.fechaAlta&&this.props.errors.fechaAlta?this.props.errors.fechaAlta:""}
								size="medium"
								margin="normal"
								id="fechaAlta"
								label="fechaAlta:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.fechaAlta}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.maximo&&this.props.errors.maximo? true:false}
								helperText={this.props.touched.maximo&&this.props.errors.maximo?this.props.errors.maximo:""}
								size="medium"
								margin="normal"
								id="maximo"
								label="maximo:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.maximo}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.cantidad&&this.props.errors.cantidad? true:false}
								helperText={this.props.touched.cantidad&&this.props.errors.cantidad?this.props.errors.cantidad:""}
								size="medium"
								margin="normal"
								id="cantidad"
								label="cantidad:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.cantidad}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.fabricante&&this.props.errors.fabricante? true:false}
								helperText={this.props.touched.fabricante&&this.props.errors.fabricante?this.props.errors.fabricante:""}
								size="medium"
								margin="normal"
								id="fabricante"
								label="fabricante:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.fabricante}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.unidadMedida&&this.props.errors.unidadMedida? true:false}
								helperText={this.props.touched.unidadMedida&&this.props.errors.unidadMedida?this.props.errors.unidadMedida:""}
								size="medium"
								margin="normal"
								id="unidadMedida"
								label="unidadMedida:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.unidadMedida}
								onBlur={this.props.handleBlur}
							/>
							<FormControl
							fullWidth
							margin="normal"
							
							>
							    <InputLabel htmlFor="age-native-simple">CATTIPOPRODUCTO</InputLabel>
								<Select
									error={this.props.touched.CATTIPOPRODUCTO_id&&this.props.errors.CATTIPOPRODUCTO_id? true:false}
									size="medium"
									id="CATTIPOPRODUCTO_id"
									label="CATTIPOPRODUCTO_id:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={this.props.handleChange} value={this.props.values.CATTIPOPRODUCTO_id}
									onBlur={this.props.handleBlur}
									native
								>
								          <option aria-label="None" value="" disabled/>
							
									{
									this.props.catTipoProducto.map(cattipoproducto=> (
										<option key={cattipoproducto.id}  value={cattipoproducto.id}>{cattipoproducto.nombre}</option>)
									)}
								</Select>
							</FormControl>
                        </DialogContent>                          
					  {actions} 
                    </Dialog>
                    <ConfirmacionDialog modalConfirmacion={props.modalConfirmacion}   
                                        cerrarConfirmacion={props.cerrarConfirmacion}
                                        eliminar={props.eliminar} 
                                        payload={props.payload}/>
                    </form>   
                )
}
export default  withRouter(Page);

//recibe fecha 
export const getColorSemana=(fecha)=>{

    let color="#f5f5f5";
    let dia=fecha.substring(0,3);
    console.log("diaSemana:"+dia);
    switch (dia) {
      case "mar":
        color = "#e1f5fe";
        break;
      case 'sáb':
        color = "#e8f5e9";
        break;
      case "jue":
        color = "#fffde7";
        break;
      case "mié":
        color = "#ffebee";
        break;
      case "lun":
        color = "#fbe9e7";
        break;
      case "vie":
        color = "#fff3e0";
        break;
      case "dom":
        color = "#eceff1";
        break;
      }

    return color;
}
import React,{Component} from "react";
import Page from "./page";

export default class CitaSubList extends Component{

    render(){
			
        return <Page citas={this.props.citas} fecha={this.props.fecha} abrirModalEditar={this.props.abrirModalEditar}/>
    }

}


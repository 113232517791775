import {CONSULTAR_GLUCOSAES,AGREGAR_GLUCOSA,ACTUALIZAR_GLUCOSA, ELIMINAR_GLUCOSA} from "../constants/Glucosa-action-type";


/* GLUCOSAES (HOSPITAL CLINICA) */
export const obtenerGlucosaListWSAction = (dataType)=> {
    return {
        type:CONSULTAR_GLUCOSAES,
        dataType:dataType,
        loading: true
    }
}

export const agregarGlucosaWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_GLUCOSA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarGlucosaWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_GLUCOSA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarGlucosaWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_GLUCOSA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

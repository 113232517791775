
import { Grid, Paper,Box, Typography } from "@material-ui/core";
import React,{Component, useEffect, useState} from "react";


function DisplayDate(props) {

  const [dateState,setDateState] = useState(new Date());

  useEffect(() => {
    setInterval(() => setDateState(new Date()), 30000);
  }, []);

  return <Grid container align="center" alignItems="center" style={{display:"flex", justifyContent:"space-between", backgroundColor:"transparent",alignItems:"center"}}>
                              
                <Grid style={{color:"#0066b8",fontFamily:"sans-serif"}}>
                  <Typography variant="h5" style={{ textTransform:"uppercase",fontWeight:"bold"}}>
                    

                      FECHA: {dateState.toLocaleDateString('es-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: '2-digit',
                    })}
                  </Typography>                  
                
                  <Typography variant="h5" style={{ textTransform:"none",fontWeight:"bold"}}>
                    HORA: {dateState.toLocaleString('es-US', {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true,
                            })}

                  </Typography>                  

                </Grid>
              </Grid>                                
          ;
}
export default DisplayDate;
import React,{Component} from "react";
import Page from "./page";

class MedicoListItem extends Component{
    render(){    
        return< Page medico={this.props.medico} abrirModalEditar={this.props.abrirModalEditar}/>
    }

}




export default MedicoListItem;
import { Box, LinearProgress, makeStyles, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React,{Component, useRef} from "react";
import { getHorarioLocaleString } from "../../../utils/date";
import EventoPaper from "../EventoPaper";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 2,
    borderRadius: 3,
    backgroundColor: 'red',
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 3,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
 
  paper: {
    borderRadius:"0",
    padding: theme.spacing(1.5),
    borderBottom:"0",
    borderRight:"0",
    height:"48px",

  },
  boxContainer: {
    position: "relative",
    textAlign:"left"
  },  
  textHora:{
    display: "block",
    position: "relative",
    top: "-15px",
    textAlign:"center",
    width:"80px"
  },  
  boxpaper:{     
    position:"relative",
  },

}));

function Page(props){
  
  const classes = useStyles();

      return <div>            
                      
                      <Box p={0} className={classes.boxpaper} flexGrow={1} >
                        <Paper className={classes.paper} variant="outlined"> 
                          {props.horaActualIntercepcion
                            ?<div style={{backgroundColor:"cyan",zIndex:"7",top:props.topHoraActual,position:"absolute",left:"0",width:"100%"}}>
                              <BorderLinearProgress variant="indeterminate" value={100} />
                             </div>
                            :""
                          }                       
                        </Paper>
                        {
                          props.citasFiltradas.map((item,index)=>
                            <EventoPaper 
                              key={index} 
                              index={index} 
                              cita={item} 
                              horaItem={props.horaItem}
                            />
                          )
                        }   
                      </Box>

                   
             </div> 
                            
}

export default Page;

import React,{Component, useEffect, useState} from "react";
import CitaList from "../CitaList";
import CitaBusqueda from "../CitaBusqueda";
import CitaForm from "../CitaForm";
import CircularProgress from '@material-ui/core/CircularProgress';
import CitaFloatButton from "../CitaFloatButton";
import CitaFormEdit from "../CitaFormEdit";
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import QRFloatButton from "../QRFloatButton";
import QRScan from "../QRScan";
import { Collapse, Hidden } from "@material-ui/core";
import { useRef } from "react";
import CalendarioEncabezado from "../../CalendarioComponent/CalendarioEncabezado";
import moment from "moment";
import { MODO_VISTA_DIA, MODO_VISTA_MES, MODO_VISTA_SEMANA,MODO_VISTA_AGENDADOS, PROFIL_ADMINISTRADOR  } from "../../../utils/constantes";
import DiaGrid from "../../CalendarioComponent/DiaGrid";
import CalendarioGrid from "../../CalendarioComponent/CalendarioGrid";
import SemanaGrid from "../../CalendarioComponent/SemanaGrid";

moment.locale('es', {
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
    monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
    weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
    weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
  }
  );

export default function Page(props){

        const [qrmodal,setQrmodal]= useState(false);
        const inputRef = useRef(null);

        const [hoy,setHoy]=useState(moment());
        let diaInicial =hoy.clone().startOf(props.modoVista).startOf("week").subtract(1,"day");

        if(props.modoVista===MODO_VISTA_DIA){
            diaInicial =hoy.clone().startOf(props.modoVista).startOf("hour");
        }
    
        if(props.modoVista===MODO_VISTA_SEMANA){
            diaInicial =hoy.clone().startOf(props.modoVista);
        }

        const anteriorHandler=()=>{
            setHoy(prev=>prev.clone().subtract(1,props.modoVista))
        };
        const siguienteHandler=()=>{
            setHoy(prev=>prev.clone().add(1,props.modoVista))
        };
        const hoyHandler=()=>{
            setHoy(moment())
        };
        console.log("qrmodal:"+qrmodal)
        let loading=props.loading;
        let QR="";
        let resultDatos;
        let animacionCargando;
        

        if(props.profil===PROFIL_ADMINISTRADOR){
               QR=<Hidden xsDown implementation="css"><QRFloatButton setQrmodal={setQrmodal} /></Hidden>
        }
        
        if (loading) {
            animacionCargando = <Fab position="fixed" variant="circular" size="small" aria-label="cargando" style={{position: 'fixed', top: "5.5rem", float: "left",
                                    left: "45%", boxShadow:"1", backgroundColor:"#FFFFFF",padding:"0.1rem"}}>
                                    <CircularProgress color="secondary" size="1.5rem"/>                                        
                                </Fab>
        }else{
            animacionCargando=<div/>
        }
        
        if(props.citaes.length>=0){
            resultDatos =  <div className="row" ref={inputRef} >  
                                <Collapse in={props.mostrarBusqueda}>
                                    <CitaBusqueda buscar={props.buscar}  />
                                </Collapse>
                                <CitaList  citaes={props.citaes} abrirModalEditar={props.abrirModalEditar}
                                                    busqueda={props.busqueda}/>
                            </div>
                                
        }

        useEffect(() => {
            
            console.log("useEffect (modoVista):"+props.modoVista);
            console.log("useEffect (Cita)");
            if(props.mostrarBusqueda){
                //inputRef.current.scrollIntoView({behavior: "smooth"}); 
                //window.scrollTo(0, 0)

                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  });
            }

          //  setModoVista(props.modoVista);


          })
        return <div style={{marginBottom:"4rem" , marginTop:"4rem" }}>
                     
                     <Hidden xsDown implementation="css">
                        <CalendarioEncabezado 
                            hoy={hoy}
                            diaInicial={diaInicial}
                            anteriorHandler={anteriorHandler}
                            siguienteHandler={siguienteHandler}
                            hoyHandler={hoyHandler}
                            modoVista={props.modoVista}
                            setModoVista={props.setModoVista}

                        />
                       
                     </Hidden>

                    <CitaForm  modal={props.modal} 
                                      tipoSeleccion={props.tipoSeleccion}
                                        cerrarModal={props.cerrarModal} 
                                            agregarCitaWSAction={props.agregarCitaWSAction}/>                
                               
                    <CitaFormEdit  modalEditar={props.modalEditar} 
                                          payload={props.payload} 
                                          tipoSeleccion={props.tipoSeleccion}
                                            cerrarModalEditar={props.cerrarModalEditar} 
                                                actualizarCitaWSAction={props.actualizarCitaWSAction}
                                                eliminarCitaWSAction={props.eliminarCitaWSAction}    />                

                    <QRScan qrmodal={qrmodal}
                            setQrmodal={setQrmodal}
                            />

                    <div className="row">
                         {props.modoVista===MODO_VISTA_DIA
                              ?
                              <div>
                                    <DiaGrid 
                                          hoy={hoy}
                                          diaInicial={diaInicial.clone()}
                                          diaInicialDia={diaInicial}

                                    />
                              </div>
                              :null
                        }
                        {
                            props.modoVista===MODO_VISTA_SEMANA
                              ?
                              <div>
                                    <SemanaGrid 
                                          hoy={hoy}
                                          diaInicial={diaInicial.clone()} //Se envia un a copoia ya que el momento queda en otro dia el final del array
                                          diaInicialSemana={diaInicial}
                                    />
                              </div>      
                              :null
                        }
                        {
                            props.modoVista===MODO_VISTA_MES
                                    ?
                                            <CalendarioGrid 
                                                hoy={hoy}
                                                diaInicial={diaInicial}
                                            />
                                    :null
                        }
                        {
                            props.modoVista===MODO_VISTA_AGENDADOS
                                    ?   resultDatos

                                    :null
                        }
                    </div>               

                    <div className="row">
                       {QR}
                    </div>                
                    <div className="row">
                        <CitaFloatButton abrirModal={props.abrirModal} />
                    </div>                
                    

                </div>
         
};

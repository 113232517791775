import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="standard" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Page(props) {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.mostrarMensaje(false,props.mensaje);
    };

  return (
    <div className={classes.root}>
     
      <Snackbar open={props.abrirMensaje} autoHideDuration={2000} onClose={handleClose} style={{marginBottom:75}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
          }} >
        <Alert onClose={handleClose} severity={props.tipoMensaje} variant="standard" >
          {props.mensaje}
        </Alert>
      </Snackbar>
    </div>
  );
}

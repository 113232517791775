import {connect} from "react-redux";
import React,{Component} from "react";
import { CAT_TIPO_CITA, CITA, INSTITUCION, MEDICO, MEDICO_USUARIO, PACIENTE } from "../../../redux/constants/action-type";
import Page from "./page";
import { PROFIL_ADMINISTRADOR, PROFIL_MEDICO } from "../../../utils/constantes";
import { actualizarRegistrosAction } from "../../../redux/actions/stateActions";

class TablaBasica extends Component{

    render(){
	        return <Page 
                        citas={this.props.citas}
                        catTipoCita={this.props.catTipoCita}
                  />
    }

}


const mapStateToProps=(state)=>({
 catTipoCita:state.CatTipoCitaReducer[CAT_TIPO_CITA],
})

const mapDispatchToProps=(dispatch)=>({
	actualizarRegistrosAction: (type,registros) => dispatch(actualizarRegistrosAction(type,registros)),

})

export default connect(mapStateToProps,mapDispatchToProps)(TablaBasica);
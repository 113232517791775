import React,{Component} from "react";
import Page from "./page";

export default class PacientesListItem extends Component{
    render(){    
        return< Page producto={this.props.producto} abrirModalEditar={this.props.abrirModalEditar}/>
    }

}


import React from "react";
import Dashboard from '../dashboard';
import { Route,Switch } from "react-router-dom";
import Login from "../LoginComponent/Login";
import requireAutenticacion from "../../utils/requireAutenticacion"
import Instituciones from "../InstitucionComponent/Institucion";
import Usuarios from "../UsuarioComponent/Usuario";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SnackbarsMensaje from "../SnackbarsMensajeComponent";
import Medicos from "../MedicoComponent/Medico"
import Pacientes from "../PacienteComponent/Paciente"
import Citas from "../CitaComponent/Cita"
import CitasTV from "../CitaTVComponent/Cita"
import EsquemaTrabajo from "../EsquemaTrabajoComponent/EsquemaTrabajo"
import CatTipoCita from "../CatTipoCitaComponent/CatTipoCita"
import PresionArterial from "../PresionArterialComponent/PresionArterial"
import Glucosa from "../GlucosaComponent/Glucosa"
import Comida from "../ComidaComponent/Comida"
import PaginaWeb from "../PaginaWebComponent/PaginaWeb"
import Producto from "../ProductoComponent/Producto"
import Procedimiento from "../ProcedimientoComponent/Procedimiento"
import Movimiento from "../MovimientoComponent/Movimiento"
import Tecnico from "../TecnicoComponent/Tecnico"
import Navbar from "../navbar";
import Registro from "../RegistroComponent/Registro";

const Page =()=>{

    const useStyles = makeStyles((theme) => ({
        root: {
          display: 'flex',
        },
        drawer: {
          [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
          },
        },
        appBar: {
          [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
          },
        },
        menuButton: {
          marginRight: theme.spacing(2),
          [theme.breakpoints.up('sm')]: {
            display: 'none',
          },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
          width: drawerWidth,
        },
        content: {
          flexGrow: 1,
          padding: theme.spacing(0,1,0,0),
          marginTop: "0rem",
          backgroundColor:"white"
        },
      }));

      const drawerWidth = 240;
      const classes = useStyles();
      const pathname = window.location.pathname; //returns the current url minus the domain name

  
        return  <div>
                       <main className={classes.content}>                        
                        <Route  exact={true} path="/tv" component={requireAutenticacion(CitasTV)} />
                        <Route  exact={true} path="/login" component={Login} />
                        <Route  exact={true} path="/" component={Login} /> 
                        <SnackbarsMensaje />
                       </main>
                </div>
};

export default Page;







import React,{Component} from "react";
import List from '@material-ui/core/List';
import CitaSubList from "../CitaSubList";

export default function Page(props) {
  return (
            <List>
            {
              Object.keys(props.citaesFilter).map((fecha,index)=>{ 
                return <CitaSubList key={index} id={index}  fecha={fecha} citas={props.citaesFilter[fecha]} abrirModalEditar={props.abrirModalEditar}/>
              })

            }
          </List>
         )
}
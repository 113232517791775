import React,{Component} from "react";
import Page from "./page";
import {Formik,ErrorMessage } from "formik";
import * as yup from 'yup'; // for everything
import { MEDICO } from "../../../redux/constants/action-type";


const validationSchema = yup.object({
	//	especialidad: yup.string().required("Campo requerido"),
		nombres: yup.string().required("Campo requerido"),
		apellidoPaterno: yup.string().required("Campo requerido"),
		apellidoMaterno: yup.string().required("Campo requerido"),
	//	cedulaProfesional: yup.string().required("Campo requerido"),
	//	correo: yup.string().required("Campo requerido"),
	//	telefono: yup.string().required("Campo requerido"),
	//	celular: yup.string().required("Campo requerido")
})

class RegistroForm extends Component{
        
    render(){    
    
        return <Formik
                    initialValues={{
                        'titulo': '',
                        'nombres': '',
                        'apellidoPaterno': '',
                        'apellidoMaterno': '',
                        'cedulaProfesional': '',
                        'correo': '',
                        'telefono': '',
                        'celular': '',
                        'especialidad': '',

                    }}

                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm}) => {
                          console.log("valores "+JSON.stringify(values));
                          setSubmitting(this.props.agregarMedicoWSAction(MEDICO,values));
                          resetForm({values:""});      
                          this.props.setMedicoRegistrado(values.titulo+" "+values.nombres+" "+values.apellidoMaterno+" "+values.apellidoPaterno);
                      }}


                >
                {props => < Page {...props}  openModalPaciente={this.props.openModalPaciente}  
                                finalizaCreacionPaciente={this.props.finalizaCreacionPaciente}
                                    errorsForm={this.props.errorsForm}  usuario={this.props.usuario} 
                                        loading={this.props.loading}/>}
                </Formik> 
    }
}

export default RegistroForm;


import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {COMIDA,PACIENTE,CAT_TIPO_COMIDA} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";


const validationSchema = yup.object({
		nombre: yup.string().required("Campo requerido"),
		descripcion: yup.string().required("Campo requerido"),
		notas: yup.string().required("Campo requerido"),
		CAT_TIPO_COMIDA_id: yup.string().required("Campo requerido"),
		PACIENTE_id: yup.string().required("Campo requerido")
})

class ComidaFormEdit extends Component{

    constructor(props) {
        super(props);
        this.state={
                modalConfirmacion:false,
        };
    }
    abrirConfirmacion=()=> {
        this.setState({
            modalConfirmacion:true
        });
    }
    cerrarConfirmacion=()=> {
        this.setState({
            modalConfirmacion:false
        });
    }

    eliminar=(dato)=> {
        this.cerrarConfirmacion();
        this.props.cerrarModalEditar();
        this.props.eliminarComidaWSAction(COMIDA,dato);
    }

    
    render(){    
    
        let valores=this.props.payload;
        let id=this.props.payload.id;
        const { bindSubmitForm } = this.props;

        return <Formik
                    enableReinitialize
                    initialValues={valores}
                    validationSchema={validationSchema}
                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          resetForm({values:""});
                          setSubmitting(this.props.actualizarComidaWSAction(COMIDA,values));
                         this.props.history.goBack();
                      }}
                >
                {props => < Page {...props}  catTipoComida={this.props.catTipoComida}  paciente={this.props.paciente}      
                                                tipoSeleccion={this.props.tipoSeleccion} 
                                                modalEditar={this.props.modalEditar}                                                 
                                                cerrarModalEditar={this.props.cerrarModalEditar} 
                                                eliminar={this.eliminar} 
                                                payload={this.props.payload}
                                                actualizarComidaWSAction={this.props.actualizarComidaWSAction}  
                                                modalConfirmacion={this.state.modalConfirmacion}
                                                cerrarConfirmacion={this.cerrarConfirmacion}
                                                abrirConfirmacion={this.abrirConfirmacion} />}
                </Formik> 
    }



}

const mapStateToProps=(state)=>({
  catTipoComida:state.CattipocomidaReducer[CAT_TIPO_COMIDA],
  paciente:state.PacienteReducer[PACIENTE],
})

export default withRouter(connect(mapStateToProps)(ComidaFormEdit));


import { Avatar, Badge, Box, Card, CardContent, CardHeader, Chip, Container, ListItem, ListItemText, makeStyles, Paper, Typography, withStyles } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import moment from "moment/moment";
import React,{Component} from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 104,
    minHeight: 104,
    borderColor:"green",
    backgroundColor:"yellow",
    paddingBottom:0,
  },
  content:{
    padding:1,

  },
  avatar: {
    backgroundColor: blue[500],
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  listaItem:{
    paddingTop:0,
    paddingBottom:0,
    paddingLeft:0,
    paddingRight:0,
    padding:0,
  }
}));

function Page(props){   
  const classes = useStyles();
  
  let duracionTipoCita="";
  let tipoCita="";

  if(props.catTipoCita!==undefined){
    tipoCita=props.catTipoCita.nombre;
    duracionTipoCita=props.catTipoCita.duracion+"min";

  }
      return <div>
                      <ListItem className={classes.listaItem}>
                        <ListItemText                          
                          primary={
                                    
                                      <Chip 
                                        variant="default" 
                                        size="small" 
                                        style={{color:"#FFFFFF",borderWidth:"0px",maxWidth: 160,minWidth:30, backgroundColor:props.codColor, borderColor:props.codColor }} 
                                        avatar={
                                                  <Avatar  style={{backgroundColor:"#FFFFFF" ,color:props.codColor}} >
                                                       {tipoCita.charAt(0)}
                                                  </Avatar>
                                                }
                                        label={
                                                <Typography 
                                                  component="span" 
                                                  variant="subtitle2" 
                                                  style={{ 
                                                            textTransform:"capitalize",
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis"
                                                        }}
                                                >
                                                  {tipoCita}
                                                </Typography>
                                              }
                                        /> 
                                  }
                        />
                      </ListItem>

              </div>
           
}

export default Page;

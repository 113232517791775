import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import {logout} from "../../redux/actions/autenticacionActions"
import {mostrarBusqueda} from "../../redux/actions/stateActions"

class Navbar extends Component{
    render(){        
        return  <Page 
                    autenticado={this.props.autenticado} 
                    logout={this.props.logout} 
                    tipoSeleccion={this.props.tipoSeleccion} 
                    registros={this.props.registros}
                    profil={this.props.profil}
                    mostrarBusqueda={this.props.mostrarBusqueda}
                    />    
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        mostrarBusqueda: (mostrar) => dispatch(mostrarBusqueda(mostrar)),
        logout: () => dispatch(logout())

    }
}

const mapStateToProps=(state)=>({
    autenticado:state.autenticacionReducer.autenticado,    
    tipoSeleccion:state.estadosReducer.selectedType,
    registros:state.estadosReducer.registros,
    profil:state.autenticacionReducer.profil,     

})

export default connect(mapStateToProps,mapDispatchToProps)(Navbar);

import React,{Component } from "react";
import Page from "./page";
import {connect} from "react-redux";
import {PRODUCTO,CAT_TIPO_PRODUCTO} from "../../../redux/constants/action-type";
import {obtenerCatTipoProductoListWSAction,agregarCatTipoProductoWSAction,actualizarCatTipoProductoWSAction,eliminarCatTipoProductoWSAction} from "../../../redux/actions/CatTipoProductoActions";
import {obtenerProductoListWSAction,agregarProductoWSAction,actualizarProductoWSAction,eliminarProductoWSAction} from "../../../redux/actions/ProductoActions";


class Productos extends Component{

    constructor(props) {
        super(props);
        this.state={
                modal:false,
                modalEditar:false,
                payload: {
					'descripcion': '',
					'precio': '',
					'codigo': '',
					'codBarras': '',
					'lote': '',
					'fechaCaducidad': '',
					'fechaAlta': '',
					'maximo': '',
					'cantidad': '',
					'fabricante': '',
					'unidadMedida': '',
					'CAT_TIPO_PRODUCTO_id': ''
                },
                busqueda:""

        };
    }
     
    abrirModal=()=> {
        this.setState({
            modal:true
        });
    }

    cerrarModal=()=> {
        this.setState({
            modal:false
        });
    }

    abrirModalEditar = (value)=> {
        this.setState({
            modalEditar:true,
            payload: value

        });
    };

    cerrarModalEditar=()=> {
        this.setState({
            modalEditar:false
        });
    }

    buscar=(valor)=> {
        this.setState({
            busqueda:valor
        });
    }


    render(){        
        return  <Page productos={this.props.productos} loading={this.props.loading} 
                    modal={this.state.modal}
                    modalEditar={this.state.modalEditar}
                    payload={this.state.payload}
                       abrirModal={this.abrirModal}
                           cerrarModal={this.cerrarModal}
                                abrirModalEditar={this.abrirModalEditar}
                                    cerrarModalEditar={this.cerrarModalEditar}
                                    tipoSeleccion={this.props.tipoSeleccion}
                                agregarProductoWSAction={this.props.agregarProductoWSAction}
                                actualizarProductoWSAction={this.props.actualizarProductoWSAction}
                                eliminarProductoWSAction={this.props.eliminarProductoWSAction}   
                                buscar={this.buscar} busqueda={this.state.busqueda}/>    
    }

 /* esta es la accion de middleware*/
        componentDidMount(){            
            let valores=this.props.location.valores;            
            if(valores === undefined){
				this.props.obtenerCatTipoProductoListWSAction(CAT_TIPO_PRODUCTO,false);
                this.props.obtenerProductoListWSAction(PRODUCTO,true); /* Invoca por primara vez a productos (default) */
            }else{
                this.setState(this.props.location.valores);
            }

        }


}

/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
    productos:state.ProductoReducer[PRODUCTO],    
    loading:state.estadosPeticionReducer.loading,
    tipoSeleccion:state.estadosReducer.selectedType,

})

/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        obtenerProductoListWSAction: (type,loading) => dispatch(obtenerProductoListWSAction(type,loading)),
        obtenerCatTipoProductoListWSAction: (type,loading) => dispatch(obtenerCatTipoProductoListWSAction(type,loading)),
        agregarProductoWSAction: (type,payload) => dispatch(agregarProductoWSAction(type,payload)),
        actualizarProductoWSAction: (type,payload) => dispatch(actualizarProductoWSAction(type,payload)),
        eliminarProductoWSAction: (type,payload) => dispatch(eliminarProductoWSAction(type,payload))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Productos);



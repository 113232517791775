import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {USUARIO,CAT_ROL,INSTITUCION} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {obtenerCatRolListWSAction} from "../../../redux/actions/CatRolActions"
import { CONSULTAR_PACIENTEES } from "../../../redux/constants/Paciente-action-type";
import {ID_ROL_MEDICO,ROLES_ERLAUB_ARRAY} from "../../../utils/constantes"

const addressRegex = /^[a-zA-Z0-9][a-zA-Z0-9 .,-]*$/;
const passwordRegex = /^[a-zA-Z0-9][a-zA-Z0-9 .,]*$/;
const alfanumericoRegex = /^[a-zA-Z0-9][a-zA-Z0-9]*$/;


const validationSchema = yup.object().shape({
    usuario:yup.object().shape({
        usuario: yup.string().matches(
            alfanumericoRegex,
            "Solo es permitido valores alphanumericos (letras y numeros)."
          ).min(8, 'debe ser mayor a 8 caracteres').max(25, 'debe ser menor a 25 caracteres').required("Campo requerido"),
		password: yup.string().matches(
            passwordRegex,
            "Solo es permitido valores alphanumericos (letras y numeros) puntos y comas."
          ).min(8, 'debe ser mayor a 8 caracteres').max(45, 'debe ser menor a 45 caracteres').required("Campo requerido"),
        passwordConfirmacion:yup.string().oneOf([yup.ref("password"),null],"Las contraseñas no coinciden. Vuelve a intentarlo."), 
		correo: yup.string().min(2, 'debe ser mayor a 2 caracteres').max(45, 'debe ser menor a 45 caracteres').email('Correo no valido').required("Campo requerido"),
        }),
    rol:yup.object().shape({
        CAT_ROL_id: yup.string().required("Campo requerido"),
        MEDICO_id: yup.string().when("CAT_ROL_id",{ is:ID_ROL_MEDICO,then:yup.string().required("Campo requerido")})
        }),
})



class UsuarioForm extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }
    
    goBack(){
        this.props.history.goBack();
    }
    render(){    

        let idInstitucion="";
        if(this.props.instituciones!==undefined){
            idInstitucion=this.props.instituciones[0].id;
        }
        console.log("(idInstitucion):"+idInstitucion);                              

        const filteredRoles = this.props.roles.filter(rol => {
            return ROLES_ERLAUB_ARRAY.some((val) => rol.nombre === val);
          });
    
        return <Formik

                    initialValues={{
                        usuario:{'usuario': '',
                                 'password': '',
                                 'passwordConfirmacion': '',
                                 'correo': '',
                                 'ultimoLogin': '',
                                 'token': '',
                                 'INSTITUCION_id': idInstitucion,
                                 'ESTADO_id': '1' },
                            rol:{'CAT_ROL_id': '',
                                'MEDICO_id': '',
                                'PACIENTE_id': ''} // 21Feb21Dom - Guarda el id del paciente para este Usuario 
                    }}

                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                        console.log("(setSubmitting)valores: "+JSON.stringify(values));                              
                          setSubmitting(this.props.agregarUsuarioWSAction(USUARIO,values));
                          resetForm({values:""});
                          this.goBack();

                      }}
                >
                    {props => < Page {...props} 
                                     idInstitucion={this.idInstitucion} 
                                     instituciones={this.props.instituciones}  
                                     roles={filteredRoles}   
                                     modal={this.props.modal} 
                                     cerrarModal={this.props.cerrarModal} 
                                     profil={this.props.profil}
                                     />}
                </Formik> 
    }

    componentDidMount(){
        console.log("obtenerCatRolListWSAction: (desde) componentDidMount")
        this.props.obtenerCatRolListWSAction(CAT_ROL,false); /* Invoca por primara vez a productos (default) */
 
    }
 
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        obtenerCatRolListWSAction: (type,loading) => dispatch(obtenerCatRolListWSAction(type,loading)),
    }
}

const mapStateToProps=(state)=>({
  instituciones:state.InstitucionReducer[INSTITUCION],
  roles:state.CatRolReducer[CAT_ROL],
  profil:state.autenticacionReducer.profil,     

})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(UsuarioForm));



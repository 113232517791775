import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {PRODUCTO,CAT_TIPO_PRODUCTO} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";


const validationSchema = yup.object({
		descripcion: yup.string().required("Campo requerido"),
		precio: yup.string().required("Campo requerido"),
		codigo: yup.string().required("Campo requerido"),
		codBarras: yup.string().required("Campo requerido"),
		lote: yup.string().required("Campo requerido"),
		fechaCaducidad: yup.string().required("Campo requerido"),
		fechaAlta: yup.string().required("Campo requerido"),
		maximo: yup.string().required("Campo requerido"),
		cantidad: yup.string().required("Campo requerido"),
		fabricante: yup.string().required("Campo requerido"),
		unidadMedida: yup.string().required("Campo requerido"),
		CAT_TIPO_PRODUCTO_id: yup.string().required("Campo requerido")
})

class ProductoForm extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }
    
    goBack(){
        this.props.history.goBack();
    }

    render(){        
        return <Formik

                    initialValues={{
		'descripcion': '',
		'precio': '',
		'codigo': '',
		'codBarras': '',
		'lote': '',
		'fechaCaducidad': '',
		'fechaAlta': '',
		'maximo': '',
		'cantidad': '',
		'fabricante': '',
		'unidadMedida': '',
		'CAT_TIPO_PRODUCTO_id': ''
                                   }}

                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          setSubmitting(this.props.agregarProductoWSAction(PRODUCTO,values));
                          console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          resetForm({values:""});
                          this.goBack();

                    }}
                >
                    {props => < Page {...props}  catTipoProducto={this.props.catTipoProducto}   modal={this.props.modal} cerrarModal={this.props.cerrarModal} />}
                </Formik> 
    }
}

const mapStateToProps=(state)=>({
  catTipoProducto:state.CattipoproductoReducer[CAT_TIPO_PRODUCTO],
})

export default withRouter(connect(mapStateToProps)(ProductoForm));

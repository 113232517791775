import { Avatar, Box, Button, ButtonGroup, Grid, IconButton, Typography } from "@material-ui/core";
import React,{Component} from "react";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  container: {
    display:"flex",
    flexDirection:"column",
    margin: theme.spacing(1),
    alignItems:"center",
  },
  itemStyle: {
    alignItems:"center",
  },
  text: {
    paddingLeft: theme.spacing(1),
    textTransform:"capitalize",
  },
}));

function Page(props){   
  const classes = useStyles();
  
  let backgroundColorAvatar="";
  let diaActual=moment().isSame(props.hoy,"day");
  if(diaActual){
    backgroundColorAvatar="#1a90ff";
  }
  console.log("diaActual_:"+diaActual);

      return<Box
              className={classes.container}
            >

              <Box className={classes.itemStyle}>
                <Typography 
                  variant="caption" 
                  color="textPrimary" 
                  className={classes.text} 
                  style={{color:backgroundColorAvatar}}>
                    {
                      props.hoy.format("ddd")
                    }
                </Typography>
              </Box>  
              <Box className={classes.itemStyle}>
                <Avatar style={{backgroundColor:backgroundColorAvatar}}>{props.hoy.format("DD")}</Avatar>
              </Box>  

           </Box>
}

export default Page;

import React,{Component} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CardActionArea, Container, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import runImg from "../../../logo/1_opt.jpg";
import runImg2 from "../../../logo/1_opt.jpg";
import CardMedia from '@material-ui/core/CardMedia';



    function Page(props) {

    const useStyles  = () => makeStyles(theme => ({
        root: {
            maxWidth: 345,
            flexDirection:"column",
            borderRadius:"19px",
            position:"relative",
            display:"flex"

        },
        card: {
            maxWidth: 345,
            padding: theme.spacing.unit,
            textAlign: "center",
            color: theme.palette.text.secondary,
            marginBottom: theme.spacing.unit
        },
        pos: {
            marginBottom: 12,
          },
        }));
    const classes = useStyles();    
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    let cardAncho="80%";
    if(fullScreen){
        cardAncho="100%"
    }
      let errorsForm=props.errorsForm;
      let loading=props.loading;

      console.log("fullScreen:"+fullScreen);

      function handleChange(e){	
		props.setFieldValue(
			"titulo",e.target.value);	
	  }

        return (<Container maxWidth="sm" className={classes.container}>
                <Card variant="outlined" style={{width:cardAncho}} className={classes.root} elevation={3}>
                 
                     <CardHeader        
                                title="Registro"
                                subheader="Cree su cuenta como especialista."
                     /> 
                    <form onSubmit={props.handleSubmit}>
                             
       
                        

                        {errorsForm && <Alert severity="error">{errorsForm}</Alert>}

                        <CardContent >
                        
                        {loading && <div className="container-fluid" align="center"><CircularProgress color="secondary" /> </div> }


                        <FormControl
								fullWidth
								margin="normal"
								variant="filled" 

						>
                        <InputLabel htmlFor="age-native-simple"> Titulo:</InputLabel>

                        <Select
                            error={props.touched.titulo&&props.errors.titulo? true:false}
                            size="medium"
                            id="titulo"
                            label="Titulo:"
                            fullWidth
                            variant="outlined"
                            onChange={handleChange} 
                            value={props.values.titulo}

                            >							
                            <MenuItem value={"Dr."}>Dr.</MenuItem>
                            <MenuItem value={"Dra."}>Dra.</MenuItem>
                        </Select>
                        </FormControl>

                        <TextField
                            error={props.touched.nombres&&props.errors.nombres? true:false}
                            helperText={props.touched.nombres&&props.errors.nombres?props.errors.nombres:""}
                            size="medium"
                            margin="normal"
                            id="nombres"
                            label="Nombres:"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={props.handleChange} value={props.values.nombres}
                            onBlur={props.handleBlur}
                        />
                        <TextField
                            error={props.touched.apellidoPaterno&&props.errors.apellidoPaterno? true:false}
                            helperText={props.touched.apellidoPaterno&&props.errors.apellidoPaterno?props.errors.apellidoPaterno:""}
                            size="medium"
                            margin="normal"
                            id="apellidoPaterno"
                            label="Apellido Paterno:"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={props.handleChange} value={props.values.apellidoPaterno}
                            onBlur={props.handleBlur}
                        />
                        <TextField
                            error={props.touched.apellidoMaterno&&props.errors.apellidoMaterno? true:false}
                            helperText={props.touched.apellidoMaterno&&props.errors.apellidoMaterno?props.errors.apellidoMaterno:""}
                            size="medium"
                            margin="normal"
                            id="apellidoMaterno"
                            label="Apellido Materno:"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={props.handleChange} value={props.values.apellidoMaterno}
                            onBlur={props.handleBlur}
                        />
                        <TextField
                            error={props.touched.cedulaProfesional&&props.errors.cedulaProfesional? true:false}
                            helperText={props.touched.cedulaProfesional&&props.errors.cedulaProfesional?props.errors.cedulaProfesional:""}
                            size="medium"
                            margin="normal"
                            id="cedulaProfesional"
                            label="Cedula Profesional:"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={props.handleChange} value={props.values.cedulaProfesional}
                            onBlur={props.handleBlur}
                        />
                        <TextField
                            error={props.touched.correo&&props.errors.correo? true:false}
                            helperText={props.touched.correo&&props.errors.correo?props.errors.correo:""}
                            size="medium"
                            margin="normal"
                            id="correo"
                            label="Correo Eléctronico:"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={props.handleChange} value={props.values.correo}
                            onBlur={props.handleBlur}
                        />
                        <TextField
                            error={props.touched.telefono&&props.errors.telefono? true:false}
                            helperText={props.touched.telefono&&props.errors.telefono?props.errors.telefono:""}
                            size="medium"
                            margin="normal"
                            id="telefono"
                            label="Telefono Contacto:"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={props.handleChange} value={props.values.telefono}
                            onBlur={props.handleBlur}
                        />
                        <TextField
                            error={props.touched.celular&&props.errors.celular? true:false}
                            helperText={props.touched.celular&&props.errors.celular?props.errors.celular:""}
                            size="medium"
                            margin="normal"
                            id="celular"
                            label="Celular Contacto:"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={props.handleChange} value={props.values.celular}
                            onBlur={props.handleBlur}
                        />
                        <TextField
                            error={props.touched.especialidad&&props.errors.especialidad? true:false}
                            helperText={props.touched.especialidad&&props.errors.especialidad?props.errors.especialidad:""}
                            size="medium"
                            margin="normal"
                            id="especialidad"
                            label="Especialidad:"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={props.handleChange} value={props.values.especialidad}
                            onBlur={props.handleBlur}
                        />
                            
                            </CardContent>
                                <CardActions>

                                    <Button size="large" onClick={props.handleSubmit} variant="contained" color="primary" disabled={loading} type="submit" fullWidth={true}>
                                        Enviar
                                    </Button>
                                </CardActions>
                        </form>   
                    </Card>
                  </Container>  
                )
    
}
export default Page;

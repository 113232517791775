import React,{Component, useCallback, useEffect} from "react";
import PacienteListItem from "../PacienteListItem";
import List from '@material-ui/core/List';
import { useRef } from "react";



export default function Page(props) {

  const observerRef=useRef();
  const lastElementRef=useCallback(node=>{
    if(props.loading) return;
    console.log("observerRef:"+observerRef);
    console.log("observerRef:"+observerRef.current);  
    console.log("node :"+node);
    if(observerRef.current){
      observerRef.current.disconnect();

    }

    observerRef.current=new IntersectionObserver(entries=>{
      console.log("observer[entries]:"+entries);
      console.log("observer[entries]2:"+entries[0]);
      console.log("observer[entries]3:"+entries[0].isIntersecting);
      if(entries[0].isIntersecting && props.hatMehr){
        props.setPage(prevPageNum=>prevPageNum+1);
      }
  
    })

    if(node){
      observerRef.current.observe(node);
    }

  },[props.hatMehr]);

    

  useEffect(()=>{
    console.log("useEffect[observerRef]:");

  }
  )
      return (<List>
         <div>{props.loading && "cargando..."}</div> 
                {
                props.pacienteesFilter.map((paciente,index)=> {
                  if(props.pacienteesFilter.length===index+1){
                    return <div id={index} key={index} ref={lastElementRef}>
                           
                            <PacienteListItem  key={index} paciente={paciente} abrirModalEditar={props.abrirModalEditar}/> 
                          </div>
                  
                  }else{
                    return<div id={index} key={index} >
                            <PacienteListItem  key={index} paciente={paciente} abrirModalEditar={props.abrirModalEditar}/> 
                          </div>
                  
                  }
                })
                }
            </List>)
                             
}

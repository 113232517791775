import React,{Component, useEffect, useState} from "react";
import Page from "./page";

export default function PacientesList(props) {

	const [query,setQuery]=useState("");
	const [loading,setLoading]=useState(true);
	const [rows,setRows]=useState(15);
	const [page,setPage]=useState(1);
	const [dataPaciente,setDataPaciente]=useState([]);
	const [hatMehr,setHatMehr]=useState(false);
    
        let pacienteesFilter = props.pacientees.filter((data)=>{
			if(data!=undefined){
				if(data.nombres!=undefined){
					if(props.busqueda == null){
						return data;
					}else if(data.nombres.toLowerCase().includes(props.busqueda.toLowerCase())){
						return data;
					}
				}
			}
         });   
  
	useEffect(() => {
		if(props.busqueda !== query){
			setQuery(props.busqueda );
			setDataPaciente([]);
			setPage(1);
			setHatMehr(false);
		}
		setLoading(true);
		let newDataPaciente=[...pacienteesFilter.slice(((rows*page)-rows), (rows*page))];
		setDataPaciente( prev=> {
							return [...prev,...pacienteesFilter.slice(((rows*page)-rows), (rows*page))]
						});
		setHatMehr(newDataPaciente.length>0);
		setLoading(false);
	},[
		page, 
		props.busqueda
	  ]);

	return(
			< Page pacienteesFilter={dataPaciente} 
					abrirModalEditar={props.abrirModalEditar} 
					setPage={setPage} 
					loading={loading} 
					hatMehr={hatMehr} 
					busqueda={props.busqueda}
					/>
			)
    

}


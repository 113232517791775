import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {GLUCOSA,PACIENTE,CAT_UNIDAD_MEDIDA,PACIENTE_USUARIO} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {UNIDAD_MEDIDA_GLUCOSA} from "../../../utils/constantes";
import {getFormatoHorario} from "../../../utils/moment"


const validationSchema = yup.object({
		valor: yup.string().required("Campo requerido"),
		fecha: yup.string().required("Campo requerido"),
		hora: yup.string().required("Campo requerido"),
		CAT_UNIDAD_MEDIDA_id: yup.string().required("Campo requerido"),
		PACIENTE_id: yup.string().required("Campo requerido")
})

class GlucosaForm extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }
    
    goBack(){
        this.props.history.goBack();
    }
    render(){    
    
        let PACIENTE_id="";
        if(this.props.pacienteUsuario!==undefined){
            PACIENTE_id=this.props.pacienteUsuario.PACIENTE_id;
        }
        console.log("(PACIENTE_id)presion Arterial "+PACIENTE_id); 

        return <Formik

                    initialValues={{
                        'descripcion': '',
                        'valor': '',
                        'nota': '',
                        'fecha': new Date().toISOString().substring(0,10),
                        'hora': getFormatoHorario(new Date()),
                        'CAT_UNIDAD_MEDIDA_id': UNIDAD_MEDIDA_GLUCOSA,
                        'PACIENTE_id': PACIENTE_id
                    }}

                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                        console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                        setSubmitting(this.props.agregarGlucosaWSAction(GLUCOSA,values));
                          resetForm({values:""});
                          this.goBack();

                      }}
                >
                    {props => < Page {...props}  catUnidadMedida={this.props.catUnidadMedida}  paciente={this.props.paciente}   modal={this.props.modal} cerrarModal={this.props.cerrarModal} />}
                </Formik> 
    }
}

const mapStateToProps=(state)=>({
  catUnidadMedida:state.CatUnidadMedidaReducer[CAT_UNIDAD_MEDIDA],
  paciente:state.PacienteReducer[PACIENTE],
  pacienteUsuario:state.PacienteUsuarioReducer[PACIENTE_USUARIO][0] 

})

export default withRouter(connect(mapStateToProps)(GlucosaForm));



import React,{Component } from "react";
import Page from "./page";
import {connect} from "react-redux";
import {PRESION_ARTERIAL,PACIENTE,CAT_UNIDAD_MEDIDA} from "../../../redux/constants/action-type";
import {obtenerCatUnidadMedidaListWSAction,agregarCatUnidadMedidaWSAction,actualizarCatUnidadMedidaWSAction,eliminarCatUnidadMedidaWSAction} from "../../../redux/actions/CatUnidadMedidaActions";
import {obtenerPacienteListWSAction,agregarPacienteWSAction,actualizarPacienteWSAction,eliminarPacienteWSAction} from "../../../redux/actions/PacienteActions";
import {obtenerPresionArterialListWSAction,agregarPresionArterialWSAction,actualizarPresionArterialWSAction,eliminarPresionArterialWSAction} from "../../../redux/actions/PresionArterialActions";


class PresionArteriales extends Component{

    constructor(props) {
        super(props);
        this.state={
                modal:false,
                modalEditar:false,
                payload: {
					'descripcion': '',
					'valorSistolica': '',
					'valorDiastolica': '',
					'pulso': '',
					'nota': '',
					'fecha': '',
					'hora': '',
					'CAT_UNIDAD_MEDIDA_id': '',
					'PACIENTE_id': ''
                },
                busqueda:"",
                loadingReportData:false

        };
    }
     
    abrirModal=()=> {
        this.setState({
            modal:true
        });
    }

     cerrarModal=()=> {
         console.log("cerrarModal<>")
        this.setState({
            modal:false
        });
    }

    abrirModalEditar = (value)=> {
        this.setState({
            modalEditar:true,
            payload: value

        });
    };

    cerrarModalEditar=()=> {
        this.setState({
            modalEditar:false
        });
    }

    buscar=(valor)=> {
        this.setState({
            busqueda:valor
        });
    }

    setLoadingReportData=(valor)=> {
        this.setState({
            loadingReportData:valor
        });
    }


    render(){        
        return  <Page presionArteriales={this.props.presionArteriales} loading={this.props.loading} 
                    modal={this.state.modal}
                    modalEditar={this.state.modalEditar}
                    payload={this.state.payload}
                       abrirModal={this.abrirModal}
                           cerrarModal={this.cerrarModal}
                                abrirModalEditar={this.abrirModalEditar}
                                    cerrarModalEditar={this.cerrarModalEditar}
                                    tipoSeleccion={this.props.tipoSeleccion}
                                agregarPresionArterialWSAction={this.props.agregarPresionArterialWSAction}
                                actualizarPresionArterialWSAction={this.props.actualizarPresionArterialWSAction}
                                eliminarPresionArterialWSAction={this.props.eliminarPresionArterialWSAction}   
                                buscar={this.buscar} busqueda={this.state.busqueda}
                                setLoadingReportData={this.setLoadingReportData}
                                loadingReportData={this.state.loadingReportData}
                                />    
    }

 /* esta es la accion de middleware*/
        componentDidMount(){            
            let valores=this.props.location.valores;            
            if(valores === undefined){
				this.props.obtenerCatUnidadMedidaListWSAction(CAT_UNIDAD_MEDIDA,false);
                this.props.obtenerPresionArterialListWSAction(PRESION_ARTERIAL,true); /* Invoca por primara vez a productos (default) */
            }else{
                this.setState(this.props.location.valores);
            }

        }


}

/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
    presionArteriales:state.PresionArterialReducer[PRESION_ARTERIAL],    
    loading:state.estadosPeticionReducer.loading,
    tipoSeleccion:state.estadosReducer.selectedType,

})

/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        obtenerPresionArterialListWSAction: (type,loading) => dispatch(obtenerPresionArterialListWSAction(type,loading)),
        obtenerCatUnidadMedidaListWSAction: (type,loading) => dispatch(obtenerCatUnidadMedidaListWSAction(type,loading)),
        agregarPresionArterialWSAction: (type,payload) => dispatch(agregarPresionArterialWSAction(type,payload)),
        actualizarPresionArterialWSAction: (type,payload) => dispatch(actualizarPresionArterialWSAction(type,payload)),
        eliminarPresionArterialWSAction: (type,payload) => dispatch(eliminarPresionArterialWSAction(type,payload))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(PresionArteriales);



import React,{Component } from "react";
import Page from "./page";
import {connect} from "react-redux";
import {PROCEDIMIENTO,CAT_TIPO_PROCEDIMIENTO,INSTITUCION,TECNICO,MEDICO,PACIENTE} from "../../../redux/constants/action-type";
import {obtenerPacienteListWSAction,agregarPacienteWSAction,actualizarPacienteWSAction,eliminarPacienteWSAction} from "../../../redux/actions/PacienteActions";
import {obtenerMedicoListWSAction,agregarMedicoWSAction,actualizarMedicoWSAction,eliminarMedicoWSAction} from "../../../redux/actions/MedicoActions";
import {obtenerCatTipoProcedimientoListWSAction,agregarCatTipoProcedimientoWSAction,actualizarCatTipoProcedimientoWSAction,eliminarCatTipoProcedimientoWSAction} from "../../../redux/actions/CatTipoProcedimientoActions";
import {obtenerTecnicoListWSAction,agregarTecnicoWSAction,actualizarTecnicoWSAction,eliminarTecnicoWSAction} from "../../../redux/actions/TecnicoActions";
import {obtenerInstitucionListWSAction,agregarInstitucionWSAction,actualizarInstitucionWSAction,eliminarInstitucionWSAction} from "../../../redux/actions/InstitucionActions";
import {obtenerProcedimientoListWSAction,agregarProcedimientoWSAction,actualizarProcedimientoWSAction,eliminarProcedimientoWSAction} from "../../../redux/actions/ProcedimientoActions";


class Procedimientos extends Component{

    constructor(props) {
        super(props);
        this.state={
                modal:false,
                modalEditar:false,
                payload: {
					'descripcion': '',
					'PACIENTE_id': '',
					'MEDICO_id': '',
					'duracion': '',
					'CAT_TIPO_PROCEDIMIENTO_id': '',
					'TECNICO_id': '',
					'INSTITUCION_id': ''
                },
                busqueda:""

        };
    }
     
    abrirModal=()=> {
        this.setState({
            modal:true
        });
    }

    cerrarModal=()=> {
        this.setState({
            modal:false
        });
    }

    abrirModalEditar = (value)=> {
        this.setState({
            modalEditar:true,
            payload: value

        });
    };

    cerrarModalEditar=()=> {
        this.setState({
            modalEditar:false
        });
    }

    buscar=(valor)=> {
        this.setState({
            busqueda:valor
        });
    }


    render(){        
        return  <Page procedimientos={this.props.procedimientos} loading={this.props.loading} 
                    modal={this.state.modal}
                    modalEditar={this.state.modalEditar}
                    payload={this.state.payload}
                       abrirModal={this.abrirModal}
                           cerrarModal={this.cerrarModal}
                                abrirModalEditar={this.abrirModalEditar}
                                    cerrarModalEditar={this.cerrarModalEditar}
                                    tipoSeleccion={this.props.tipoSeleccion}
                                agregarProcedimientoWSAction={this.props.agregarProcedimientoWSAction}
                                actualizarProcedimientoWSAction={this.props.actualizarProcedimientoWSAction}
                                eliminarProcedimientoWSAction={this.props.eliminarProcedimientoWSAction}   
                                buscar={this.buscar} busqueda={this.state.busqueda}/>    
    }

 /* esta es la accion de middleware*/
        componentDidMount(){            
            let valores=this.props.location.valores;            
            if(valores === undefined){
				this.props.obtenerPacienteListWSAction(PACIENTE,false);
				this.props.obtenerMedicoListWSAction(MEDICO,false);
				this.props.obtenerCatTipoProcedimientoListWSAction(CAT_TIPO_PROCEDIMIENTO,false);
				this.props.obtenerTecnicoListWSAction(TECNICO,false);
				this.props.obtenerInstitucionListWSAction(INSTITUCION,false);
                this.props.obtenerProcedimientoListWSAction(PROCEDIMIENTO,true); /* Invoca por primara vez a productos (default) */
            }else{
                this.setState(this.props.location.valores);
            }

        }


}

/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
    procedimientos:state.ProcedimientoReducer[PROCEDIMIENTO],    
    loading:state.estadosPeticionReducer.loading,
    tipoSeleccion:state.estadosReducer.selectedType,

})

/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        obtenerProcedimientoListWSAction: (type,loading) => dispatch(obtenerProcedimientoListWSAction(type,loading)),
        obtenerPacienteListWSAction: (type,loading) => dispatch(obtenerPacienteListWSAction(type,loading)),
        obtenerMedicoListWSAction: (type,loading) => dispatch(obtenerMedicoListWSAction(type,loading)),
        obtenerCatTipoProcedimientoListWSAction: (type,loading) => dispatch(obtenerCatTipoProcedimientoListWSAction(type,loading)),
        obtenerTecnicoListWSAction: (type,loading) => dispatch(obtenerTecnicoListWSAction(type,loading)),
        obtenerInstitucionListWSAction: (type,loading) => dispatch(obtenerInstitucionListWSAction(type,loading)),
        agregarProcedimientoWSAction: (type,payload) => dispatch(agregarProcedimientoWSAction(type,payload)),
        actualizarProcedimientoWSAction: (type,payload) => dispatch(actualizarProcedimientoWSAction(type,payload)),
        eliminarProcedimientoWSAction: (type,payload) => dispatch(eliminarProcedimientoWSAction(type,payload))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Procedimientos);



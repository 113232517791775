import * as React from 'react';
import SliderItem from '../SliderItem';
import { Box, Button, Checkbox, ClickAwayListener, Container, Grow, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Menu, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { ArrowDropDown, Translate } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import SlideButton from '../SliderButton';
import { ESTATUS_EGRESO, ESTATUS_EN_PROCEDIMIENTO, ESTATUS_LISTO_EN_SALA } from '../../../utils/constantes';
import { getFormatoFecha } from '../../../utils/moment';
import FilterListTwoToneIcon from '@material-ui/icons/FilterListTwoTone';

const useStyles = makeStyles({

  root: {
    position: "relative",
    overflow:"hidden",
    backgroundColor: "transparent",
    padding: "0px 0",

  },
  row: {
    display:"flex",
    position: "relative",
    backgroundColor: "transparent",
    padding: "0 55px",

  },
 
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  tr:{
    background: "#f1f1f1",
    '&:hover': {
      background: "#f00",
    }
  }
});



const ITEM_HEIGHT = 48;

export default function Page(props) {

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [checked, setChecked] = React.useState(localStorage.getItem('procedsFilter') !== null ? JSON.parse(localStorage.getItem('procedsFilter')) : []);


  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleChecked = (value) => () => {
    const newValue = checked.find((item)=> item.id == value.id );

    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    console.log("currentIndex:"+currentIndex);
    console.log("newChecked:"+JSON.stringify(newChecked));

    if (newValue === undefined) {
      newChecked.push(value);
      setChecked(prev => [...prev, value]);

    } else {
      newChecked.splice(currentIndex, 1);
      setChecked(prev => {
        return prev.filter(item => item.id !== value.id)
      })
    }


    //setChecked(newChecked);
  };

  console.log("catTipoCita[SliderFilter]:"+JSON.stringify(props.catTipoCita));
  
  const classes = useStyles();

  React.useEffect(() => {

   

   console.log("localStorage[useEffect]:"+localStorage.getItem('procedsFilter'));

   console.log("checked[useEffect]:"+checked);

  console.log("checked[useEffect]:"+JSON.stringify(checked));


  localStorage.setItem('procedsFilter',JSON.stringify(checked));

  }, [checked]);

  return ( 
       <div className={classes.root} >
        <Button
            ref={anchorRef}
            color="primary"
            size="small"
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}

          >
            <FilterListTwoToneIcon />
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
            <Menu
              id="long-menu"
              anchorEl={anchorRef.current}
              keepMounted
              open={open}
              onClose={handleClose}
  
            >
                {props.catTipoCita.map((item,index)=>
                {
                  console.log("checked.includes:"+checked.includes(item));
                  console.log("checked.stringify:"+JSON.stringify(item));
                  const newValue = checked.find((value)=> value.id == item.id );
                  console.log("checked.newValue:"+newValue);

                  const labelId = `checkbox-list-secondary-label-${item}`;
                  return (
                  <ListItem  key={index} role={undefined} dense button onClick={handleChecked(item)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        //checked={checked.indexOf(item) !== -1}
                        checked={newValue!==undefined}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                          {item.nombre}
                          
                  </ListItem>)
                }
                )}
                
              </Menu>   
            </ClickAwayListener>      
          </Paper>
          </Grow>
          )}
        </Popper>
       </div>                
  ) 
  
}
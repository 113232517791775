import React,{Component} from "react";
import Page from "./page";

export default class PacientesList extends Component{

    render(){
		let presionArterialesFilter = [];
		if(this.props.presionArteriales!=undefined){
			presionArterialesFilter = this.props.presionArteriales.filter((data)=>{
				if(data!=undefined){
					if(this.props.busqueda == null){
						return data;
					}else if(data.valorSistolica.toLowerCase().includes(this.props.busqueda.toLowerCase())){
						return data;
					}
				}
			 });   
	
		}
  
        return< Page presionArterialesFilter={presionArterialesFilter} abrirModalEditar={this.props.abrirModalEditar}/>
    }

}


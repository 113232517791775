import React,{Component} from "react";
import PacientesListItem from "../PaginaWebListItem";
import List from '@material-ui/core/List';

export default class Page extends Component{

  constructor(props) {
    super(props);
  }

    render(){      
      return <List > {
            this.props.paginaWebesFilter.map(paginaWeb=> 
                      <PacientesListItem paginaWeb={paginaWeb} abrirModalEditar={this.props.abrirModalEditar}/> )
            }</List>
    }                          
}

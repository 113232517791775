import React,{Component, useState} from "react";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Typography } from "@material-ui/core";

const Page=(props)=>{
 

  const handleDelete=(idTipoProcedimiento)=>{
    console.log("(eliminado)idTipoProcedimiento:"+idTipoProcedimiento);
    props.setProcedimientos((procedimiento)=> props.procedimientos.filter((item)=>item.id!==idTipoProcedimiento));
  }
 
      return (<Box>
        
              {props.procedimientos.map(tipoProcedimiento=>
                      <Chip key={tipoProcedimiento.id} 
                          variant="default" 
                          label={<Typography  style={{ textTransform:"capitalize",whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontWeight:"500"}}>{tipoProcedimiento.nombre}</Typography>}
                          size="medium"
                          onDelete={()=>handleDelete(tipoProcedimiento.id)}
                          style={{margin:"2px",maxWidth: 230}}
                        />
                     
                )}
                
              </Box>)
                             
}

export default Page;
import { Avatar, Box, Card, CardActions, CardContent, CardHeader, Chip, Container, IconButton, List, makeStyles, Paper, Popover, Typography, withStyles } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import moment from "moment/moment";
import React,{Component, useRef} from "react";
import EventoCitaItem from "../EventoCitaItem";
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth:"400",
    paddingBottom:10,
    paddingleft:10,
    paddingRight:10,
    paddingTop:10,
    padding:10,
    borderRadius:"1"
  },
  content:{
    paddingBottom:10,
    paddingleft:10,
    paddingRight:10,
    paddingTop:10,
    padding:1,
  },
  avatar: {
    backgroundColor: blue[500],
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },  
  lista:{
    paddingBottom:0,
    paddingleft:0,
    paddingRight:0,
    paddingTop:0,
    padding:0,
  }
}));

function Page(props){   
  const classes = useStyles();

  let backgroundColor=(props.dia.day()===0||props.dia.day()===0)?"#f4f4f6":"white";
  let diaActual=moment().isSame(props.dia,"day");
  let mesActual=props.hoy.isSame(props.dia,"month");
  let mesAnterior=moment().isBefore(props.dia);

  let backgroundColorAvatar="";
  let colorAvatar="#ffffff";

  if(diaActual){
    backgroundColorAvatar= "#f50057";
  }else if(mesActual){
    backgroundColorAvatar= "#607d8b";
  }else if(mesAnterior){
    backgroundColorAvatar= "#e1f5fe";
    colorAvatar="#6d6d6d";
  }

  
      return <div>
              <Card className={classes.root} style={{backgroundColor:backgroundColor }} variant="outlined">
                <CardHeader
                  className={classes.content}
                  avatar={
                    <Avatar 
                      className={classes.small}
                      alt={props.dia.format("MMM Do YY")} 
                      style={{color:colorAvatar,backgroundColor:backgroundColorAvatar,height: "2.1rem", width: "2.1rem"}}>
                      <Typography variant="subtitle2" >{props.dia.format("DD")}</Typography>
                    </Avatar>  
                  }       
                  title={props.dia.format("ddd")}     

                
                />   
                <CardContent className={classes.content}>
                  <List className={classes.lista}>
                  {
                   props.eventos.map((i,index)=>(                      
                        <EventoCitaItem cita={i} key={index}/> 
                    ))
                  }
                  </List>
                </CardContent>  
                               
              </Card>
            </div>           
}

export default Page;

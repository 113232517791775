import {CONSULTAR_PRESION_ARTERIALES,AGREGAR_PRESION_ARTERIAL,ACTUALIZAR_PRESION_ARTERIAL, ELIMINAR_PRESION_ARTERIAL} from "../constants/PresionArterial-action-type";


/* PRESION_ARTERIALES (HOSPITAL CLINICA) */
export const obtenerPresionArterialListWSAction = (dataType)=> {
    return {
        type:CONSULTAR_PRESION_ARTERIALES,
        dataType:dataType,
        loading: true
    }
}

export const agregarPresionArterialWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_PRESION_ARTERIAL,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarPresionArterialWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_PRESION_ARTERIAL,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarPresionArterialWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_PRESION_ARTERIAL,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {PAGINA_WEB,MEDICO_USUARIO} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {agregarImagenPaginaWebWSAction} from "../../../redux/actions/PaginaWebActions"


const validationSchema = yup.object({
//		descripcion: yup.string().required("Campo requerido"),
		titulo: yup.string().required("Campo requerido"),
//		logotipo: yup.string().required("Campo requerido"),
		contacto: yup.string().required("Campo requerido"),
		MEDICO_id: yup.string().required("Campo requerido")
})

class PaginaWebFormEdit extends Component{

    constructor(props) {
        super(props);
        this.state={
                modalConfirmacion:false,
        };
    }
    abrirConfirmacion=()=> {
        this.setState({
            modalConfirmacion:true
        });
    }
    cerrarConfirmacion=()=> {
        this.setState({
            modalConfirmacion:false
        });
    }

    eliminar=(dato)=> {
        this.cerrarConfirmacion();
        this.props.cerrarModalEditar();
        this.props.eliminarPaginaWebWSAction(PAGINA_WEB,dato);
    }

    
    render(){    
    
        let valores=this.props.payload;
        let id=this.props.payload.id;
        const { bindSubmitForm } = this.props;

        let idMedico="";
        if(this.props.MedicoUsuario!==undefined){
            idMedico=this.props.MedicoUsuario.MEDICO_id;
        }
        console.log("(idMedico)paginaWeb "+idMedico);    
        valores.MEDICO_id=idMedico;
        console.log("(valores)paginaWeb "+JSON.stringify(valores));    
        console.log("(valores)paginaWeb "+JSON.stringify(this.props.payload));    

        return <Formik
                    enableReinitialize
                    initialValues={valores}
                    validationSchema={validationSchema}
                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          resetForm({values:""});
                          setSubmitting(this.props.actualizarPaginaWebWSAction(PAGINA_WEB,values));
                         this.props.history.goBack();
                      }}
                >
                {props => < Page {...props}  medico={this.props.medico}      
                                                tipoSeleccion={this.props.tipoSeleccion} 
                                                modalEditar={this.props.modalEditar}                                                 
                                                cerrarModalEditar={this.props.cerrarModalEditar} 
                                                eliminar={this.eliminar} 
                                                payload={this.props.payload}
                                                actualizarPaginaWebWSAction={this.props.actualizarPaginaWebWSAction}  
                                                modalConfirmacion={this.state.modalConfirmacion}
                                                cerrarConfirmacion={this.cerrarConfirmacion}
                                                abrirConfirmacion={this.abrirConfirmacion} 
                                                agregarImagenPaginaWebWSAction={this.props.agregarImagenPaginaWebWSAction}
                                                paginaWeb={this.props.paginaWeb}
                                                />}
                </Formik> 
    }



}

const mapStateToProps=(state)=>({
    MedicoUsuario:state.MedicoUsuarioReducer[MEDICO_USUARIO][0], 
    paginaWeb:state.PaginaWebReducer[PAGINA_WEB][0],    
})

const mapDispatchToProps=(dispatch, ownProps)=>({
    agregarImagenPaginaWebWSAction: (type,payload) => dispatch(agregarImagenPaginaWebWSAction(type,payload))

})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(PaginaWebFormEdit));
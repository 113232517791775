import React,{Component, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withRouter} from "react-router-dom";
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';

import CitasDisponibles from "../CitasDisponibles"
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Grid from "@material-ui/core/Grid"

import MedicoFormSelect from "../MedicoFormSelect";

import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';

import NuevoPacienteForm from "../NuevoPacienteForm"
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { useRef,useEffect } from "react";

/*
 30mar22 Se tuvo que crear un nuevo componente QRScanField para que no falle el focus(); por alguna razon poner la implementacion en componente dialog no reconoce el input.
*/
  function Page(props) {
	const fieldRef = React.useRef();
	
	console.log({fieldRef});

	useEffect(()=>{
		fieldRef.current.focus();
	},[]);

	function handleChange(event){
		props.setIdPacienteValor(event.target.value);
	}

        return (
			<TextField                            
				label="Escaneando.."
				fullWidth
				size="small"
				variant="outlined" 
				onChangeCapture={handleChange}     
				value={props.buscarPacienteValor}
				inputRef={fieldRef}								
			/> 
			
			);
    
}
export default Page;

import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import { CAT_DIA_SEMANA, CAT_TIPO_CITA,COLOR,ESQUEMA_TIPO_CITA, ESQUEMA_TIPO_CITA_HORARIO, HORARIO, PERIODO } from "../../../redux/constants/action-type";
import {obtenerCatTipoCitaListWSAction,agregarCatTipoCitaWSAction,actualizarCatTipoCitaWSAction,eliminarCatTipoCitaWSAction} from "../../../redux/actions/CatTipoCitaActions";
import {obtenerColorListWSAction} from "../../../redux/actions/ColorActions";
import {obtenerHorarioListWSAction} from "../../../redux/actions/HorarioActions";
import {obtenerEsquemaTipocitaHorarioListWSAction} from "../../../redux/actions/EsquemaTipoCitaHorarioActions";
import {obtenerDiaSemanaListWSAction} from "../../../redux/actions/DiaSemanaActions";
import {obtenerPeriodoListWSAction} from "../../../redux/actions/PeriodoActions";
import {obtenerEsquemaTipoCitaListWSAction} from "../../../redux/actions/EsquemaTipoCitaActions";

class TiposDeCitasForm extends Component{
    constructor(props) {
        super(props);
        this.state={
                openMenu:false,
                anchorEl:null,
        };
    }
    
    abrirMenu=()=> {
        this.setState({
            openMenu:true
        });
    }
    cerrarMenu=()=> {
        this.setState({
            openMenu:false
        });
    }

    setAnchorEl=(value)=> {
        this.setState({
            anchorEl:value
        });
    }
    setHorario(valor){
        this.setState(state => ({
            horarios: state.horarios.concat(valor)
          }));
    }

    render(){   
        let esquemaTipoCitaFilter="";
        
        console.log("esquemaTipoCitaFilter::"+JSON.stringify(this.props.esquemaTipoCita));
        console.log("esquemaTipoCitaFilter::"+this.props.ESQUEMA_TRABAJO_id);

        esquemaTipoCitaFilter=this.props.esquemaTipoCita.filter(item=>item.ESQUEMA_TRABAJO_id===this.props.ESQUEMA_TRABAJO_id);
        
        console.log("esquemaTipoCitaFilter1::"+JSON.stringify(esquemaTipoCitaFilter));

        
        return <Page openMenu={this.state.openMenu}  
                    cerrarMenu={this.cerrarMenu}  
                    abrirMenu={this.abrirMenu}
                    anchorEl={this.state.anchorEl}  
                    setAnchorEl={this.setAnchorEl} 
                    cat_tipo_cita={this.props.cat_tipo_cita}
                    esquemaTipoCita={esquemaTipoCitaFilter}
                    ESQUEMA_TRABAJO_id={this.props.ESQUEMA_TRABAJO_id}
                    />
    }

    componentDidMount(){
        console.log("componentDidMount INI(TiposDeCitasForm)");
        this.props.obtenerPeriodoListWSAction(PERIODO,false);
        this.props.obtenerDiaSemanaListWSAction(CAT_DIA_SEMANA,false);
        this.props.obtenerHorarioListWSAction(HORARIO,false);
        this.props.obtenerEsquemaTipocitaHorarioListWSAction(ESQUEMA_TIPO_CITA_HORARIO,false);
      //  this.props.obtenerEsquemaTipoCitaByEsquemaListWSAction(ESQUEMA_TIPO_CITA,this.props.ESQUEMA_TRABAJO_id);
        this.props.obtenerColorListWSAction(COLOR,false); /* (11Ago21 )Se consulta color para agregar a los tipos de cita color */	

        console.log("componentDidMount  FIN(TiposDeCitasForm)");
       // (18Jul21) Se actualiza llamada de action para wsea enviado como parametro el id de esquema correspondiente al esuqema de trabajo seleccionado
       // this.props.obtenerEsquemaTipoCitaListWSAction(ESQUEMA_TIPO_CITA,false);
      // if(this.props.ESQUEMA_TRABAJO_id!==undefined){


        
     //  }

    }

}

const mapStateToProps=(state)=>({
    cat_tipo_cita:state.CatTipoCitaReducer[CAT_TIPO_CITA],
    esquemaTipoCita:state.EsquemaTipoCitaReducer[ESQUEMA_TIPO_CITA],

})

const mapDispatchToProps=(dispatch)=>({
    // (18Jul21) Se actualiza llamada de action para wsea enviado como parametro el id de esquema correspondiente al esuqema de trabajo seleccionado
  //  obtenerEsquemaTipoCitaListWSAction: (type,loading) => dispatch(obtenerEsquemaTipoCitaListWSAction(type,loading)),
  obtenerEsquemaTipoCitaListWSAction: (type,loading) => dispatch(obtenerEsquemaTipoCitaListWSAction(type,loading)),
  obtenerColorListWSAction: (type,loading) => dispatch(obtenerColorListWSAction(type,loading)),
  obtenerHorarioListWSAction: (type,loading) => dispatch(obtenerHorarioListWSAction(type,loading)),
  obtenerEsquemaTipocitaHorarioListWSAction: (type,loading) => dispatch(obtenerEsquemaTipocitaHorarioListWSAction(type,loading)),
  obtenerDiaSemanaListWSAction: (type,loading) => dispatch(obtenerDiaSemanaListWSAction(type,loading)),
  obtenerPeriodoListWSAction: (type,loading) => dispatch(obtenerPeriodoListWSAction(type,loading)),

})

export default connect(mapStateToProps,mapDispatchToProps)(TiposDeCitasForm);
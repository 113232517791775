import React,{Component} from "react";
import Page from "./page";

export default class PacientesButtonBar extends Component{
    render(){    
        return <Page abrirModal={this.props.abrirModal} />
    }

}



export const getMesNombre=(dateResult)=>{
    const meses = ["Ene.", "Feb.", "Mar.", "Abr.", "May.", "Jun.",
    "Jul.", "Ago.", "Sep.", "Oct.", "Nov.", "Dic."];
    return meses[dateResult.getMonth()];

 }

 export const getMesNombreValue=(dateResult)=>{
    const meses = ["Ene.", "Feb.", "Mar.", "Abr.", "May.", "Jun.",
    "Jul.", "Ago.", "Sep.", "Oct.", "Nov.", "Dic."];
    return meses[dateResult];

 }
 
 
 export const getDiaSemanaNombre=(dateResult)=>{
    const dias = ["dom.","Lun.", "Mar.", "Mie.", "Jue.", "Vie.", "Sab."];
    return dias[dateResult.getDay()];
 }

 export const getDiaSemanaNombreValue=(dateResult)=>{
    const dias = ["dom.","Lun.", "Mar.", "Mie.", "Jue.", "Vie.", "Sab.","dom."];
    return dias[dateResult];
 }


 export const getFormatoFechaDisponible=(diaSem,dia,mes,anio)=>{
     return diaSem+" "+dia +", "+mes+" "+anio;
 }

 // Recibe fecha y hora y regresa con el formato YYYY-MM-DD HH:mm:ss
 export const getFechaHoraString=(fecha,hora)=>{
     console.log("< no hora:"+hora);
     let horaSplit=hora.split(":");
    let fechaHora=fecha+" "+horaSplit[0]+":"+horaSplit[1];
    console.log("< no fechaHora:"+fechaHora);

    return fechaHora;
}
export const getFechaHoytoLocaleDateString=()=>{
    let hora=new Date();
    return hora.toLocaleDateString('es-MX', {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
    })
}

export const getFechaHoytoLocaleDateSemanaString=()=>{
    let hora=new Date();
    return hora.toLocaleDateString('es-MX', {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    })
}

//recibe fecha 2022-04-23 regresa  23 abr 2022
export const getFechatoLocaleDateString=(fecha)=>{
    const [year, month, day] = fecha.split('-');
    const result = [year,month, day ].join('/');

     let hora=new Date(result);

//let hora=new Date(fecha.replaceAll("-", "/")); //2022-04-23 convierte a 2022/04/23
 //let hora=new Date(); //2022-04-23 convierte a 2022/04/23
/*
    var dia = parseInt(fecha.split("-")[2]); //10
    var mes = parseInt(fecha.split("-")[1]-1); //10
    var anio = parseInt(fecha.split("-")[0]); //10

    hora.setFullYear(anio);
    hora.setMonth(mes);
    hora.setDate(dia);
*/
    return hora.toLocaleDateString('es-MX', {
        weekday: 'long',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    })
}

export const getFechatoLocaleDateString2=(fecha)=>{
   // const date = '2022-09-24';
   console.log("getFechatoLocaleDateString2 (day)"+fecha);

    const [year, month, day] = fecha.split('-');
    const result = [year,month, day ].join('/');
    console.log("getFechatoLocaleDateString2 (result)"+result);

     let hora=new Date(result);
     
     console.log("getFechatoLocaleDateString2 (hora)"+hora);

    //let hora=new Date(fecha.replaceAll("-", "/")); //2022-04-23 convierte a 2022/04/23
     //let hora=new Date(); //2022-04-23 convierte a 2022/04/23
    /*
        var dia = parseInt(fecha.split("-")[2]); //10
        var mes = parseInt(fecha.split("-")[1]-1); //10
        var anio = parseInt(fecha.split("-")[0]); //10
    
        hora.setFullYear(anio);
        hora.setMonth(mes);
        hora.setDate(dia);
    */
        return hora.toLocaleDateString('es-MX', {
            weekday: 'short',
            day: 'numeric',
            month: 'short',
        });
    }

export const getHorarioLocaleString=(horario)=>{
    let hora=new Date();

    var minutos = parseInt(horario.substring(3,5)); //10:30:00
    var horas = parseInt(horario.substring(0,2)); //10

    hora.setMinutes(minutos);
    hora.setHours(horas);

    return hora.toLocaleString('es-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    })
}


//Recibe un Date y regresa el fromato YYYY-MM-DD HH:mm
export const getFormatoDesdeDate=(inicioDate)=>{

    // adjust 0 before single digit date
let date = ("0" + inicioDate.getDate()).slice(-2);

// current month
let month = ("0" + (inicioDate.getMonth() + 1)).slice(-2);

// current year
let year = inicioDate.getFullYear();

// current hours
let hours = ("00" + inicioDate.getHours()).slice(-2);

// current minutes
let minutes = ("00" + inicioDate.getMinutes()).slice(-2);;

// current seconds
let seconds = inicioDate.getSeconds();

 //   let fechaFormato= inicioDate.toISOString().split('T')[0]+" "+inicioDate.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
 let fechaFormato= year + "-" + month + "-" + date + " " + hours + ":" + minutes;
    return fechaFormato;
}

export const getFechaHoyFormato=()=>{
    let date = new Date()

    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()

    let fecha="";
    if(month < 10){
        fecha=`${day}/0${month}/${year}`;
    }else{
        fecha=`${day}/${month}/${year}`;
    }

    return fecha;
}

export const getFormatoFechaCita=(diaSem,dia,mes,anio)=>{
    let formatoFecha="";
    let date = new Date()
    let day = date.getDate()
    let month = getMesNombre(date);
    let year = date.getFullYear()

    console.log("day"+day);
    console.log("dia"+dia);

    console.log("month"+month);
    console.log("mes"+mes);
    
    console.log("year"+year);
    console.log("anio"+anio);

    if(day===dia&&month===mes &&year===anio){
        formatoFecha="Hoy";
    }else{
        formatoFecha=diaSem+" "+dia +", "+mes;
    }
    return formatoFecha;
}


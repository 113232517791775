import React,{Component} from "react";
import Page from "./page";

export default class PacientesList extends Component{

    render(){
        let productosFilter = this.props.productos.filter((data)=>{
			if(data.descripcion!=undefined){
				if(this.props.busqueda == null){
					return data;
				}else if(data.descripcion.toLowerCase().includes(this.props.busqueda.toLowerCase())){
					return data;
				}
			}
         });   
  
        return< Page productosFilter={productosFilter} abrirModalEditar={this.props.abrirModalEditar}/>
    }

}


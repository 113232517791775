import React,{Component} from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemComponent from "./ListItemComponent"
import FavoriteIcon from '@material-ui/icons/Favorite';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Typography from '@material-ui/core/Typography';
import OpacityIcon from '@material-ui/icons/Opacity';
import EventIcon from '@material-ui/icons/Event';
import GroupIcon from '@material-ui/icons/Group';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import Collapse from '@material-ui/core/Collapse';
import {Link} from "react-router-dom";
import {CALENDARIO,CITA,ESQUEMA_TRABAJO,CAT_TIPO_CITA,USUARIO} from "../../../redux/constants/action-type";

import RecentActorsIcon from '@material-ui/icons/RecentActors';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SettingsIcon from '@material-ui/icons/Settings';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Avatar, Box, Chip } from "@material-ui/core";
import logoInstitucion from '../../../logo/gastroUsePlus.jpg'
import TodayIcon from '@material-ui/icons/Today';
import medicosPNG from "../../../icons/medicos.png";
import planEstrategicoPNG from "../../../icons/plan-estrategico.png";
import pacientesPNG from "../../../icons/pacientes.png";
import libroMedPNG from "../../../icons/libro-medico.png";
import citaPNG from "../../../icons/cita.png";
import equipoPNG from "../../../icons/equipo.png";


const Page = (props) => {

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
      };

    const drawerWidth = 240;

    const useStyles = makeStyles((theme) => ({
        root: {
          display: 'flex',
          backgroundColor:"red"
        },
        drawer: {
          [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,

          },
        },
        appBar: {
          [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
          },
          boxShadow: "none",
          backgroundColor:"#FFFFFF",
          borderWidth:"1",
          marginTop: "0rem"
        },
        menuButton: {
          marginRight: theme.spacing(2),
          [theme.breakpoints.up('sm')]: {
            display: 'none',
          },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
          width: drawerWidth,
        },
        content: {
          flexGrow: 1,
          padding: theme.spacing(3),
          backgroundColor:"red"

        },
        nested: {
          paddingLeft: theme.spacing(4),
        },
        sectionDesktop: {
          display: 'none',
          [theme.breakpoints.up('md')]: {
            display: 'flex',
          },
        },
    }));

    const classes = useStyles();  

        return <div>                                                  
                    <List>

                        <ListItemComponent icon={<FavoriteIcon style={{ width: "2.2rem",height: "2.2rem", color:"#2196f3"}}/>}
                                        opcion={props.opcion} menu={"comida"} ruta={"/comida"} nombreMenu={"Plan de Alimentación"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>


                        <ListItemComponent icon={<FavoriteIcon style={{ width: "2.2rem",height: "2.2rem", color:"#2196f3"}}/>}
                                        opcion={props.opcion} menu={"producto"} ruta={"/producto"} nombreMenu={"Productos"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>

                        <ListItemComponent icon={<FavoriteIcon style={{ width: "2.2rem",height: "2.2rem", color:"#2196f3"}}/>}
                                        opcion={props.opcion} menu={"procedimiento"} ruta={"/procedimiento"} nombreMenu={"Procedimientos"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>

                        <ListItemComponent icon={<FavoriteIcon style={{ width: "2.2rem",height: "2.2rem", color:"#2196f3"}}/>}
                                        opcion={props.opcion} menu={"movimiento"} ruta={"/movimiento"} nombreMenu={"Movimientos"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>

                        <ListItemComponent icon={<FavoriteIcon style={{ width: "2.2rem",height: "2.2rem", color:"#2196f3"}}/>}
                                        opcion={props.opcion} menu={"tecnico"} ruta={"/tecnico"} nombreMenu={"Técnicos"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>


                        <ListItemComponent icon={<FavoriteIcon style={{ width: "2.2rem",height: "2.2rem", color:"#2196f3"}}/>}
                                            opcion={props.opcion} menu={"presionArterial"} ruta={"/presionArterial"} nombreMenu={"Presión Arterial"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>

                        <ListItemComponent icon={<OpacityIcon style={{ width: "2.2rem",height: "2.2rem", color:"#f44336"}}/>}
                                            opcion={props.opcion} menu={"glucosa"} ruta={"/glucosa"} nombreMenu={"Glucosa"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>


                        <ListItemComponent icon={<img src={citaPNG} style={{ width: "2.2rem",height: "2.2rem", color:"#1e88e5"}}/>}
                                            opcion={props.opcion} menu={CITA} ruta={"/citas"} nombreMenu={"Agendados"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>


                        <ListItemComponent icon={<img src={pacientesPNG} style={{ width: "2.2rem",height: "2.2rem", color:"#607d8b"}}/>}
                                            opcion={props.opcion} menu={"paciente"} ruta={"/pacientes"} nombreMenu={"Pacientes"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>


                        <ListItemComponent icon={<img src={medicosPNG}  style={{ width: "2.2rem",height: "2.2rem", color:"#00bfa5"}}/>}
                                            opcion={props.opcion} menu={"medico"} ruta={"/medicos"} nombreMenu={"Medicos"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>
                            
                        <ListItemComponent icon={<LocalHospitalIcon style={{ width: "2.2rem",height: "2.2rem", color:"#00838F"}}/>}
                                            opcion={props.opcion} menu={"instituciones"} ruta={"/instituciones"} nombreMenu={"Hospitales"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>


                        <ListItemComponent icon={<img src={libroMedPNG} style={{ width: "2.2rem",height: "2.2rem", color:"#f50057"}}/>}
                                            opcion={props.opcion} menu={CAT_TIPO_CITA} ruta={"/tipoCita"} nombreMenu={"Tipos Citas"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>


                        <ListItemComponent icon={<img src={planEstrategicoPNG} style={{ width: "2.2rem",height: "2.2rem", color:"#1769aa"}}/>}
                                            opcion={props.opcion} menu={ESQUEMA_TRABAJO} ruta={"/esquemas"} nombreMenu={"Plan de Trabajo"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>

                        <ListItemComponent icon={<img src={equipoPNG} style={{ width: "2.2rem",height: "2.2rem", color:"#8eacbb"}}/>}
                                            opcion={props.opcion} menu={USUARIO} ruta={"/usuarios"} nombreMenu={"Usuarios"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>

                        <ListItem button onClick={handleClick}>
                            <ListItemIcon><SettingsIcon style={{ width: "2.2rem",height: "2.2rem", color:"#757575"}}/></ListItemIcon>
                            <ListItemText  primary={<Typography type="Roboto" variant="subtitle1" style={{ color: "#000000",fontSize:"1.2rem",fontWeight:"normal" }}>Configuración</Typography>} />
                            {open ? <ArrowDropDownIcon style={{ width: "2.2rem",height: "2.2rem",}}/> : <ArrowRightIcon style={{ width: "2.2rem",height: "2.2rem",}}/>}
                        </ListItem>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemComponent className={classes.nested}
                                                icon={<ArrowRightIcon style={{ width: "2.2rem",height: "2.2rem", }}/>}
                                                opcion={props.opcion} menu={"paginaWeb"} ruta={"/paginaWeb"} nombreMenu={"Página web"} handleDrawerToggleClose={props.handleDrawerToggleClose}/>
                            </List>
                            </Collapse>                                  
                        <ListItem button  href="/login" onClick={props.logout} >
                            <ListItemIcon><ExitToAppIcon style={{ width: "2.2rem",height: "2.2rem", color:"#babdbe"}}/></ListItemIcon>
                            <ListItemText  primary={<Typography type="Roboto" variant="subtitle1" style={{ color: "#000000",fontSize:"1.2rem",fontWeight:"normal" }}>Cerrar sesión</Typography>} />
                        </ListItem>

                    </List>

                    <Box display="flex" justifyContent="flex-start" m={1} p={2} style={{ backgroundColor:"transparent",alignItems:"center"}}>                
                      <Chip   
                          avatar={<Avatar alt="Bildsoft"  src={logoInstitucion}  style={{width: "7rem",height: "7rem"}}/>}
                          label=""
                          variant="default"
                          style={{backgroundColor:"transparent",marginTop:"0.6rem",marginBottom:"0.6rem"}}
                          align="center"
                      />                                                                      
                                                      
                    </Box>
               </div>
         
};
export default Page;

import {CONSULTAR_CAT_TIPO_COMIDAES,AGREGAR_CAT_TIPO_COMIDA,ACTUALIZAR_CAT_TIPO_COMIDA, ELIMINAR_CAT_TIPO_COMIDA} from "../constants/CatTipoComida-action-type";


/* CAT_TIPO_COMIDAES (HOSPITAL CLINICA) */
export const obtenerCatTipoComidaListWSAction = (dataType)=> {
    return {
        type:CONSULTAR_CAT_TIPO_COMIDAES,
        dataType:dataType,
        loading: true
    }
}

export const agregarCatTipoComidaWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_CAT_TIPO_COMIDA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarCatTipoComidaWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_CAT_TIPO_COMIDA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarCatTipoComidaWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_CAT_TIPO_COMIDA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

import image from "../images/image.jpg";
import image2 from "../images/originalVideo.png";
import image3 from "../images/originalReporte.jpg";
 
 export const tileData = [
    {
      img: image,
      title: 'Image',
      author: 'author',
    },
    {
      img: image2,
      title: 'Image',
      author: 'author',
    },
    {
      img: image,
      title: 'Image',
      author: 'author',
    },
    {
      img: image,
      title: 'Image',
      author: 'author',
    },
    {
      img: image3,
      title: 'Image',
      author: 'author',
    },
    {
      img: image,
      title: 'Image',
      author: 'author',
    },
    {
      img: image,
      title: 'Image',
      author: 'author',
    },
    {
      img: image,
      title: 'Image',
      author: 'author',
    },
    {
      img: image,
      title: 'Image',
      author: 'author',
    },
    {
      img: image,
      title: 'Image',
      author: 'author',
    }
  ];
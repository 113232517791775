import {CONSULTAR_MOVIMIENTOS,AGREGAR_MOVIMIENTO,ACTUALIZAR_MOVIMIENTO, ELIMINAR_MOVIMIENTO} from "../constants/Movimiento-action-type";


/* MOVIMIENTOS (HOSPITAL CLINICA) */
export const obtenerMovimientoListWSAction = (dataType)=> {
    return {
        type:CONSULTAR_MOVIMIENTOS,
        dataType:dataType,
        loading: true
    }
}

export const agregarMovimientoWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_MOVIMIENTO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarMovimientoWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_MOVIMIENTO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarMovimientoWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_MOVIMIENTO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

import React,{Component} from "react";
import Page from "./page";
import { connect } from "react-redux";
import {getMesNombre,getDiaSemanaNombre,getFormatoFechaDisponible} from "../../../utils/date";

class DiaSemanaListItem extends Component{

    
	obtenerDate=(date,dias)=>{
		var result=new Date(date);
		if(dias===0){
		  return result;
		}else{
		  result.setDate(result.getDate()+dias);
		}
		return result;
	}
	   
	getDiasDiferencia=()=>{

			/* identificar el dia actual concurente */
		//let dia = newDate.getDay();
		let dia =this.props.diaBusquedaSelecionado.getDay();
		/* buscar los dias de de diferencia al actual*/
		let diasDiferencia=0;// LOS DIAS DE LA SEMANA SE CUENTAS DEL 0 a 7 Lun a Dom
		console.log(">>>>>this.props.idDia:"+this.props.idDia);
        let diaHorario=this.props.idDia;
      //  let diaHorario=6;

		for(let i = dia; i<diaHorario||i>diaHorario; i++){
			if(i==7){
				i=0;
			}
			diasDiferencia++;
		}
	
		return diasDiferencia;

	 }

    /*11Ene21 obtener la fecha del dia disponible para la cita segun el tipo de cita selecionado */
    getFechaDisponible=()=>{
        let diasDiferencia=this.getDiasDiferencia();
        console.log("this.props.diaBusquedaSelecionado :"+this.props.diaBusquedaSelecionado);
        /*11Ene21 obtener la fecha del dia disponible para la cita segun el tipo de cita selecionado */
        return this.obtenerDate(this.props.diaBusquedaSelecionado,diasDiferencia);

        }

    
    render(){
        let dateResult=this.getFechaDisponible();
        console.log("dateResult::::< >"+JSON.stringify(dateResult));
        let mes=getMesNombre(dateResult);
        let diaSem=getDiaSemanaNombre(dateResult);
        let formatoFechaDisponible=getFormatoFechaDisponible(diaSem,dateResult.getDate(),mes,dateResult.getFullYear());

        console.log(">>> periodoList"+ JSON.stringify(this.props.periodoList));
        return <Page 
                     action={this.props.action}  
                     formatoFechaDisponible={formatoFechaDisponible}                    
                                          
                     setFecha={this.props.setFecha} 
                     setHoraInicio={this.props.setHoraInicio} 
                     setHoraFin={this.props.setHoraFin} 
                     
                     codColor={this.props.codColor} 
                     horaInicio={this.props.horaInicio} 
                     horaFin={this.props.horaFin}
                     
                     dia={dateResult.getDate()} 
                     idMes={dateResult.getMonth()}
                     anio={dateResult.getFullYear()}  
                     
                     horaInicial={this.props.horaInicial} 
                     horaFinal={this.props.horaFinal} 
                     fecha={this.props.fecha}
                     idDia={this.props.idDia}
                     diaBusquedaSelecionado={this.props.diaBusquedaSelecionado}
                     duracionTipoCita={this.props.duracionTipoCita}
                     setFieldValue={this.props.setFieldValue} 
                     periodoList={this.props.periodoList}

                     />
    }
}

const mapStateToProps =(state,ownProps)=>({
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	
})

export default connect(mapStateToProps,mapDispatchToProps)(DiaSemanaListItem);


import React,{Component} from "react";
import EsquemaTrabajoList from "../EsquemaTrabajoList";
import EsquemaTrabajoBusqueda from "../EsquemaTrabajoBusqueda";
import EsquemaTrabajoForm from "../EsquemaTrabajoForm";
import CircularProgress from '@material-ui/core/CircularProgress';
import EsquemaTrabajoFloatButton from "../EsquemaTrabajoFloatButton";
import EsquemaTrabajoFormEdit from "../EsquemaTrabajoFormEdit";
import Fab from '@material-ui/core/Fab';


export default class Page extends Component{

    render(){
        let loading=this.props.loading;
        var resultDatos;
        var animacionCargando;
        if (loading) {
            animacionCargando = <Fab position="fixed" variant="round" size="small" aria-label="cargando" style={{position: 'fixed', top: "5.5rem", float: "left",
                                    left: "45%", boxShadow:"1", backgroundColor:"#FFFFFF",padding:"0.1rem"}}>
                                    <CircularProgress color="secondary" size="1.5rem"/>           
                                </Fab>
        }else{
            animacionCargando=<div/>
        }
        
        if(this.props.esquemaTrabajos.length>0){
            resultDatos =  <div className="col-12" >                       
                              
                                <EsquemaTrabajoList  esquemaTrabajos={this.props.esquemaTrabajos} abrirModalEditar={this.props.abrirModalEditar}
                                                    busqueda={this.props.busqueda}/> 
                            </div>
                                
        }

        let botonCrearEsquema="";
 //       if(this.props.esquemaTrabajos!=undefined){
 //           if(this.props.esquemaTrabajos.length==0){                
            botonCrearEsquema=<EsquemaTrabajoFloatButton abrirModal={this.props.abrirModal} />
//            }    
 //       }


        return <div style={{marginBottom:"5rem" , marginTop:"5rem" }}>
                                 
                    <EsquemaTrabajoForm  modal={this.props.modal} 
                                      tipoSeleccion={this.props.tipoSeleccion}
                                        cerrarModal={this.props.cerrarModal} 
                                            agregarEsquemaTrabajoWSAction={this.props.agregarEsquemaTrabajoWSAction}/>                
                               
                    <EsquemaTrabajoFormEdit  modalEditar={this.props.modalEditar} 
                                          payload={this.props.payload} 
                                          tipoSeleccion={this.props.tipoSeleccion}
                                            cerrarModalEditar={this.props.cerrarModalEditar} 
                                                actualizarEsquemaTrabajoWSAction={this.props.actualizarEsquemaTrabajoWSAction}
                                                eliminarEsquemaTrabajoWSAction={this.props.eliminarEsquemaTrabajoWSAction}    />                

                    <div className="row">
                        {resultDatos} 
                        {animacionCargando}
                    </div>               
                    <div className="row">
                        {botonCrearEsquema}
                    </div>                
                    

                </div>
         
    }
}

import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {MEDICO} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";


const validationSchema = yup.object({
		nombres: yup.string().trim().required("Campo requerido").matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
                'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
		apellidoPaterno: yup.string().required("Campo requerido").matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
                'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
		apellidoMaterno: yup.string().matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
                'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
		cedulaProfesional: yup.string().max(45, 'debe ser menor a 45 caracteres'),
        correo: yup.string().email('el correo no es valido').max(45, 'debe ser menor a 45 caracteres'),
		telefono: yup.string().max(45, 'debe ser menor a 45 caracteres'),
		celular: yup.string().max(45, 'debe ser menor a 45 caracteres'),
		especialidad: yup.string().max(45, 'debe ser menor a 45 caracteres')
})

class MedicoForm extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }
    
    goBack(){
        this.props.history.goBack();
    }

    render(){    
    
        return <Formik

                    initialValues={{
                        'titulo': '',
                        'nombres': '',
                        'apellidoPaterno': '',
                        'apellidoMaterno': '',
                        'cedulaProfesional': '',
                        'correo': '',
                        'telefono': '',
                        'celular': '',
                        'especialidad': '',

                    }}

                    validationSchema={validationSchema}
                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                        console.log("AGREGAR MEDICO (onSubmit):"+JSON.stringify(values));
                          setSubmitting(this.props.agregarMedicoWSAction(MEDICO,values));
                          resetForm({values:""});
                          this.goBack();

                      }}
                >
                     {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    setFieldValue
                    }) => < Page setFieldValue={setFieldValue} 
                                 handleSubmit={handleSubmit} 
                                 handleChange={handleChange} 
                                 handleBlur={handleBlur} 
                                 values={values} 
                                 errors={errors} 
                                 touched={touched}                                            
                                 modal={this.props.modal}                                                     
                                 cerrarModal={this.props.cerrarModal}                                                    
                    />}
                </Formik> 
    }
}

const mapStateToProps=(state)=>({
})

export default withRouter(connect(mapStateToProps)(MedicoForm));



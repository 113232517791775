import React,{Component} from "react";
import Page from "./page";

export default class PacientesList extends Component{

    render(){
        let movimientosFilter = this.props.movimientos.filter((data)=>{
			if(data.nombre!=undefined){
				if(this.props.busqueda == null){
					return data;
				}else if(data.nombre.toLowerCase().includes(this.props.busqueda.toLowerCase())){
					return data;
				}
			}
         });   
  
        return< Page movimientosFilter={movimientosFilter} abrirModalEditar={this.props.abrirModalEditar}/>
    }

}


import React,{Component} from "react";
import "./estilo.css"
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box, Button, IconButton, Paper, Typography } from "@material-ui/core";
import OtroHorario from "../OtroHorario";
import BuscarHorario from "../BuscarHorario";
import { PROFIL_ADMINISTRADOR } from "../../../utils/constantes";

const Page=(props)=>{

      let OtroHorarioBoton=<div/>; 

      if(props.profil===PROFIL_ADMINISTRADOR){
            OtroHorarioBoton=<OtroHorario
                               fechaBusqueda={props.fechaBusqueda} 
                               setFechaBusqueda={props.setFechaBusqueda}   
                               setFieldValue={props.setFieldValue}                              
                              />
      }

    return (<div>
                <Box flexDirection="row"  style={{marginTop:"0.5em"}}>
                      {OtroHorarioBoton}
                      <BuscarHorario 
                            codColor={props.codColor} 
                            duracionTipoCita={props.duracionTipoCita}
            
                            fecha={props.fecha} 
                            fechaBusqueda={props.fechaBusqueda} 
                            setFechaBusqueda={props.setFechaBusqueda} 
                            setFieldValue={props.setFieldValue} 
                            horaInicio={props.horaInicio} 
                            horaFin={props.horaFin}
                            idEsquemaTipoCita={props.idEsquemaTipoCita}                        
                      />
                </Box>
              </div>)                   
}
export default Page;
import {CONSULTAR_ESTADOS,AGREGAR_ESTADO,ACTUALIZAR_ESTADO, ELIMINAR_ESTADO} from "../constants/Estado-action-type";


/* ESTADOS (HOSPITAL CLINICA) */
export const obtenerEstadoListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_ESTADOS,
        dataType:dataType,
        loading: loading
    }
}

export const agregarEstadoWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_ESTADO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarEstadoWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_ESTADO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarEstadoWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_ESTADO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

import React,{Component} from "react";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default class Page extends Component{

    render(){
        

        return  <FormControl
                    fullWidth
                    margin="normal"	
                    variant="filled" 

                    >
                        <InputLabel htmlFor="age-native-simple">MEDICO</InputLabel>
                        <Select
                            error={this.props.touched.rol&&this.props.touched.rol.MEDICO_id&&this.props.errors.rol&&this.props.errors.rol.MEDICO_id? true:false}
                            size="medium"
                            id={this.props.MEDICO_id}
                            label="MEDICO_id:"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={this.props.handleChange} 
                            value={this.props.medicoValue}
                            onBlur={this.props.handleBlur}
                            native
                        >
                                <option aria-label="None" value="" disabled/>

                                {

                                
                                    this.props.medicos.map(medico=> (
                                        <option key={medico.id}  value={medico.id}>{medico.nombres} {medico.apellidoPaterno} </option>)
                                    )
                                    /*
                                        this.props.medico.map(item=> (
                                        <PersonaFormSelect item={item} />
                                        ))
                                        */
                                }
                            </Select>
                    </FormControl>
                
    }
}

import React,{Component} from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withRouter} from "react-router-dom";
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';
import {ID_ROL_MEDICO,ID_ROL_PACIENTE, PROFIL_ROOT } from "../../../utils/constantes";
import MedicoFormSelect from "../MedicoFormSelect";
import PacienteFormSelect from "../PacienteFormSelect";

import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';
import InstitucionFormSelect from "../InstitucionFormSelect";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  function Page(props) {
		
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	function goBack(){
		props.history.goBack();
	}

	let appBar="";
	let actions="";

	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={goBack} aria-label="close">
						<ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
						</IconButton>
						<Typography variant="h6" style={{ flexGrow: 1}} >
							Nueva Usuario
						</Typography>

						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
						<Button variant="contained"  edge="start" color="default" onClick= {props.cerrarModal}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
	}

	let Institucion="";
	if(props.idInstitucion===""){
		Institucion=<FormControl
		fullWidth
		margin="normal"
		
		>
			<InputLabel htmlFor="age-native-simple">INSTITUCION</InputLabel>
			<Select
				error={props.touched.usuario&&props.touched.usuario.INSTITUCION_id&&props.errors.usuario&&props.errors.usuario.INSTITUCION_id? true:false}
				size="medium"
				id="usuario.INSTITUCION_id"
				label="INSTITUCION_id:"
				type="text"
				fullWidth
				variant="outlined"
				onChange={props.handleChange} value={props.values.usuario.INSTITUCION_id}
				onBlur={props.handleBlur}
				native
			>
					<option aria-label="None" value="" disabled/>
		
				{
				props.instituciones.map(institucion=> (
					<option key={institucion.id}  value={institucion.id}>{institucion.nombre}</option>)
				)}
			</Select>
		</FormControl>

	}


	//	let //
	console.log("ERRORS :"+JSON.stringify(props.errors));
	let institucionesList="";
	if(props.profil==PROFIL_ROOT){
		institucionesList=<InstitucionFormSelect
								INSTITUCION_id="usuario.INSTITUCION_id"
								institucionValue={props.values.usuario.INSTITUCION_id}
								errors={props.errors}
								touched={props.touched} 
								handleChange={props.handleChange}
							/>		
	}

	let medicosList="";
	medicosList=(props.values.rol.CAT_ROL_id===ID_ROL_MEDICO)?
							<MedicoFormSelect MEDICO_id="rol.MEDICO_id"  
								medicoValue={props.values.rol.MEDICO_id}
								errors={props.errors}
								touched={props.touched} 
								handleChange={props.handleChange} 
								/>:null;
	let pacientesList="";
	pacientesList=(props.values.rol.CAT_ROL_id===ID_ROL_PACIENTE)?
							<PacienteFormSelect PACIENTE_id="rol.PACIENTE_id"  
								pacienteValue={props.values.rol.PACIENTE_id}
								INSTITUCION_id={props.values.usuario.INSTITUCION_id}
								errors={props.errors}
								touched={props.touched} 
								handleChange={props.handleChange} 
								/>:null;


														
        return (<form onSubmit={props.handleSubmit}> 
                    <Dialog
                    open={props.modal}
                    onClose={props.cerrarModal}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
					fullScreen={fullScreen}


                    >
                    
                        <DialogTitle id="form-dialog-title">Nuevo Usuario</DialogTitle>
                        
						{appBar}

							<DialogContent>
							<TextField
								error={props.touched.usuario&&props.touched.usuario.usuario&&props.errors.usuario&&props.errors.usuario.usuario? true:false}
								helperText={props.touched.usuario&&props.touched.usuario.usuario&&props.errors.usuario&&props.errors.usuario.usuario?props.errors.usuario.usuario:""}
								size="medium"
								margin="normal"
								id="usuario.usuario"
								label="Usuario:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} 
								value={props.values.usuario.usuario}
								onBlur={props.handleBlur}
							/>
							<TextField
								error={props.touched.usuario&&props.touched.usuario.password&&props.errors.usuario&&props.errors.usuario.password? true:false}
								helperText={props.touched.usuario&&props.touched.usuario.password&&props.errors.usuario&&props.errors.usuario.password?props.errors.usuario.password:""}
								size="medium"
								margin="normal"
								id="usuario.password"
								label="Password:"
								type="password"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.usuario.password}
								onBlur={props.handleBlur}
							/>
							<TextField
								error={props.touched.usuario&&props.touched.usuario.passwordConfirmacion&&props.errors.usuario&&props.errors.usuario.passwordConfirmacion? true:false}
								helperText={props.touched.usuario&&props.touched.usuario.passwordConfirmacion&&props.errors.usuario&&props.errors.usuario.passwordConfirmacion?props.errors.usuario.passwordConfirmacion:""}
								size="medium"
								margin="normal"
								id="usuario.passwordConfirmacion"
								label="Confirmacion:"
								type="password"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.usuario.passwordConfirmacion}
								onBlur={props.handleBlur}
							/>
							<TextField
								error={props.touched.usuario&&props.touched.usuario.correo&&props.errors.usuario&&props.errors.usuario.correo? true:false}
								helperText={props.touched.usuario&&props.touched.usuario.correo&&props.errors.usuario&&props.errors.usuario.correo?props.errors.usuario.correo:""}
								size="medium"
								margin="normal"
								id="usuario.correo"
								label="Correo:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.usuario.correo}
								onBlur={props.handleBlur}
							/>
							{institucionesList}
							<FormControl
							fullWidth
							margin="normal"
							variant="filled" 
							
							>
							    <InputLabel htmlFor="age-native-simple">PERFIL</InputLabel>
								<Select
									error={props.touched.rol&&props.touched.rol.CAT_ROL_id&&props.errors.rol&&props.errors.rol.CAT_ROL_id? true:false}
									size="medium"
									id="rol.CAT_ROL_id"
									label="PERFIL:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={props.handleChange} value={props.values.rol.CAT_ROL_id}
									onBlur={props.handleBlur}
									native
								>
								          <option aria-label="None" value="" disabled/>
							
									{
									props.roles.map(rol=> (
										<option key={rol.id}  value={rol.id}>{rol.nombre}</option>)
									)}
								</Select>
							</FormControl>
							{
								medicosList
							}			
							{
								pacientesList
							}			

                            </DialogContent>
							{actions}                
 
                    </Dialog>
                    </form>
                       
                )
    
}
export default  withRouter(Page);

import React,{Component } from "react";
import Page from "./page";
import {connect} from "react-redux";
import {MOVIMIENTO,INSTITUCION,TECNICO,CAT_TIPO_MOVIMIENTO,PRODUCTO} from "../../../redux/constants/action-type";
import {obtenerProductoListWSAction,agregarProductoWSAction,actualizarProductoWSAction,eliminarProductoWSAction} from "../../../redux/actions/ProductoActions";
import {obtenerCatTipoMovimientoListWSAction,agregarCatTipoMovimientoWSAction,actualizarCatTipoMovimientoWSAction,eliminarCatTipoMovimientoWSAction} from "../../../redux/actions/CatTipoMovimientoActions";
import {obtenerTecnicoListWSAction,agregarTecnicoWSAction,actualizarTecnicoWSAction,eliminarTecnicoWSAction} from "../../../redux/actions/TecnicoActions";
import {obtenerInstitucionListWSAction,agregarInstitucionWSAction,actualizarInstitucionWSAction,eliminarInstitucionWSAction} from "../../../redux/actions/InstitucionActions";
import {obtenerMovimientoListWSAction,agregarMovimientoWSAction,actualizarMovimientoWSAction,eliminarMovimientoWSAction} from "../../../redux/actions/MovimientoActions";


class Movimientos extends Component{

    constructor(props) {
        super(props);
        this.state={
                modal:false,
                modalEditar:false,
                payload: {
					'nombre': '',
					'descripcion': '',
					'cantidad': '',
					'PRODUCTO_id': '',
					'CAT_TIPO_MOVIMIENTO_id': '',
					'TECNICO_id': '',
					'INSTITUCION_id': ''
                },
                busqueda:""

        };
    }
     
    abrirModal=()=> {
        this.setState({
            modal:true
        });
    }

    cerrarModal=()=> {
        this.setState({
            modal:false
        });
    }

    abrirModalEditar = (value)=> {
        this.setState({
            modalEditar:true,
            payload: value

        });
    };

    cerrarModalEditar=()=> {
        this.setState({
            modalEditar:false
        });
    }

    buscar=(valor)=> {
        this.setState({
            busqueda:valor
        });
    }


    render(){        
        return  <Page movimientos={this.props.movimientos} loading={this.props.loading} 
                    modal={this.state.modal}
                    modalEditar={this.state.modalEditar}
                    payload={this.state.payload}
                       abrirModal={this.abrirModal}
                           cerrarModal={this.cerrarModal}
                                abrirModalEditar={this.abrirModalEditar}
                                    cerrarModalEditar={this.cerrarModalEditar}
                                    tipoSeleccion={this.props.tipoSeleccion}
                                agregarMovimientoWSAction={this.props.agregarMovimientoWSAction}
                                actualizarMovimientoWSAction={this.props.actualizarMovimientoWSAction}
                                eliminarMovimientoWSAction={this.props.eliminarMovimientoWSAction}   
                                buscar={this.buscar} busqueda={this.state.busqueda}/>    
    }

 /* esta es la accion de middleware*/
        componentDidMount(){            
            let valores=this.props.location.valores;            
            if(valores === undefined){
				this.props.obtenerProductoListWSAction(PRODUCTO,false);
				this.props.obtenerCatTipoMovimientoListWSAction(CAT_TIPO_MOVIMIENTO,false);
				this.props.obtenerTecnicoListWSAction(TECNICO,false);
				this.props.obtenerInstitucionListWSAction(INSTITUCION,false);
                this.props.obtenerMovimientoListWSAction(MOVIMIENTO,true); /* Invoca por primara vez a productos (default) */
            }else{
                this.setState(this.props.location.valores);
            }

        }


}

/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
    movimientos:state.MovimientoReducer[MOVIMIENTO],    
    loading:state.estadosPeticionReducer.loading,
    tipoSeleccion:state.estadosReducer.selectedType,

})

/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        obtenerMovimientoListWSAction: (type,loading) => dispatch(obtenerMovimientoListWSAction(type,loading)),
        obtenerProductoListWSAction: (type,loading) => dispatch(obtenerProductoListWSAction(type,loading)),
        obtenerCatTipoMovimientoListWSAction: (type,loading) => dispatch(obtenerCatTipoMovimientoListWSAction(type,loading)),
        obtenerTecnicoListWSAction: (type,loading) => dispatch(obtenerTecnicoListWSAction(type,loading)),
        obtenerInstitucionListWSAction: (type,loading) => dispatch(obtenerInstitucionListWSAction(type,loading)),
        agregarMovimientoWSAction: (type,payload) => dispatch(agregarMovimientoWSAction(type,payload)),
        actualizarMovimientoWSAction: (type,payload) => dispatch(actualizarMovimientoWSAction(type,payload)),
        eliminarMovimientoWSAction: (type,payload) => dispatch(eliminarMovimientoWSAction(type,payload))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Movimientos);



import {CONSULTAR_CAT_TIPO_PROCEDIMIENTOS,AGREGAR_CAT_TIPO_PROCEDIMIENTO,ACTUALIZAR_CAT_TIPO_PROCEDIMIENTO, ELIMINAR_CAT_TIPO_PROCEDIMIENTO} from "../constants/CatTipoProcedimiento-action-type";


/* CAT_TIPO_PROCEDIMIENTOS (HOSPITAL CLINICA) */
export const obtenerCatTipoProcedimientoListWSAction = (dataType)=> {
    return {
        type:CONSULTAR_CAT_TIPO_PROCEDIMIENTOS,
        dataType:dataType,
        loading: true
    }
}

export const agregarCatTipoProcedimientoWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_CAT_TIPO_PROCEDIMIENTO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarCatTipoProcedimientoWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_CAT_TIPO_PROCEDIMIENTO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarCatTipoProcedimientoWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_CAT_TIPO_PROCEDIMIENTO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";


class DiaSemanaCardForm extends Component{
   
    render(){ 

        console.log("esquemaTipoCitaHorario<:>"+JSON.stringify(this.props.esquemaTipoCitaHorario));   
        return <Page  
                        esquemaTipoCitaHorarioList={this.props.esquemaTipoCitaHorarioList}
                        setEsquemaTipoCitaHorario={this.props.setEsquemaTipoCitaHorario}
                        esquemaTipoCitaItem={this.props.esquemaTipoCitaItem}
                        dia_semanaItem={this.props.dia_semanaItem} 
                        setOpenPeriodoDialog={this.props.setOpenPeriodoDialog}
                        setOpenPeriodoEditDialog={this.props.setOpenPeriodoEditDialog}
                        setPeriodo={this.props.setPeriodo}
                        setHorario={this.props.setHorario}
                        handleEliminarPeriodoClick={this.props.handleEliminarPeriodoClick}
                        />
    }
   

}

const mapStateToProps=(state,ownProps)=>({

})

const mapDispatchToProps=(dispatch)=>({

})

export default connect(mapStateToProps,mapDispatchToProps)(DiaSemanaCardForm);



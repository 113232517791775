import React,{Component, useState} from "react";
import Page from "./page";
import {connect} from "react-redux";


function CalendarioGrid(props) {

    const totalDias=42;
    const diasArray=[...Array(totalDias)].map(()=>props.diaInicial.add(1,"day").clone());
    const diasSemanaArray=[...Array(7)];

    return <Page 
            diasArray={diasArray}
            diasSemanaArray={diasSemanaArray}
            hoy={props.hoy}

            />                        
       
    
}




/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
})
/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(mapStateToProps,mapDispatchToProps)(CalendarioGrid);

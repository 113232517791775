import React,{Component} from "react";
import {connect} from "react-redux";
import {GLUCOSA} from "../../../redux/constants/action-type";
import {obtenerGlucosaListWSAction,agregarGlucosaWSAction,actualizarGlucosaWSAction,eliminarGlucosaWSAction} from "../../../redux/actions/GlucosaActions";

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ReactPDF from '@react-pdf/renderer';
import GlucosaDocument from "../GlucosaDocument"
import ReactDOM from 'react-dom';
import axios from 'axios';
import { PDFDownloadLink} from '@react-pdf/renderer'

 const Page=(props)=>{

  
     let stuff;
      if(props.glucosaes.length>0){
        stuff =<Fab
                position="fixed"
                variant="extended"
                size="large"
                color="default"
                aria-label="agregar"
                style={{position: 'fixed', bottom: "5.5rem", right: "1rem",boxShadow:"1", backgroundColor:"#FFFFFF"}}
              
              >
                  <PDFDownloadLink document={<GlucosaDocument glucosaes={props.glucosaes}/>} fileName="glucosa.pdf">
                    <PictureAsPdfIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
                    {({ blob, url, loading, error }) => (loading ? 'Loading document...' : '')}
                  </PDFDownloadLink> 
              </Fab>
       }

  
      return ( <div>{stuff}</div> )
                   
  }             

export default Page;
import React,{Component, useState} from "react";
import Page from "./page";
import {connect} from "react-redux";
import { CAT_TIPO_CITA, CITA, COLOR } from "../../../redux/constants/action-type";

function EventoCitaItem(props) {
   

    let colorItem=[];
    if(props.catTipoCita!==undefined){
        colorItem=props.colores.filter(i => i.id === props.catTipoCita.COLOR_id)[0];
    }

    let codColor="";
    if(colorItem!==undefined){
        codColor=colorItem.color;
    }
   
    return <Page 
             cita={props.cita}
             catTipoCita={props.catTipoCita}  
             codColor={codColor}                         

            />                        
          
}




/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state,ownProps)=>({ 
    catTipoCita:state.CatTipoCitaReducer[CAT_TIPO_CITA].filter(p => p.id === ownProps.cita.CAT_TIPO_CITA_id)[0],
    colores:state.ColorReducer[COLOR],

})
/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(mapStateToProps,mapDispatchToProps)(EventoCitaItem);

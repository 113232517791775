import {connect} from "react-redux";
import React,{Component} from "react";
import { CITA, INSTITUCION, MEDICO, MEDICO_USUARIO, PACIENTE } from "../../../redux/constants/action-type";
import Page from "./page";
import { PROFIL_ADMINISTRADOR, PROFIL_MEDICO } from "../../../utils/constantes";
import { actualizarRegistrosAction } from "../../../redux/actions/stateActions";

class CitaList extends Component{
	
    render(){

		/*
		(09Ago21) Si el perfil es de medico se debe aplicar un filtro a las citas que corresponmda al MEDICO_id
		this.props.citaes
		
		*/
/*(22oct22) El filtro se pasa al webservices
		let citasFilter=this.props.citaes.filter((cita)=>{
			if(this.props.profil===PROFIL_ADMINISTRADOR){
				console.log("institucion de list citas:"+this.props.institucion.id);
				return cita.INSTITUCION_id===this.props.institucion.id;
			}else if(this.props.profil===PROFIL_MEDICO){
				console.log("medico de list citas:"+this.props.medicoUsuario.MEDICO_id);
				return cita.MEDICO_id===this.props.medicoUsuario.MEDICO_id;
			}else{
				return false;
			}
		});
*/
	//(23mar22) Esta linea esta causando conflicto por que cambia el id de la cita al combinar con el paciente	
	//	let citasPacienteCombinacion=citasFilter.map(cita=>Object.assign({},cita,this.props.pacientes.find(item=>cita.PACIENTE_id===item.id)));

		let citaesPacienteCombinacionFilter = this.props.citaes.filter((data)=>{

					if(this.props.busqueda === ""){
						return data;
					}else if(this.props.pacientes!==undefined ){
						  let paciente = this.props.pacientes.find(item=>data.PACIENTE_id===item.id); //(06nov22) Se optiene el paciente del objeto principal de pacientes
						  if(paciente!==undefined){
							if(paciente.nombres.toLowerCase().includes(this.props.busqueda.toLowerCase())){ //(06nov22) Regresa solo las coincidencias
								return data;
							}
						  }						
	
					}else{
						return data;

					}
         });   
		 

		console.log(" citaesPacienteCombinacionFilter:"+JSON.stringify(citaesPacienteCombinacionFilter));	 
		let agrupacionCitasByFecha = citaesPacienteCombinacionFilter.reduce((recursivo, cita) => {
			recursivo[cita.fecha] = [...recursivo[cita.fecha] || [], cita];
			  return recursivo;
			 }, {});

		 console.log("agurcaion grupo:"+JSON.stringify(agrupacionCitasByFecha));	 
		// console.log("agurcaion grupo:"+agrupacionCitasByFecha.size);	 
	   let count=0;
		console.log(Object.keys(agrupacionCitasByFecha));
		Object.keys(agrupacionCitasByFecha).map(fecha=>{
			console.log("fecha key:"+fecha);
			agrupacionCitasByFecha[fecha].map(cita=>{
				count++;
//				this.setState({ count: count });
				console.log("cita values:"+JSON.stringify(cita));
			})			
		});
	//	console.log("actualizarRegistrosAction:"+count);
	
//		console.log("actualizarRegistrosAction:"+count);

        return <Page citaesFilter={agrupacionCitasByFecha} 
					 abrirModalEditar={this.props.abrirModalEditar}
					/>
    }

}


const mapStateToProps=(state)=>({
    profil:state.autenticacionReducer.profil,     
    medicoUsuario:state.MedicoUsuarioReducer[MEDICO_USUARIO][0],  
    institucion:state.InstitucionReducer[INSTITUCION][0],
	pacientes: state.PacienteReducer[PACIENTE],

})

const mapDispatchToProps=(dispatch)=>({
	actualizarRegistrosAction: (type,registros) => dispatch(actualizarRegistrosAction(type,registros)),

})

export default connect(mapStateToProps,null)(CitaList);
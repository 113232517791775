import {CONSULTAR_CAT_TIPO_MOVIMIENTOS,AGREGAR_CAT_TIPO_MOVIMIENTO,ACTUALIZAR_CAT_TIPO_MOVIMIENTO, ELIMINAR_CAT_TIPO_MOVIMIENTO} from "../constants/CatTipoMovimiento-action-type";


/* CAT_TIPO_MOVIMIENTOS (HOSPITAL CLINICA) */
export const obtenerCatTipoMovimientoListWSAction = (dataType)=> {
    return {
        type:CONSULTAR_CAT_TIPO_MOVIMIENTOS,
        dataType:dataType,
        loading: true
    }
}

export const agregarCatTipoMovimientoWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_CAT_TIPO_MOVIMIENTO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarCatTipoMovimientoWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_CAT_TIPO_MOVIMIENTO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarCatTipoMovimientoWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_CAT_TIPO_MOVIMIENTO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

import {CONSULTAR_DIA_SEMANAES,AGREGAR_DIA_SEMANA,ACTUALIZAR_DIA_SEMANA, ELIMINAR_DIA_SEMANA} from "../constants/DiaSemana-action-type";


/* DIA_SEMANAES (HOSPITAL CLINICA) */
export const obtenerDiaSemanaListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_DIA_SEMANAES,
        dataType:dataType,
        loading: loading
    }
}

export const agregarDiaSemanaWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_DIA_SEMANA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarDiaSemanaWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_DIA_SEMANA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarDiaSemanaWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_DIA_SEMANA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import {PRESION_ARTERIAL} from "../../../redux/constants/action-type";
import {obtenerPresionArterialListWSAction,agregarPresionArterialWSAction,actualizarPresionArterialWSAction,eliminarPresionArterialWSAction} from "../../../redux/actions/PresionArterialActions";


 class PresionArterialPDFFloatButton extends Component{
    render(){    
        return <Page setLoadingReportData={this.props.setLoadingReportData}  loadingReportData={this.props.loadingReportData} presionArteriales={this.props.presionArterialesList}/>
     }

    /* esta es la accion de middleware*/
    componentDidMount(){                
        this.props.obtenerPresionArterialListWSAction(PRESION_ARTERIAL,true); /* Invoca por primara vez a productos (default) */
    }

  }

    /*
    funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
    */
    const mapStateToProps=(state)=>({
     presionArterialesList:state.PresionArterialReducer[PRESION_ARTERIAL],    
    })


    const mapDispatchToProps = (dispatch, ownProps) => ({ 
        obtenerPresionArterialListWSAction: (type,loading) => dispatch(obtenerPresionArterialListWSAction(type,loading)),        
    })

export default connect(mapStateToProps,mapDispatchToProps)(PresionArterialPDFFloatButton);

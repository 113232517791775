import React,{Component} from "react";
import PresionArterialList from "../PresionArterialList";
import PresionArterialBusqueda from "../PresionArterialBusqueda";
import PresionArterialForm from "../PresionArterialForm";
import CircularProgress from '@material-ui/core/CircularProgress';
import PresionArterialButtonBar from "../PresionArterialFloatButton";
import PresionArterialPDFFloatButton from "../PresionArterialPDFFloatButton";
import PresionArterialFormEdit from "../PresionArterialFormEdit";
import Fab from '@material-ui/core/Fab';


export default class Page extends Component{

    render(){
        let loading=this.props.loading;
        var resultDatos;
        var animacionCargando;
        if (loading) {
            animacionCargando = <Fab position="fixed" variant="round" size="small" aria-label="cargando" style={{position: 'fixed', top: "5.5rem", float: "left",
                                    left: "45%", boxShadow:"1", backgroundColor:"#FFFFFF",padding:"0.1rem"}}>
                                    <CircularProgress color="secondary" size="1.5rem"/>           
                                </Fab>
        }else{
            animacionCargando=<div/>
        }
        
        if(this.props.presionArteriales.length>0){
            resultDatos =  <div className="col-12" >                       
                                <PresionArterialList  presionArteriales={this.props.presionArteriales} abrirModalEditar={this.props.abrirModalEditar}
                                                    busqueda={this.props.busqueda}/> 
                            </div>
                                
        }

        console.log("Inion");

        return <div style={{paddingBottom:"5rem" , paddingTop:"3rem"}} >
                                 
                    <PresionArterialForm  modal={this.props.modal} 
                                      tipoSeleccion={this.props.tipoSeleccion}
                                        cerrarModal={this.props.cerrarModal} 
                                            agregarPresionArterialWSAction={this.props.agregarPresionArterialWSAction}/>                
                               
                    <PresionArterialFormEdit  modalEditar={this.props.modalEditar} 
                                          payload={this.props.payload} 
                                          tipoSeleccion={this.props.tipoSeleccion}
                                            cerrarModalEditar={this.props.cerrarModalEditar} 
                                                actualizarPresionArterialWSAction={this.props.actualizarPresionArterialWSAction}
                                                eliminarPresionArterialWSAction={this.props.eliminarPresionArterialWSAction}    />                

                    <div className="row">
                        {resultDatos} 
                        {animacionCargando}
                    </div>               
                    <div className="row">
                        <PresionArterialButtonBar  abrirModal={this.props.abrirModal}/>
                        <PresionArterialPDFFloatButton
                                presionArteriales={this.props.presionArteriales}
                                setLoadingReportData={this.props.setLoadingReportData} 
                                loadingReportData={this.props.loadingReportData} />
                    </div>                
                               
                    

                </div>
         
    }
}

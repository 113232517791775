import {CONSULTAR_ESQUEMA_TIPOCITA_HORARIOS,AGREGAR_ESQUEMA_TIPOCITA_HORARIO,ACTUALIZAR_ESQUEMA_TIPOCITA_HORARIO, ELIMINAR_ESQUEMA_TIPOCITA_HORARIO} from "../constants/EsquemaTipocitaHorario-action-type";


/* ESQUEMA_TIPOCITA_HORARIOS (HOSPITAL CLINICA) */
export const obtenerEsquemaTipocitaHorarioListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_ESQUEMA_TIPOCITA_HORARIOS,
        dataType:dataType,
        loading: loading
    }
}

export const agregarEsquemaTipocitaHorarioWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_ESQUEMA_TIPOCITA_HORARIO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarEsquemaTipocitaHorarioWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_ESQUEMA_TIPOCITA_HORARIO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarEsquemaTipocitaHorarioWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_ESQUEMA_TIPOCITA_HORARIO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

import { Avatar, Box, Card, CardActions, CardContent, CardHeader, Chip, Container, IconButton, List, makeStyles, Paper, Popover, Typography, withStyles } from "@material-ui/core";
import React,{Component, useRef} from "react";

const useStyles = makeStyles((theme) => ({
  paperpopper:{    
    color:"#FFFFFF",
    backgroundColor:"silver",    
    left:"0", 
    top:"0",
    height:"24px",
    position:"absolute",
    zIndex:"5",
    width:"100%",
    paddingLeft: theme.spacing(1),

  },
  text: {
    paddingLeft: theme.spacing(1),
    textTransform:"capitalize",
  },
}));

function Page(props){   
  const classes = useStyles();
  
  let duracionTipoCita="";
  let tipoCita="";

  if(props.catTipoCita!==undefined){
    tipoCita=props.catTipoCita.nombre;
    duracionTipoCita=props.catTipoCita.duracion+"min";

  }
      return <div>
                   <Paper 
                      p={1} 
                      className={classes.paperpopper} 
                      style={{background:props.codColor,top:props.top,height:props.height-3}}
                      elevation={0}
                    > 
                      <Typography variant="caption" className={classes.text}>                                                  
                        {tipoCita}
                      </Typography>  
                   </Paper>
            </div>           
}

export default Page;

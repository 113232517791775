import React,{Component} from "react";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import OpacityIcon from '@material-ui/icons/Opacity';
import {Link} from "react-router-dom";
import "./estilo.css"
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import {getFecha,getFormatoHora} from "../../../utils/moment";
import {getCodigoColorCategoria} from "../../../utils/presionArterial";
import Avatar from '@material-ui/core/Avatar';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

export default class Page extends Component{

    render(){
        
        let p=this.props.glucosa;

        let fechaTitulo =getFecha(p.fecha);
        let horaTitulo =getFormatoHora(p.hora);
        
        return  <Card className="card" variant="elevation" boxShadow={3} elevation={3} >
                  <CardHeader                  
                  elevation="2"
                  avatar={
                    <Avatar fontSize="large" style={{  marginRight:".8rem", width: "3.5rem",height: "3.5rem",borderColor:"red",backgroundColor:"#eceff1"}}>
                        <OpacityIcon fontSize="large" alt={p.descripcion} style={{ color:"primary"}} color="primary" /></Avatar> 
                  }
                
                  title={fechaTitulo} 
                  subheader={horaTitulo}
                />
                    <CardContent  className="card_content" >
                    <Grid container spacing={3}>

                    <Grid item xs={6}>
                      <Typography variant="h3" component="h2" style={{textAlign:"center"}}>
                         
                        {p.valor} 
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h5" component="h2" color="primary" style={{textAlign:"justify",verticalAlign:"middle",}} >
                            mg/dl 
                      </Typography>
                    </Grid>
                  
                    </Grid>
                    
                  </CardContent>
                  <CardActions className="card_pie"   >
                    <Typography variant="h5" component="h5" color="textPrimary">
                      Notas
                    </Typography>
                    <Typography variant="body2" component="h5">
                      {p.nota}
                    </Typography>
                  </CardActions>
                </Card>

        
        
       
    }
}
/*

<div>
<ListItem dense button style={{  paddingBottom:6, paddingTop:6 }} component={Link}  to={{
  hash:'id', 
  valores:{
     modalEditar:true,
     payload: p
  }
  }} >
    <ListItemAvatar>
<OpacityIcon style={{ marginRight:".8rem", width: "3.5rem",height: "3.5rem", color:obtenerColor(p.descripcion)}} alt={p.descripcion} />
    </ListItemAvatar>

<ListItemText id={p.id} primary={<Typography type="Roboto" style={{ color: '#000000',fontSize:'1rem',fontWeight:'normal' }}>{p.valor}</Typography>}
secondary={<Typography type="Roboto" style={{color: '#6c757d', fontSize:'0.9rem',fontWeight:'normal' }}>{p.fecha} / {p.hora}</Typography>} />


</ListItem>
<Divider variant="inset" component="li" />

</div>
*/
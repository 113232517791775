import {CONSULTAR_PAGINA_WEBES,AGREGAR_PAGINA_WEB,AGREGAR_IMAGEN_PAGINA_WEB,ACTUALIZAR_PAGINA_WEB, ELIMINAR_PAGINA_WEB} from "../constants/PaginaWeb-action-type";


/* PAGINA_WEBES (HOSPITAL CLINICA) */
export const obtenerPaginaWebListWSAction = (dataType)=> {
    return {
        type:CONSULTAR_PAGINA_WEBES,
        dataType:dataType,
        loading: true
    }
}

export const agregarPaginaWebWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_PAGINA_WEB,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const agregarImagenPaginaWebWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_IMAGEN_PAGINA_WEB,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarPaginaWebWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_PAGINA_WEB,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarPaginaWebWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_PAGINA_WEB,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

import React,{Component} from "react";
import Page from "./page";

export default class PacientesBusqueda extends Component{

    busquedaFuncion=(event)=>{
        let valor = event.target.value;
        this.props.buscar(valor);
      }

    render(){    
        return <Page pacientes={this.props.pacientes} busquedaFuncion={this.busquedaFuncion}/>
    }

}


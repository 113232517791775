import { Box, Card, CardContent, Container, Grid, makeStyles, Paper, Popper, Typography } from "@material-ui/core";
import React,{Component, useRef} from "react";
import Hora from "../Hora";
import ReglaLateralHorasDisplay from "../ReglaLateralHorasDisplay";
import DiaTopDisplay from "../DiaTopDisplay";

const useStyles = makeStyles((theme) => ({
  root: {
    display:"flex",
    flexDirection:"column",
  },
  grid: {
    display:"flex",
    flexDirection: 'row',
    height:"80vh",
    
  },
  containerRoot: {
    overflow:"auto",
    display:"flex",
    flexDirection: 'row',
    width:"100%",

  },
  container: {
    display: 'grid',
    gridTemplateRows: 'repeat(24, 1fr)',
    gridAutoFlow:"column",
    gridGap: theme.spacing(0),
  },
}));

function Page(props){
  
  const classes = useStyles();
  


      return  <div className={classes.root}>   
                    <div>           
                      <Box display="flex" flexDirection="row">
                        <div style={{width:"48px"}}></div>  
                        <div style={{width:"6px"}}></div>  
                        <Box display="flex" flexDirection="row" justifyContent="space-around" flexGrow="1">
                        {
                          props.diasSemanaArray.map((item,index)=>
                              <DiaTopDisplay
                                key={index}
                                hoy={item}
                              />
                          )
                        }
                        </Box>
                      </Box>
                    </div>
                    <div className={classes.grid}>
                      <div className={classes.containerRoot}>
                        <ReglaLateralHorasDisplay horasDiaArray={props.horasDiaArray}/>                  
                        <Grid container spacing={0} className={classes.container}>
                          
                            {
                            props.horasSemanaArray.map((item,index)=>
                              <Grid 
                                item                    
                                key={index} 
                                xs={12}
                              >       
                                <Hora hora={item} hoy={props.hoy}/>             
                              </Grid>
                            )
                        }
                      </Grid>     
                    </div>      
                  </div>
              </div> 
                            
}

export default Page;

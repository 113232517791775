import {CONSULTAR_HORARIOS,AGREGAR_HORARIO,ACTUALIZAR_HORARIO, ELIMINAR_HORARIO} from "../constants/Horario-action-type";


/* HORARIOS (HOSPITAL CLINICA) */
export const obtenerHorarioListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_HORARIOS,
        dataType:dataType,
        loading: loading
    }
}

export const agregarHorarioWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_HORARIO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarHorarioWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_HORARIO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarHorarioWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_HORARIO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

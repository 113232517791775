import React,{Component,Papper} from "react";
import TextField from "@material-ui/core/TextField";
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';

import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Delete from '@material-ui/icons/Delete';
import { useEffect } from "react";
import { Box, Paper } from "@material-ui/core";
import { useRef } from "react";

//export default class Page extends Component{
  function Page (props){
    const MINUTE_MS = 500;

   
    
    const scrollToBottom = (input) => {  

      const interval = setTimeout(() => {
        if (input) {
          input.focus();
        }          
      }, MINUTE_MS);
  
      return () => clearInterval(interval);

    }
 
      return <Box m={2}>      
                <Paper  elevation={0}>
    
                    <TextField                            
                      id="busqueda"
                      label="Buscar..."
                      type="text"
                      fullWidth
                      size="small"
                      variant="outlined" 
                      InputProps={{
                        style: { color: "#000",backgroundColor:"whitesmoke" },
                         startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon color="primary"/>
                          </InputAdornment>
                        )
                      }}  
                      onChange={(e)=>props.busquedaFuncion(e)}    
                      style={{backgroundColor:"#fff"}}
                      inputRef={scrollToBottom}
                       
                      /> 
              </Paper>   
             </Box>   
                             
}
export default Page;
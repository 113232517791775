import React,{Component} from "react";
import Page from "./page";
import {  INSTITUCION } from "../../../redux/constants/action-type";
import {connect} from "react-redux";
import {obtenerInstitucionListWSAction} from "../../../redux/actions/InstitucionActions";


class InstitucionFormSelect extends Component{
    render(){    
        return<Page instituciones={this.props.instituciones} 
                    INSTITUCION_id={this.props.INSTITUCION_id} 
                    institucionValue={this.props.institucionValue} 
                    handleChange={this.props.handleChange} 
                    errors={this.props.errors}
                    touched={this.props.touched}/>
    }

    componentDidMount(){            
        this.props.obtenerInstitucionListWSAction(INSTITUCION,false); /* Invoca por primara vez a productos (default) */
    }
}

const mapStateToProps=(state)=>({
    instituciones:state.InstitucionReducer[INSTITUCION],
})


const mapDispatchToProps = (dispatch, ownProps) => ({
    obtenerInstitucionListWSAction: (type,loading) => dispatch(obtenerInstitucionListWSAction(type,loading)),
})


export default connect(mapStateToProps,mapDispatchToProps)(InstitucionFormSelect);
import React,{Component} from "react";
import Grid from '@material-ui/core/Grid';
import DiaSemanaListItem from "../DiaSemanaListItem";
import "./estilo.css"
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import OtroHorario from "../OtroHorario";
import BuscarHorario from "../BuscarHorario";

export default class Page extends Component{

 

  render(){

    
    console.log("this.props.periodoPorDiaOrdenado:"+JSON.stringify(this.props.periodoPorDiaOrdenado));
    console.log("weekday:"+JSON.stringify(this.props.weekday));
       
    return <CardContent className="card_main" >
               
             {this.props.weekday.map((item,index)=>{             // Se obtinene los dias de la semana y los periodos de horario desde el esquema de trabajo {this.props.setPeriodoPorDia({p.horaInicial+","+p.horaInicial+","+p.horaInicial})}

                                    if(this.props.periodoPorDiaOrdenado[item]!==undefined){
                                      return  <DiaSemanaListItem  
                                                key={index}
                                                idDia={item}           
                                                periodoList={this.props.periodoPorDiaOrdenado[item]}

                                                diaBusquedaSelecionado={this.props.diaBusquedaSelecionado}

                                                duracionTipoCita={this.props.duracionTipoCita}
                            
                                                fecha={this.props.fecha} 
                                                setFieldValue={this.props.setFieldValue} 
                                                horaInicio={this.props.horaInicio} 

                                                />   
                                    }                  
                                  }                  
                            )
              }    
              
              </CardContent>                    
                      
    }                          
}
import React,{Component} from "react";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";
import FaceIcon from '@material-ui/icons/Face';
import Chip from '@material-ui/core/Chip';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Badge from '@material-ui/core/Badge';
import {MASCULINO,FEMENINO, ESTATUS_ENVIADA} from "../../../utils/constantes";
import "./estilo.css";
import { getHorarioLocaleString } from "../../../utils/date";
import { alpha, Avatar, Box, withStyles } from "@material-ui/core";
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import pacienteHPNG from "../../../icons/pacienteH.png";
import pacienteMPNG from "../../../icons/pacienteM.png";
import estetoscopioMedPNG from "../../../icons/estetoscopioMed.png";
import relojPNG from "../../../icons/reloj.png";


String.prototype.toHex = function() {
  var hash = 0;
  if (this.length === 0) return hash;
  for (var i = 0; i < this.length; i++) {
      hash = this.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
  }
  var color = '#';
  for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 255;
      color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
}

String.prototype.toColor = function() {
	var colors = ["#bf360c","#3e2723","#212121","#616161","#5d4037","#455a64","#00838f","#f44336","#e91e63","#9c27b0","#673ab7","#3f51b5","#2196f3","#03a9f4","#00bcd4","#009688","#4caf50","#8bc34a","#0471a5","#827717","#0b6f9f","#9d5e00","#ab2900","#795548","#607d8b","#01579b","#006064","#004d40","#6200ea","#1a237e","#c51162","#880e4f"]
	
    var hash = 0;
	if (this.length === 0) return hash;
    for (var i = 0; i < this.length; i++) {
        hash = this.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    hash = ((hash % colors.length) + colors.length) % colors.length;
    return colors[hash];
}

const StyledBadge = withStyles((theme) => ({
    badge: {
      top: 35,
      border: `1px solid ${theme.palette.background.paper}`,
      zIndex:"0",
      backgroundColor:"#1dbd9e",
      color:"#ffffff",
    },
  }))(Badge);

  function Page(props) {

        let p=props.cita;
        let desde=getHorarioLocaleString(props.cita.horaInicio);//props.cita.horaInicio.substring(0,5);
        let hasta=getHorarioLocaleString(props.cita.horaFin);//props.cita.horaFin.substring(0,5);
        let nombrePaciente="";
        let nombreMedico="";
        let tipoCita="";
        let duracionTipoCita="";
        let edad="";
        let colorFace="#90a4ae";
        let estatusCita="";
        let codColorStatus="#90a4ae";
        let codColorStatusBackground="transparent";


        if(props.catEstadoCita!==undefined){
          estatusCita=props.catEstadoCita.nombre;
          codColorStatus=props.catEstadoCita.codColor;
        }

        if(props.cita.CAT_ESTADO_CITA_id===ESTATUS_ENVIADA){
          codColorStatusBackground=alpha(codColorStatus,0.15);
        }

        if(props.paciente!=undefined){
          nombrePaciente=<Typography component="span" variant="body1" style={{ textTransform:"capitalize",color:"#000000",fontWeight:"500"}} >
                            {props.paciente.nombres.toLowerCase()+" "+props.paciente.apellidoPaterno.toLowerCase()+" "+props.paciente.apellidoMaterno.toLowerCase()}
                        </Typography>

        }

        if(props.medico!==undefined){
          nombreMedico=<Typography component="span" variant="body1" style={{ textTransform:"capitalize",display: 'flex',justifyContent:"left",color:(props.medico.titulo+props.medico.nombres+props.medico.apellidoPaterno).toColor()}} >
                        <img alt="Bildsoft"  
                                  src={estetoscopioMedPNG}  
                                   style={{ width: "1.5rem",height: "1.5rem",
                                    marginRight:"3px",marginLeft:"3px",marginBottom:"3px"}}
                          />
                                  
                         {props.medico.titulo+" "+props.medico.nombres+" "+props.medico.apellidoPaterno+" "+props.medico.apellidoMaterno}                                                                   
                       </Typography>
                                                 
                        
        }

        if(props.catTipoCita!==undefined){
          tipoCita=props.catTipoCita.nombre;
          console.log("tipo de cita:"+JSON.stringify(props.catTipoCita));
          duracionTipoCita=props.catTipoCita.duracion+"min";
          //estatusCita=props.catEstadoCita.nombre;

        }

        if(props.paciente!==undefined){
          edad=props.paciente.edad+"a.";
        }

        let avatarPaciente="";

        if(props.paciente!==undefined){          
          console.log("props.paciente.sexo:"+props.paciente.sexo);
          if(props.paciente.SEXO_id===MASCULINO){
            colorFace="#e4f8ff";
            avatarPaciente =<Avatar alt="Bildsoft"  
                                  src={pacienteHPNG}  
                                  style={{ marginRight:".8rem", width: "3.5rem",height: "3.5rem" ,color:colorFace,backgroundColor:colorFace}} 
                                />

          }else if(props.paciente.SEXO_id===FEMENINO){
            colorFace="#ffdee3";
            avatarPaciente =<Avatar alt="Bildsoft"  
                                  src={pacienteMPNG}  
                                  style={{ marginRight:".8rem", width: "3.5rem",height: "3.5rem" ,color:colorFace,backgroundColor:colorFace}} 
                                />
          }  
        }
      



     console.log("props.key:"+props.item);

        return <span>              
                <ListItem   
                   id={props.item}                  
                    key={props.item}
                    button 
                    spacing={3}
                    component={Link} 
                    my={3}
                    to={{
                        hash:'id', 
                        valores:{
                                modalEditar:true,
                                payload:p
                                }
                        }}
                    style={{borderColor:alpha(codColorStatus,0.85),borderWidth:"0rem 0rem 0rem 0.4rem",borderStyle:"solid",backgroundColor:codColorStatusBackground,borderRadius:"0.25rem"}}
                  >
                    <ListItemAvatar >                      
                            {avatarPaciente}
                    </ListItemAvatar>


                    <ListItemText id={p.id} 
                          primary={nombrePaciente}                                                                                              
					      			    secondary={<Typography component="span">
                                      <Box my={1} display={{ xs: 'block', sm: 'flex' }}>                                       
                                      <div style={{marginRight:"3px",marginLeft:"3px",marginBottom:"3px"}}>
                                      {nombreMedico}
                                      </div>
                                      <div style={{marginRight:"3px",marginLeft:"3px",marginBottom:"3px"}}>
                                            <Chip  
                                              variant="outlined"                                           
                                              size="medium"
                                              icon={<img src={relojPNG} 
                                                        style={{ width: "1.4rem",height: "1.4rem"}}          
                                                    />
                                                   } 
                                              label={<Typography component="span" variant="subtitle2" 
                                                      style={{ textTransform:"none",
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis"
                                                            }}
                                                     >{ desde+"-"+hasta}</Typography>} 

                                              style={{maxWidth: 210,backgroundColor:"whitesmoke",borderColor:"#1dbd9e",color:"#000000",opacity:"0.9"}} 

                                            />
                                      </div> 
                                      <div style={{marginRight:"3px",marginLeft:"3px",marginBottom:"3px"}}>                                           
                                    
                                        <StyledBadge 
                                          badgeContent={duracionTipoCita}  
                                          color="primary"
                                          overlap="rectangular"
                                          >

                                            <Chip 
                                              variant="default" 
                                              size="medium" 
                                              style={{color:"#FFFFFF",borderWidth:"0px",maxWidth: 210, backgroundColor:props.codColor, borderColor:props.codColor }} 
                                              avatar={<Avatar  style={{backgroundColor:"#FFFFFF" ,color:props.codColor }} >{tipoCita.charAt(0)}</Avatar>}
                                              label={<Typography component="span" variant="subtitle2" style={{ textTransform:"capitalize",
                                                                  whiteSpace: "nowrap",
                                                                  overflow: "hidden",
                                                                  textOverflow: "ellipsis"
                                                                  }}>{tipoCita}</Typography>}
                                              />  
                                        </StyledBadge>
                                        </div>      
                                      </Box>
                                      </Typography> 
                                     } 
                      />
                      
                      <ListItemSecondaryAction >
                         <Chip
                            variant="outlined"
                            size="small"
                            style={{borderColor:"#1dbd9e",backgroundColor:"whitesmoke",color:"default"}} 
                            label={<Typography component="span" variant="caption">{edad}</Typography>}
                          />                             
                      </ListItemSecondaryAction>
                </ListItem>
                <Divider variant="inset"/>
                </span>
    
}
export default Page;

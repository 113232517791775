import React,{Component} from "react";
import PacientesListItem from "../InstitucionListItem";
import List from '@material-ui/core/List';

export default class Page extends Component{

  constructor(props) {
    super(props);
  }

    render(){      
      return <List > {
            this.props.institucionesFilter.map(institucion=> 
                      <PacientesListItem institucion={institucion} abrirModalEditar={this.props.abrirModalEditar}/> )
            }</List>
    }                          
}

import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Box  from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  paper2: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}));



export default function Page(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);


  function handleSelect(event){
    var esquemaTipoCita ={};
		esquemaTipoCita["ESQUEMA_TRABAJO_id"]=props.ESQUEMA_TRABAJO_id;
		esquemaTipoCita["CAT_TIPO_CITA_id"]=event.currentTarget.id;
    props.setEsquemaTipoCita(esquemaTipoCita);
    setOpen(false);
  }
  let menuItem="";
  let buttonAgregar="";

  if(props.tipoDeCitasMenu!==""){
    menuItem=props.tipoDeCitasMenu.map(tipo_cita=> 
              <MenuItem id={tipo_cita.id}  onClick={handleSelect}>{tipo_cita.nombre}</MenuItem>
              );

    if(props.tipoDeCitasMenu.length>0){
      buttonAgregar  =<Button
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        variant="outlined"
                      
                      >             
                        <AddCircleOutlineIcon  /><Box margin="1rem">AGREGAR TIPO DE CITA</Box> 
                      </Button>

    }
  }
 

  return (
    <div className={classes.root}>

      <div>
        {buttonAgregar}
         
        <Popover 
          open={open} 
          placement={"top"} 
          anchorEl={anchorRef.current} 
          role={undefined} 
          transition 
          disablePortal 
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          >

              <Paper>
                <ClickAwayListener onClickAway={handleClose} >
                  <MenuList className={classes.paper2}  id="menu-list-grow" onKeyDown={handleListKeyDown} >
                    {menuItem}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
          
        </Popover>
      </div>
    </div>
  );
}
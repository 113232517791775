import React,{Component} from "react";
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import {getFormatoHoraHrs} from "../../../utils/moment";
import ConfirmacionDialog from "./ConfirmacionDialog";

export default class Page extends Component{
/* MUESTRA LOS PERIODOS DE HORARIOS EN LOS COMPONENTES CHIP DE CADA SEMANA*/
  handleDelete = (h) => {
    console.log('You clicked the handleDelete icon.');
    this.props.setModalDialog(true);
    this.props.setPeriodo(h);

  }

  handleClick = (h) => {
    console.log('You clicked the handleClick icon.'+h);
    console.log('You clicked the handleClick icon.'+JSON.stringify(h));
    console.log('You clicked the handleClick icon2.'+this.props.dia_semanaItem);
    console.log('You clicked the handleClick icon2.'+JSON.stringify(this.props.dia_semanaItem));

    this.props.setOpenPeriodoEditDialog(true);
    var horario ={};
    horario["DIA_SEMANA_id"]=this.props.dia_semanaItem.id;
    this.props.setHorario(horario);
    this.props.setPeriodo(h);
  }  
    render(){
        let periodoChip="";
        if(this.props.periodo!==undefined){
          periodoChip=this.props.periodo.map(h=> 
                        <Grid item >
                          <Chip label={getFormatoHoraHrs(h.horaInicial)+" - "+getFormatoHoraHrs(h.horaFinal)}  
                                onDelete={() => this.handleDelete(h)} onClick={() => this.handleClick(h)} style={{margin:"2px"}} variant="outlined" 
                                color="primary"
                                clickable={true}/>
                        </Grid>        
              );
        }

        return  <div>
                    {periodoChip} 
                    <ConfirmacionDialog modalConfirmacion={this.props.modalConfirmacion}   
                                        setModalDialog={this.props.setModalDialog}
                                        handleEliminarPeriodoClick={this.props.handleEliminarPeriodoClick}
                                        />
                </div>
    }
}


import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {MEDICO} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";


const validationSchema = yup.object({
	//	especialidad: yup.string().required("Campo requerido"),
    nombres: yup.string().trim().required("Campo requerido").matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
    apellidoPaterno: yup.string().required("Campo requerido").matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
    apellidoMaterno: yup.string().matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Solo se permite letras.').max(45, 'debe ser menor a 45 caracteres'),
    cedulaProfesional: yup.string().max(45, 'debe ser menor a 45 caracteres'),
    correo: yup.string().email('el correo no es valido').max(45, 'debe ser menor a 45 caracteres'),
    telefono: yup.string().max(45, 'debe ser menor a 45 caracteres'),
    celular: yup.string().max(45, 'debe ser menor a 45 caracteres'),
    especialidad: yup.string().max(45, 'debe ser menor a 45 caracteres')
})

class MedicoFormEdit extends Component{

    constructor(props) {
        super(props);
        this.state={
                modalConfirmacion:false,
        };
    }
    abrirConfirmacion=()=> {
        this.setState({
            modalConfirmacion:true
        });
    }
    cerrarConfirmacion=()=> {
        this.setState({
            modalConfirmacion:false
        });
    }

    eliminar=(dato)=> {
        this.cerrarConfirmacion();
        this.props.cerrarModalEditar();
        this.props.eliminarMedicoWSAction(MEDICO,dato); /* Se implementa eliminar de web services y redux */
    }

    
    render(){    
    
        let valores=this.props.payload;

        console.log("valoresvalores:"+JSON.stringify(valores));

        return <Formik
                    enableReinitialize
                    initialValues={valores}
                    validationSchema={validationSchema}
                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                        console.log("EDITAR MEDICO (onSubmit)"+JSON.stringify(values));
                          setSubmitting(this.props.actualizarMedicoWSAction(MEDICO,values)); /* Se implementa actualizar de web services y redux */
                          resetForm({values:""});
                         this.props.history.goBack();
                      }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        errors,
                        touched,
                        setFieldValue
                    }) => < Page 
                                setFieldValue={setFieldValue} 
                                handleSubmit={handleSubmit} 
                                handleChange={handleChange} 
                                handleBlur={handleBlur} 
                                values={values} 
                                errors={errors} 
                                touched={touched}  
                                modalEditar={this.props.modalEditar} 
                                tipoSeleccion={this.props.tipoSeleccion} 
                                cerrarModalEditar={this.props.cerrarModalEditar} 
                                eliminar={this.eliminar} 
                                payload={this.props.payload}
                                actualizarMedicoWSAction={this.props.actualizarMedicoWSAction}  
                                modalConfirmacion={this.state.modalConfirmacion}
                                cerrarConfirmacion={this.cerrarConfirmacion}
                                abrirConfirmacion={this.abrirConfirmacion} />}
                </Formik> 
    }

}

const mapStateToProps=(state)=>({
})

export default withRouter(connect(mapStateToProps)(MedicoFormEdit));


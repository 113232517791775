import React,{Component} from "react";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/PersonRounded';
import {Link} from "react-router-dom";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Badge from '@material-ui/core/Badge';
import {MASCULINO,FEMENINO} from "../../../utils/constantes";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {getFormatoFechaNacimiento} from "../../../utils/moment"
import Chip from '@material-ui/core/Chip';
import { ArrowRightOutlined } from "@material-ui/icons";
import EventIcon from '@material-ui/icons/Event';

export default class Page extends Component{

    render(){
        
        function obtenerColor(nombre) {
            if(nombre!=null){
                var size=nombre.length;
                do {
                   if(size>colores.length){
                      size=size-colores.length;
                   } 
                }while (size>colores.length);
  
              return colores[size];
  
            }else{
                return colores[0];
            }
          }

        let colores=["#babdbe","#00bfa5","#8eacbb","#0288D1","#34515e"];
        let nombrePaciente="";

        let edad="";
        if(this.props.paciente!==undefined){
          edad=this.props.paciente.edad+"a";
        }
        let fechaNac="";
        if(this.props.paciente!==undefined){
          fechaNac=<Typography component="span" variant="body1" style={{ textTransform:"capitalize",display: 'flex',justifyContent:"left"}} color="secondary">
                      <EventIcon fontSize="small" padding={0} margin={0}/>  {getFormatoFechaNacimiento(this.props.paciente.fechaNacimiento)}
                  </Typography>
        }
        if(this.props.paciente!=undefined){
          nombrePaciente=<Typography component="span" variant="body1" style={{ textTransform:"capitalize"}} color="primary">
                            {this.props.paciente.nombres.toLowerCase()+" "+this.props.paciente.apellidoPaterno.toLowerCase()+" "+this.props.paciente.apellidoMaterno.toLowerCase()}
                        </Typography>

        }
        
        let colorFace="#90a4ae";
        if(this.props.paciente!==undefined){          
          console.log("this.props.paciente.sexo:"+this.props.paciente.sexo);
          if(this.props.paciente.SEXO_id===MASCULINO){
            colorFace="#00aae4";
          }else if(this.props.paciente.SEXO_id===FEMENINO){
            colorFace="#fe4164";
          }  
        }
        
        return  <span>
                <ListItem dense button style={{  paddingBottom:6, paddingTop:6 }} component={Link}  to={{
                  hash:'id', 
                  valores:{
                     modalEditar:true,
                     payload: this.props.paciente  
                  }
                  }} >
                    <ListItemAvatar>
						         <AccountCircleIcon style={{ marginRight:".8rem", width: "3.5rem",height: "3.5rem" ,color:colorFace,opacity:"0.8"}} alt={this.props.paciente.expediente} />
                    </ListItemAvatar>
                    <ListItemText 
                      id={this.props.paciente.id} 
                      primary={nombrePaciente}                      
								      secondary={fechaNac}
                    />
                    <ListItemSecondaryAction >
                        <Chip
                            color="secondary"
                            variant="outlined"
                            size="small"
                            style={{borderColor:"#1dbd9e",backgroundColor:"whitesmoke",color:"default"}} 
                            label={edad}
                          />                             
                    </ListItemSecondaryAction>
                   
                </ListItem>
                <Divider variant="inset" component="li" />

                </span>
    }
}

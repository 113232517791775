import { Box, Card, CardContent, Container, Grid, makeStyles, Paper, Popper, Typography } from "@material-ui/core";
import React,{Component, useRef} from "react";
import DiaTopDisplay from "../DiaTopDisplay";
import Hora from "../Hora";
import ReglaLateralHorasDisplay from "../ReglaLateralHorasDisplay";

const useStyles = makeStyles((theme) => ({

  root: {
    display:"flex",
    flexDirection:"column",
  },
  grid: {
    display:"flex",
    flexDirection: 'row',
    height:"80vh",
    
  },
  containerRoot: {
    overflow:"auto",
    display:"flex",
    flexDirection: 'row',
    width:"100%",

  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridGap: theme.spacing(0),
    flexGrow:1,
  },
 
}));

function Page(props){
  
  const classes = useStyles();

      return<div className={classes.root}>   
                <div>           
                  <Box display="flex" flexDirection="row" >
                    <div style={{width:"48px"}}></div>  
                    <div style={{width:"6px"}}></div>  
                    <DiaTopDisplay 
                      hoy={props.diaInicialDia}
                    />
                  </Box>
                </div>
                <div className={classes.grid}>
                  <div className={classes.containerRoot}>
                    <ReglaLateralHorasDisplay horasDiaArray={props.horasDiaArray}/>                  
                    <Grid container spacing={0} className={classes.container}>
                      
                        {
                        props.horasArray.map((item,index)=>
                          <Grid 
                            item                    
                            key={index} 
                            xs={12}
                          >       
                            <Hora hora={item} hoy={props.hoy}/>             
                          </Grid>
                        )
                    }
                  </Grid>     
                </div>      
              </div>
          </div> 
                            
}

export default Page;

import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {PRESION_ARTERIAL,PACIENTE,CAT_UNIDAD_MEDIDA,PACIENTE_USUARIO} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {UNIDAD_MEDIDA_PRESION_ARTERIAL} from "../../../utils/constantes";
import {getFormatoHorario,getFormatoFecha} from "../../../utils/moment"

const validationSchema = yup.object({
		valorSistolica: yup.string().required("Campo requerido"),
		valorDiastolica: yup.string().required("Campo requerido"),
		fecha: yup.string().required("Campo requerido"),
		hora: yup.string().required("Campo requerido"),
		CAT_UNIDAD_MEDIDA_id: yup.string().required("Campo requerido"),
		PACIENTE_id: yup.string().required("Campo requerido")
})

class PresionArterialForm extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }
    
    goBack(){
        this.props.history.goBack();
    }
    render(){    
        let PACIENTE_id="";
        if(this.props.pacienteUsuario!==undefined){
            PACIENTE_id=this.props.pacienteUsuario.PACIENTE_id;
        }
        console.log("(PACIENTE_id)presion Arterial "+PACIENTE_id); 
        
    
        return <Formik

                    initialValues={{
                        'descripcion': '',
                        'valorSistolica': '',
                        'valorDiastolica': '',
                        'pulso': '',
                        'nota': '',
                        'fecha': getFormatoFecha(new Date()),
                        'hora': getFormatoHorario(new Date()),
                        'CAT_UNIDAD_MEDIDA_id': UNIDAD_MEDIDA_PRESION_ARTERIAL,
                        'PACIENTE_id': PACIENTE_id
                    }}

                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                           console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          setSubmitting(this.props.agregarPresionArterialWSAction(PRESION_ARTERIAL,values));
                          resetForm({values:""});
                          this.goBack();

                      }}
                >
                    {props => < Page {...props}  catUnidadMedida={this.props.catUnidadMedida}  paciente={this.props.paciente}   modal={this.props.modal} cerrarModal={this.props.cerrarModal} />}
                </Formik> 
    }
}

const mapStateToProps=(state)=>({    
  catUnidadMedida:state.CatUnidadMedidaReducer[CAT_UNIDAD_MEDIDA],
  paciente:state.PacienteReducer[PACIENTE],
  pacienteUsuario:state.PacienteUsuarioReducer[PACIENTE_USUARIO][0] 

})

export default withRouter(connect(mapStateToProps)(PresionArterialForm));



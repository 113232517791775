import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React,{Component} from "react";
import { blueGrey } from "@material-ui/core/colors";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 12,
    spacing: 8,
    borderWidth:2,
    BorderColor:blueGrey

  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 12,
  },
  pos: {
    marginBottom: 12,
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#1976d2 !important",
    color:"#1976d2 !important"
  },
  label: {
    textTransform: 'capitalize',
    borderRadius: 3,
    border: 0,
    color: '#01579b',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .12)',
    },
    
});

const Page=(props)=>{
  const classes = useStyles();
  
  function handleChange(e){
		var value = e.target.value;
		props.setFechaBusqueda(value);
		console.log("value(fechaBusqueda):"+value);
	}

      return (<div>
                  <TextField
                        className="text_titulo"
                        size="medium"
                        margin="dense"
                        id="fechaBusqueda"
                        label={props.label}
                        type="date"
                        fullWidth
                        variant="outlined"
                        style={{borderColor:"red"}}
                        onChange={handleChange} 
                        value={props.fechaBusqueda}
                        InputLabelProps={{
                              shrink: true,
                              style: { color: '#1976d2' },

                              }}	
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                            root: classes.label, // class name, e.g. `classes-nesting-label-x`

                          }
                        }}	                                                          					
                        />              
            </div>)
                             
}

export default Page;
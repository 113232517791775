import React,{Component} from "react";
import PacientesListItem from "../TecnicoListItem";
import List from '@material-ui/core/List';

export default class Page extends Component{

  constructor(props) {
    super(props);
  }

    render(){      
      return <List > {
            this.props.tecnicosFilter.map(tecnico=> 
                      <PacientesListItem tecnico={tecnico} abrirModalEditar={this.props.abrirModalEditar}/> )
            }</List>
    }                          
}

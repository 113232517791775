import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {CAT_TIPO_CITA,COLOR,INSTITUCION} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";


const validationSchema = yup.object({
		nombre: yup.string().required("Campo requerido").matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u,
                'No se permiten caracteres especiales').min(2, 'Debe ser mayor a 2 caracteres')
                .max(45, 'debe ser menor a 45 caracteres'),
		descripcion: yup.string().required("Campo requerido").min(2, 'Debe ser mayor a 2 caracteres')
        .max(45, 'debe ser menor a 45 caracteres'),
		duracion: yup.string().required("Campo requerido"),
		COLOR_id: yup.string().required("Campo requerido")
})

class CatTipoCitaForm extends Component{
    constructor(props){
        super(props);
     }
    
       render(){    
    
        let idInstitucion="";
        if(this.props.Institucion!==undefined){
            idInstitucion=this.props.Institucion.id;
        }
        console.log("(idInstitucion):"+idInstitucion);                              

        return <Formik

                    initialValues={{
                    'nombre': '',
                    'descripcion': '',
                    'duracion': '30',
                    'COLOR_id': "",
                    'INSTITUCION_id': idInstitucion
                    
                    }}

                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                        console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                        setSubmitting(this.props.agregarCatTipoCitaWSAction(CAT_TIPO_CITA,values));
                          resetForm({values:""});
                          this.props.history.goBack();

                      }}
                >
                    {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    setFieldValue
                    }) => < Page setFieldValue={setFieldValue} 
                                 handleSubmit={handleSubmit} 
                                 handleChange={handleChange} 
                                 handleBlur={handleBlur} 
                                 values={values} 
                                 errors={errors} 
                                 touched={touched}                                            
                                 modal={this.props.modal}                                                     
                                 cerrarModal={this.props.cerrarModal}                                                    
                                 color={this.props.color} 
                    />}

                </Formik> 
    }
}

const mapStateToProps=(state)=>({
  color:state.ColorReducer[COLOR],
  Institucion:state.InstitucionReducer[INSTITUCION][0],

})

export default withRouter(connect(mapStateToProps)(CatTipoCitaForm));
import React,{Component} from "react";
import Grid from '@material-ui/core/Grid';
import HorarioList from "../HorarioList"
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import "./estilo.css"

export default class Page extends Component{

  getFechaComponent=()=>{
    let dia=("0" + this.props.dia).slice(-2);
    let idMes=("0" + (this.props.idMes + 1)).slice(-2);
    let anio=this.props.anio
    return anio+"-"+idMes+"-"+dia;    
  }

  render(){
    let fechaLocal=this.getFechaComponent();    
    let action=(this.props.fecha===fechaLocal)? <CheckCircleIcon  fontSize="default" color="secondary"/>:"";

             return <Grid item xs={12}>
                      <Card variant="elevation" elevation={3} className="card_day" color="default" >
                      <CardHeader     
                        className="text_fecha"           
                        title={<Typography>{this.props.formatoFechaDisponible}</Typography>}
                        action={action}
                      /> 
                      <CardContent >
                        {
                          this.props.periodoList.map((item,index)=>{
                            return  <HorarioList  
                                      key={index}
                                      horaInicial={item.horaInicial} 
                                      horaFinal={item.horaFinal} 
                                      idDia={item.DIA_SEMANA_id}           
                                    
                                      codColor={this.props.codColor}
                                      duracionTipoCita={this.props.duracionTipoCita}

                                      fecha={this.props.fecha} 
                                      fechaBusqueda={this.props.fechaBusqueda} 
                                      setFieldValue={this.props.setFieldValue} 
                                      horaInicio={this.props.horaInicio} 
                                      horaFin={this.props.horaFin}
                                      diaBusquedaSelecionado={this.props.diaBusquedaSelecionado}

                                  />

                          }

                          )
                        }
                      </CardContent>
                      </Card>
                    </Grid> 
            
                      
    }                          
}

/*  

                                      horaInicial={item.horaInicial} 
                                      horaFinal={item.horaFinal} 
                                      idDia={item.DIA_SEMANA_id} 
                   <HorarioList  
                                      horaInicial={this.props.horaInicial} 
                                      horaFinal={this.props.horaFinal} 
                                      idDia={this.props.idDia} 
                                    
                                      codColor={this.props.codColor}
                                      duracionTipoCita={this.props.duracionTipoCita}

                                      fecha={this.props.fecha} 
                                      fechaBusqueda={this.props.fechaBusqueda} 
                                      setFieldValue={this.props.setFieldValue} 
                                      horaInicio={this.props.horaInicio} 
                                      horaFin={this.props.horaFin}
                                      diaBusquedaSelecionado={this.props.diaBusquedaSelecionado}
                                      duracionTipoCita={this.props.duracionTipoCita}

                                  />
   
*/
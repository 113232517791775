import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {MOVIMIENTO,INSTITUCION,TECNICO,CAT_TIPO_MOVIMIENTO,PRODUCTO} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";


const validationSchema = yup.object({
		nombre: yup.string().required("Campo requerido"),
		descripcion: yup.string().required("Campo requerido"),
		cantidad: yup.string().required("Campo requerido"),
		PRODUCTO_id: yup.string().required("Campo requerido"),
		CAT_TIPO_MOVIMIENTO_id: yup.string().required("Campo requerido"),
		TECNICO_id: yup.string().required("Campo requerido"),
		INSTITUCION_id: yup.string().required("Campo requerido")
})

class MovimientoForm extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }
    
    goBack(){
        this.props.history.goBack();
    }

    render(){        
        return <Formik

                    initialValues={{
		'nombre': '',
		'descripcion': '',
		'cantidad': '',
		'PRODUCTO_id': '',
		'CAT_TIPO_MOVIMIENTO_id': '',
		'TECNICO_id': '',
		'INSTITUCION_id': ''
                                   }}

                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          setSubmitting(this.props.agregarMovimientoWSAction(MOVIMIENTO,values));
                          console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          resetForm({values:""});
                          this.goBack();

                    }}
                >
                    {props => < Page {...props}  producto={this.props.producto}  catTipoMovimiento={this.props.catTipoMovimiento}  tecnico={this.props.tecnico}  instituciones={this.props.instituciones}   modal={this.props.modal} cerrarModal={this.props.cerrarModal} />}
                </Formik> 
    }
}

const mapStateToProps=(state)=>({
  producto:state.ProductoReducer[PRODUCTO],
  catTipoMovimiento:state.CattipomovimientoReducer[CAT_TIPO_MOVIMIENTO],
  tecnico:state.TecnicoReducer[TECNICO],
  instituciones:state.InstitucionReducer[INSTITUCION],
})

export default withRouter(connect(mapStateToProps)(MovimientoForm));

import React,{Component} from "react";
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';

import PeriodoDiaDialog from "../PeriodoDiaDialog";
import PeriodoDiaEditDialog from "../PeriodoDiaEditDialog";
import Paper from '@material-ui/core/Paper';
import DiaSemanaCardForm from "../DiaSemanaCardForm";
import { AccordionActions, Badge, Button, Chip, Divider } from "@material-ui/core";
import { ESQUEMA_TIPO_CITA } from "../../../redux/constants/action-type";
import ConfirmacionDialog from "./ConfirmacionDialog";


const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 12,
    spacing: 8,

  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 12,
  },
  pos: {
    marginBottom: 12,
  },
  details: {
    alignItems: 'center',
    backgroundColor:"#f5f5f5"

  },
});

export default function Page(props) {
  
  const classes =useStyles();

  const handleClick=()=>{
    props.handleClick();
  }
    
  const [expanded, setExpanded] = React.useState(false);
  const [modalConfirmacion,setModalConfirmacion] =React.useState(false);

  const handleClickExpanded = () => {
    setExpanded((prev) => !prev);
  };

  const handleClickAceptar = () => {
    console.log("expanded:"+expanded);
    setExpanded(false);
  };

  const handleClickEliminar = () => {
    console.log("handleClickEliminar(esquemaTipoCitaItem)):"+JSON.stringify(props.esquemaTipoCitaItem));
    props.eliminarEsquemaTipoCitaWSAction(ESQUEMA_TIPO_CITA,props.esquemaTipoCitaItem);
  };

  const handleClickConfirmar = () => {
    setModalConfirmacion(true);
  };

  //  props.handleEliminarPeriodoClick
  

    let nombreEsquemaTipoCita="";
    let duracionTipoCita="";

    let codigoColorTipoCita="#ffffff";
    if(props.esquemaTipoCitaItem.CAT_TIPO_CITA_id!==undefined && props.cat_tipo_cita!==undefined){
        let cat_tipo_citaItem=props.cat_tipo_cita.filter(item => item.id === props.esquemaTipoCitaItem.CAT_TIPO_CITA_id );
        console.log("cat_tipo_citaItem>>><<<<"+JSON.stringify(cat_tipo_citaItem));
        console.log("cat_tipo_citaItem>>><<<<"+cat_tipo_citaItem.length);

        if(cat_tipo_citaItem.length >0){
          nombreEsquemaTipoCita=cat_tipo_citaItem[0].nombre;
          duracionTipoCita=cat_tipo_citaItem[0].duracion+"min";
          codigoColorTipoCita=props.colores.filter(color=>color.id===cat_tipo_citaItem[0].COLOR_id).map(item=>item.color);  
                
        }
    }
    console.log("nombreEsquemaTipoCita>>><<<<"+nombreEsquemaTipoCita);
    console.log("nombreEsquemaTipoCita (props.esquemaTipoCitaItem.CAT_TIPO_CITA_id)>>><<<<"+props.esquemaTipoCitaItem.CAT_TIPO_CITA_id);
    console.log("nombreEsquemaTipoCita>>><<<<"+JSON.stringify(props.cat_tipo_cita));


        return ( <Box mt="1rem" >
                    <Accordion elevation="3" expanded={expanded}>
                      <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      IconButtonProps={{
                        onClick: handleClickExpanded
                      }}
                      
                      //style={{backgroundColor: codigoColorTipoCita ,color:"white"}} 

                    >
                    <Badge variant="standard" 
                          color="primary" 
                          anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        badgeContent={duracionTipoCita} 
                    >
                      <Typography style={{color:codigoColorTipoCita,paddingTop:"6px"}}>{nombreEsquemaTipoCita}</Typography>

                    </Badge>                    
                    </AccordionSummary>
                    <AccordionDetails style={{display:"block"}} className={classes.details}>
                    <Box mt="1rem">
                    {  /* SE MUESTRAN TODAS LOS DIAS DE LA SEMANA PARQUE SE AGREGEN LOS HORARIOS */
                                    props.cat_dia_semana.map(dia_semanaItem=> 
                                      <Paper spacing={3} style={{marginTop:"1rem"}}> 
                                        <DiaSemanaCardForm  esquemaTipoCitaHorarioList={props.esquemaTipoCitaHorarioList}
                                                            setEsquemaTipoCitaHorario={props.setEsquemaTipoCitaHorario}
                                                            dia_semanaItem={dia_semanaItem} 
                                                            setOpenPeriodoDialog={props.setOpenPeriodoDialog}
                                                            setOpenPeriodoEditDialog={props.setOpenPeriodoEditDialog}
                                                            setPeriodo={props.setPeriodo}
                                                            setHorario={props.setHorario}
                                                            esquemaTipoCitaItem={props.esquemaTipoCitaItem}
                                                            handleEliminarPeriodoClick={props.handleEliminarPeriodoClick}
                                        />
                                      </Paper>    
                                      )
                          }    
                    </Box>
                    </AccordionDetails>
                    <Divider />
                    <AccordionActions>
                      <Button size="small" color="secondary" onClick={handleClickConfirmar}>Eliminar</Button>
                      <Button size="small" color="primary" onClick={handleClickAceptar}>
                        Aceptar
                      </Button>
                    </AccordionActions>
                    </Accordion>

                    <PeriodoDiaDialog 
                        dia={props.dia}
                        setOpenPeriodoDialog={props.setOpenPeriodoDialog} 
                        openPeriodoDialog={props.openPeriodoDialog} 
                        handleClick={handleClick}
                        periodo={props.periodo}
                        setPeriodo={props.setPeriodo}
                        esquemaTipoCitaHorarioList={props.esquemaTipoCitaHorarioList} /* Lista de horarios ya existentes se envia como parametro para realizar la validadcion */
                        horario={props.horario}
                        esquemaTipoCitaHorario={props.esquemaTipoCitaHorario}
                      />

                    <PeriodoDiaEditDialog 
                        dia={props.dia}
                        setOpenPeriodoEditDialog={props.setOpenPeriodoEditDialog} 
                        openPeriodoEditDialog={props.openPeriodoEditDialog} 
                        handleEditClick={props.handleEditClick}
                        periodo={props.periodo}
                        setPeriodo={props.setPeriodo}
                        esquemaTipoCitaHorarioList={props.esquemaTipoCitaHorarioList} /* Lista de horarios ya existentes se envia como parametro para realizar la validadcion */
                        horario={props.horario}
                        esquemaTipoCitaHorario={props.esquemaTipoCitaHorario}
                      />  

                      <ConfirmacionDialog 
                          modalConfirmacion={modalConfirmacion}   
                          setModalConfirmacion={setModalConfirmacion}
                          handleClickEliminar={handleClickEliminar} 
                          />
                          
                  </Box>)
    
}


import React,{useState } from "react";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CropFreeIcon from '@material-ui/icons/CropFree';
import { Fade, useScrollTrigger, Zoom } from "@material-ui/core";
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import { memo } from "react";

function Page(props){


  const ScrollTop=(props)=>{

    const { window } = props;
    
    const trigger=useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 100,
    });

 console.log("trigger ScrollTop:"+trigger);
    return(
           <div>
              <Fade in={!trigger}>
                <Fab
                  position="fixed"
                  variant="extended"
                  size="large"
                  color="primary"
                  aria-label="QR"
                  style={{position: 'fixed', bottom: "5.5rem", right: "1rem",boxShadow:"1",backgroundColor:"#115293",zIndex:"10"}}
                  onClick={() => props.setQrmodal(true)}                            
                  >
                    <PhonelinkRingIcon style={{ borderWidth:"1",width: "1.5rem",height: "1.5rem"}} />
                    <Typography style={{textTransform: "capitalize",marginRight:"2px"}}>QR</Typography>
                </Fab>
              </Fade>
            
            </div>
    )
  }

      return(<ScrollTop {...props}>                
                    
              </ScrollTop>
            );                       
}
export default memo(Page);
import {connect} from "react-redux";
import React,{Component} from "react";
import { CITA, INSTITUCION, MEDICO, MEDICO_USUARIO, PACIENTE,CAT_TIPO_CITA,COLOR,CAT_ESTADO_CITA } from "../../../redux/constants/action-type";
import Page from "./page";
import { PROFIL_ADMINISTRADOR, PROFIL_MEDICO } from "../../../utils/constantes";
import { actualizarRegistrosAction } from "../../../redux/actions/stateActions";

class TablaContentCell extends Component{  

    render(){
        let colorItem=[];
        if(this.props.catTipoCita!==undefined){
            colorItem=this.props.colores.filter(i => i.id === this.props.catTipoCita.COLOR_id)[0];
        }

        let codColor="";
        if(colorItem!==undefined){
            codColor=colorItem.color;
        }

        return <Page cita={this.props.cita} 
                     paciente={this.props.paciente} 
                     catTipoCita={this.props.catTipoCita} 
                     catEstadoCita={this.props.catEstadoCita}
                     codColor={codColor}                         
                     medico={this.props.medico}
                     index={this.props.index}
                     rowsPerPage={this.props.rowsPerPage}
                     setOpen={this.props.setOpen}
                     setMensaje={this.props.setMensaje}
                     setEnSala={this.props.setEnSala}
                     enSala={this.props.enSala}
                     setPlayListoEnSala={this.props.setPlayListoEnSala} 
                     okSala={this.props.okSala} 
                     setOkSala={this.props.setOkSala}
                     
                     />
    }

}


const mapStateToProps=(state,ownProps)=>({
/*    profil:state.autenticacionReducer.profil,     
    medicoUsuario:state.MedicoUsuarioReducer[MEDICO_USUARIO][0],  
    institucion:state.InstitucionReducer[INSTITUCION][0],*/
    colores:state.ColorReducer[COLOR],
    catTipoCita:state.CatTipoCitaReducer[CAT_TIPO_CITA].filter(p => p.id === ownProps.cita.CAT_TIPO_CITA_id)[0],
    catEstadoCita:state.CatEstadoCitaReducer[CAT_ESTADO_CITA].filter(p => p.id === ownProps.cita.CAT_ESTADO_CITA_id)[0],
    paciente: state.PacienteReducer[PACIENTE].filter(p => p.id === ownProps.cita.PACIENTE_id)[0],
    medico:state.MedicoReducer[MEDICO].filter(m => m.id === ownProps.cita.MEDICO_id)[0],

})

const mapDispatchToProps=(dispatch)=>({
	actualizarRegistrosAction: (type,registros) => dispatch(actualizarRegistrosAction(type,registros)),

})

export default connect(mapStateToProps,mapDispatchToProps)(TablaContentCell);
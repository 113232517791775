import React,{Component } from "react";
import Page from "./page";

class MenuPrincipalList extends Component{
    render(){     
        return  <Page opcion={this.props.opcion}
                      handleDrawerToggleClose={this.props.handleDrawerToggleClose}
                      logout={this.props.logout}
                      />    
    }
}


export default MenuPrincipalList;

import { Box, Card, CardContent, Container, makeStyles, Paper, Typography, withStyles } from "@material-ui/core";
import React,{Component, useState} from "react";
import { MODO_VISTA_AGENDADOS, MODO_VISTA_DIA, MODO_VISTA_MES, MODO_VISTA_SEMANA } from "../../../utils/constantes";
import Cita from "../../CitaComponent/Cita";
import CalendarioEncabezado from "../CalendarioEncabezado";
import CalendarioGrid from "../CalendarioGrid";
import DiaGrid from "../DiaGrid";
import SemanaGrid from "../SemanaGrid";

//console.log("heigt(calendario):"+calc(100% - 240px));

function Page(props){   


      return<div style={{marginBottom:"4rem" , marginTop:"64px",maxHeight:"calc(100% - 64px)" }}> 
              <CalendarioEncabezado 
                  hoy={props.hoy}
                  anteriorHandler={props.anteriorHandler}
                  siguienteHandler={props.siguienteHandler}
                  hoyHandler={props.hoyHandler}
                  modoVista={props.modoVista}
                  setModoVista={props.setModoVista}
               />
                  {
                        props.modoVista===MODO_VISTA_DIA
                              ?
                              <div>
                                    <DiaGrid 
                                          hoy={props.hoy}
                                          diaInicial={props.diaInicial.clone()}
                                          diaInicialDia={props.diaInicial}

                                    />
                              </div>
                              :null
                  }
                  {
                        props.modoVista===MODO_VISTA_SEMANA
                              ?
                              <div>
                                    <SemanaGrid 
                                          hoy={props.hoy}
                                          diaInicial={props.diaInicial.clone()} //Se envia un a copoia ya que el momento queda en otro dia el final del array
                                          diaInicialSemana={props.diaInicial}
                                    />
                              </div>      
                              :null
                  }
                  {
                        props.modoVista===MODO_VISTA_MES
                              ?
                                    <CalendarioGrid 
                                          hoy={props.hoy}
                                          diaInicial={props.diaInicial}
                                    />
                              :null
                  }
                   {
                        props.modoVista===MODO_VISTA_AGENDADOS
                              ?
                                    <Cita/>                             
                              :null
                  }
           </div>
}

export default Page;

import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import { PACIENTE,CAT_TIPO_CITA,COLOR, MEDICO } from "../../../redux/constants/action-type";
import {obtenerColorListWSAction} from "../../../redux/actions/ColorActions";


class CitaListItem extends Component{
    
    render(){    

        let colorItem=[];
        if(this.props.catTipoCita!==undefined){
            colorItem=this.props.colores.filter(i => i.id === this.props.catTipoCita.COLOR_id)[0];
        }

        let codColor="";
        if(colorItem!==undefined){
            codColor=colorItem.color;
        }

        return< Page    cita={this.props.cita} 
                        paciente={this.props.paciente} 
                        abrirModalEditar={this.props.abrirModalEditar}  
                        catTipoCita={this.props.catTipoCita} 
                        codColor={codColor}                         
                        medico={this.props.medico}
                        index={this.props.index}/>
    }



}

const mapStateToProps = (state, ownProps) => ({
  colores:state.ColorReducer[COLOR],
  catTipoCita:state.CatTipoCitaReducer[CAT_TIPO_CITA].filter(p => p.id === ownProps.cita.CAT_TIPO_CITA_id)[0],
  paciente: state.PacienteReducer[PACIENTE].filter(p => p.id === ownProps.cita.PACIENTE_id)[0],
  medico:state.MedicoReducer[MEDICO].filter(m => m.id === ownProps.cita.MEDICO_id)[0],
 })

 


 export default connect(mapStateToProps,null)(CitaListItem);
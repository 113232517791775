import React,{Component} from "react";
import CitaListItem from "../CitaListItem";
import List from '@material-ui/core/List';
import CitaSubList from "../CitaSubList";

export default class Page extends Component{

  constructor(props) {
    super(props);    
    }
    

    render(){   
      let fecha="";// new Date().toISOString().substring(0,10);


      return <List>
              {
                Object.keys(this.props.citaesFilter).map(fecha=>{ 
                  return <CitaSubList fecha={fecha} citas={this.props.citaesFilter[fecha]} abrirModalEditar={this.props.abrirModalEditar}/>
                })

              }
            </List>
    }                          
}
import React,{Component} from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
//import Select from '@material-ui/core/Select';
import Select, { Option, ReactSelectProps } from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withRouter} from "react-router-dom";
import Divider from '@material-ui/core/Divider';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ListItemText from '@material-ui/core/ListItemText';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  function Page(props) {
		
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	function goBack(){
		props.history.goBack();
	}

	let appBar="";
	let actions="";

	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={goBack} aria-label="close">
						<ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
						</IconButton>
						<Typography variant="h6" style={{ flexGrow: 1}} >
							Nuevo Tipo de Cita
						</Typography>

						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
						<Button variant="contained"  edge="start" color="default" onClick= {props.cerrarModal}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
	}





	function handleChange(e){
		console.log("e.target.selectedOptions:"+e.target.selectedOptions);
		console.log("e.target.selectedOptions:"+e.target.value);
		props.setFieldValue(
			"COLOR_id",e.target.value);	
	  }
	  
        return (<form onSubmit={props.handleSubmit}> 
                    <Dialog
                    open={props.modal}
                    onClose={props.cerrarModal}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
					fullScreen={fullScreen}


                    >
                   
                        <DialogTitle id="form-dialog-title">Nuevo Tipo de Cita</DialogTitle>
                        
						{appBar}


							<DialogContent>
							<TextField
								error={props.touched.nombre&&props.errors.nombre? true:false}
								helperText={props.touched.nombre&&props.errors.nombre?props.errors.nombre:""}
								size="medium"
								margin="normal"
								id="nombre"
								label="Nombre Tipo de Cita:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.nombre}
								onBlur={props.handleBlur}
							/>
							<TextField
								error={props.touched.descripcion&&props.errors.descripcion? true:false}
								helperText={props.touched.descripcion&&props.errors.descripcion?props.errors.descripcion:""}
								size="medium"
								margin="normal"
								id="descripcion"
								label="Descripcion Tipo de Cita:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} 
								value={props.values.descripcion}
								onBlur={props.handleBlur}
							/>
							<TextField
								error={props.touched.duracion&&props.errors.duracion? true:false}
								helperText={props.touched.duracion&&props.errors.duracion?props.errors.duracion:""}
								size="medium"
								margin="normal"
								id="duracion"
								label="Duracion en minutos de la Cita:"
								type="number"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} 
								value={props.values.duracion}
								onBlur={props.handleBlur}
								autoComplete="off"
								InputProps={{
									endAdornment: <InputAdornment position="end">(minutos)</InputAdornment>
								  }}
							/>
							<FormControl
							fullWidth
							margin="normal"
							
							>
							    <InputLabel htmlFor="age-native-simple"> Color asignado a la Cita:</InputLabel>
								<Select
									error={props.touched.COLOR_id&&props.errors.COLOR_id? true:false}
									size="medium"
									id="COLOR_id"
									label="COLOR_id:"
									type="text"
									fullWidth
									variant="outlined"
									onChange={handleChange} 
									value={props.values.COLOR_id}
									onBlur={props.handleBlur}
									
								>						
									{props.color.map((color)=> (
										<MenuItem key={color.id} value={color.id} >
										 <FiberManualRecordIcon style={{color:color.color,marginRight:"1rem"}}/>
										 <ListItemText primary={color.color} />
										</MenuItem>
									))}
								</Select>

							</FormControl>
                            </DialogContent>
							{actions}                

                    </Dialog>
                    </form>
                       
                )
    
}
export default  withRouter(Page);

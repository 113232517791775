import React from "react";
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import runImg from '../../logo/logo.ico'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SnackbarsMensaje from "../SnackbarsMensajeComponent";
import {getFechaHoyFormato, getFechaHoytoLocaleDateString} from "../../utils/date";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CloseIcon from '@material-ui/icons/Close';
import {Link} from "react-router-dom";
import logoBS from '../../logo/logo.ico'
import logoInstitucion from '../../logo/especializada.jpg'
import MenuPrincipalList from "./MenuPrincipalList";
import { getColorSemana } from "../../utils/utils";
import { getFechaHoytoLocaleDateSemanaString } from "../../utils/date"; 
import Search from "@material-ui/icons/Search";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import { Badge, Collapse } from "@material-ui/core";
import { PROFIL_ADMINISTRADOR } from "../../utils/constantes";
import { useEffect } from "react";
import usuarioPNG from "../../icons/usuario.png";


const StyledBadge = withStyles((theme) => ({
  badge: {
    top: 25,
    border: `1px solid ${theme.palette.background.paper}`,
    zIndex:"0",
    backgroundColor:"#1dbd9e",
    color:"#ffffff",
  },
}))(Badge);

  const Page = (props) => {

      const drawerWidth = 240;

      const [isBusqueda, setIsBusqueda] = React.useState(false);

      const toggleIsBusqueda = () => {
        console.log("isBusqueda:"+isBusqueda);
        setIsBusqueda(!isBusqueda);
        console.log("isBusqueda:"+isBusqueda);
      };
      const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
      };
      
      const handleDrawerToggleClose = () => {
        setMobileOpen(false);
      };

      const logoutHandler =(e) => {
        props.history.push('/login');
        props.logout();
        props.history.replace("/login");
    
      };

      const useStyles = makeStyles((theme) => ({
            root: {
              display: 'flex',
              backgroundColor:"red"
            },
            drawer: {
              [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,

              },
            },
            appBar: {
              [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
              },
              boxShadow: "none",
              backgroundColor:"#FFFFFF",
              borderWidth:"1",
              marginTop: "0rem",
            },
            menuButton: {
              marginRight: theme.spacing(2),
              [theme.breakpoints.up('sm')]: {
                display: 'none',
              },
            },
            // necessary for content to be below app bar
            toolbar: theme.mixins.toolbar,
            drawerPaper: {
              width: drawerWidth,
            },
            content: {
              flexGrow: 1,
              padding: theme.spacing(3),
              backgroundColor:"red",
            },
            nested: {
              paddingLeft: theme.spacing(4),
            },
            sectionDesktop: {
              display: 'none',
              [theme.breakpoints.up('md')]: {
                display: 'flex',
              },
            },
          }));

      const classes = useStyles();

      let  encabezado=(<div className={classes.toolbar} >
                          <Container maxWidth="sm">
                              <Grid container wrap="nowrap" spacing={2} margin={2}>
                              <Grid item mt={13}>
                                  <Avatar alt="Bildsoft" src={runImg}  style={{width: "3rem",height: "3rem"}}/> 
                              </Grid>
                              <Grid item >
                              <Typography type="Roboto" variant="subtitle1" style={{ fontWeight:"600" }}>
                                  <Box  m={2}>
                                    InnovAgenda®
                                  </Box>
                              </Typography>
                              </Grid>
                              
                              </Grid>
                          </Container>
                      </div>);
      let opcion=props.tipoSeleccion;
      let registros=props.registros;
      let autenticado=props.autenticado;
      let notificaciones="";
      
      if(props.profil===PROFIL_ADMINISTRADOR){ 
        notificaciones= <Box ml={0}>
                          <IconButton  color="inherit" m={0} p={0} >
                            <StyledBadge badgeContent={4} color="primary">
                              <AssignmentInd />
                            </StyledBadge>  
                          </IconButton>                                  
                        </Box>

      }                                           

      

      const [mobileOpen, setMobileOpen] = React.useState(false);


      const usuario=(<div>
                      <Divider />
                      <Box style={{textAlign:"center",paddingTop:"0.1rem"}}>
                        <Chip 
                                size="medium"
                                label={localStorage.urs}
                                variant="outlined"
                                icon={<img src={usuarioPNG} style={{ width: "1.1rem",height: "1.1rem", color:"#1e88e5"}}/>}
                                position="rigth"
                                />

                      </Box> 
                      <MenuPrincipalList opcion={opcion} handleDrawerToggleClose={handleDrawerToggleClose} logout={props.logout}/>
                    </div>);

      const invitado=(<div>
                        <Divider />
                      </div>);                            

      const container = window !== undefined ? () => window.document.body : undefined;
      const theme = useTheme();

      useEffect(() => {
        console.log("isBusqueda: (useEffect)"+isBusqueda);
        props.mostrarBusqueda(isBusqueda);

      });

      return  <div className={classes.root} >
                <CssBaseline />               
                <AppBar  position="fixed"  className={classes.appBar} >
                  <Toolbar>                                                                                    
                    {autenticado?   
                            <Grid container  alignItems="center" p={0} m={0} style={{display:"flex",alignContent:"center",width:"100%",backgroundColor:"#ffffff"}}>   
                                <Grid item xs={4}>
                                  <Box style={{textAlign:"left"}} >
                                    <IconButton edge="start"  aria-label="menu"  onClick={handleDrawerToggle} className={classes.menuButton}>
                                      <MenuIcon color="primary"  style={{  borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
                                    </IconButton>
                                  </Box>
                                </Grid>
                                <Grid item xs={4}>
                                  <Box style={{textAlign:"center"}} >
                                      <Chip 
                                        variant="default" 
                                        color="primary"   
                                        avatar={<Avatar  
                                                    variant="circular" 
                                                    color="primary"
                                                >                                                            
                                                  {(registros>99)?"99+":registros}
                                                </Avatar>} 
                                          label={
                                                  <Typography variant="body2"  style={{textTransform: "capitalize"}}>
                                                          {opcion==="instituciones"?"Hospitales":opcion}
                                                  </Typography>
                                                } 
                                          style={{textAlign:"center",alignItems:"center",alignContent:"center",alignSelf:"center"}}
                                          size="medium"
                                      />                                   
                                   
                                  </Box>
                                </Grid>
                                <Grid item xs={4} p={0} m={0} style={{color:"#000000"}} >
                                  <Box display="flex"  p={0} m={0} style={{textAlign:"right"}} justifyContent="flex-end" alignContent="flex-end">                                      
                                    <Box ml={0}>
                                      <IconButton color="inherit" m={0} p={0} onClick={toggleIsBusqueda} size="medium">
                                        {isBusqueda?<Collapse in={isBusqueda}>
                                                      <CloseIcon m={0} p={0} color="secondary"/>
                                                    </Collapse>
                                                   :<Collapse in={!isBusqueda}>
                                                     <Search  m={0} p={0} color="primary" />
                                                    </Collapse>
                                        }
                                        
                                        
                                      </IconButton>              
                                    </Box>                                                                         
                                    <Hidden xsDown implementation="css">
                                      <Box p={1} flexGrow={1}>
                                          <Button color="default"  onClick={props.logout} href="/login"  >Cerrar Sesion</Button>
                                      </Box>
                                    </Hidden> 

                                  </Box>
                              </Grid>
                            </Grid>
                        :<div>
                            <Hidden xsDown implementation="css">
                              <Button color="primary" component={Link} to="/login">Iniciar sesión</Button>
                            </Hidden> 
                         </div> 
                   }
                              
                  </Toolbar>
                  <Divider />
                </AppBar>
                <nav className={classes.drawer} aria-label="mailbox folders">
                  <Hidden smUp implementation="css">
                                              
                      <Drawer
                      container={container}
                      variant="temporary"
                      anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                      open={mobileOpen}
                      onClose={handleDrawerToggle}
                      classes={{
                        paper: classes.drawerPaper,
                      }}
                      ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                      }}                    
                      >
                        {encabezado}
                        {autenticado?usuario:invitado}
                      </Drawer>
                  </Hidden>
                  <Hidden xsDown implementation="css">
                      <Drawer
                          classes={{
                              paper: classes.drawerPaper,
                          }}
                          variant="permanent"
                          open
                      >
                          {encabezado}
                          {autenticado?usuario:invitado}
                      </Drawer>
                  </Hidden>
                  <SnackbarsMensaje />
                </nav>                  
             </div>   
  };
export default Page;
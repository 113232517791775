import React,{Component} from "react";

export default class Page extends Component{

    render(){
        return <div className="my-3 m-3  bg-light" >
                 <h2 className="text-center"> Bienvenido </h2>
               </div>
    }
}

import {CONSULTAR_PRODUCTOS,AGREGAR_PRODUCTO,ACTUALIZAR_PRODUCTO, ELIMINAR_PRODUCTO} from "../constants/Producto-action-type";


/* PRODUCTOS (HOSPITAL CLINICA) */
export const obtenerProductoListWSAction = (dataType)=> {
    return {
        type:CONSULTAR_PRODUCTOS,
        dataType:dataType,
        loading: true
    }
}

export const agregarProductoWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_PRODUCTO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarProductoWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_PRODUCTO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarProductoWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_PRODUCTO,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

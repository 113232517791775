import React,{Component } from "react";
import Page from "./page";
import {connect} from "react-redux";
import {RECORDATORIO,CITA,CAT_ESTADO_CITA,CAT_TIPO_CITA,PACIENTE,MEDICO,ESQUEMA_TRABAJO, COLOR} from "../../../redux/constants/action-type";
import {obtenerMedicoListWSAction,agregarMedicoWSAction,actualizarMedicoWSAction,eliminarMedicoWSAction} from "../../../redux/actions/MedicoActions";
import {obtenerPacienteListWSAction,agregarPacienteWSAction,actualizarPacienteWSAction,eliminarPacienteWSAction} from "../../../redux/actions/PacienteActions";
import {obtenerCatEstadoCitaListWSAction,agregarCatEstadoCitaWSAction,actualizarCatEstadoCitaWSAction,eliminarCatEstadoCitaWSAction} from "../../../redux/actions/CatEstadoCitaActions";
import {obtenerCatTipoCitaListWSAction,agregarCatTipoCitaWSAction,actualizarCatTipoCitaWSAction,eliminarCatTipoCitaWSAction} from "../../../redux/actions/CatTipoCitaActions";
import {obtenerRecordatorioListWSAction,agregarRecordatorioWSAction,actualizarRecordatorioWSAction,eliminarRecordatorioWSAction} from "../../../redux/actions/RecordatorioActions";
import {obtenerCitaListWSAction,agregarCitaWSAction,actualizarCitaWSAction,eliminarCitaWSAction} from "../../../redux/actions/CitaActions";
import {obtenerColorListWSAction} from "../../../redux/actions/ColorActions";
import { setModoVista } from "../../../redux/actions/stateActions";


class Citaes extends Component{

    constructor(props) {
        super(props);
        this.state={
                modal:false,
                modalEditar:false,
                payload: {
					'MEDICO_id': '',
					'PACIENTE_id': '',
					'CAT_ESTADO_CITA_id': '',
					'CAT_TIPO_CITA_id': '',
					'fecha': '',
					'horaInicio': '',
					'horaFin': '',
					'RECORDATORIO_id': ''
                },
                busqueda:""

        };
    }
     
    abrirModal=()=> {
        this.setState({
            modal:true
        });
    }

     cerrarModal=()=> {
        this.setState({
            modal:false
        });
    }

    abrirModalEditar = (value)=> {
        this.setState({
            modalEditar:true,
            payload: value

        });
    };

    cerrarModalEditar=()=> {
        this.setState({
            modalEditar:false
        });
    }

    buscar=(valor)=> {
        this.setState({
            busqueda:valor
        });
    }


    render(){     
        console.log("CITAS EN COMPONENTE:"+JSON.stringify(this.props.citaes));   
        return  <Page 
                    citaes={this.props.citaes} 
                    loading={this.props.loading} 
                    modal={this.state.modal}
                    modalEditar={this.state.modalEditar}
                    payload={this.state.payload}
                    abrirModal={this.abrirModal}
                    cerrarModal={this.cerrarModal}
                    abrirModalEditar={this.abrirModalEditar}
                    cerrarModalEditar={this.cerrarModalEditar}
                    tipoSeleccion={this.props.tipoSeleccion}
                    agregarCitaWSAction={this.props.agregarCitaWSAction}
                    actualizarCitaWSAction={this.props.actualizarCitaWSAction}
                    eliminarCitaWSAction={this.props.eliminarCitaWSAction}   
                    buscar={this.buscar} 
                    busqueda={this.state.busqueda}
                    profil={this.props.profil}
                    mostrarBusqueda={this.props.mostrarBusqueda}
                    modoVista={this.props.modoVista}
                    setModoVista={this.props.setModoVista}
                />    
    }

 /* esta es la accion de middleware*/
        componentDidMount(){ 
            console.log(">>>> componentDidMount:"+this.props);
            console.log(">>>> componentDidMount:"+this.props.location);

            let esquemaTrabajo=this.props.esquemaTrabajos;                  
            let valores=undefined;
            if(this.props.location!==undefined){
                valores=this.props.location.valores;  
            }



            console.log(">>>> esquemaTrabajo:"+esquemaTrabajo);


            if(esquemaTrabajo!==undefined){
                console.log(">>>> esquemaTrabajo:"+esquemaTrabajo.length);

               // if(esquemaTrabajo.length!==0){
                    if(valores === undefined){
                        this.props.obtenerCitaListWSAction(CITA,true); /* Invoca por primara vez a productos (default) */
                        this.props.obtenerPacienteListWSAction(PACIENTE,false); /* Invoca por primara vez a productos (default) */
                        this.props.obtenerMedicoListWSAction(MEDICO,false); /* Invoca por primara vez a productos (default) */
                        this.props.obtenerColorListWSAction(COLOR,false); /* Invoca por primara vez a productos (default) */	
                        this.props.obtenerCatEstadoCitaListWSAction(CAT_ESTADO_CITA,false);

                    }else{
                        this.setState(this.props.location.valores);
                    }
    
               // }
                /*else{
                    this.props.history.push('/esquemas');
                }*/
    
            }

        }



}

/*
funcion que se utilza para regresar el estado del sotre y hacerlo dispobible
*/
const mapStateToProps=(state)=>({
    esquemaTrabajos:state.EsquemaTrabajoReducer[ESQUEMA_TRABAJO], 
    citaes:state.CitaReducer[CITA],    
    loading:state.estadosPeticionReducer.loading,
    tipoSeleccion:state.estadosReducer.selectedType,
    mostrarBusqueda:state.estadosReducer.mostrarBusqueda,
    catEstadosCita:state.CatEstadoCitaReducer[CAT_ESTADO_CITA],
    profil:state.autenticacionReducer.profil,   //(23oct22)Para poner el floar de QR solo al perfil de ADMINISTRADOR  
    modoVista:state.estadosReducer.modoVista,

})

/*
permite que una funcion o llamada a funcion esten disponibles como un props
regresa una funcion creadas en actions
*/
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        obtenerCitaListWSAction: (type,loading) => dispatch(obtenerCitaListWSAction(type,loading)),
        obtenerPacienteListWSAction: (type,payload) => dispatch(obtenerPacienteListWSAction(type,payload)),
        obtenerMedicoListWSAction: (type,loading) => dispatch(obtenerMedicoListWSAction(type,loading)),
        obtenerColorListWSAction: (type,loading) => dispatch(obtenerColorListWSAction(type,loading)),
        obtenerCatEstadoCitaListWSAction: (type,loading) => dispatch(obtenerCatEstadoCitaListWSAction(type,loading)),
        setModoVista:(payload)=>dispatch(setModoVista(payload))

    //    obtenerMedicoListWSAction: (type,loading) => dispatch(obtenerMedicoListWSAction(type,loading)),
    //    obtenerPacienteListWSAction: (type,loading) => dispatch(obtenerPacienteListWSAction(type,loading)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Citaes);



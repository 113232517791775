import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import { HORARIO,PERIODO } from "../../../redux/constants/action-type";
import {isHorarioInicialMenorFinal,isHorarioDesdeContenidoSolo,isHorarioHastaContenidoSolo} from "../../../utils/moment"
import {mostrarMensaje} from "../../../redux/actions/stateActions"

class PeriodoDiaDialog extends Component{
    constructor(props) {
        super(props);
	}


	setHoraInicial=(valor)=>{
		var periodo ={};
		periodo["horaInicial"]=valor;
		periodo["horaFinal"]=this.props.periodo.horaFinal;
		this.props.setPeriodo(periodo);
	}
	
	setHoraFinal=(valor)=>{
		var periodo ={};
		periodo["horaInicial"]=this.props.periodo.horaInicial;
		periodo["horaFinal"]=valor;
		this.props.setPeriodo(periodo);
	}

	isHorarioCorrecto=()=>{
		if(this.props.periodo!==undefined){
			console.log("PERIODO::"+JSON.stringify(this.props.periodo));
			return !isHorarioInicialMenorFinal(this.props.periodo.horaInicial,this.props.periodo.horaFinal);	
		}
	}

	existeHorario=()=>{

		console.log("this.props.horarioList:"+JSON.stringify(this.props.horarioList));

		let horariosValidos=this.props.horarioList.filter(item=>{
			let itemValido=this.props.esquemaTipoCitaHorarioList.filter(i=>i.HORARIO_id==item.id)[0]
			return itemValido;

		}
		);

		console.log("horariosValidos:"+JSON.stringify(horariosValidos));

		let horariosPorDiaSelecionado=horariosValidos.filter(item=>
		item.DIA_SEMANA_id==this.props.horario.DIA_SEMANA_id
		);

		console.log("horariosPorDiaSelecionado:"+JSON.stringify(horariosPorDiaSelecionado));

		let periodosOcupados=this.props.periodoList.filter(item=>{
			let itemValido=horariosPorDiaSelecionado.filter(i=>i.PERIODO_id==item.id)[0]
			return itemValido;
		}
		);

		console.log("periodosOcupados:"+JSON.stringify(periodosOcupados));

		let periodoContenido=periodosOcupados.some(item=>{
		let horaInicial=item.horaInicial;
		let horaFinal=item.horaFinal;

		let horarioDesdeContenido=isHorarioDesdeContenidoSolo(horaInicial,horaFinal, this.props.periodo.horaInicial);
		let horarioHastaContenido=isHorarioHastaContenidoSolo(horaInicial,horaFinal, this.props.periodo.horaFinal);

		return (horarioDesdeContenido||horarioHastaContenido);

		});

		return periodoContenido;
	 } 
	

	
	/* 
	12Abril21
	Validar que el periodo que se agrega no este contenido dentro de un periodo existente 
	*/
    render(){    
	
		return <Page setOpenPeriodoDialog={this.props.setOpenPeriodoDialog} 
					 openPeriodoDialog={this.props.openPeriodoDialog}					 
					 dia={this.props.dia}	
					 horaInicial={this.props.periodo.horaInicial} 
					 horaFinal={this.props.periodo.horaFinal} 
					 setHoraInicial={this.setHoraInicial} 
					 setHoraFinal={this.setHoraFinal}					 
					 handleClick={this.props.handleClick}
					 esquemaTipoCitaHorarioList={this.props.esquemaTipoCitaHorarioList}
					 mostrarMensaje={this.props.mostrarMensaje}
					 existeHorario={this.existeHorario}
					 isHorarioCorrecto={this.isHorarioCorrecto}
					 />
    }

	componentDidMount(){
        console.log("componentDidMount (PeriodoDiaDialog)");
	}
    
}

const mapStateToProps=(state,ownProps)=>({
    //horario:state.HorarioReducer[HORARIO].filter(item => item.id === ownProps.esquemaTipoCitaHorarioItem.HORARIO_id).filter(i => i.DIA_SEMANA_id === ownProps.dia_semanaItem.id) ,
    //periodo:state.PeriodoReducer[PERIODO].filter(item => item.id === ownProps.horariosChipItem.PERIODO_id)
	horarioList:state.HorarioReducer[HORARIO],
	periodoList:state.PeriodoReducer[PERIODO]

})

const mapDispatchToProps=(dispatch)=>({
	mostrarMensaje: (valor,mensaje) => dispatch(mostrarMensaje(valor,mensaje))

})
export default connect(mapStateToProps,mapDispatchToProps)(PeriodoDiaDialog);


import * as React from 'react';
import { alpha, Box, Grid, Paper, Snackbar, Table, TableBody, TableCell, TableContainer,TableHead,TableRow, withStyles, } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import Typography from '@material-ui/core/Typography';
import {ESTATUS_EN_SALA_DE_ESPERA, ESTATUS_LISTO_EN_SALA, FEMENINO, MASCULINO} from "../../../utils/constantes"
import { AccountCircle } from '@material-ui/icons';



const TableContentCell = withStyles(theme => ({
  root: {
    color: 'black',
    fontSize:'1.5rem'
  }
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: "1.5rem",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: alpha(theme.palette.action.hover,0.03),
      color: "red",
    },
  },
}))(TableRow);

export default function Page(props) {

let nombrePaciente="";
let nombreMedico="";
let tipoCita="";
let estatusCita="";
let edad="";
let codColorStatus=""
let colorFace="#90a4ae";


if(props.paciente!==undefined){
  edad=props.paciente.edad+"a.";
}

if(props.medico!==undefined){
  nombreMedico=<Typography variant='h6' style={{ textTransform:"uppercase"}}>
                {props.medico.titulo+" "+props.medico.nombres+" "+props.medico.apellidoPaterno}
               </Typography>
}
if(props.paciente!=undefined){
  nombrePaciente=<Typography variant='h6' style={{ textTransform:"uppercase"}}>
                  {props.paciente.nombres+" "+props.paciente.apellidoPaterno+" "+props.paciente.apellidoMaterno} 
                 </Typography>
}

if(props.paciente!==undefined){          
  console.log("props.paciente.sexo:"+props.paciente.SEXO_id);
  if(props.paciente.SEXO_id===MASCULINO){
    colorFace="#00aae4";
  }else if(props.paciente.SEXO_id===FEMENINO){
    colorFace="#fe4164";
  }  
}

if(props.catTipoCita!==undefined){
  tipoCita=props.catTipoCita.nombre;
}

const addObjectToArray = obj => {
  props.setEnSala(current => [...current, obj]);
};

const addListoEnSala = obj => {
  props.setOkSala(current => [...current, obj]);
};


const userExists= (id) =>{
  return props.enSala.some(function(el) {
    return el.id === id;
  }); 
};

const listoEnSalaExists= (id) =>{
  return props.okSala.some(function(el) {
    return el.id === id;
  }); 
};




if(props.catEstadoCita!==undefined){
  estatusCita=props.catEstadoCita.nombre;
  codColorStatus=props.catEstadoCita.codColor;
}

if(props.cita.CAT_ESTADO_CITA_id===ESTATUS_EN_SALA_DE_ESPERA){ 
  if(!userExists(props.cita.id)){
    console.log("LO AGREGA (userExists): "+JSON.stringify(props.cita));
    props.setOpen(true);
    props.setMensaje(nombrePaciente);
    addObjectToArray( props.cita);
    console.log("props (ESTATUS_EN_SALA_DE_ESPERA):"+ JSON.stringify(props.enSala));

  }

}

if(props.cita.CAT_ESTADO_CITA_id===ESTATUS_LISTO_EN_SALA){ 
  if(!listoEnSalaExists(props.cita.id)){
    console.log("LO AGREGA (listoEnSalaExists):"+JSON.stringify(props.cita));
    props.setPlayListoEnSala(true);
    props.setMensaje(nombrePaciente);
    addListoEnSala( props.cita);
    console.log("props (ESTATUS_LISTO_EN_SALA):"+ JSON.stringify(props.okSala));

  }

}




  return ( <StyledTableRow              
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          
            >
              <StyledTableCell align="center" component="td" scope="row"  >                
                {props.index + props.rowsPerPage}
              </StyledTableCell>
              <StyledTableCell align="left" component="td" scope="row" colSpan={2}>
              
              <Grid container spacing={0} p={0} m={0} alignItems="center"
                            style={{borderColor:codColorStatus,borderWidth:"0rem 0rem 0rem 0.6rem",borderStyle:"solid",borderRadius:"0.5rem"}}

               >
                <Grid item xs={2} p={0} m={0}>
                  <AccountCircle style={{ marginRight:".8rem", width: "3.5rem",height: "3.5rem" ,color:colorFace,opacity:"0.6"}} alt={nombrePaciente}/>
                </Grid>
                <Grid item xs={8} p={0} m={0}>
                  {nombrePaciente}  
                </Grid>
                <Grid item xs={2} p={0} m={0}>
                  <Box display="flex" justifyContent="flex-end" m={0} p={0} >
                    <Box m={0} >
                          <Chip  
                            variant="default"                                           
                            size="small"
                            color="secondary"
                            label={ <Typography  style={{ textTransform:"capitalize",fontSize:"medium",fontWeight:"bolder",alignContent:"end"}}>{edad}</Typography>} 
                          />  
                    </Box>
                    
                  </Box>
                </Grid>
              </Grid>
                

              </StyledTableCell>
              
              <TableContentCell align="right">
                         
                           <Chip  
                              variant="default"                                           
                              icon={<QueryBuilderIcon style={{color:"whitesmoke"}}/>} 
                              label={  <Typography variant='h6' style={{ textTransform:"uppercase"}}>{props.cita.horaInicio.substring(0,5)+" - "+props.cita.horaFin.substring(0,5)}</Typography>} 
                              style={{maxWidth: 210,backgroundColor:"#009688",color:"WHITE"}} 

                            />
              </TableContentCell>
              <TableContentCell align="center">
                          <Chip 
                              variant="default" 
                              label={<Typography variant='h6' style={{ textTransform:"uppercase",
                              overflow: "hidden",
                              textOverflow: "ellipsis",                              
                              }}>{tipoCita}</Typography>}
                              size="medium" 
                              style={{padding:"0.3rem",backgroundColor: props.codColor ,color: "whitesmoke",maxWidth: 225}} 
                              />  
              </TableContentCell>
              <TableContentCell align="left">
                          {nombreMedico}                                                                   
              </TableContentCell>
              <TableContentCell align="center">
                         <Chip  
                            variant="outlined"                                           
                            color="secondary"
                            label={ <Typography variant='h6' style={{ textTransform:"uppercase"}}>1</Typography>} 
                          />     
              </TableContentCell>
              <TableContentCell align="center">
                        <Chip  
                            variant="default"                                                                       
                            label={ <Typography variant='h6' 
                            style={{ textTransform:"uppercase",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}>
                              {estatusCita}</Typography>} 
                            size="medium"
                            style={{backgroundColor: codColorStatus ,color: "whitesmoke",maxWidth: 150}}   
                         />
              </TableContentCell>
            </StyledTableRow>
                   
  );
}
import React,{Component} from "react";
import CitaList from "../CitaList";

import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import DisplayTV from "../DisplayTV";
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Paper } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import logoBS from '../../../logo/logo.ico'
import logoInstitucion from '../../../logo/especializada.jpg'
import { Avatar,Chip } from "@material-ui/core";
import {getFechaHoyFormato} from "../../../utils/date";
import DisplayDate from "./DisplayDate";
import LogoFloat from "./LogoFloat";


 function Page(props) {
    
        let loading=props.loading;
        var animacionCargando;
        if (loading) {
            animacionCargando = <Box color="text.primary">
                                    <LinearProgress color="secondary"/>
                                </Box>
           
                               
        }else{
            animacionCargando= <Box color="text.primary">
                                    <LinearProgress variant="determinate" value={0} />
                                </Box>
        }



        return     <div style={{marginBottom:"0rem" , marginTop:"0rem" ,paddingTop:"0rem",paddingRight:"0rem",paddingLeft:"0rem"}}>
                        <DisplayTV citas={props.citaes}/>
                        {animacionCargando}
                        <LogoFloat />
                    </div>  
                            
               
             
}
export default Page;

import React,{Component} from "react";
import Page from "./page";

export default class QRFloatButton extends Component{
    render(){    
        return <Page setQrmodal={this.props.setQrmodal} />
    }

}


import React,{Component} from "react";
import TextField from "@material-ui/core/TextField";
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';

import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Delete from '@material-ui/icons/Delete';

export default class Page extends Component{

    render(){      
      return <List >
              <ListItem dense>
                  
                    <TextField                            
                      id="busqueda"
                      label="Buscar Médico"
                      type="text"
                      fullWidth
                      size="small"
                      variant="outlined" 
                      InputProps={{
                         startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}  
                      onChange={(e)=>this.props.busquedaFuncion(e)}              
                      /> 
              </ListItem>
             </List>     
    }                          
}

import React,{Component} from "react";
import Page from "./page";

export default class PacientesListItem extends Component{
    render(){    
        return< Page movimiento={this.props.movimiento} abrirModalEditar={this.props.abrirModalEditar}/>
    }

}


import React,{Component} from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withRouter} from "react-router-dom";
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

class Page extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }

    goBack(){
        this.props.history.goBack();
    }


    render(){
        return (<form onSubmit={this.props.handleSubmit}> 
                    <Dialog
                    open={this.props.modal}
                    onClose={this.props.cerrarModal}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
                    fullScreen

                    >
                    <AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.goBack} aria-label="close">
                            <ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
                            </IconButton>
                            <Typography variant="h6" style={{ flexGrow: 1}} >
                                {<Typography type="Roboto" style={{flexGrow:"1",color: '#000000', fontSize:'1.2rem',fontWeight:'normal',textTransform: "capitalize" }}>Nueva Medición </Typography>}
                            </Typography>

                            <IconButton edge="start" color="inherit" onClick={this.props.handleSubmit} aria-label="close">
                                <DoneIcon  style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}} />
                            </IconButton>
                        </Toolbar>
                        <Divider/>
                        </AppBar>

                        <DialogTitle id="form-dialog-title">Agregar Hospital</DialogTitle>
                        
							<DialogContent>
							<TextField
								error={this.props.touched.valor&&this.props.errors.valor? true:false}
								helperText={this.props.touched.valor&&this.props.errors.valor?this.props.errors.valor:""}
								size="medium"
								margin="normal"
								id="valor"
								label="Glucosa en Sangre:"
								type="number"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.valor}
								onBlur={this.props.handleBlur}
								autoComplete="off"
								InputProps={{
									endAdornment: <InputAdornment position="end">mg/dl</InputAdornment>
								  }}
							/>
							
							<TextField
								error={this.props.touched.fecha&&this.props.errors.fecha? true:false}
								helperText={this.props.touched.fecha&&this.props.errors.fecha?this.props.errors.fecha:""}
								size="medium"
								margin="normal"
								id="fecha"
								label="Fecha:"
								type="date"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.fecha}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.hora&&this.props.errors.hora? true:false}
								helperText={this.props.touched.hora&&this.props.errors.hora?this.props.errors.hora:""}
								size="medium"
								margin="normal"
								id="hora"
								label="Hora:"
								type="time"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.hora}
								onBlur={this.props.handleBlur}
							/>
							<TextField
								error={this.props.touched.nota&&this.props.errors.nota? true:false}
								helperText={this.props.touched.nota&&this.props.errors.nota?this.props.errors.nota:""}
								size="medium"
								margin="normal"
								id="nota"
								label="NOTA(opcional):"
								type="area"
								fullWidth
								variant="outlined"
								onChange={this.props.handleChange} value={this.props.values.nota}
								onBlur={this.props.handleBlur}
								multiline
								rows={4}
								rowsMax={6}
								
							/>


                            </DialogContent>
                                         
                    </Dialog>
                    </form>
                       
                )
    }
}
export default  withRouter(Page);

import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {CITA,RECORDATORIO,CAT_ESTADO_CITA,CAT_TIPO_CITA,PACIENTE,MEDICO,ESQUEMA_TRABAJO,ESQUEMA_TIPO_CITA,MEDICO_USUARIO,INSTITUCION, COLOR} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { actualizarCitaWSAction } from "../../../redux/actions/CitaActions";

class QRScanDialog extends Component{
 
    
    render(){    

        let colorItem=[];
        if(this.props.catTipoCita!==undefined){
            colorItem=this.props.colores.filter(i => i.id === this.props.catTipoCita.COLOR_id)[0];
        }

        let codColor="";
        if(colorItem!==undefined){
            codColor=colorItem.color;
        }

        return <Page idPacienteValor={this.props.idPacienteValor}
                     setIdPacienteValor={this.props.setIdPacienteValor}
                     cita={this.props.cita}
                     paciente={this.props.paciente}
                     medico={this.props.medico}
                     catTipoCita={this.props.catTipoCita} 
                     codColor={codColor}                         
                     setQrmodal={this.props.setQrmodal}
                     actualizarCitaWSAction={this.props.actualizarCitaWSAction}  
                       />

    }            

    componentDidMount(){            
    }

}

const mapStateToProps=(state,ownProps)=>({
    paciente: state.PacienteReducer[PACIENTE].filter(p => p.id === ownProps.cita.PACIENTE_id)[0],
    medico:state.MedicoReducer[MEDICO].filter(m => m.id === ownProps.cita.MEDICO_id)[0],
    catTipoCita:state.CatTipoCitaReducer[CAT_TIPO_CITA].filter(p => p.id === ownProps.cita.CAT_TIPO_CITA_id)[0],
    colores:state.ColorReducer[COLOR],
  
})


const mapDispatchToProps = (dispatch, ownProps) => ({
    actualizarCitaWSAction: (type,payload) => dispatch(actualizarCitaWSAction(type,payload)),
})


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(QRScanDialog));
import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {CAT_TIPO_CITA,COLOR} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";


const validationSchema = yup.object({
		nombre: yup.string().required("Campo requerido").matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u,
        'No se permiten caracteres especiales').max(45, 'debe ser menor a 45 caracteres'),
		descripcion: yup.string().required("Campo requerido").max(45, 'debe ser menor a 45 caracteres'),
		duracion: yup.string().required("Campo requerido"),
		COLOR_id: yup.string().required("Campo requerido")
})

class CatTipoCitaFormEdit extends Component{

    constructor(props) {
        super(props);
        this.state={
                modalConfirmacion:false,
        };
    }
    abrirConfirmacion=()=> {
        this.setState({
            modalConfirmacion:true
        });
    }
    cerrarConfirmacion=()=> {
        this.setState({
            modalConfirmacion:false
        });
    }

    eliminar=(dato)=> {
        this.cerrarConfirmacion();
        this.props.cerrarModalEditar();
        this.props.eliminarCatTipoCitaWSAction(CAT_TIPO_CITA,dato);
    }

    
    render(){    
    
        let valores=this.props.payload;

        return <Formik
                    enableReinitialize
                    initialValues={valores}
                    validationSchema={validationSchema}
                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          resetForm({values:""});
                          setSubmitting(this.props.actualizarCatTipoCitaWSAction(CAT_TIPO_CITA,values));
                         this.props.history.goBack();
                      }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    setFieldValue
                    }) => <Page setFieldValue={setFieldValue} 
                                 handleSubmit={handleSubmit} 
                                 handleChange={handleChange} 
                                 handleBlur={handleBlur} 
                                 values={values} 
                                 errors={errors} 
                                 touched={touched}
                                 color={this.props.color}   
                                 modalEditar={this.props.modalEditar} 
                                 tipoSeleccion={this.props.tipoSeleccion} 
                                cerrarModalEditar={this.props.cerrarModalEditar} 
                                eliminar={this.eliminar} 
                                payload={this.props.payload}
                                actualizarCatTipoCitaWSAction={this.props.actualizarCatTipoCitaWSAction}  
                                modalConfirmacion={this.state.modalConfirmacion}
                                cerrarConfirmacion={this.cerrarConfirmacion}
                                abrirConfirmacion={this.abrirConfirmacion} />}
                </Formik> 
    }



}

const mapStateToProps=(state)=>({
  color:state.ColorReducer[COLOR],
})

export default withRouter(connect(mapStateToProps)(CatTipoCitaFormEdit));
import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import { PACIENTE,CAT_TIPO_CITA,COLOR, MEDICO, CAT_ESTADO_CITA } from "../../../redux/constants/action-type";
import {obtenerColorListWSAction} from "../../../redux/actions/ColorActions";


class CitaListItem extends Component{
    
    constructor(props) {
        super(props);
    }
    render(){    

        let colorItem=[];
        if(this.props.catTipoCita!==undefined){
            colorItem=this.props.colores.filter(i => i.id === this.props.catTipoCita.COLOR_id)[0];
        }

        let codColor="";
        if(colorItem!==undefined){
            codColor=colorItem.color;
        }
       

        return <Page    cita={this.props.cita} 
                        paciente={this.props.paciente} 
                        medico={this.props.medico}
                        catTipoCita={this.props.catTipoCita} 
                        catEstadoCita={this.props.catEstadoCita}
                        codColor={codColor}                         
                        abrirModalEditar={this.props.abrirModalEditar}  
                        item={this.props.item}
                        />
    }



}

const mapStateToProps = (state, ownProps) => ({
  paciente: state.PacienteReducer[PACIENTE].filter(p => p.id === ownProps.cita.PACIENTE_id)[0],
  medico:state.MedicoReducer[MEDICO].filter(m => m.id === ownProps.cita.MEDICO_id)[0],
  catTipoCita:state.CatTipoCitaReducer[CAT_TIPO_CITA].filter(p => p.id === ownProps.cita.CAT_TIPO_CITA_id)[0],
  catEstadoCita:state.CatEstadoCitaReducer[CAT_ESTADO_CITA].filter(p => p.id === ownProps.cita.CAT_ESTADO_CITA_id)[0],
  colores:state.ColorReducer[COLOR],
 })

 


 export default connect(mapStateToProps,null)(CitaListItem);
import { Card, CardContent, Container, makeStyles, Typography } from "@material-ui/core";
import React,{Component} from "react";
import { diaFinal, diaInicial } from "../../../utils/calendario";
import Calendario from "../../CalendarioComponent/Calendario";
import RegistroForm from "../RegistroForm";

function Page(props){


    const useStyles  = () => makeStyles(theme => ({
        root: {
            maxWidth: 345,
            flexDirection:"column",
            borderRadius:"19px",
            position:"relative",
            display:"flex"

        },
        card: {
            maxWidth: 345,
            padding: theme.spacing.unit,
            textAlign: "center",
            color: theme.palette.text.secondary,
            marginBottom: theme.spacing.unit
        },
        pos: {
          margin: 12,
        },
        }));
    const classes = useStyles(); 
  
      return <div style={{marginBottom:"5rem" , marginTop:"5rem" }}>
              {(props.medicoRegistrado!=="")?
                <Container maxWidth="sm" className={classes.container}>
                  <Card className={classes.root}>
                    <CardContent>                   
                      <Typography variant="body1" style={{marginBottom:"1rem" , marginTop:"1rem" }}>
                        Estimado@ {props.medicoRegistrado},
                        su segistro fue exitoso.
                      </Typography>
                      <Typography color="textSecondary" style={{marginBottom:"1rem" , marginTop:"1rem" }}>
                        Se ha enviado su información para verificación.
                      </Typography>
                      <Typography variant="body2" style={{marginBottom:"1rem" , marginTop:"1rem" }}>
                        En breve nos comunicaremos con usted.
                      </Typography>
                    </CardContent>
                  </Card>
                </Container>:
                <RegistroForm 
                    autenticacionLogin={props.autenticacionLogin} 
                    errorsForm={props.errorsForm} 
                    usuario={props.usuario}
                    loading={props.loading}
                    agregarMedicoWSAction={props.agregarMedicoWSAction}
                    setMedicoRegistrado={props.setMedicoRegistrado}

                  />
              }     
             </div>
                            
}

export default Page;

import React,{Component} from "react";
import Documento from "./page";

export default class PresionArterialDocument extends Component{
    render(){    
        return <Documento presionArteriales={this.props.presionArteriales} />
    }

}


import { Box, Card, CardContent, Container, makeStyles, Paper, Typography } from "@material-ui/core";
import { repeat } from "lodash";
import moment from "moment";
import React,{Component} from "react";
import Dia from "../Dia";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gridGap: theme.spacing(0),
  },

}));

function Page(props){
  
  const classes = useStyles();
  
      return <div className={classes.container}>
              
              {
                props.diasSemanaArray.map((item,index)=>
                  <Paper key={index}  style={{display:"flex",justifyContent:"center",color:"",borderRadius:"0",borderLeft:"0"}} variant="outlined">
                    <Typography color="primary" variant="caption">{moment().day(index).format("ddd")}</Typography>
                  </Paper>
                )
              }
              {
                props.diasArray.map((item,index)=>
                   <Dia 
                    hoy={props.hoy}
                    key={index} 
                    dia={item}/>
                )
             }
           </div>
  
                            
}

export default Page;

import React,{Component} from "react";
import Page from "./page";
import {Formik} from "formik";
import * as yup from 'yup'; // for everything
import {PROCEDIMIENTO,CAT_TIPO_PROCEDIMIENTO,INSTITUCION,TECNICO,MEDICO,PACIENTE} from "../../../redux/constants/action-type";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";


const validationSchema = yup.object({
		descripcion: yup.string().required("Campo requerido"),
		PACIENTE_id: yup.string().required("Campo requerido"),
		MEDICO_id: yup.string().required("Campo requerido"),
		duracion: yup.string().required("Campo requerido"),
		CAT_TIPO_PROCEDIMIENTO_id: yup.string().required("Campo requerido"),
		TECNICO_id: yup.string().required("Campo requerido"),
		INSTITUCION_id: yup.string().required("Campo requerido")
})

class ProcedimientoForm extends Component{
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this); // i think you are missing this
     }
    
    goBack(){
        this.props.history.goBack();
    }

    render(){        
        return <Formik

                    initialValues={{
		'descripcion': '',
		'PACIENTE_id': '',
		'MEDICO_id': '',
		'duracion': '',
		'CAT_TIPO_PROCEDIMIENTO_id': '',
		'TECNICO_id': '',
		'INSTITUCION_id': ''
                                   }}

                    validationSchema={validationSchema}

                    onSubmit={(values,{setSubmitting, resetForm,stopPropagation}) => {
                          setSubmitting(this.props.agregarProcedimientoWSAction(PROCEDIMIENTO,values));
                          console.log("(setSubmitting)valores "+JSON.stringify(values));                              
                          resetForm({values:""});
                          this.goBack();

                    }}
                >
                    {props => < Page {...props}  paciente={this.props.paciente}  medico={this.props.medico}  catTipoProcedimiento={this.props.catTipoProcedimiento}  tecnico={this.props.tecnico}  instituciones={this.props.instituciones}   modal={this.props.modal} cerrarModal={this.props.cerrarModal} />}
                </Formik> 
    }
}

const mapStateToProps=(state)=>({
  paciente:state.PacienteReducer[PACIENTE],
  medico:state.MedicoReducer[MEDICO],
  catTipoProcedimiento:state.CattipoprocedimientoReducer[CAT_TIPO_PROCEDIMIENTO],
  tecnico:state.TecnicoReducer[TECNICO],
  instituciones:state.InstitucionReducer[INSTITUCION],
})

export default withRouter(connect(mapStateToProps)(ProcedimientoForm));

import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import { MEDICO } from "../../../redux/constants/action-type";

class PacientesListItem extends Component{
    render(){    
        return< Page esquemaTrabajo={this.props.esquemaTrabajo} abrirModalEditar={this.props.abrirModalEditar}
                     medico={this.props.medico}/>
    }

}

const mapStateToProps = (state, ownProps) => ({
    medico:state.MedicoReducer[MEDICO].filter(med => med.id === ownProps.esquemaTrabajo.MEDICO_id)[0],
   })
export default connect(mapStateToProps)(PacientesListItem);
import {connect} from "react-redux";
import React,{Component} from "react";
import Page from "./page";
import {getMesNombre,getDiaSemanaNombre,getFechaHoraString,getFormatoDesdeDate} from "../../../utils/date";
import { CAT_TIPO_CITA, CITA, COLOR } from "../../../redux/constants/action-type";
import { isHorarioIntersect } from "../../../utils/moment";
import {mostrarMensaje} from "../../../redux/actions/stateActions"


class ProcedimientoChipFilter extends Component{
 

    render(){
        let colorItem=[];
        if(this.props.catTipoCita!==undefined){
            colorItem=this.props.colores.filter(i => i.id === this.props.catTipoCita.COLOR_id)[0];
        }

        let codColor="";
        if(colorItem!==undefined){
            codColor=colorItem.color;
        }

        return<Page 
                colores={this.props.colores}    
                procedimientos={this.props.procedimientos}
                setProcedimientos={this.props.setProcedimientos}
                    />
    }

}


const mapStateToProps=(state)=>({
    colores:state.ColorReducer[COLOR],
})

const mapDispatchToProps=(dispatch)=>({

})

export default connect(mapStateToProps,mapDispatchToProps)(ProcedimientoChipFilter);
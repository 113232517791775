import {CONSULTAR_CAT_UNIDAD_MEDIDAES,AGREGAR_CAT_UNIDAD_MEDIDA,ACTUALIZAR_CAT_UNIDAD_MEDIDA, ELIMINAR_CAT_UNIDAD_MEDIDA} from "../constants/CatUnidadMedida-action-type";


/* CAT_UNIDAD_MEDIDAES (HOSPITAL CLINICA) */
export const obtenerCatUnidadMedidaListWSAction = (dataType,loading)=> {
    return {
        type:CONSULTAR_CAT_UNIDAD_MEDIDAES,
        dataType:dataType,
        loading: loading
    }
}

export const agregarCatUnidadMedidaWSAction = (dataType,payload)=> {
    return {
        type:AGREGAR_CAT_UNIDAD_MEDIDA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const actualizarCatUnidadMedidaWSAction = (dataType,payload)=> {
    return {
        type:ACTUALIZAR_CAT_UNIDAD_MEDIDA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

export const eliminarCatUnidadMedidaWSAction = (dataType,payload)=> {
    return {
        type:ELIMINAR_CAT_UNIDAD_MEDIDA,
        dataType:dataType,
        loading: true,
        payload:payload
    }
}

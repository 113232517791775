import React from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withRouter} from "react-router-dom";
import Divider from '@material-ui/core/Divider';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
const Page=(props)=>{
		
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	function goBack(){
		props.history.goBack();
	}

	let appBar="";
	let actions="";

	if(fullScreen){
		appBar=<AppBar color="default" style={{boxShadow: "none",backgroundColor:"#FFFFFF"}}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={goBack} aria-label="close">
						<ArrowBackIcon style={{ color:"#0288D1", borderWidth:"1",borderColor:"#0288D1",width: "2.0rem",height: "2.0rem"}}  />
						</IconButton>
						<Typography variant="h6" style={{ flexGrow: 1}} >
							{<Typography type="Roboto" style={{flexGrow:"1",color: '#000000', fontSize:'1.2rem',fontWeight:'normal',textTransform: "capitalize" }}>Nueva PaginaWeb</Typography>}
						</Typography>

						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>

					</Toolbar>
					<Divider/>
				</AppBar>

	}else{
		actions=<MuiDialogActions>
						<Button variant="contained"  edge="start" color="default" onClick= {props.cerrarModal}>Cancelar</Button>
						<Button variant="contained"  edge="start" color="primary" onClick= {props.handleSubmit}>Aceptar</Button>
				</MuiDialogActions>
	}

    return (<form onSubmit={props.handleSubmit}> 

                    <Dialog
                    open={props.modal}
                    onClose={props.cerrarModal}
                    aria-labelledby="form-dialog-title"
                    TransitionComponent={Transition}
        			fullScreen={fullScreen}


                    >

                        <DialogTitle id="form-dialog-title">Agregar PaginaWeb</DialogTitle>
                        
						{appBar}


							<DialogContent>
							<TextField
								error={props.touched.titulo&&props.errors.titulo? true:false}
								helperText={props.touched.titulo&&props.errors.titulo?props.errors.titulo:""}
								size="medium"
								margin="normal"
								id="titulo"
								label="titulo:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.titulo}
								onBlur={props.handleBlur}
							/>
							<TextField
								error={props.touched.descripcion&&props.errors.descripcion? true:false}
								helperText={props.touched.descripcion&&props.errors.descripcion?props.errors.descripcion:""}
								size="medium"
								margin="normal"
								id="descripcion"
								label="descripcion:"
								type="text"
								fullWidth
								variant="outlined"
								onChange={props.handleChange} value={props.values.descripcion}
								onBlur={props.handleBlur}
							/>


                            </DialogContent>
                 		{actions}                        
                    </Dialog>
                    </form>
                       
                )
}
export default  withRouter(Page);

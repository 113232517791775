import React,{Component} from "react";
import Page from "./page";
import {connect} from "react-redux";
import { PERIODO } from "../../../redux/constants/action-type";

class HorariosChipListItemForm extends Component{
    constructor(props) {
        super(props);
        this.state={
                modalConfirmacion:false,
        };
    }

    setModalDialog=(valor)=> {
        this.setState({
            modalConfirmacion:valor
        });
    }
    render(){ 

        return <Page 
                    modalConfirmacion={this.state.modalConfirmacion}
                    setModalDialog={this.setModalDialog}
                    periodo={this.props.periodo} 
                    dia_semanaItem={this.props.dia_semanaItem}
                    setHorario={this.props.setHorario}
                    setOpenPeriodoEditDialog={this.props.setOpenPeriodoEditDialog}
                    setPeriodo={this.props.setPeriodo}
                    handleEliminarPeriodoClick={this.props.handleEliminarPeriodoClick}
                    />
    }
   

}
/*UTILIZA LAS PROPIEDADES PASADAS */
const mapStateToProps=(state,ownProps)=>({
    periodo:state.PeriodoReducer[PERIODO].filter(item => item.id === ownProps.horariosChipItem.PERIODO_id)

})



export default connect(mapStateToProps,null)(HorariosChipListItemForm);



import React,{Component, useEffect} from "react";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import DiaSemanaList from "../DiaSemanaList"
import "./estilo.css"
import Typography from '@material-ui/core/Typography';
import { Accordion, AccordionSummary, Button, Chip } from "@material-ui/core";
import { getFechatoLocaleDateString, getHorarioLocaleString } from "../../../utils/date";
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import FechaDeBusquedaTextField from "../FechaDeBusquedaTextField";
import { Edit } from "@material-ui/icons";
import SeleccionHorarioCita from "../SeleccionHorarioCita";

function Page (props){

  const MINUTE_MS = 400;

  const bottomRef = React.useRef();

  const [expanded, setExpanded] = React.useState(false);

  const scrollToBottom = () => {  
		if (bottomRef.current) {
			console.log(">>scrollToBottom()<<:");
			bottomRef.current.scrollIntoView({behavior: "smooth"});
		}
	}

  const handleClickExpanded = () => {
    setExpanded((prev) => !prev);

    const interval = setTimeout(() => {
      console.log('Logs every minute');
      scrollToBottom();
    }, MINUTE_MS);

    return () => clearInterval(interval);
  };


  let citaDato="";

  if(props.fecha!==""&&props.horaInicio!==""){
    citaDato=<Box>
                CITA 
                <Box display={{ xs: 'block', sm: 'flex' }} >
                  <Typography  style={{ textTransform:"none", marginRight:"6px",color:"#ff7f16"}}>{getFechatoLocaleDateString(props.fecha)} </Typography>
                  <Chip  
                      variant="outlined"                                           
                      size="medium"
                      color="secondary"
                      icon={<QueryBuilderIcon  />} 
                      label={ <Typography  style={{ textTransform:"lowercase",color:"#1dbd9e"}}>{getHorarioLocaleString(props.horaInicio)+"-"+getHorarioLocaleString(props.horaFin)}</Typography>}
                      style={{maxWidth: 260,backgroundColor:"whitesmoke"}} 

                    />
                </Box>  
              </Box>
  }

  useEffect(() => {
    if(props.fecha!==""&&props.horaInicio!==""){
      setExpanded(false);          
    }else{
      setExpanded(true);         
    } 
    console.log(">>useEffect(setExpanded)<<:");

  }, []);
  
  let card="";
  if(expanded){

      card=<Card  	variant="outlined"  >
              <CardContent >
                <FechaDeBusquedaTextField
                  fechaBusqueda={props.fechaBusqueda}                  
                  setFechaBusqueda={props.setFechaBusqueda}
                  label="FECHA"
                />  

               <SeleccionHorarioCita 
                  codColor={props.codColor} 
                  duracionTipoCita={props.duracionTipoCita}
  
                  fecha={props.fecha} 
                  fechaBusqueda={props.fechaBusqueda} 
                  setFechaBusqueda={props.setFechaBusqueda} 
                  setFieldValue={props.setFieldValue} 
                  horaInicio={props.horaInicio} 
                  horaFin={props.horaFin}
                  idEsquemaTipoCita={props.idEsquemaTipoCita}  
               
               />
              </CardContent>
            </Card>   
  }else{
    card=<div>hpal</div>
  }

      return <Accordion 
              variant="outlined" 
              expanded={expanded}                        
              style={{borderColor:"dodgerblue",marginTop:"1rem"}}
             >
              <AccordionSummary
                expandIcon={<Edit />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                IconButtonProps={{
                  onClick: handleClickExpanded
                }}
              >
                {citaDato}
              </AccordionSummary>              
              
              {card}
              <div ref={bottomRef}/>
             </Accordion>        
              
              
}

export default Page;